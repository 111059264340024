import React from 'react';
import { times } from 'lodash';

const LoadingSkeleton = () => {
  return (
    <div data-testid='overview-loading-skeleton' className='builder-p-5 builder-w-1/2'>
      <div className='builder-flex builder-items-center builder-mb-5'>
        <div className='builder-mr-6 builder-w-12 builder-bg-gray-100 builder-animate-pulse builder-h-8' />
        <div className='builder-mr-4 builder-w-36 builder-bg-gray-100 builder-animate-pulse builder-h-12' />
        <div className='builder-mr-4 builder-w-36 builder-bg-gray-100 builder-animate-pulse builder-h-12' />
        <div className='builder-w-36 builder-bg-gray-100 builder-animate-pulse builder-h-12' />
      </div>

      <div className='builder-flex builder-flex-col builder-justify-center builder-space-y-4 builder-max-w-7xl builder-min-w-min builder-pb-5 builder-space-y-2'>
        {times(20, (item) => {
          return <div key={item} className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />;
        })}
      </div>
    </div>
  );
};

export default LoadingSkeleton;
