import { actionTypes } from './reducer';

// Question group loading state setters
export const questionGroupSetLoadingHandler = () => ({
  type: actionTypes.QUESTION_GROUP_SET_LOADING
});

export const questionGroupResetLoadingHandler = () => ({
  type: actionTypes.QUESTION_GROUP_RESET_LOADING
});

export const questionGroupSetSavingHandler = () => ({
  type: actionTypes.QUESTION_GROUP_SET_SAVING
});

export const questionGroupResetSavingHandler = () => ({
  type: actionTypes.QUESTION_GROUP_RESET_SAVING
});

export const questionGroupSetInitializedHandler = () => ({
  type: actionTypes.QUESTION_GROUP_SET_INITIALIZED
});
//

// Question groups data setters
export const questionGroupInitializeHandler = (id) => ({
  type: actionTypes.QUESTION_GROUP_INITIALIZE,
  payload: {
    id
  }
});

export const questionGroupSetHandler = (questionGroup) => ({
  type: actionTypes.QUESTION_GROUP_SET,
  payload: {
    questionGroup
  }
});

export const questionGroupSetTitleHandler = (title, language) => ({
  type: actionTypes.QUESTION_GROUP_SET_TITLE,
  payload: {
    title,
    language
  }
});

export const questionGroupSetCategoryHandler = (categoryId) => ({
  type: actionTypes.QUESTION_GROUP_SET_CATEGORY,
  payload: {
    categoryId
  }
});

export const questionGroupSetSaveCompletedHandler = () => ({
  type: actionTypes.QUESTION_GROUP_SET_SAVE_COMPLETED
});

export const questionGroupUndoChangesHandler = () => ({
  type: actionTypes.QUESTION_GROUP_UNDO_CHANGES
});

//

// Categories loading state setters
export const categoriesSetFetchingHandler = () => ({
  type: actionTypes.CATEGORIES_SET_FETCHING
});

export const categoriesResetFetchingHandler = () => ({
  type: actionTypes.CATEGORIES_RESET_FETCHING
});
//

// Categories data setter
export const categoriesSetHandler = (categories) => ({
  type: actionTypes.CATEGORIES_SET,
  payload: {
    categories
  }
});

export const categoriesAddHandler = (category) => ({
  type: actionTypes.CATEGORIES_ADD,
  payload: {
    category
  }
});
//
