import React, { useEffect, useRef } from 'react';
import PageSeoForm from './PageSeoForm';
import PageSeoOverview from './PageSeoOverview';
import ButtonWithIndicator from '../../../../components/common/ButtonWithIndicator';

const PageSeo = ({ page, pageSeos, pageSeo, onDelete, onEdit, onInitialize, onCloseEditor, onUpdateContent, onUpdate, onUpdateFilter, deleting, saving, isDuplicatedPageSeoKey, context }) => {
  const pageSeoFormRef = useRef(null);

  // Effect to scroll to form when pageSeo id exist (when its clicked to Add new)
  useEffect(() => {
    if (pageSeo.id && pageSeoFormRef.current) {
      pageSeoFormRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
    }
  }, [pageSeo.id]);

  return (
    <div className='builder-w-full builder-relative'>
      {saving && <div data-testid='page-seo-saving-overlay' className='builder-bg-gray-200 builder-animate-pulse builder-absolute builder-inset-0 builder-z-10' />}
      <div className='builder-text-2xl builder-text-black builder-font-bold builder-mb-5'>Custom seo text for page</div>
      <div className='builder-space-y-4'>
        <div className='builder-w-full md:builder-w-full builder-max-w-2xl'>
          <div className='builder-flex builder-items-center builder-mb-2'>
            <div className='builder-text-black builder-text-[18px] builder-font-bold builder-mr-4'>Current seo texts per filter</div>
            <ButtonWithIndicator
              dataTestId='page-seo-add-custom-text-button'
              onClick={onInitialize}
              icon='fal fa-plus'
              text='Add new'
              colorClass='builder-bg-primary builder-text-white'
              borderClass='builder-border builder-border-bg-blue-500'
              className='builder-ml-0 builder-mr-0'
            />
          </div>
          <PageSeoOverview selectedPageSeo={pageSeo} pageSeos={pageSeos} context={context} onDelete={onDelete} deleting={deleting} onEdit={onEdit} isDuplicatedPageSeoKey={isDuplicatedPageSeoKey} />
        </div>

        {pageSeo?.id && (
          <div data-testid='page-seo-content-form'>
            <div className='builder-py-4'>
              <div className='builder-h-px builder-bg-gray-200' />
            </div>

            <div className='builder-flex builder-justify-between builder-mb-2'>
              <div className='builder-flex builder-items-center'>
                <div className='builder-text-black builder-text-[18px] builder-font-bold'>Add/Edit page seo text per filter</div>
                <div className='builder-mx-4'>|</div>
                <div className='builder-cursor-pointer hover:builder-text-gray-900' onClick={onCloseEditor}>
                  <i className='fal fa-times' /> <span>Close</span>
                </div>
              </div>
              {isDuplicatedPageSeoKey && <div className='builder-flex builder-justify-self-end builder-text-red-500'>Page seo with this key already exists.</div>}
            </div>
            <div className='builder-leading-[20px] builder-mb-5'>
              Choose filters where you want to show the custom seo text. If you want to be more generic, then choose 'All' in the dropdown target all filters from that group. <br />
              For example, in make dropdown choose 'All' and then in the custom text you can use {'{{make}}'} and it will be replaced with the real make name.
            </div>

            <PageSeoForm pageSeo={pageSeo} onUpdateContent={onUpdateContent} onUpdate={onUpdate} onUpdateFilter={onUpdateFilter} context={context} />
          </div>
        )}

        <div ref={pageSeoFormRef} />
      </div>
    </div>
  );
};

export default PageSeo;
