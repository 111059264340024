import React, { useState } from 'react';
import TextInput from '../builder/editors/components/TextInput';
import ImageUrlFieldEditor from '../builder/editors/ImageUrlFieldEditor';

const FeatureImageEditor = ({ image, onChange }) => {
  const [subComponent, setSubComponent] = useState(undefined);

  const handleChangeField = (name, value) => {
    onChange({
      ...image,
      [name]: value
    });
  };

  return (
    <div className='builder-overflow-visible'>
      {subComponent === undefined && (
        <React.Fragment>
          <div className='builder-flex builder-flex-col builder-p-2  builder-bg-white builder-p-4'>
            <div className='builder-flex builder-flex-1 builder-flex-col builder-text-sm builder-pr-2'>
              <div className='builder-flex builder-flex-col builder-mb-2 builder-cursor-pointer'>
                <div className='builder-flex builder-flex-col builder-mb-6'>
                  <div
                    className={`builder-draggable-item builder-flex builder-flex-row builder-border-b builder-border-gray-300 builder-p-4 hover:bg-gray-100 builder-items-center  builder-bg-white builder-cursor-pointer`}
                    onClick={() => {
                      setSubComponent('mobile');
                    }}
                  >
                    <div className='builder-flex builder-flex-1 builder-items-center builder-text-sm builder-pr-2 builder-font-bold builder-text-primary'>
                      <i className='fal fa-edit builder-mr-4' /> mobile
                    </div>
                  </div>
                </div>

                <div className='builder-flex builder-flex-col builder-mb-6'>
                  <div
                    className={`builder-draggable-item builder-flex builder-flex-row builder-border-b builder-border-gray-300 builder-p-4 hover:bg-gray-100 builder-items-center  builder-bg-white builder-cursor-pointer`}
                    onClick={() => {
                      setSubComponent('tablet');
                    }}
                  >
                    <div className='builder-flex builder-flex-1 builder-items-center builder-text-sm builder-pr-2 builder-font-bold builder-text-primary'>
                      <i className='fal fa-edit builder-mr-4' /> tablet
                    </div>
                  </div>
                </div>

                <div className='builder-flex builder-flex-col builder-mb-6'>
                  <div
                    className={`builder-draggable-item builder-flex builder-flex-row builder-border-b builder-border-gray-300 builder-p-4 hover:bg-gray-100 builder-items-center  builder-bg-white builder-cursor-pointer`}
                    onClick={() => {
                      setSubComponent('desktop');
                    }}
                  >
                    <div className='builder-flex builder-flex-1 builder-items-center builder-text-sm builder-pr-2 builder-font-bold builder-text-primary'>
                      <i className='fal fa-edit builder-mr-4' /> desktop
                    </div>
                  </div>
                </div>

                <div className='builder-flex builder-flex-col builder-mb-6'>
                  <div className='builder-font-bold mb-2 builder-uppercase builder-text-primary'>Alt text:</div>
                  <div className='builder-text-sm builder-font-medium  builder-mb-2'>
                    <TextInput placeholder='Image alt text' value={image.alt} onChanged={(value) => handleChangeField('alt', value)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}

      {subComponent && (subComponent === 'mobile' || subComponent === 'tablet' || subComponent === 'desktop') && (
        <ImageUrlFieldEditor
          value={image[subComponent]}
          type='image'
          className='builder-bg-white'
          definition={{ description: 'Feature image' }}
          breadcrumbs={[subComponent]}
          onChange={(url) => handleChangeField(subComponent, url)}
          onBack={(index) => {
            setSubComponent(undefined);
          }}
        />
      )}
    </div>
  );
};

export default FeatureImageEditor;
