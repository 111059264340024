import React from 'react';
import { routes } from '../../../data/constants';
import { Link } from 'react-router-dom';
import ButtonWithIndicator from '../../../../components/common/ButtonWithIndicator';

const Header = ({ loading, onSave, context }) => {
  return (
    <div className='builder-flex builder-w-full builder-justify-between py-5 builder-sticky builder-top-0 builder-z-40' style={{ backgroundColor: 'rgb(222, 226, 236)' }}>
      <div className='builder-flex builder-flex-row builder-items-center '>
        <Link
          to={routes.CUSTOM_BLOCKS}
          data-testid='custom-block-details-back-to-overview-button'
          className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-cursor-pointer opacity-100 builder-mr-4'
        >
          <i className='fa fa-chevron-left builder-mr-2' />
          Overview
        </Link>
        <div className='builder-text-2xl builder-text-black builder-font-bold builder-mr-4'>Custom block detail</div>
      </div>

      <div className='builder-flex builder-space-x-4 builder-items-center'>
        {/*<button className="btn btn-text-danger" onClick={onDelete}>*/}
        {/*  <i className="fal fa-trash builder-mr-2" />*/}
        {/*  Delete*/}
        {/*</button>*/}
        <ButtonWithIndicator
          dataTestId='custom-block-add-button'
          loading={loading}
          disabled={loading}
          onClick={onSave}
          icon='fal fa-save'
          text='Save'
          colorClass='builder-bg-primary builder-text-white'
          borderClass='builder-border builder-border-bg-blue-500'
          className='builder-ml-0 builder-mr-0'
        />

        {/*<button className="btn btn-primary" onClick={onSave}>*/}
        {/*  <i className="fal fa-save builder-mr-2" />*/}
        {/*  Save*/}
        {/*</button>*/}
      </div>
    </div>
  );
};

export default Header;
