import React, { useState } from 'react';
import TextArea from '../editors/components/TextArea';
import TextInput from '../editors/components/TextInput';
import FeatureImageEditor from '../../blog/FeatureImageEditor';

const initial = {
  _type: 'blog-overview',
  title: '',
  text: '',
  image: null
};

const PageProperties = ({ data, onChange, context }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleChangeValue = (name, value) => {
    onChange({
      ...initial,
      ...data,
      [name]: value
    });
  };

  return (
    <div className='builder-flex builder-flex-col'>
      <div className='builder-flex builder-justify-between builder-items-center builder-py-2 builder-border-b builder-border-gray-300 builder-shadow-sm builder-cursor-pointer' onClick={() => setIsOpen(!isOpen)}>
        <h3 className='builder-font-bold builder-text-lg builder-ml-4'>Blog overview</h3>
        <i className={`fas builder-mr-4 builder-text-md ${isOpen ? 'fa-caret-up' : 'fa-caret-down'}`} />
      </div>
      <div className={`${isOpen ? 'builder-flex builder-flex-col' : 'builder-hidden'}`}>
        <div className=''>
          <div className='builder-flex builder-flex-col builder-p-4'>
            <label className='builder-block builder-text-gray-700 builder-text-md builder-font-bold builder-mb-2 '>Title</label>

            <TextInput className='builder-border builder-border-gray-300 builder-rounded-md' value={data && data.title ? data.title : ''} onChanged={(value) => handleChangeValue('title', value)} />

            <label className='builder-block builder-text-gray-700 builder-text-md builder-font-bold builder-mb-2 builder-mt-2 builder-mt-6 '>Description</label>

            <TextArea value={data && data.text ? data.text : ''} rows={10} onChanged={(value) => handleChangeValue('text', value)} />

            <label className='builder-block builder-text-gray-700 builder-text-md builder-font-bold builder-mb-2 builder-mt-2 builder-mt-6 '>Image</label>

            <FeatureImageEditor image={data && data.image ? data.image : ''} onChange={(image) => handleChangeValue('image', image)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageProperties;
