import React, { useContext } from 'react';
import { StateContext } from '../../App';

const TrackingUser = ({ data }) => {
  return (
    <div title={data.userName} className='builder-inline-flex builder-rounded-full builder-border-2 builder-justify-center builder-items-center builder-w-10 builder-h-10 builder-capitalize -builder-ml-5 builder-bg-white'>
      {data.userName.slice(0, 2)}
    </div>
  );
};

const TrackingUserIndication = ({ className }) => {
  const state = useContext(StateContext);

  let users = undefined;
  if (state.tracking?.users) {
    users = [];
    Object.keys(state.tracking?.users).forEach((userTrackingId) => {
      if (state.tracking?.users[userTrackingId].route === window.location.pathname && userTrackingId !== state.session.sessionId) {
        users.push(state.tracking.users[userTrackingId]);
      }
    });
  }

  return (
    <div className={`${className} builder-flex builder-justify-center builder-items-center`}>
      {users && users.length > 0 && (
        <div className='builder-inline-block builder-ml-5'>
          {users.map((user, index) => (
            <TrackingUser key={index} data={user} />
          ))}
        </div>
      )}
    </div>
  );
};

export default TrackingUserIndication;
