import React, { Fragment, useContext, useState } from 'react';
import { filter, find, includes, map, orderBy, values } from 'lodash';
import ConfirmBox from '../../components/common/ConfirmBox';
import { useHistory } from 'react-router-dom';
import { getTranslationForPage } from '../../utils';
import { modalOpenHandler, pageDeleteHandler, pageSelectHandler } from '../data/actions';
import { MODAL_TYPES } from '../data/reducers';
import { DispatchContext, StateContext } from '../../App';
import Search from '../../components/common/Search';
import usePages, { PAGE_STATUSES } from './usePages';
import TrackingUserIndicationPerPage from '../../components/common/TrackingUserIndicationPerPage';

const Overview = ({ context, currentLanguage, setCurrentLanguage, onChangeEnvironment }) => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const contextState = useContext(StateContext);
  const currentState = state.history[state.history.length - 1];
  const [confirmDelete, setConfirmDelete] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const { pageStatus, deletePage } = usePages(context);

  const filterPages = (pages) => {
    // This function will filter out pages that are prod when they have a draft page so the list shows only one page
    if (!pages) return [];
    const pageArray = Object.values(pages);
    const _pages = [];
    pageArray.forEach((p) => {
      if (p.isDraft) {
        _pages.push(p);
        return;
      }
      const samePages = pageArray.filter((_p) => _p.url === p.url);

      if (samePages.length !== 2) {
        _pages.push(p);
      }
    });
    return _pages;
  };

  const history = useHistory();
  const languages = currentState.languages;
  const pages = filterPages(currentState.pages);
  const currentWebsite = find(contextState?.websites, (w) => w.id === contextState.currentWebsite);

  let tableRows = orderBy(
    map(pages, (page) => {
      return {
        value: getTranslationForPage(page, currentLanguage) === '' ? 'homepage' : getTranslationForPage(page, currentLanguage),
        key: page.id,
        type: page.type,
        priority: page.url === '/index/' ? 1 : 0,
        isDraft: page.isDraft
      };
    }),
    ['priority', 'value'],
    ['desc', 'asc']
  );

  if (searchTerm !== '') {
    tableRows = filter(tableRows, (row) => includes(row.value, searchTerm) || includes(row.type, searchTerm));
  }

  const onSelectPage = (item) => {
    history.push(`/builder/${item.key}`);
    pageSelectHandler(dispatch, item.key);
  };

  const onEditPage = (item, languageCode) => {
    history.push(`/builder/${item.key}`);
    setCurrentLanguage(languageCode);
    pageSelectHandler(dispatch, item.key);
  };

  const onDeletePage = (item) => {
    deletePage(item.key, currentWebsite.id, () => pageDeleteHandler(dispatch, item.key));
  };

  const onCreatePage = () => {
    modalOpenHandler(dispatch, MODAL_TYPES.PAGE_CREATE);
  };

  return (
    <div className='builder-flex builder-flex-col builder-p-5 builder-w-full'>
      {confirmDelete !== '' && (
        <ConfirmBox
          title='Delete page'
          text='Are you sure you want to delete this page?'
          type='DANGER'
          onCancel={() => setConfirmDelete('')}
          onConfirm={() => {
            onDeletePage(confirmDelete);
            setConfirmDelete('');
          }}
        />
      )}

      <div className='builder-flex builder-justify-between builder-mb-5 builder-space-x-4'>
        <div className='builder-text-2xl builder-text-black builder-font-bold'>Pages</div>
        <div>
          <Search onChange={(newSearchTerm) => setSearchTerm(newSearchTerm)} value={searchTerm} dataTestId='pages-search-button' placeholder='Search by page or type' />
        </div>
        <div className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-cursor-pointer opacity-100' onClick={() => onCreatePage()}>
          <i className='fal fa-plus builder-mr-2' />
          Add new
        </div>
      </div>

      <div className='builder-flex builder-flex-col builder-w-full'>
        <div className='builder-overflow-x-auto'>
          <div className='builder-py-2 builder-align-middle builder-inline-block builder-min-w-full'>
            <div className='builder-shadow builder-overflow-hidden builder-border-b builder-border-gray-200'>
              <table className='builder-min-w-full builder-divide-y builder-divide-gray-200'>
                <thead className='builder-bg-gray-50'>
                  <tr>
                    <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '10%' }}>
                      Users
                    </th>
                    <th scope='col' className='' style={{ width: '5%' }}></th>
                    <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '35%' }}>
                      Page
                    </th>
                    <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '10%' }}>
                      Type
                    </th>
                    <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '20%' }}>
                      Edit
                    </th>
                    <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '20%' }}>
                      Move to trash
                    </th>
                  </tr>
                </thead>
                <tbody className='builder-bg-white builder-divide-y builder-divide-gray-200'>
                  {map(orderBy(tableRows, ['priority'], ['desc']), (row) => {
                    let users = undefined;
                    if (state.tracking?.users) {
                      users = [];
                      Object.keys(state.tracking?.users).forEach((userTrackingId) => {
                        if (state.tracking?.users[userTrackingId].route === `/builder/${row.key}` && userTrackingId !== state.session.sessionId) {
                          users.push(state.tracking.users[userTrackingId]);
                        }
                      });
                    }
                    const disabledDelete = users && users.length > 0;
                    return (
                      <tr key={row.key}>
                        <td className='builder-px-6'>
                          <TrackingUserIndicationPerPage className='builder-justify-start' route={`/builder/${row.key}`} />
                        </td>
                        <td className='builder-cursor-pointer builder-text-black builder-font-bold' onClick={() => onSelectPage(row)}>
                          {row.isDraft ? '[Draft] ' : ''}
                        </td>
                        <td className='builder-pr-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black builder-cursor-pointer builder-font-bold' onClick={() => onSelectPage(row)}>
                          {row.value}
                        </td>
                        <td className='builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black builder-cursor-pointer builder-font-bold'>{row.type}</td>
                        <td className='builder-flex builder-items-center builder-space-x-4 builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-sm'>
                          {map(
                            filter(languages, (l) => l.available),
                            (language) => {
                              return (
                                <div key={language.code} className='builder-flex builder-items-center builder-text-md builder-uppercase builder-text-black builder-cursor-pointer' onClick={() => onEditPage(row, language.code)}>
                                  {language.code} <i className='fal fa-pencil-alt builder-ml-1' style={{ fontSize: 12 }} />
                                </div>
                              );
                            }
                          )}
                        </td>
                        <td className='builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black'>
                          <button className={`builder-flex builder-items-center ${disabledDelete ? 'builder-opacity-30' : 'builder-cursor-pointer'}`} disabled={disabledDelete} onClick={() => setConfirmDelete(row)}>
                            {pageStatus === PAGE_STATUSES.DELETING ? (
                              'Deleting'
                            ) : (
                              <Fragment>
                                Delete <i className='fal fa-trash-alt builder-ml-1' style={{ fontSize: 12 }} />
                              </Fragment>
                            )}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
