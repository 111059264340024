import { actionTypes } from './reducer';

export const pageSetLoadingHandler = () => ({
  type: actionTypes.PAGE_SET_LOADING
});

export const pageResetLoadingHandler = () => ({
  type: actionTypes.PAGE_RESET_LOADING
});

export const pageSetHandler = (page) => ({
  type: actionTypes.PAGE_SET,
  payload: {
    page
  }
});

export const pageSetMetaHandler = (language, updatedMeta) => ({
  type: actionTypes.PAGE_SET_META,
  payload: {
    language,
    updatedMeta
  }
});

export const toggleCollapseHandler = () => ({
  type: actionTypes.TOGGLE_COLLAPSE
});

export const pageSetSavingHandler = () => ({
  type: actionTypes.PAGE_SET_SAVING
});

export const pageResetSavingHandler = () => ({
  type: actionTypes.PAGE_RESET_SAVING
});

export const pageSavedHandler = (page) => ({
  type: actionTypes.PAGE_SAVED,
  payload: {
    page
  }
});
