import {
  dataResetLoadingHandler,
  dataSetHandler,
  dataSetLoadingHandler,
  makeDisabledUpdatedHandler,
  makeResetDisabledUpdatingHandler,
  makeResetPrioritiesUpdatingHandler,
  makeResetSeoUpdatingHandler,
  makeSeoUpdatedHandler,
  makeSetDisabledUpdatingHandler,
  makeSetPrioritiesUpdatingHandler,
  makeSetSeoUpdatingHandler
} from '../overview/actions';
import cogoToast from 'cogo-toast';
import { resolveMakesPriorities } from '../../../../faq/utils';
import { filter, find, map, orderBy } from 'lodash';
import { useGraphQLRequest } from '../../../../../hooks/useGraphQLRequest';
import { getDealerMakes, getOtherMakes } from '../utils';

export const MakesDataQuery = `
  query ($data: MakeSearchInputType) {
    makes(data: $data) {
        disabled
        id
        key
        name
        priority
        seo
    }    
  }
`;

export const MakeUpdateMutation = `
  mutation MakeUpdate($data: [MakeUpdateInputType!]!) {
    makeUpdate(data: $data) {
      ok
    }
  }
`;

export const MakeDisableMutation = `
  mutation MakeDisable($data: [MakeDisableInputType!]!) {
    makeDisable(data: $data) {
      ok
    }
  }
`;

export const useMakes = (state, dispatch) => {
  const { makeGraphQLRequest } = useGraphQLRequest();

  const fetchMakes = async () => {
    dispatch(dataSetLoadingHandler());

    const query = MakesDataQuery;
    const variables = { data: { excludeDisabled: false } };

    const result = await makeGraphQLRequest(query, variables);

    if (result?.makes) {
      dispatch(dataSetHandler(result?.makes));
    } else {
      cogoToast.error('No makes returned from the server.');
    }

    dispatch(dataResetLoadingHandler());
  };

  const updateMakeSeo = async (makeId, makePriority, makeSeo) => {
    dispatch(makeSetSeoUpdatingHandler(makeId));

    const query = MakeUpdateMutation;
    const variables = { data: [{ id: makeId, priority: makePriority, seo: makeSeo }] };

    const result = await makeGraphQLRequest(query, variables);

    if (result?.makeUpdate?.ok) {
      dispatch(makeSeoUpdatedHandler(makeId, makePriority, makeSeo));
      cogoToast.success('Make seo updated.');
    } else {
      cogoToast.error('Make seo could not be updated.');
    }
    dispatch(makeResetSeoUpdatingHandler());
  };

  const updateMakePriority = async (draggedMakeId, targetMakeId) => {
    const currentMakes = [...state.makes];
    const otherMakes = getOtherMakes(currentMakes);
    const dealerMakes = getDealerMakes(currentMakes);
    const updatedDealerMakes = resolveMakesPriorities(dealerMakes, draggedMakeId, targetMakeId);
    const updatedMakes = [...otherMakes, ...updatedDealerMakes];

    dispatch(makeSetPrioritiesUpdatingHandler());

    // Update state directly to see it in the UI
    dispatch(dataSetHandler(updatedMakes));

    const query = MakeUpdateMutation;
    const variables = { data: map(updatedDealerMakes, (make) => ({ id: make.id, priority: make.priority, seo: make.seo })) };

    const result = await makeGraphQLRequest(query, variables);

    if (result?.makeUpdate?.ok) {
      cogoToast.success('Makes priority updated.');
    } else {
      dispatch(dataSetHandler(currentMakes)); // Reset state if update unsuccessful
      cogoToast.error('Makes priority could not be updated.');
    }

    dispatch(makeResetPrioritiesUpdatingHandler());
  };

  const onMakeDisabledUpdate = async (makeId, makeDisabled) => {
    dispatch(makeSetDisabledUpdatingHandler(makeId));

    const query = MakeDisableMutation;
    const variables = { data: [{ id: makeId, disabled: makeDisabled }] };

    const result = await makeGraphQLRequest(query, variables);

    if (result?.makeDisable?.ok) {
      dispatch(makeDisabledUpdatedHandler(makeId, makeDisabled));
      cogoToast.success('Make disabled status updated.');
    } else {
      cogoToast.error('Make disabled status could not be updated.');
    }
    dispatch(makeResetDisabledUpdatingHandler());
  };

  const onRemoveFromDealerMakes = async (makeId) => {
    const currentMakes = [...state.makes];
    const selectedMake = find(currentMakes, (make) => make.id === makeId);
    const dealerMakes = getDealerMakes(currentMakes);
    const otherMakes = getOtherMakes(currentMakes);

    const updatedMakes = [
      ...map(
        orderBy(
          filter(dealerMakes, (make) => make.id !== makeId),
          ['priority'],
          ['asc']
        ),
        (dealerMake, index) => ({ ...dealerMake, priority: index + 1 })
      ),
      ...otherMakes,
      { ...selectedMake, priority: 0 }
    ];

    const query = MakeUpdateMutation;
    const variables = { data: map(updatedMakes, (make) => ({ id: make.id, priority: make.priority, seo: make.seo })) };

    // Update state directly to see it in the UI
    dispatch(dataSetHandler(updatedMakes));

    // Do the request
    const result = await makeGraphQLRequest(query, variables);

    if (result?.makeUpdate?.ok) {
      cogoToast.success('Makes updated.');
    } else {
      cogoToast.error('Makes could not be updated.');
      // Reset the state because it is not updated
      dispatch(dataSetHandler(currentMakes));
    }
  };

  const onAddToDealerMakes = async (makeId) => {
    const currentMakes = [...state.makes];
    const selectedMake = find(currentMakes, (make) => make.id === makeId);
    const dealerMakes = getDealerMakes(currentMakes);
    const otherMakes = getOtherMakes(currentMakes);
    const updatedMakes = [...filter(otherMakes, (make) => make.id !== makeId), ...map(dealerMakes, (make) => ({ ...make, priority: make.priority + 1 })), { ...selectedMake, priority: 1 }];

    const query = MakeUpdateMutation;
    const variables = { data: map(updatedMakes, (make) => ({ id: make.id, priority: make.priority, seo: make.seo })) };

    // Update state directly to see it in the UI
    dispatch(dataSetHandler(updatedMakes));

    // Do the request
    const result = await makeGraphQLRequest(query, variables);

    if (result?.makeUpdate?.ok) {
      cogoToast.success('Makes updated.');
    } else {
      cogoToast.error('Makes could not be updated.');
      // Reset the state because it is not updated
      dispatch(dataSetHandler(currentMakes));
    }
  };

  return {
    fetchMakes: () => fetchMakes(),
    updateMakeSeo: (makeId, makePriority, makeSeo) => updateMakeSeo(makeId, makePriority, makeSeo),
    updateMakePriority: (draggedMakeId, targetMakeId) => updateMakePriority(draggedMakeId, targetMakeId),
    onMakeDisabledUpdate: (makeId, makeDisabled) => onMakeDisabledUpdate(makeId, makeDisabled),
    onRemoveFromDealerMakes: (makeId) => onRemoveFromDealerMakes(makeId),
    onAddToDealerMakes: (makeId) => onAddToDealerMakes(makeId)
  };
};
