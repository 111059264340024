import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'graphql-hooks';
import { useHistory } from 'react-router-dom';
import { routes } from '../data/constants';
import { StateContext } from '../../App';
import TextInput from '../builder/editors/components/TextInput';
import background from '../../assets/img/login-bg.jpeg';

export const AUTH_MUTATION = `
    mutation AuthUser($username: String!, $password: String!, $application: Int!) {
        authUser(username: $username, password: $password, application: $application) {          
           user {
              firstName
              lastName
              email
              roles              
            }
            session
            otherSessionsCount
        }
    }`;

export const AUTH_MUTATION_BY_PROVIDER = `
    mutation AuthUser($application: Int!, $provider: String!, $payload: String!,) {
        authUserByProvider(application: $application, provider: $provider, payload: $payload) {          
           user {
              firstName
              lastName
              email
              roles              
            }
            session
            otherSessionsCount
        }
    }`;

export const Login = ({ onAuthenticated }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const state = useContext(StateContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [authMutation] = useMutation(AUTH_MUTATION);
  const [authMutationByProvider] = useMutation(AUTH_MUTATION_BY_PROVIDER);
  const [errors, setErrors] = useState({});
  const [automaticLoginKey, setAutomaticLoginKey] = useState('');

  let history = useHistory();

  useEffect(() => {
    if (state.session && state.session.authenticated) {
      history.push(routes.HOME);
    }

    if (urlParams.has('key')) {
      const key = urlParams.get('key');
      setAutomaticLoginKey(key);
      history.replace(routes.LOGIN);
      handleLoginByProvider(key);
    }
  }, []);

  const handleLoginByProvider = (key) => {
    setLoading(true);
    authMutationByProvider({
      variables: {
        application: 0,
        provider: 'expert',
        payload: JSON.stringify({ key: key })
      }
    }).then((result) => {
      if (result.data && result.data.authUserByProvider) {
        const { authUserByProvider } = result.data;
        if (authUserByProvider.user) {
          onAuthenticated(authUserByProvider.user, authUserByProvider.session, authUserByProvider.otherSessionsCount);
          setLoading(false);
          history.push(routes.HOME);
        }
      } else if (result.error && result.error.graphQLErrors && result.error.graphQLErrors.length > 0) {
        const error = result.error.graphQLErrors[0];
        if (error.code === '401') {
          let message = 'Incorrect email address or password.';
          if (error.message === '9001') message = 'To many users logged in';
          let tmpErrors = { ...errors };
          tmpErrors['auth'] = message;
          setErrors(tmpErrors);
          setLoading(false);
          setAutomaticLoginKey(undefined);
        } else {
          let tmpErrors = { ...errors };
          tmpErrors['auth'] = error.message;
          tmpErrors['auth'] = error.message;
          setErrors(tmpErrors);
          setLoading(false);
          setAutomaticLoginKey(undefined);
        }
      } else {
        let tmpErrors = { ...errors };
        tmpErrors['auth'] = 'Something went wrong';
        setErrors(tmpErrors);
        setLoading(false);
        setAutomaticLoginKey(undefined);
      }
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    authMutation({
      variables: {
        username: username,
        password: password,
        application: 0
      }
    }).then((result) => {
      if (result.data && result.data.authUser) {
        const { authUser } = result.data;
        if (authUser.user) {
          onAuthenticated(authUser.user, authUser.session, authUser.otherSessionsCount);
          setLoading(false);
          history.push(routes.HOME);
        }
      } else if (result.error && result.error.graphQLErrors && result.error.graphQLErrors.length > 0) {
        const error = result.error.graphQLErrors[0];
        if (error.code === '401') {
          let message = 'Incorrect email address or password.';
          if (error.message === '9001') message = 'To many users logged in';
          let tmpErrors = { ...errors };
          tmpErrors['auth'] = message;
          setErrors(tmpErrors);
          setLoading(false);
        } else {
          let tmpErrors = { ...errors };
          tmpErrors['auth'] = error.message;
          setErrors(tmpErrors);
          setLoading(false);
        }
      } else {
        let tmpErrors = { ...errors };
        tmpErrors['auth'] = 'Something went wrong';
        setErrors(tmpErrors);
        setLoading(false);
      }
    });
  };

  return (
    <div className='builder-flex builder-flex-col builder-h-screen'>
      {/*<Header/>*/}

      <div
        className='builder-flex builder-flex-1 builder-items-center builder-justify-center builder-bg-gray-50 builder-py-12 builder-px-4 sm:builder-px-6 lg:builder-px-8'
        style={{
          background: `url(${background})`,
          backgroundSize: 'cover'
        }}
      >
        <div className='builder-max-w-md builder-w-full builder-space-y-8 builder-bg-black builder-bg-opacity-40 builder-p-32 builder-rounded-2xl' style={{ minWidth: 700 }}>
          <h2 className='builder-text-center builder-text-3xl builder-font-extrabold builder-text-white'>Sign in to your account</h2>

          {!automaticLoginKey && (
            <form className='builder-mt-8' action='#' method='POST' onSubmit={handleLogin}>
              <div className='builder-mb-2'>
                <div className='builder-text-white builder-font-bold builder-mb-1'>Username</div>
                <TextInput value={username} className='builder-border builder-border-gray-300 builder-bg-white' placeHolder='Username' onChanged={(value) => setUsername(value)} />
              </div>

              <div>
                <div className='builder-text-white builder-font-bold builder-mb-1'>Password</div>
                <TextInput value={password} type='password' className='builder-border builder-border-gray-300 builder-bg-white' placeHolder='Password' onChanged={(value) => setPassword(value)} />
              </div>

              <div className='builder-mt-8'>
                <button type='submit' className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-cursor-pointer builder-w-full'>
                  {loading ? 'Signing in..' : 'Sign in'}
                </button>
              </div>
            </form>
          )}

          {Object.keys(errors).length > 0 && errors['auth'] !== '' && <div className='builder-bg-red-200 builder-text-red-800 builder-p-4 builder-w-full builder-mb-4'>{errors['auth']}</div>}
        </div>
      </div>
    </div>
  );
};

export default Login;
