import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { map, head, indexOf } from 'lodash';
import cogoToast from 'cogo-toast';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { StateContext } from '../../../../App';
import { routes } from '../../../data/constants';

import {
  filterInitializeHandler,
  filterSetHandler,
  filterSetDescriptionHandler,
  filterSetTypeHandler,
  filterSetFiltersHandler,
  filterFiltersModalHandler,
  filterUndoChangesHandler,
  filterSetLoadingHandler,
  filterResetLoadingHandler
} from '../actions';
import { reducer, initialState } from '../reducer';

import ButtonWithIndicator from '../../../../components/common/ButtonWithIndicator';
import TextInput from '../../../builder/editors/components/TextInput';
import FiltersModal from '../../../builder/editors/FiltersModal';
import LoadingSkeleton from './LoadingSkeleton';

const filterTypes = ['category', 'segment'];

export const FilterQuery = `query filters($data: FilterSearchInputType!) { 
  filters(data: $data) { 
    id
    description
    urls{
      url
      description
      language
    }
    type
    filters
    isDraft
    disabled 
  } 
}`;

export const FilterSaveMutation = `mutation FilterUpdate($data: FilterUpdateInputType!) {
  filterUpdate(data: $data) {
      ok
  }
}`;

const Detail = ({ context }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const globalState = useContext(StateContext);
  const location = useLocation();
  const history = useHistory();

  const [filterLanguage] = useState(location?.state?.language ? location.state.language : context.culture);

  const filter = state.filter;
  const languages = globalState?.configuration?.website?.languages;

  const { id: idFromUrl } = useParams();
  const [formErrors, setFormErrors] = useState({});

  const tabClassNames = 'relative -bottom-px builder-p-3 builder-cursor-pointer builder-text-black builder-text-md builder-uppercase focus:outline-none';
  const activeTabClassNames = 'builder-font-bold builder-bg-white builder-border-t builder-border-r builder-border-l builder-border-b-0 builder-rounded-sm builder-border-gray-300';
  const tabPanelClassNames = 'builder-bg-white builder-border-l builder-border-r builder-border-gray-300';

  const fetchFilter = (id) => {
    filterSetLoadingHandler(dispatch);

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: FilterQuery, variables: { data: { id: id } } })
    })
      .then((resp) => resp.json())
      .then((result) => {
        const filter = result && result.data && result.data.filters && result.data.filters.length > 0 ? result.data.filters[0] : undefined;
        if (filter) {
          filterSetHandler(dispatch, filter);
        } else {
          filterInitializeHandler(dispatch, id);
        }

        if (result?.errors) {
          const resultError = head(result?.errors);
          cogoToast.error(resultError.message);
        }

        filterResetLoadingHandler(dispatch);
      })
      .catch((err) => {
        cogoToast.error(err);
        filterResetLoadingHandler(dispatch);
      });
  };

  useEffect(() => {
    fetchFilter(idFromUrl);
  }, []);

  const handleChangeDescription = (value, language) => {
    const data = {
      description: {
        ...filter.description,
        [language]: value
      }
    };
    filterSetDescriptionHandler(dispatch, data.description);
  };

  const handleFiltersModal = () => {
    filterFiltersModalHandler(dispatch, { ...state.filtersModal, isOpen: !state.filtersModal.isOpen });
  };

  const handleChangeFilters = (filters) => {
    filterSetFiltersHandler(dispatch, filters);
  };

  const validateForm = () => {
    let isValid = true;
    let errors = {};
    setFormErrors({});

    let filterDescriptionErrors = {};
    map(languages, (language) => {
      if (!filter?.description[language] || filter?.description[language] === '') {
        filterDescriptionErrors[language] = 'The ' + language + ' filter needs a description.';
      }
    });
    if (filterDescriptionErrors && Object.keys(filterDescriptionErrors).length > 0) {
      errors['filterDescription'] = filterDescriptionErrors;
      isValid = false;
    }

    if (filter.type === '') {
      errors['filterType'] = 'The filter needs a type assigned.';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSave = () => {
    if (validateForm()) {
      const variables = {
        data: {
          id: filter.id,
          description: JSON.stringify(filter?.description),
          type: filter.type,
          filters: JSON.stringify(filter.filters),
          isDraft: filter.isDraft
        }
      };
      fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query: FilterSaveMutation, variables: variables })
      })
        .then((resp) => resp.json())
        .then((result) => {
          if (result?.data?.filterUpdate?.ok) {
            cogoToast.success('Filter Saved');
          } else {
            const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
            cogoToast.error(error);
          }
        })
        .catch((err) => {
          cogoToast.error(err);
        });
    }
  };

  if (state.filter === undefined || state.filterLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <React.Fragment>
      <div className='builder-flex builder-flex-col builder-flex-1 builder-p-5'>
        <div className='builder-flex builder-flex-row builder-items-center builder-justify-between builder-mb-5'>
          <div className='builder-flex builder-flex-row builder-items-center'>
            <Link to={`${routes.VEHICLE_FILTERS}`} className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-cursor-pointer opacity-100 builder-mr-4'>
              <i className='fa fa-chevron-left builder-mr-2' />
              Overview
            </Link>
            <div className='builder-text-2xl builder-text-black builder-font-bold builder-mr-4'>
              Filter: {filter?.description && filter?.description[filterLanguage] !== undefined ? filter?.description[filterLanguage] : ''} <small>#{idFromUrl}</small>
            </div>
          </div>
          <div className='flex'>
            {state.history === undefined && (
              <ButtonWithIndicator
                loading={false}
                onClick={() => history.push(`${routes.VEHICLE_FILTERS}`)}
                icon='fal fa-times'
                text='Cancel'
                colorClass='builder-bg-primary builder-text-white'
                borderClass='builder-border builder-border-bg-blue-500'
                className='builder-ml-0 builder-mr-0'
              />
            )}
            {state.history && (
              <ButtonWithIndicator
                loading={false}
                disabled={state.history.length === 1}
                onClick={() => filterUndoChangesHandler(dispatch)}
                icon='fal fa-undo'
                text='Undo changes'
                colorClass='builder-bg-primary builder-text-white'
                borderClass='builder-border builder-border-bg-blue-500'
                className='builder-ml-0 builder-mr-0'
              />
            )}

            <ButtonWithIndicator
              loading={false}
              onClick={() => handleSave(state.filter)}
              icon='fal fa-save'
              text='Save'
              colorClass='builder-bg-primary builder-text-white'
              borderClass='builder-border builder-border-bg-blue-500'
              className='builder-ml-0 builder-mr-0'
            />
          </div>
        </div>

        <div className='builder-flex builder-p-5 builder-w-full h-full builder-bg-gray-50'>
          <div className='builder-w-3/4 builder-mr-5'>
            <div className='builder-flex builder-flex-col builder-mb-5'>
              <div className='builder-flex builder-flex-col builder-mb-5'>
                <div className='builder-text-black builder-text-md builder-font-bold builder-mb-2'>Filter type</div>
                <div
                  className='builder-flex builder-mb-2'
                  onChange={(e) => {
                    filterSetTypeHandler(dispatch, e.target.value);
                  }}
                >
                  {map(filterTypes, (type) => {
                    return (
                      <React.Fragment>
                        <div className='builder-mr-4'>
                          <label className='builder-flex builder-flex-row builder-items-start builder-cursor-pointer'>
                            <div className='builder-mr-1'>
                              <input type='radio' value={type} name='type' checked={filter.type === type} />
                            </div>
                            <div>{type}</div>
                          </label>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
                {formErrors['filterType'] && <span className='builder-mt-1 builder-text-sm builder-text-red-500'>{formErrors['filterType']}</span>}
              </div>
              <Tabs defaultIndex={indexOf(languages, filterLanguage)}>
                <TabList className='builder-flex builder-flex-row builder-justify-start builder-align-center builder-border-b builder-border-gray-300'>
                  {map(languages, (language) => {
                    return (
                      <React.Fragment>
                        <Tab className={tabClassNames} selectedClassName={activeTabClassNames}>
                          {language}
                        </Tab>
                      </React.Fragment>
                    );
                  })}
                </TabList>

                {map(languages, (language) => {
                  return (
                    <TabPanel className={tabPanelClassNames}>
                      <div className='builder-py-4 builder-mx-2'>
                        <TextInput
                          placeHolder='Add description'
                          className='builder-flex builder-flex-1 builder-border builder-gray-300 builder-w-full builder-rounded-md'
                          value={filter?.description && filter?.description[language] !== undefined ? filter?.description[language] : ''}
                          onChanged={(value) => handleChangeDescription(value, language)}
                        />
                      </div>
                      {formErrors['filterDescription'] && formErrors['filterDescription'][language] && <span className='builder-mt-1 builder-mb-4 builder-mx-2 builder-text-sm builder-text-red-500'>{formErrors['filterDescription'][language]}</span>}
                    </TabPanel>
                  );
                })}
              </Tabs>
              {formErrors['filterDescription'] && Object.keys(formErrors['filterDescription']).length > 0 && <span className='builder-mt-2 builder-text-sm builder-text-red-500'>One or more description is empty</span>}
            </div>
          </div>
          <div className='builder-w-1/4'>
            <div className={`builder-pt-2 builder-pb-4 builder-bg-white builder-mb-5 builder-border-b builder-border-gray-300 builder-shadow-sm'}`}>
              <div className='builder-flex builder-justify-between builder-items-center'>
                <h3 className='builder-text-black builder-text-md builder-font-bold builder-ml-5'>Criteria</h3>
              </div>
              <div className={`builder-px-5 builder-flex builder-flex-col`}>
                <div className='mt-5 builder-cursor-pointer' onClick={() => handleFiltersModal()}>{`Edit criteria (${filter?.filters?.length ? filter?.filters?.length : '0'})`}</div>

                <FiltersModal
                  isOpen={state.filtersModal.isOpen}
                  filters={filter.filters ? filter.filters : []}
                  //availableFilterTypes={[FilterTypes.makeModel, FilterTypes.label, FilterTypes.fuel]}
                  onClose={(filters) => {
                    handleChangeFilters([...filters]);
                    handleFiltersModal();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Detail;
