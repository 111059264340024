import React, { useState } from 'react';
import { coreTypes } from 'dealer-website-components';

const initCoValue = { min: '', max: '' };

const CO2Filter = ({ filterValues, filterAdd, filterUpdate, filterDelete }) => {
  const activeFilter = filterValues && filterValues.length > 0 ? filterValues[0] : undefined;
  const [value, setValue] = useState(activeFilter ? { min: activeFilter[0], max: activeFilter[1] } : initCoValue);
  const { FilterTypes } = coreTypes;

  const handleChange = () => {
    if (value.min !== '' || value.max !== '') {
      const range = [value.min, value.max];

      if (filterValues.length > 0) {
        filterUpdate(FilterTypes.co2Range, range);
      } else {
        filterAdd(FilterTypes.co2Range, range);
      }
    } else {
      filterDelete(FilterTypes.co2Range);
    }
  };

  return (
    <React.Fragment>
      <div className='builder-font-semibold builder-mb-2 builder-mt-5'>CO2 (g/km)</div>
      <div className='builder-flex' style={{ minHeight: 44 }}>
        <input
          className='builder-w-1/2 builder-filter-input builder-border builder-rounded-l-4 focus:builder-outline-none'
          value={value.min}
          style={{ maxHeight: 46 }}
          placeholder='From'
          onChange={(e) => setValue({ ...value, min: e.target.value })}
          onBlur={() => handleChange()}
        />
        <input
          className='builder-w-1/2 builder-filter-input builder-border-t builder-border-b builder-border-r  builder-rounded-r-4 focus:builder-outline-none'
          value={value.max}
          style={{ maxHeight: 46 }}
          placeholder='To'
          onChange={(e) => setValue({ ...value, max: e.target.value })}
          onBlur={() => handleChange()}
        />
      </div>
    </React.Fragment>
  );
};

export default CO2Filter;
