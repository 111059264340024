import React, { useState } from 'react';
import { find, isEmpty, map } from 'lodash';
import AddEditRow from '../../detail/components/AddEditRow';
import ButtonWithIndicator from '../../../../../../components/common/ButtonWithIndicator';

const FileUploadOverview = ({ state, onRemove, onAdd, onCancel, onBulkSave, context }) => {
  const [saveDisabled, setSavedDisabled] = useState(false);
  const redirectsFromFile = state.redirectsFromFile;
  const pages = state.pages;
  const isSaving = state.bulkSaving;
  const notUpdatedRedirectsFromFile = state.notUpdatedRedirectsFromFile;

  const handleErrorsUpdate = (hasNoErrors) => {
    setSavedDisabled(!hasNoErrors);
  };

  return (
    <div data-testid='file-upload-overview-modal' className='builder-flex builder-items-center builder-justify-center builder-fixed builder-inset-0 builder-bg-black builder-bg-opacity-75 builder-p-10 builder-z-50'>
      <div className='builder-relative builder-w-full builder-max-h-full builder-overflow-y-scroll' style={{ maxWidth: '120rem' }}>
        {/*Modal content*/}
        <div className='builder-relative builder-bg-white builder-rounded-lg builder-shadow'>
          {/*Modal header*/}
          <div className='builder-flex builder-items-start builder-justify-between builder-p-4 builder-border-b builder-rounded-t builder-sticky builder-top-0 builder-bg-white builder-z-10'>
            <div>
              <h3 className='builder-text-2xl builder-font-semibold builder-text-gray-900'>Loaded records ({redirectsFromFile && redirectsFromFile.length})</h3>
              <div className='builder-text-gray-700'>Update all by clicking on Save all or update obe by one</div>
            </div>
            <div className='builder-flex builder-items-center builder-justify-center builder-mt-2.5'>
              <div className='builder-mr-2 builder-text-black builder-transition-all builder-cursor-pointer hover:builder-text-gray-800' onClick={onCancel}>
                Cancel
              </div>
              <ButtonWithIndicator
                onClick={onBulkSave}
                dataTestId='redirect-bulk-save-button'
                icon='far fa-save'
                text='Save all'
                disabled={saveDisabled || isSaving}
                colorClass='builder-bg-primary builder-text-white'
                borderClass='builder-border builder-border-bg-primary'
              />
            </div>
          </div>
          {/*Modal body*/}
          <div className='builder-p-6 builder-space-y-6 builder-relative'>
            {isSaving && <div className='builder-absolute builder-inset-0 builder-bg-gray-100 builder-bg-opacity-75 builder-z-10 builder-animate-pulse' />}
            {map(redirectsFromFile, (redirectFromFile) => {
              const redirectWithError = find(notUpdatedRedirectsFromFile, (redirect) => redirect.id === redirectFromFile.id);
              const hasError = !isEmpty(redirectWithError);

              return (
                <div key={redirectFromFile.id}>
                  <div className={`builder-relative ${hasError ? 'builder-border builder-border-red-500' : ''}`}>
                    <AddEditRow
                      redirect={redirectFromFile}
                      onCancel={() => onRemove(redirectFromFile.id)}
                      handleUpdate={(updatedRedirect) => onAdd(updatedRedirect)}
                      pages={pages}
                      isLoadedFromFile={true}
                      onErrorsChange={(hasNoErrors) => handleErrorsUpdate(hasNoErrors)}
                      context={context}
                    />
                  </div>
                  {hasError && <div className='builder-mt-2 builder-text-red-900'>{redirectWithError?.message}</div>}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUploadOverview;
