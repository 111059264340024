import { FILTER_TYPES } from './general/redirects/constants';
import { filter, groupBy, includes, map, mapValues, omit, orderBy } from 'lodash';
import { flatMap } from 'lodash/collection';

export const resolveSorter = (currentSorter, addedSorter) => {
  let newSorter = null;

  // Same sorter we just change sort type
  if (currentSorter && currentSorter.key === addedSorter.key) {
    const newSortType = currentSorter.sortType === 'asc' ? 'desc' : 'asc';
    newSorter = {
      ...addedSorter,
      sortType: newSortType
    };
  } else {
    // New sorter. We remove existing one and add a new one
    newSorter = addedSorter;
  }

  return newSorter;
};

export const resolveRedirects = (currentFilters, currentRedirects) => {
  let filteredRedirects = [...currentRedirects];
  currentFilters.forEach((filterObj) => {
    switch (filterObj.type) {
      case FILTER_TYPES.STATUS:
        if (filterObj?.value?.length > 0) {
          filteredRedirects = filter(filteredRedirects, (redirect) => includes(filterObj.value, redirect.fromUrlStatusCode) || includes(filterObj.value, redirect.toUrlStatusCode));
        }
        break;
      case FILTER_TYPES.SEARCH:
        if (filterObj.value && filterObj.value !== '') {
          filteredRedirects = filteredRedirects.filter((redirect) => includes(redirect.fromPath, filterObj.value) || includes(redirect.toPath, filterObj.value));
        }
        break;
      case FILTER_TYPES.SORT:
        filteredRedirects = orderBy(filteredRedirects, [filterObj.extra], [filterObj.value]);
        break;
      default:
        // Do nothing for unsupported filter types
        break;
    }
  });

  return filteredRedirects;
};

export const isGoodFromPath = (type, fromUrlStatusCode) => {
  return type === fromUrlStatusCode;
};

export const isGoodToPath = (toUrlStatusCode) => {
  return toUrlStatusCode === 200;
};

export const cleanedOpeningsHoursForCompare = (openingsHours) => {
  if (!openingsHours) return [];
  // Remove uuid from each object in openingsHours
  const cleanedOpeningsHours = map(openingsHours, (hour) => omit(hour, 'uuid'));
  // Order openingsHours by day date and type id
  return orderBy(cleanedOpeningsHours, ['day', 'type.id'], ['asc', 'asc']);
};

export const cleanedClosingDaysForCompare = (closingDays) => {
  if (!closingDays) return [];
  // Remove uuid from each object in closingDays
  const cleanedClosingDays = map(closingDays, (day) => omit(day, 'uuid'));
  // Order closingDays by start date and type id
  return orderBy(cleanedClosingDays, ['start', 'type.id'], ['asc', 'asc']);
};

export const isSameDay = (date1, date2) => {
  if (!date1 || !date2) {
    return false;
  }
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
};

export const isDateInPast = (date) => {
  if (!date) return true;
  const now = new Date();
  return new Date(date) < now;
};
