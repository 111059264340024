import React from 'react';
import { routes } from '../../../data/constants';
import { useHistory } from 'react-router-dom';

const Header = ({ context }) => {
  const history = useHistory();

  const onCreateCustomBlock = () => {
    history.push(`${routes.CUSTOM_BLOCK}`, {
      language: context.culture
    });
  };
  return (
    <div className='builder-flex builder-items-center builder-justify-between builder-py-5 builder-sticky builder-top-0 builder-z-40' style={{ backgroundColor: 'rgb(222, 226, 236)' }}>
      <div className='builder-flex builder-items-center'>
        <div className='builder-text-2xl builder-text-black builder-font-bold builder-mr-6'>Custom blocks</div>
        <div
          data-testid='create-custom-block-button'
          className={`builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-primary builder-transition-all builder-cursor-pointer builder-opacity-100`}
          onClick={() => onCreateCustomBlock()}
        >
          <i className='fal fa-plus builder-mr-2' />
          Add new
        </div>
      </div>
      <div className={`builder-flex builder-items-center builder-space-x-2 builder-transition-all builder-opacity-0`}></div>
    </div>
  );
};

export default Header;
