import React from 'react';
import { coreDefaults } from 'dealer-website-components';
import ImageDetailV2 from './components/ImageDetailV2';
import ImageUrlFieldEditor from './ImageUrlFieldEditor';

const JsonImageFieldEditor = (props) => {
  // For BW compatible if a field is changed from ImageUrlField to JsonImageField
  if (props.value && typeof props.value === 'object') {
    const { definition, value, onChange, context, className, breadcrumbs, onBack, editorFields } = props;

    return <ImageDetailV2 image={value} onChange={onChange} context={context} className={className} definition={definition} breadcrumbs={breadcrumbs} onBack={onBack} editorFields={editorFields} />;
  } else {
    return <ImageUrlFieldEditor {...props} />;
  }
};

JsonImageFieldEditor.defaultProps = {
  value: coreDefaults?.DefaultImage ? { ...coreDefaults.DefaultImage } : {}
};

export default JsonImageFieldEditor;
