import { map, filter } from 'lodash';
import moment from 'moment';

// INITIAL STATES
//
export const initialState = {
  campaigns: [],
  campaignsLoading: true,
  campaignsError: false,
  history: [] //?
};

export const initialCampaign = {
  id: '',
  name: '',
  startDate: new Date(),
  stopDate: new Date(),
  isExpired: false,
  isActive: false,
  isInFuture: false,
  activeInDays: '',
  disabled: false,
  isDraft: false
};

// ACTION TYPES
//
export const actionTypes = {
  CAMPAIGNS_SET: 'CAMPAIGNS_SET',
  CAMPAIGN_DELETE: 'CAMPAIGN_DELETE',
  CAMPAIGNS_SET_LOADING: 'CAMPAIGNS_SET_LOADING',
  CAMPAIGNS_RESET_LOADING: 'CAMPAIGNS_RESET_LOADING'
};

// REDUCER
//
export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.CAMPAIGNS_SET:
      const today = moment(new Date()).format('YYYY-MM-DD');

      return {
        ...state,
        campaigns: map(action.payload.campaigns, (campaign) => {
          const start = moment(campaign.start).format('YYYY-MM-DD');
          const stop = moment(campaign.stop).format('YYYY-MM-DD');
          const todayStartDiff = moment(campaign.start).diff(today, 'days');
          return {
            ...initialCampaign,
            id: campaign.id,
            name: campaign.name,
            startDate: start,
            stopDate: stop,
            isExpired: today > stop,
            isActive: today >= start && today <= stop,
            isInFuture: todayStartDiff > 0,
            activeInDays: todayStartDiff,
            isDraft: campaign.isDraft,
            disabled: campaign.disabled
          };
        }),
        campaignsLoading: false,
        campaignsError: undefined
      };
    case actionTypes.CAMPAIGN_DELETE:
      return {
        ...state,
        campaigns: filter(state.campaigns, (campaign) => campaign.id !== action.payload.id),
        campaignsLoading: false,
        campaignsError: undefined
      };
    case actionTypes.CAMPAIGNS_SET_LOADING:
      return {
        ...state,
        campaignsLoading: true
      };
    case actionTypes.CAMPAIGNS_RESET_LOADING:
      return {
        ...state,
        campaignsLoading: false
      };
    default:
      return state;
  }
};
