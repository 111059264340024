import React from 'react';
import { includes } from 'lodash';
import { coreTypes } from 'dealer-website-components';

const AvailableFilter = ({ filterValues, filterAdd, filterUpdate, filterDelete }) => {
  const { FilterTypes } = coreTypes;

  const handleFilterChanged = (value) => {
    if (filterValues.length > 0) {
      filterUpdate(FilterTypes.available, value);
    } else {
      filterAdd(FilterTypes.available, value);
    }
  };

  const isSelected = includes(filterValues, true);
  return (
    <React.Fragment>
      <div className='builder-font-semibold builder-mb-2 builder-mt-5'>Available</div>
      <div key={`filter-vat`} className='builder-flex builder-flex-col builder-text-gray-900 builder-mb-1'>
        <div className='builder-flex builder-flex-row builder-py-1 builder-cursor-pointer' onClick={() => handleFilterChanged(!isSelected)}>
          <div className='builder-flex builder-items-center'>
            <div className={`builder-flex builder-items-center builder-justify-center builder-rounded builder-border builder-border-black builder-mr-2`} style={{ height: 16, width: 16 }}>
              {isSelected && <i className='far fa-check builder-text-black builder-font-bold' style={{ fontSize: 10, marginTop: 2 }} />}
            </div>
          </div>
          <div className={`builder-flex builder-flex-1 ${isSelected ? 'builder-font-semibold' : 'builder-font-normal'}`}>
            <div className=''>Not sold and not reserved</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AvailableFilter;
