export const actionTypes = {
  REDIRECT_INITIALIZE: 'REDIRECT_INITIALIZE',
  REDIRECT_SET_INITIALIZED: 'REDIRECT_SET_INITIALIZED',
  REDIRECT_SET: 'REDIRECT_SET',

  REDIRECT_SET_TYPE: 'REDIRECT_SET_TYPE',
  REDIRECT_SET_FROM_PATH: 'REDIRECT_SET_FROM_PATH',
  REDIRECT_SET_TO_PATH: 'REDIRECT_SET_TO_PATH'
};

export const initialRedirectFromFile = {
  id: '',
  type: 302,
  fromPath: '',
  toPath: ''
};

export const initialRedirect = {
  id: '',
  type: 302,
  fromUrl: '',
  fromPath: '',
  fromUrlStatusCode: undefined,
  toUrl: '',
  toPath: '',
  toUrlStatusCode: undefined
};

export const initialState = {
  saving: false,
  initRedirect: {},
  redirect: initialRedirect,
  formInitialized: false
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.REDIRECT_SET_INITIALIZED:
      return {
        ...state,
        formInitialized: true
      };
    case actionTypes.REDIRECT_INITIALIZE:
      return {
        ...state,
        redirect: {
          ...initialRedirect,
          id: action.payload.id
        },
        initRedirect: {
          ...initialRedirect,
          id: action.payload.id
        }
      };
    case actionTypes.REDIRECT_SET:
      const redirect = {
        id: action.payload.redirect.id,
        type: action.payload.redirect.type,
        fromUrl: action.payload.redirect.fromUrl,
        fromPath: action.payload.redirect.fromPath,
        fromUrlStatusCode: action.payload.redirect.fromUrlStatusCode,
        toUrl: action.payload.redirect.toUrl,
        toUrlStatusCode: action.payload.redirect.toUrlStatusCode,
        toPath: action.payload.redirect.toPath
      };

      return {
        ...state,
        redirect: redirect,
        initRedirect: redirect
      };

    case actionTypes.REDIRECT_SET_TYPE:
      return {
        ...state,
        redirect: {
          ...state.redirect,
          type: action.payload.type
        }
      };

    case actionTypes.REDIRECT_SET_FROM_PATH:
      return {
        ...state,
        redirect: {
          ...state.redirect,
          fromPath: action.payload.fromPath
        }
      };

    case actionTypes.REDIRECT_SET_TO_PATH:
      return {
        ...state,
        redirect: {
          ...state.redirect,
          toPath: action.payload.toPath
        }
      };

    default:
      return state;
  }
};
