import React from 'react';

const EditorMenu = ({ renderMenu, description }) => {
  return (
    <React.Fragment>
      <div className='builder-border-b builder-bg-gray-100 builder-border-gray-400 builder-p-2 builder-pl-4 builder-pr-4'>
        <div className='builder-flex builder-items-center builder-justify-end builder-mt-2'>{renderMenu()}</div>
      </div>
      {description && description !== '' && (
        <div className='builder-text-md builder-bg-gray-100 builder-italic builder-p-4 mt-2'>
          <i className='fal fa-info-circle builder-mr-4 builder-text-xl'></i>
          {description}
        </div>
      )}
    </React.Fragment>
  );
};

export default EditorMenu;
