import React from 'react';
import InfoIndicator from '../../../../components/common/indicators/InfoIndicator';

const NoResults = () => {
  return (
    <div data-testid='seo-pages-no-results' className='builder-max-w-xl'>
      <InfoIndicator message='There are no pages to show.' />
    </div>
  );
};

export default NoResults;
