import React from 'react';
import { coreTypes } from 'dealer-website-components';
import GenericList from '../../../../../components/common/GenericList';
import { map, filter } from 'lodash';

const MakesFilter = ({ filterData, filterValues, filterAdd, filterDelete }) => {
  const { FilterTypes } = coreTypes;
  const handleChange = (data) => {
    const filtersWithMake = filter(filterValues, (v) => v === data.value || (v.indexOf('|') > -1 && v.split('|')[0] === data.value));
    if (filtersWithMake.length > 0) {
      filterDelete(FilterTypes.makeModel, data.value);
    } else {
      filterAdd(FilterTypes.makeModel, data.value);
    }
  };

  const makeValues = map(filterValues, (v) => (v.indexOf('|') > -1 ? v.split('|')[0] : v));

  return (
    <React.Fragment>
      <div className='builder-font-semibold builder-mb-2 builder-mt-5'>Makes</div>
      <GenericList items={filterData} values={makeValues} handleFilterChanged={handleChange} />
    </React.Fragment>
  );
};

export default MakesFilter;
