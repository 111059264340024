import React, { useContext, useState } from 'react';
import TextInput from '../../../../../builder/editors/components/TextInput';
import { filter, forEach, includes, map, uniq } from 'lodash';
import { StateContext } from '../../../../../../App';

const TargetUrlField = ({ value, onChange, errorText, pages }) => {
  const [initValue, setInitValue] = useState(value);
  const [currentValue, setCurrentValue] = useState(value);
  const contextState = useContext(StateContext);
  const languages = contextState?.configuration?.website?.languages;

  // Generate slugs //
  const slugs = [];
  forEach(pages, (page) => {
    forEach(languages, (language) => {
      const slug = page.slug[language] ? page.slug[language] : '';
      slugs.push(`/${language}/${slug}/`);
    });
  });
  // Generate slugs //

  const resolveMatchedSlugs = () => {
    return filter(uniq(slugs), (slug) => includes(slug, currentValue));
  };

  const hasChanges = initValue !== currentValue;
  const matchedSlugs = hasChanges ? resolveMatchedSlugs() : [];
  const showDropDown = hasChanges && matchedSlugs && matchedSlugs?.length > 0;

  return (
    <div className='builder-relative'>
      <TextInput
        label='To path'
        placeHolder='Redirects to'
        dataTestId='redirect-to-path-input'
        errorText={errorText}
        className='builder-flex builder-flex-1 builder-max-w-xl builder-rounded-md'
        value={currentValue}
        onChanged={(newValue) => {
          setCurrentValue(newValue);
          onChange(newValue);
        }}
      />
      <div
        className={`builder-absolute builder-top-full builder-mt-1 builder-space-y-2 builder-border builder-border-gray-300 builder-rounded-md builder-bg-white builder-p-4 builder-transition-all builder-h-[250px] builder-overflow-y-scroll ${
          showDropDown ? 'builder-opacity-100 builder-z-[1]' : 'builder-opacity-0 builder-z-[-1]'
        }`}
      >
        {map(matchedSlugs, (matchedSlug) => {
          return (
            <div
              key={matchedSlug}
              className='builder-text-black hover:builder-text-gray-800 builder-transition-all builder-cursor-pointer'
              onClick={() => {
                setCurrentValue(matchedSlug);
                setInitValue(matchedSlug);
                onChange(matchedSlug);
              }}
            >
              {matchedSlug}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TargetUrlField;
