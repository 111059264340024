import { actionTypes } from './reducer';

export const dataLoadingHandler = () => ({
  type: actionTypes.DATA_LOADING
});

export const dataSetHandler = (categories, questionGroups, questions) => ({
  type: actionTypes.DATA_SET,
  payload: {
    categories,
    questionGroups,
    questions
  }
});

// CATEGORIES
export const categorySetDeletedHandler = (categoryId) => ({
  type: actionTypes.CATEGORY_DELETE,
  payload: {
    categoryId
  }
});

export const categoryChangeDisabledStatusHandler = (categoryId, disabled) => ({
  type: actionTypes.CATEGORY_CHANGE_DISABLED_STATUS,
  payload: {
    categoryId,
    disabled
  }
});

export const categoryChangePrioritiesHandler = (draggedIndex, targetIndex) => ({
  type: actionTypes.CATEGORY_CHANGE_PRIORITIES,
  payload: {
    draggedIndex,
    targetIndex
  }
});
/////

// QUESTIONS GROUP
export const groupChangePrioritiesHandler = (draggedGroupId, targetGroupId, targetCategoryId) => ({
  type: actionTypes.GROUP_CHANGE_PRIORITIES,
  payload: {
    draggedGroupId,
    targetGroupId,
    targetCategoryId
  }
});

export const groupSetDeletedHandler = (categoryId, groupId) => ({
  type: actionTypes.GROUP_DELETE,
  payload: {
    categoryId,
    groupId
  }
});

//

// QUESTIONS
export const questionChangePrioritiesHandler = (draggedQuestionId, targetQuestionId, targetGroupId) => ({
  type: actionTypes.QUESTION_CHANGE_PRIORITIES,
  payload: {
    draggedQuestionId,
    targetQuestionId,
    targetGroupId
  }
});

export const questionSetDeletedHandler = (groupId, questionId) => ({
  type: actionTypes.QUESTION_DELETE,
  payload: {
    groupId,
    questionId
  }
});
//

// PRIORITIES
export const prioritiesSaveHandler = () => ({
  type: actionTypes.PRIORITIES_SAVE
});

export const prioritiesCancelHandler = () => ({
  type: actionTypes.PRIORITIES_CANCEL
});

export const prioritiesSetSavingHandler = () => ({
  type: actionTypes.PRIORITIES_SET_SAVING
});

export const prioritiesResetSavingHandler = () => ({
  type: actionTypes.PRIORITIES_RESET_SAVING
});
//

// COLLAPSE STATUS
export const collapseStatusChangeHandler = (key, id) => ({
  type: actionTypes.COLLAPSE_STATUS_CHANGE,
  payload: {
    key,
    id
  }
});

export const collapseStatusesOnDragHandler = (key) => ({
  type: actionTypes.COLLAPSE_STATUSES_ON_DRAG,
  payload: {
    key
  }
});

export const resetCollapseStatusesOnDragDoneHandler = (key) => ({
  type: actionTypes.COLLAPSE_STATUSES_RESET_ON_DRAG_DONE,
  payload: {
    key
  }
});
