import React, { useEffect, useRef, useState } from 'react';
import { get, filter, some, find } from 'lodash';
import { DND_TYPES } from '../../../../constants';
import { useDrag, useDrop } from 'react-dnd';
import Questions from './Questions';
import Row from './Row';
import { collapseStatusChangeHandler, collapseStatusesOnDragHandler, groupChangePrioritiesHandler, resetCollapseStatusesOnDragDoneHandler } from '../actions';
import { ROW_HEIGHT, SPACE_BETWEEN_CATEGORY_ROWS } from '../reducer';

const QuestionGroup = ({ group, index, currentLanguage, state, dispatch, onEditGroup, onDeleteGroup, onChangePriority, isDeleting }) => {
  const [height, setHeight] = useState(0);

  const categoryId = group.categoryId;
  const groupId = group.id;
  const groupTitle = get(group.title, currentLanguage);
  const groupQuestions = filter(state.questions, (question) => question.groupId === groupId);

  const isCollapsible = some(state.questions, (question) => question.groupId === groupId);
  const collapseStatus = find(state?.collapseStatus?.questionGroups, (collapseStatus) => collapseStatus.id === groupId);
  const isCollapsed = collapseStatus?.isCollapsed;
  const canBeDeleted = !isCollapsible;

  // Only accepts drag and drop inside the same category.
  // const acceptedDragAndDropType = `${DND_TYPES.GROUP}-${categoryId}`;
  const acceptedDragAndDropType = `${DND_TYPES.GROUP}`;

  const dndGroupRef = useRef(null);

  const resolveTotalGroupChildren = () => {
    const groupQuestions = filter(state.questions, (question) => question.groupId === groupId);
    return groupQuestions.length;
  };

  const resolveGroupChildrenContainerHeight = () => {
    const totalGroupChildren = resolveTotalGroupChildren();
    const totalContainerHeight = totalGroupChildren * ROW_HEIGHT + (totalGroupChildren - 1) * SPACE_BETWEEN_CATEGORY_ROWS;
    return totalContainerHeight > 0 ? totalContainerHeight : 0;
  };

  const handleEditGroup = (e) => {
    e.stopPropagation();
    onEditGroup(groupId);
  };

  const handleDeleteGroup = (e) => {
    e.stopPropagation();
    onDeleteGroup(groupId, categoryId);
  };

  const handleRowClick = () => {
    if (!isDeleting && isCollapsible) {
      dispatch(collapseStatusChangeHandler('questionGroups', groupId));
    }
  };

  // Droppable
  const [{ handlerId, isOver }, drop] = useDrop({
    accept: acceptedDragAndDropType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: !!monitor.isOver()
      };
    },
    drop(item, monitor, ...props) {
      if (!dndGroupRef.current) {
        return;
      }

      const targetCategoryId = categoryId;

      const draggedGroupId = monitor.getItem().groupId;
      const targetGroupId = groupId;

      const hoverIndex = index;
      // Don't replace items with themselves
      // if (dragIndex === hoverIndex) {
      if (targetGroupId === draggedGroupId) {
        return;
      }

      // Time to actually perform the action
      if (isOver) {
        // onChangePriority(dragIndex, hoverIndex, group.categoryId);
        dispatch(groupChangePrioritiesHandler(draggedGroupId, targetGroupId, targetCategoryId));
      }
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  // Draggable
  const [{ isDragging }, drag] = useDrag({
    type: acceptedDragAndDropType,
    item: () => {
      return { index, categoryId: categoryId, groupId: groupId };
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging()
      };
    }
  });

  drag(drop(dndGroupRef));

  const resolveStyling = () => {
    // content not visible
    if (isCollapsed || isDragging || !isCollapsible) {
      return {
        style: { height: 0 },
        classes: 'builder-opacity-0'
      };
    }

    // content visible
    return {
      style: { height: height ? height : '' },
      classes: 'builder-opacity-100'
    };
  };

  const { classes, style } = resolveStyling();

  // Effect to collapse question groups on drag and undo it if drag is done
  useEffect(() => {
    isDragging ? dispatch(collapseStatusesOnDragHandler('questionGroups')) : dispatch(resetCollapseStatusesOnDragDoneHandler('questionGroups'));
  }, [isDragging]);

  // Effect to set height of the component
  useEffect(() => {
    setHeight(resolveGroupChildrenContainerHeight());
  }, [state.questions]);

  return (
    <div>
      <div ref={dndGroupRef} data-handler-id={handlerId} data-category-id={categoryId} data-question-group-id={groupId} className={`${isCollapsible && !isCollapsed ? 'builder-mb-2' : ''}`}>
        {isDeleting && <div data-testid='question-group-overview-loading-overlay' className='builder-absolute builder-inset-0 builder-bg-gray-600 builder-opacity-30 builder-z-50' />}

        <Row title={groupTitle} onClick={handleRowClick} onEdit={handleEditGroup} onDelete={handleDeleteGroup} canBeDeleted={canBeDeleted} isCollapsed={isCollapsed} isCollapsible={isCollapsible} isOver={isOver} isDragging={isDragging} />
      </div>
      <div className={`builder-ml-4 builder-transition-all builder-ease-in builder-transform ${classes}`} style={{ ...style }}>
        <Questions questions={groupQuestions} categoryId={group.categoryId} currentLanguage={currentLanguage} state={state} dispatch={dispatch} />
      </div>
    </div>
  );
};

export default QuestionGroup;
