import { find, map, filter } from 'lodash';
import { v4 } from 'uuid';

export const initialOverviewState = {
  blocks: [],
  blocksLoading: true
};

export const initialDetailState = {
  fieldTypes: [],
  history: [],
  block: undefined
};

export const initialBlock = {
  id: '',
  name: '',
  fields: [],
  isTemplate: false,
  readonly: false
};

export const initialField = {
  id: '',
  name: '',
  type: ''
};

export const overviewActionTypes = {
  BLOCKS_SET: 'BLOCKS_SET',
  BLOCK_DELETE: 'BLOCK_DELETE'
};

export const detailActionTypes = {
  BLOCK_SET: 'BLOCK_SET',
  FIELD_TYPES_SET: 'FIELD_TYPES_SET',
  BLOCK_INITIALIZE: 'BLOCK_INITIALIZE',
  BLOCK_SET_NAME: 'BLOCK_SET_NAME',
  BLOCK_SET_IS_TEMPLATE: 'BLOCK_SET_IS_TEMPLATE',
  BLOCK_ADD_FIELD: 'BLOCK_ADD_FIELD',
  BLOCK_DELETE_FIELD: 'BLOCK_DELETE_FIELD',
  BLOCK_SET_FIELD_NAME: 'BLOCK_FIELD_NAME_SET',
  BLOCK_UNDO_CHANGES: 'BLOCK_UNDO_CHANGES'
};

export const overviewReducer = (state, action) => {
  switch (action.type) {
    case overviewActionTypes.BLOCKS_SET:
      return {
        ...state,
        blocks: map(action.payload.blocks, (block) => ({
          ...initialBlock,
          id: block.id,
          name: block.name,
          isTemplate: block.isTemplate,
          readonly: block.readonly
        })),
        blocksLoading: false,
        blocksError: undefined
      };
    case overviewActionTypes.BLOCK_DELETE:
      return {
        ...state,
        blocks: filter(state.blocks, (block) => block.id !== action.payload.id)
      };
    default:
      return state;
  }
};

export const detailReducer = (state, action) => {
  switch (action.type) {
    case detailActionTypes.BLOCK_INITIALIZE:
      return {
        ...state,
        block: {
          ...initialBlock,
          id: action.payload.id
        },
        history: undefined
      };
    case detailActionTypes.BLOCK_SET:
      const block = {
        id: action.payload.block.id,
        name: action.payload.block.name,
        fields: map(action.payload.block.fields, (field) => ({
          id: v4(),
          name: field.name,
          type: field.type
        })),
        isTemplate: action.payload.block.isTemplate,
        readonly: action.payload.block.readonly
      };

      return {
        ...state,
        block: block,
        history: [block]
      };
    case detailActionTypes.BLOCK_SET_NAME:
      return {
        ...state,
        block: {
          ...state.block,
          name: action.payload.name
        },
        history: state.history !== undefined ? [...state.history, { ...state.block }] : undefined
      };
    case detailActionTypes.BLOCK_SET_IS_TEMPLATE:
      return {
        ...state,
        block: {
          ...state.block,
          isTemplate: action.payload.isTemplate
        },
        history: state.history !== undefined ? [...state.history, { ...state.block }] : undefined
      };
    case detailActionTypes.BLOCK_ADD_FIELD:
      return {
        ...state,
        block: {
          ...state.block,
          fields: [
            ...state.block.fields,
            {
              ...initialField,
              id: v4(),
              name: action.payload.name,
              type: action.payload.type
            }
          ]
        },
        history: state.history !== undefined ? [...state.history, { ...state.block }] : undefined
      };
    case detailActionTypes.BLOCK_DELETE_FIELD:
      return {
        ...state,
        block: {
          ...state.block,
          fields: filter(state.block.fields, (field) => field.id !== action.payload.id)
        },
        history: state.history !== undefined ? [...state.history, { ...state.block }] : undefined
      };
    case detailActionTypes.BLOCK_SET_FIELD_NAME:
      const fieldsCopy = map(state.block.fields, (f) => ({ ...f }));
      let fieldToChange = find(fieldsCopy, (f) => f.id === action.payload.id);
      if (fieldToChange) {
        fieldToChange.name = action.payload.name;
      }

      return {
        ...state,
        block: {
          ...state.block,
          fields: fieldsCopy
        },
        history: state.history !== undefined ? [...state.history, { ...state.block }] : undefined
      };

    case detailActionTypes.FIELD_TYPES_SET:
      return {
        ...state,
        fieldTypes: map(action.payload.fieldTypes, (fieldType) => ({
          name: fieldType.name,
          label: fieldType.label,
          contentType: fieldType.contentType,
          fieldType: fieldType.fieldType,
          dataSchema: fieldType.dataSchema ? JSON.parse(fieldType.dataSchema) : undefined
        }))
      };
    case detailActionTypes.BLOCK_UNDO_CHANGES:
      return {
        ...state,
        block: {
          ...state.history[0]
        },
        history: [{ ...state.history[0] }]
      };
    default:
      return state;
  }
};
