import React, { useContext, useState, useEffect, useReducer } from 'react';
import { filter, find, map, head } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import ConfirmBox from '../../../../components/common/ConfirmBox';
import { StateContext } from '../../../../App';
import { routes } from '../../../data/constants';
import { v4 } from 'uuid';

import { filterDeleteHandler, filtersSetHandler, filtersSetLoadingHandler, filtersResetLoadingHandler } from '../actions';
import { reducer, initialState } from '../reducers';

import cogoToast from 'cogo-toast';
import LoadingSkeleton from './LoadingSkeleton';

export const FiltersQuery = `query Filters{    
  filters {
    id
    description
    type
    filters
    isDraft
    disabled
  }   
}`;

export const FilterDeleteMutation = `mutation FilterDelete($data: FilterDeleteInputType!) {
  filterDelete(data: $data) {
    ok
  }
}`;

const Overview = ({ context, currentLanguage, setCurrentLanguage, onChangeEnvironment }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const globalState = useContext(StateContext);

  const currentWebsite = find(globalState.websites, (w) => w.id === globalState.currentWebsite);
  const isCurrentSiteProduction = currentWebsite ? currentWebsite.isProduction : false;

  const [confirmDelete, setConfirmDelete] = useState('');

  const history = useHistory();
  const vehicleFilters = filter(state.filters, (f) => !f.disabled);

  const languages = globalState?.configuration?.website?.languages;

  useEffect(() => {
    fetchFilters();
  }, []);

  const fetchFilters = () => {
    filtersSetLoadingHandler(dispatch);

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: FiltersQuery })
    })
      .then((resp) => resp.json())
      .then((result) => {
        filtersSetHandler(dispatch, result.data.filters);

        if (result?.errors) {
          const resultError = head(result?.errors);
          cogoToast.error(resultError.message);
        }

        filtersResetLoadingHandler(dispatch);
      })
      .catch((err) => {
        cogoToast.error(err);
        filtersResetLoadingHandler(dispatch);
      });
  };

  const handleDelete = () => {
    const id = confirmDelete;

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: FilterDeleteMutation, variables: { data: { id: id } } })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data?.filterDelete?.ok) {
          filterDeleteHandler(dispatch, id);
          cogoToast.success('Filter deleted');
        } else {
          const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
          cogoToast.error(error);
        }
        setConfirmDelete('');
      })
      .catch((err) => {
        cogoToast.error(err);
      });
  };

  const handleEdit = (reference, lng) => {
    history.push(`${routes.VEHICLE_FILTERS_DETAIL}/${reference}`, {
      language: lng
    });
  };

  if (state.filters === undefined || state.filtersLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <div className='builder-flex builder-flex-col builder-p-5 builder-w-full'>
      {confirmDelete !== '' && <ConfirmBox title='Delete filter' text='Are you sure you want to delete this filter?' type='DANGER' onCancel={() => setConfirmDelete('')} onConfirm={handleDelete} />}

      <div className='builder-flex builder-mb-5'>
        <div className='builder-text-2xl builder-text-black builder-font-bold builder-mr-4'>Filter</div>
        <Link to={`${routes.VEHICLE_FILTERS_DETAIL}/${v4()}`} className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-cursor-pointer opacity-100'>
          <i className='fal fa-plus builder-mr-2' />
          Add new
        </Link>
      </div>

      <div className='builder-flex builder-flex-col builder-w-full'>
        <div className='builder-overflow-x-auto'>
          <div className='builder-py-2 builder-align-middle builder-inline-block builder-min-w-full'>
            <div className='builder-shadow builder-overflow-hidden builder-border-b builder-border-gray-200'>
              <table className='builder-min-w-full builder-divide-y builder-divide-gray-200'>
                <thead className='builder-bg-gray-50'>
                  <tr>
                    {map(languages, (language) => {
                      return (
                        <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '20%' }}>
                          {language}
                        </th>
                      );
                    })}

                    <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '20%' }}>
                      type
                    </th>

                    <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '30%' }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className='builder-bg-white builder-divide-y builder-divide-gray-200'>
                  {map(vehicleFilters, (f, i) => {
                    const canEdit = !f.isDraft && !isCurrentSiteProduction ? false : true; // Only edit drafts in draft environment, (production posts not editable in draft)

                    return (
                      <tr key={f.id}>
                        {map(languages, (language) => {
                          return (
                            <td className='builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '20%' }}>
                              {f.description[language]}
                            </td>
                          );
                        })}

                        <td className='builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '20%' }}>
                          {f.type}
                        </td>

                        <td className='builder-flex builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '30%' }}>
                          {canEdit && (
                            <React.Fragment>
                              {map(languages, (language) => {
                                return (
                                  <div key={language} className='builder-flex builder-items-center builder-cursor-pointer builder-border-r builder-border-gray-300 builder-mr-3 builder-pr-3' onClick={() => handleEdit(f.id, language)}>
                                    <span className='builder-uppercase'>{language}</span> <i className='fal fa-pencil-alt builder-ml-1' style={{ fontSize: 12 }} />
                                  </div>
                                );
                              })}
                              <div className='builder-flex builder-items-center builder-cursor-pointer' onClick={() => setConfirmDelete(f.id)}>
                                Delete <i className='fal fa-trash-alt builder-ml-1' style={{ fontSize: 12 }} />
                              </div>
                            </React.Fragment>
                          )}
                          {!canEdit && (
                            <React.Fragment>
                              <span className='builder-font-bold'>No actions allowed in draft environment</span>
                            </React.Fragment>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
