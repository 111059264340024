import React from 'react';

const Header = ({ context }) => {
  return (
    <div className='builder-flex builder-items-center builder-justify-between builder-py-5 builder-sticky builder-top-0 builder-z-40' style={{ backgroundColor: 'rgb(222, 226, 236)' }}>
      <div className='builder-flex builder-items-center'>
        <div data-testid='seo-overview-header-title' className='builder-text-2xl builder-text-black builder-font-bold builder-mr-6'>
          Pages SEO overview
        </div>
      </div>
    </div>
  );
};

export default Header;
