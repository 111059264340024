import React from 'react';
import { Container, Draggable } from 'react-smooth-dnd';
import { map } from 'lodash';
import Switch from '../../../../../../components/common/Switch';
import { AnimatePresence, motion } from 'framer-motion';

const DealerMakes = ({ makes, onMakeSeoUpdate, onMakeDisabledUpdate, onMakePriorityUpdate, onRemoveFromDealerMakes, state, context }) => {
  const noMakes = !makes || makes?.length === 0;
  const renderNoResults = () => {
    return (
      <div data-testid='dealer-makes-no-results' className='builder-m-4 builder-p-4 builder-text-sm builder-text-gray-800 builder-rounded-lg builder-bg-gray-50' role='alert'>
        There are no dealer makes selected.
      </div>
    );
  };

  return (
    <div>
      <div className='builder-mb-4 builder-font-semibold builder-text-[20px]'>Dealer makes</div>
      <div className='builder-min-w-full builder-border builder-border-gray-200 builder-shadow'>
        <div className={`builder-flex builder-items-center builder-bg-gray-50`}>
          <div className='builder-px-4 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-whitespace-nowrap builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '50%' }}>
            Make
          </div>
          <div className='builder-px-4 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-whitespace-nowrap builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '15%' }}>
            Seo
          </div>

          <div className='builder-px-4 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-whitespace-nowrap builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '15%' }}>
            Disabled
          </div>

          <div className='builder-px-4 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-whitespace-nowrap builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '20%' }}>
            Actions
          </div>
        </div>

        {/*CONTENT*/}
        {noMakes && renderNoResults()}

        {!noMakes && (
          <motion.div className='builder-bg-white builder-divide-y builder-divide-gray-200'>
            <AnimatePresence initial={false}>
              <Container
                getChildPayload={(index) => makes[index]}
                dragClass='builder-shadow-2xl'
                dropPlaceholder={{
                  animationDuration: 150,
                  showOnTop: true,
                  className: 'builder-bg-gray-50'
                }}
                onDrop={(dropResult) => {
                  const draggedMake = dropResult?.payload ? dropResult?.payload : undefined;
                  const targetMake = makes[dropResult?.addedIndex];

                  if (draggedMake && targetMake && draggedMake.key !== targetMake.key) {
                    onMakePriorityUpdate(draggedMake.id, targetMake.id);
                  }
                }}
              >
                {map(makes, (make, index) => {
                  const isUpdating = state.updatingMakeSeoId === make.id || state.updatingMakeDisabledId === make.id;
                  return (
                    <Draggable key={make.id}>
                      <motion.div initial={{ x: -200, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: -20, opacity: 0 }} key={make.id} className='builder-flex builder-items-center hover:builder-bg-gray-50 builder-relative'>
                        {isUpdating && <div className='builder-absolute builder-inset-0 builder-bg-gray-200 builder-bg-opacity-50 builder-transition-all builder-animate-pulse builder-z-10' />}
                        <div className='builder-px-4 builder-py-1 builder-whitespace-nowrap builder-text-md builder-capitalize builder-text-black builder-cursor-grab' style={{ width: '50%' }}>
                          <i className='fal fa-grip-lines-vertical builder-text-[12px] builder-text-gray-500 builder-mr-2' /> {index + 1}. {make.name?.toLowerCase()}
                        </div>
                        <div className='builder-flex builder-items-center builder-px-4 builder-py-1 builder-whitespace-nowrap builder-text-md builder-capitalize builder-text-black' style={{ width: '15%' }}>
                          <Switch context={context} isChecked={make.seo} dataTestId='dealer-make-seo-switch' onChange={(value) => onMakeSeoUpdate(make.id, make.priority, value)} />
                        </div>
                        <div className='builder-flex builder-items-center builder-px-4 builder-py-1 builder-whitespace-nowrap builder-text-md builder-capitalize builder-text-black' style={{ width: '15%' }}>
                          <Switch context={context} isChecked={make.disabled} dataTestId='dealer-make-disabled-switch' onChange={(value) => onMakeDisabledUpdate(make.id, value)} />
                        </div>
                        <div className='builder-flex builder-items-center builder-px-4 builder-py-1 builder-whitespace-nowrap builder-text-md builder-capitalize builder-text-black' style={{ width: '20%' }}>
                          <button
                            className='builder-px-2 builder-py-0.5 builder-text-[10px] builder-font-normal builder-text-gray-700 builder-bg-gray-50 hover:builder-bg-gray-200 builder-transition-all builder-rounded-md builder-border builder-border-gray-200'
                            data-testid='dealer-make-remove-button'
                            onClick={() => onRemoveFromDealerMakes(make.id)}
                          >
                            <i className='fal fa-minus builder-mr-1' /> Remove
                          </button>
                        </div>
                      </motion.div>
                    </Draggable>
                  );
                })}
              </Container>
            </AnimatePresence>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default DealerMakes;
