import React, { useReducer } from 'react';
import { initialState, reducer } from '../reducer';
import PageEditor from './PageEditor';

const PageDetailsContainer = ({ currentLanguage, setCurrentLanguage, context }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <>
      <PageEditor context={context} state={state} dispatch={dispatch} />
    </>
  );
};

export default PageDetailsContainer;
