import { actionTypes } from './reducer';

export const dataSetLoadingHandler = () => ({
  type: actionTypes.DATA_SET_LOADING
});

export const dataResetLoadingHandler = () => ({
  type: actionTypes.DATA_RESET_LOADING
});

export const dataSetHandler = (redirects) => ({
  type: actionTypes.DATA_SET,
  payload: {
    redirects
  }
});

export const pagesSetHandler = (pages) => ({
  type: actionTypes.PAGES_SET,
  payload: {
    pages
  }
});

export const redirectsFromFileSetHandler = (redirectsFromFile) => ({
  type: actionTypes.REDIRECTS_FROM_FILE_SET,
  payload: {
    redirectsFromFile
  }
});

export const redirectDeletedHandler = (redirectId) => ({
  type: actionTypes.REDIRECT_DELETED,
  payload: {
    redirectId
  }
});

export const redirectFromFileRemovedHandler = (redirectFromFileId) => ({
  type: actionTypes.REDIRECT_FROM_FILE_REMOVED,
  payload: {
    redirectFromFileId
  }
});

export const redirectFromFileAddedHandler = (redirectFromFile) => ({
  type: actionTypes.REDIRECT_FROM_FILE_ADDED,
  payload: {
    redirectFromFile
  }
});

export const redirectAddHandler = (redirect) => ({
  type: actionTypes.REDIRECT_ADD,
  payload: {
    redirect
  }
});

export const redirectBulkSetSavingHandler = () => ({
  type: actionTypes.REDIRECT_BULK_SET_SAVING
});

export const redirectBulkResetSavingHandler = () => ({
  type: actionTypes.REDIRECT_BULK_RESET_SAVING
});

export const redirectBulkSetSavedHandler = (updatedRedirectIds, notUpdatedRedirectIdsWithError) => ({
  type: actionTypes.REDIRECT_BULK_SET_SAVED,
  payload: {
    updatedRedirectIds,
    notUpdatedRedirectIdsWithError
  }
});

export const redirectSetToDeleteHandler = (id) => ({
  type: actionTypes.REDIRECT_SET_TO_DELETE,
  payload: {
    id
  }
});

export const redirectToggleDeleteAllHandler = (value) => ({
  type: actionTypes.REDIRECT_TOGGLE_DELETE_ALL,
  payload: {
    value
  }
});

export const redirectBulkSetDeletingHandler = () => ({
  type: actionTypes.REDIRECT_BULK_SET_DELETING
});

export const redirectBulkResetDeletingHandler = () => ({
  type: actionTypes.REDIRECT_BULK_RESET_DELETING
});

export const redirectBulkSetDeletedHandler = () => ({
  type: actionTypes.REDIRECT_BULK_SET_DELETED
});

export const onlineStatusCodesSetFetchingHandler = () => ({
  type: actionTypes.ONLINE_STATUS_CODES_SET_FETCHING
});

export const onlineStatusCodesResetFetchingHandler = () => ({
  type: actionTypes.ONLINE_STATUS_CODES_RESET_FETCHING
});

export const onlineStatusCodesSetHandler = (onlineStatusCodes) => ({
  type: actionTypes.ONLINE_STATUS_CODES_SET_HANDLER,
  payload: {
    onlineStatusCodes
  }
});
