import React from 'react';
import { find } from 'lodash';

export const getCustomBlockContent = (content, language) => {
  const content_for_language = find(content, (c) => c.culture === language);
  if (content_for_language) {
    return content_for_language.data;
  }
  return '';
};

const BlockComponentCustom = ({ block, context }) => {
  const customBlock = find(context.customBlocks, (b) => b.name === block.componentName);

  if (!customBlock) {
    return null;
  }
  return (
    <div id={`b${block.id.split('-')[0]}`} className='font-body' style={{ minHeight: 50 }}>
      <div
        dangerouslySetInnerHTML={{
          __html: getCustomBlockContent(customBlock.content, context.culture)
        }}
      />
    </div>
  );
};

export default BlockComponentCustom;
