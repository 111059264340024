import React, { useContext } from 'react';
import { StateContext } from '../../App';

const TrackingUser = ({ data }) => {
  return (
    <div
      title={data.userName}
      className='builder-inline-flex builder-rounded-full builder-border-2 builder-justify-center builder-items-center builder-w-6 builder-h-6 builder-capitalize -builder-ml-3 builder-bg-white builder-text-sm builder-font-normal'
    >
      {data.userName.slice(0, 2)}
    </div>
  );
};

const TrackingUserIndicationPerPage = ({ className, route }) => {
  const state = useContext(StateContext);

  let users = undefined;
  if (state.tracking?.users) {
    users = [];
    Object.keys(state.tracking?.users).forEach((userTrackingId) => {
      if (state.tracking?.users[userTrackingId].route === route && userTrackingId !== state.session.sessionId) {
        users.push(state.tracking.users[userTrackingId]);
      }
    });
  }
  return (
    <div className={`builder-flex ${className}`}>
      {users && users.length > 0 && (
        <div className='builder-inline-block builder-ml-3'>
          {users.map((user, index) => (
            <TrackingUser key={index} data={user} />
          ))}
        </div>
      )}
    </div>
  );
};

export default TrackingUserIndicationPerPage;
