import { lowerFirst } from 'lodash';
import { formatMatchPath } from '../utils';

export const actionTypes = {
  PAGE_TYPE_INITIALIZE: 'PAGE_TYPE_INITIALIZE',
  PAGE_TYPE_SET_INITIALIZED: 'PAGE_TYPE_SET_INITIALIZED',
  PAGE_TYPE_SET: 'PAGE_TYPE_SET',

  PAGE_TYPE_SET_NAME: 'PAGE_TYPE_SET_NAME',
  PAGE_TYPE_SET_PATH: 'PAGE_TYPE_SET_PATH',
  PAGE_TYPE_SET_CATEGORY: 'PAGE_TYPE_SET_CATEGORY',
  PAGE_TYPE_SET_MATCH_PATH: 'PAGE_TYPE_SET_MATCH_PATH',
  PAGE_TYPE_CLEAR_MATCH_PATH: 'PAGE_TYPE_CLEAR_MATCH_PATH',
  PAGE_TYPE_SET_DISABLED: 'PAGE_TYPE_SET_DISABLED',
  PAGE_TYPE_SET_SINGLE_USE: 'PAGE_TYPE_SET_SINGLE_USE',
  PAGE_TYPE_SET_IS_TEMPLATE: 'PAGE_TYPE_SET_IS_TEMPLATE',
  PAGE_TYPE_SET_INDEXABLE: 'PAGE_TYPE_SET_INDEXABLE'
};

export const initialPageType = {
  id: '',
  isDraft: false,
  disabled: false,
  immutable: false,
  isTemplate: false,
  indexable: false,
  matchPath: '',
  name: '',
  path: '',
  singleUse: false,
  category: 'other'
};

export const initialState = {
  saving: false,
  initPageType: {},
  pageType: initialPageType,
  formInitialized: false
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.PAGE_TYPE_SET_INITIALIZED:
      return {
        ...state,
        formInitialized: true
      };
    case actionTypes.PAGE_TYPE_INITIALIZE:
      return {
        ...state,
        pageType: {
          ...initialPageType,
          id: action.payload.id
        }
      };
    case actionTypes.PAGE_TYPE_SET:
      const pageType = {
        id: action.payload.pageType.id,
        isDraft: action.payload.pageType.isDraft,
        disabled: action.payload.pageType.disabled,
        immutable: action.payload.pageType.immutable,
        isTemplate: action.payload.pageType.isTemplate,
        indexable: action.payload.pageType.indexable,
        matchPath: formatMatchPath(action.payload.pageType.matchPath),
        name: action.payload.pageType.name,
        path: action.payload.pageType.path,
        singleUse: action.payload.pageType.singleUse,
        category: action.payload.pageType.category
      };

      return {
        ...state,
        pageType: pageType,
        initPageType: pageType
      };

    case actionTypes.PAGE_TYPE_SET_NAME:
      return {
        ...state,
        pageType: {
          ...state.pageType,
          name: lowerFirst(action.payload.name)
        }
      };
    case actionTypes.PAGE_TYPE_SET_PATH:
      return {
        ...state,
        pageType: {
          ...state.pageType,
          path: action.payload.path
        }
      };
    case actionTypes.PAGE_TYPE_SET_CATEGORY:
      return {
        ...state,
        pageType: {
          ...state.pageType,
          category: action.payload.category
        }
      };
    case actionTypes.PAGE_TYPE_SET_MATCH_PATH:
      let newMatchType = '';
      if (state.pageType.matchPath === '') {
        newMatchType = formatMatchPath(action.payload.matchPath);
      } else {
        newMatchType = formatMatchPath(`${state.pageType.matchPath}${action.payload.matchPath}`);
      }

      return {
        ...state,
        pageType: {
          ...state.pageType,
          matchPath: newMatchType
        }
      };
    case actionTypes.PAGE_TYPE_CLEAR_MATCH_PATH:
      return {
        ...state,
        pageType: {
          ...state.pageType,
          matchPath: ''
        }
      };
    case actionTypes.PAGE_TYPE_SET_DISABLED:
      return {
        ...state,
        pageType: {
          ...state.pageType,
          disabled: action.payload.disabled
        }
      };
    case actionTypes.PAGE_TYPE_SET_SINGLE_USE:
      return {
        ...state,
        pageType: {
          ...state.pageType,
          singleUse: action.payload.singleUse
        }
      };
    case actionTypes.PAGE_TYPE_SET_IS_TEMPLATE:
      return {
        ...state,
        pageType: {
          ...state.pageType,
          isTemplate: action.payload.isTemplate
        }
      };

    case actionTypes.PAGE_TYPE_SET_INDEXABLE:
      return {
        ...state,
        pageType: {
          ...state.pageType,
          indexable: action.payload.indexable
        }
      };

    default:
      return state;
  }
};
