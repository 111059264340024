import { map } from 'lodash';
import { tryParse } from '../../../utils';

// INITIAL STATES
//
export const initialState = {
  history: [], //?
  advertisement: undefined,
  advertisementGroups: [],
  adGroupsModal: {
    isOpen: false
  },
  filtersModal: {
    isOpen: false
  },
  advertisementLoading: false
};

export const initialAdvertisement = {
  id: '',
  description: '',
  content: [],
  groups: [],
  filters: [],
  disabled: false,
  isDraft: false
};

export const initialAdvertisementContent = {
  id: '',
  culture: '',
  data: '<div>Add your HTML here</div>'
};

export const initialAdvertisementGroup = {
  id: '',
  name: '',
  disabled: false,
  isDraft: false
};

export const initialAdvertisementVehicleFilter = {
  id: '',
  make: [],
  model: [],
  fuel: [],
  label: []
};

// ACTION TYPES
//
export const actionTypes = {
  ADVERTISEMENT_SET: 'ADVERTISEMENT_SET',
  ADVERTISEMENT_INITIALIZE: 'ADVERTISEMENT_INITIALIZE',
  ADVERTISEMENT_SET_DESCRIPTION: 'ADVERTISEMENT_SET_DESCRIPTION',
  ADVERTISEMENT_SET_CONTENT: 'ADVERTISEMENT_SET_CONTENT',
  ADVERTISEMENT_SET_GROUP: 'ADVERTISEMENT_SET_GROUP',
  FILTERS_MODAL_SET_OPEN: 'FILTERS_MODAL_SET_OPEN',
  ADVERTISEMENT_SET_FILTERS: 'ADVERTISEMENT_SET_FILTERS',
  ADVERTISEMENT_UNDO_CHANGES: 'ADVERTISEMENT_UNDO_CHANGES',
  ADVERTISEMENT_SET_LOADING: 'ADVERTISEMENT_SET_LOADING',
  ADVERTISEMENT_RESET_LOADING: 'ADVERTISEMENT_RESET_LOADING'
};

// REDUCER
//
export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ADVERTISEMENT_INITIALIZE:
      return {
        ...state,
        advertisement: {
          ...initialAdvertisement,
          id: action.payload.id
        },
        history: undefined
      };
    case actionTypes.ADVERTISEMENT_SET:
      const advertisement = {
        id: action.payload.advertisement.id,
        description: action.payload.advertisement.description,
        content: map(action.payload.advertisement.content, (c) => ({
          id: c.id,
          culture: c.culture,
          data: c.data
        })),
        groups: action.payload.advertisement.groups,
        filters: tryParse(action.payload.advertisement.filters),

        disabled: action.payload.advertisement.disabled,
        isDraft: action.payload.advertisement.isDraft
      };

      return {
        ...state,
        advertisement: advertisement,
        history: [advertisement]
      };
    case actionTypes.ADVERTISEMENT_SET_DESCRIPTION:
      return {
        ...state,
        advertisement: {
          ...state.advertisement,
          description: action.payload.description
        },
        history: state.history !== undefined ? [...state.history, { ...state.advertisement }] : undefined
      };
    case actionTypes.ADVERTISEMENT_SET_CONTENT:
      return {
        ...state,
        advertisement: {
          ...state.advertisement,
          content: action.payload.content
        },
        history: state.history !== undefined ? [...state.history, { ...state.advertisement }] : undefined
      };
    case actionTypes.ADVERTISEMENT_SET_GROUP:
      return {
        ...state,
        advertisement: {
          ...state.advertisement,
          groups: action.payload.group
        },
        history: state.history !== undefined ? [...state.history, { ...state.advertisement }] : undefined
      };
    case actionTypes.FILTERS_MODAL_SET_OPEN:
      return {
        ...state,
        filtersModal: action.payload.filtersModal
      };
    case actionTypes.ADVERTISEMENT_SET_FILTERS:
      return {
        ...state,
        advertisement: {
          ...state.advertisement,
          filters: tryParse(action.payload.filters)
        },
        history: state.history !== undefined ? [...state.history, { ...state.advertisement }] : undefined
      };
    case actionTypes.ADVERTISEMENT_UNDO_CHANGES:
      return {
        ...state,
        advertisement: {
          ...state.history[0]
        },
        history: [{ ...state.history[0] }]
      };
    case actionTypes.ADVERTISEMENT_SET_LOADING:
      return {
        ...state,
        advertisementLoading: true
      };
    case actionTypes.ADVERTISEMENT_RESET_LOADING:
      return {
        ...state,
        advertisementLoading: false
      };
    default:
      return state;
  }
};
