import React, { useState } from 'react';
import GenericSelect from '../../../components/common/GenericSelect';
import { map } from 'lodash';
import { PAGE_TYPE_CATEGORIES } from '../../../utils';

const PageOptions = ({ page, category, onChangeCategory, context }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleChangeCategory = (newCategory) => {
    onChangeCategory(newCategory);
  };

  return (
    <div className='builder-flex builder-flex-col'>
      <div className='builder-flex builder-justify-between builder-items-center builder-py-2 builder-border-b builder-border-gray-300 builder-shadow-sm builder-cursor-pointer' onClick={() => setIsOpen(!isOpen)}>
        <h3 className='builder-font-bold builder-text-lg builder-ml-4'>Page options</h3>
        <i className={`fas builder-mr-4 builder-text-md ${isOpen ? 'fa-caret-up' : 'fa-caret-down'}`} />
      </div>
      <div className={`${isOpen ? 'builder-flex builder-flex-col' : 'builder-hidden'}`}>
        <div className=''>
          <div className='builder-flex builder-flex-col builder-p-4'>
            <label className='builder-block builder-text-gray-700 builder-text-md builder-font-bold builder-mb-2'>Category</label>
            <GenericSelect
              placeHolder='Page category'
              items={map(PAGE_TYPE_CATEGORIES, (category) => ({ key: category.key, text: category.name }))}
              values={[category]}
              handleFilterChanged={(newCategoryObject) => {
                handleChangeCategory(newCategoryObject.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageOptions;
