import { map } from 'lodash';

export const actionTypes = {
  DATA_SET_LOADING: 'DATA_SET_LOADING',
  DATA_RESET_LOADING: 'DATA_RESET_LOADING',
  DATA_SET: 'DATA_SET',

  MAKE_SET_SEO_UPDATING: 'MAKE_SET_SEO_UPDATING',
  MAKE_RESET_SEO_UPDATING: 'MAKE_RESET_SEO_UPDATING',
  MAKE_SEO_UPDATED: 'MAKE_SEO_UPDATED',

  MAKE_SET_DISABLED_UPDATING: 'MAKE_SET_DISABLED_UPDATING',
  MAKE_RESET_DISABLED_UPDATING: 'MAKE_RESET_DISABLED_UPDATING',
  MAKE_DISABLED_UPDATED: 'MAKE_DISABLED_UPDATED',

  MAKE_SET_PRIORITIES_UPDATING: 'MAKE_SET_PRIORITIES_UPDATING',
  MAKE_RESET_PRIORITIES_UPDATING: 'MAKE_RESET_PRIORITIES_UPDATING'
};

export const initialState = {
  makes: [],
  loading: false,
  updatingMakeSeoId: null,
  updatingMakeDisabledId: null,
  updatingPriorities: false
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.DATA_SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case actionTypes.DATA_RESET_LOADING:
      return {
        ...state,
        loading: false
      };
    case actionTypes.DATA_SET:
      return {
        ...state,
        loading: false,
        makes: action.payload.makes
      };

    case actionTypes.MAKE_SET_SEO_UPDATING:
      return {
        ...state,
        updatingMakeSeoId: action.payload.makeId
      };
    case actionTypes.MAKE_RESET_SEO_UPDATING:
      return {
        ...state,
        updatingMakeSeoId: null
      };
    case actionTypes.MAKE_SEO_UPDATED:
      const { makeId, makePriority, makeSeo } = action.payload;
      const newMakes = map(state.makes, (make) => {
        if (make.id !== makeId) return make;

        return {
          ...make,
          seo: makeSeo,
          priority: makePriority
        };
      });
      return {
        ...state,
        makes: newMakes
      };

    case actionTypes.MAKE_SET_DISABLED_UPDATING:
      return {
        ...state,
        updatingMakeDisabledId: action.payload.makeId
      };
    case actionTypes.MAKE_RESET_DISABLED_UPDATING:
      return {
        ...state,
        updatingMakeDisabledId: null
      };
    case actionTypes.MAKE_DISABLED_UPDATED:
      return {
        ...state,
        makes: map(state.makes, (make) => {
          if (make.id !== action.payload.makeId) return make;

          return {
            ...make,
            disabled: action.payload.makeDisabled
          };
        })
      };

    case actionTypes.MAKE_SET_PRIORITIES_UPDATING:
      return {
        ...state,
        updatingPriorities: true
      };
    case actionTypes.MAKE_RESET_PRIORITIES_UPDATING:
      return {
        ...state,
        updatingPriorities: false
      };

    default:
      return state;
  }
};
