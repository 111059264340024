import { filter } from 'lodash';

export const hasMultipleTypes = (item, items) => {
  // Check if contentType has multiple fieldTypes
  const eq = filter(items, (elem) => {
    return elem.contentType === item.contentType && elem.fieldType !== item.fieldType;
  });

  if (eq && eq.length > 0) {
    return true;
  } else {
    return false;
  }
};
