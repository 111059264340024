import React, { useContext } from 'react';
import GenericSelect from '../../../../components/common/GenericSelect';
import { find, map } from 'lodash';
import { StateContext } from '../../../../App';
import { filterTypes } from '../reducer';
import { hasFilter } from '../../utils';

const resolveMakeModelFilter = (filterValues) => {
  if (!filterValues) return [];

  return [{ key: 'all', name: 'All', priority: 1 }, ...filterValues];
};

const resolveMakeModels = (makeKey, makes) => {
  if (!makeKey) return [];

  const selectedMake = find(makes, (make) => make.key === makeKey);
  const models = makeKey === 'all' ? [] : selectedMake?.models;

  return resolveMakeModelFilter(models);
};

const resolveFilterItems = (filterValues) => {
  if (!filterValues) return [];

  return [{ key: 'all', description: 'All', priority: 1 }, ...filterValues];
};

// Make is required: All filter selects should be disabled if make is not selected.
// One of three: If make is selected and one of fuel, transmission, or category is selected, disable the remaining two.
// One of three (with model): If make and model are selected and one of fuel, transmission, or category is selected, disable the remaining two.
const resolveIsDisabled = (filterType, filters) => {
  switch (filterType) {
    case filterTypes.MAKE:
      return false; // Make is always required, so it's never disabled
    case filterTypes.MODEL:
      return !hasFilter(filters[filterTypes.MAKE]);
    case filterTypes.CATEGORY:
      return false;
    case filterTypes.FUEL:
      return false;
    case filterTypes.TRANSMISSION:
      return false;
    default:
      return false;
  }
};

const PageSeoFilters = ({ currentFilters, onUpdateFilter, context }) => {
  const globalState = useContext(StateContext);
  const dealerMakes = resolveMakeModelFilter(globalState?.dealerMakes);
  const makeModels = resolveMakeModels(currentFilters[filterTypes.MAKE], dealerMakes);
  const categories = resolveFilterItems(globalState?.categories);
  const fuels = resolveFilterItems(globalState?.fuels);
  const transmissions = resolveFilterItems(globalState?.gearboxes);

  return (
    <>
      <div className='builder-grid builder-grid-cols-3 builder-gap-4'>
        <div style={{ zIndex: 5 }}>
          <div className='builder-text-black builder-text-md builder-mb-1'>Make</div>
          <GenericSelect
            placeHolder='Make'
            items={map(dealerMakes, (dealerMake) => ({
              key: dealerMake.key,
              text: dealerMake.name,
              priority: dealerMake.priority
            }))}
            values={[currentFilters[filterTypes.MAKE]]}
            handleFilterChanged={(item) => onUpdateFilter(filterTypes.MAKE, item.value)}
          />
        </div>

        <div style={{ zIndex: 4 }}>
          <div className='builder-text-black builder-text-md builder-mb-1'>Model</div>
          <GenericSelect
            placeHolder='Model'
            items={map(makeModels, (model) => ({
              key: model.key,
              text: model.name,
              priority: model.priority
            }))}
            values={[currentFilters[filterTypes.MODEL]]}
            handleFilterChanged={(item) => onUpdateFilter(filterTypes.MODEL, item.value)}
            isDisabled={resolveIsDisabled(filterTypes.MODEL, currentFilters)}
          />
        </div>

        <div style={{ zIndex: 3 }}>
          <div className='builder-text-black builder-text-md builder-mb-1'>Category</div>
          <GenericSelect
            placeHolder='Category'
            items={map(categories, (category) => ({
              key: category.key,
              text: category.description,
              priority: category.priority
            }))}
            values={[currentFilters[filterTypes.CATEGORY]]}
            handleFilterChanged={(item) => onUpdateFilter(filterTypes.CATEGORY, item.value)}
            isDisabled={resolveIsDisabled(filterTypes.CATEGORY, currentFilters)}
          />
        </div>

        <div style={{ zIndex: 2 }}>
          <div className='builder-text-black builder-text-md builder-mb-1'>Fuel</div>
          <GenericSelect
            placeHolder='Fuel'
            items={map(fuels, (fuel) => ({
              key: fuel.key,
              text: fuel.description,
              priority: fuel.priority
            }))}
            values={[currentFilters[filterTypes.FUEL]]}
            handleFilterChanged={(item) => onUpdateFilter(filterTypes.FUEL, item.value)}
            isDisabled={resolveIsDisabled(filterTypes.FUEL, currentFilters)}
          />
        </div>

        <div style={{ zIndex: 1 }}>
          <div className='builder-text-black builder-text-md builder-mb-1'>Gearbox</div>
          <GenericSelect
            placeHolder='Gearbox'
            items={map(transmissions, (transmission) => ({
              key: transmission.key,
              text: transmission.description,
              priority: transmission.priority
            }))}
            values={[currentFilters[filterTypes.TRANSMISSION]]}
            handleFilterChanged={(item) => onUpdateFilter(filterTypes.TRANSMISSION, item.value)}
            isDisabled={resolveIsDisabled(filterTypes.TRANSMISSION, currentFilters)}
          />
        </div>
      </div>
    </>
  );
};

export default PageSeoFilters;
