import React, { useContext, useState, useEffect, useReducer } from 'react';
import { find, map, orderBy, head } from 'lodash';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import { routes } from '../../../data/constants';
import ConfirmBox from '../../../../components/common/ConfirmBox';
import { campaignDeleteHandler, campaignsSetHandler, campaignsSetLoadingHandler, campaignsResetLoadingHandler } from '../actions';
import { reducer, initialState } from '../reducer';
import { StateContext } from '../../../../App';
import { v4 } from 'uuid';
import StatusLabel from './StatusLabel';
import LoadingSkeleton from './LoadingSkeleton';

export const CampaignsQuery = `query Campaigns{    
  campaigns {
    id
    name
    start
    stop
    isDraft
    disabled
  }   
}`;

export const CampaignDeleteMutation = `mutation CampaignDelete($data: CampaignDeleteInputType!) {
  campaignDelete(data: $data) {
    ok
  }
}`;

const Overview = ({ context, onChangeEnvironment }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const globalState = useContext(StateContext);
  const history = useHistory();

  const campaigns = state.campaigns;

  const [campaignToDelete, setCampaignToDelete] = useState('');
  const currentWebsite = find(globalState?.websites, (w) => w.id === globalState?.currentWebsite);
  const isCurrentSiteProduction = currentWebsite ? currentWebsite.isProduction : false;

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const fetchCampaigns = () => {
    campaignsSetLoadingHandler(dispatch);

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: CampaignsQuery })
    })
      .then((resp) => resp.json())
      .then((result) => {
        campaignsSetHandler(dispatch, result.data.campaigns);

        if (result?.errors) {
          const resultError = head(result?.errors);
          cogoToast.error(resultError.message);
        }

        campaignsResetLoadingHandler(dispatch);
      })
      .catch((err) => {
        cogoToast.error(err);
        campaignsResetLoadingHandler(dispatch);
      });
  };

  const handleDelete = (item) => {
    const id = item.id;

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: CampaignDeleteMutation, variables: { data: { id: id } } })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data?.campaignDelete?.ok) {
          campaignDeleteHandler(dispatch, id);
          cogoToast.success('Campaign deleted');
        } else {
          const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
          cogoToast.error(error);
        }
        setCampaignToDelete('');
      })
      .catch((err) => {
        cogoToast.error(err);
      });
  };

  const sortedCampaigns = orderBy(campaigns, ['startDate'], ['asc']);

  if (state.campaigns === undefined || state.campaignsLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <div className='builder-flex builder-flex-col builder-p-5 builder-w-full'>
      {campaignToDelete !== '' && (
        <ConfirmBox
          title='Delete campaign'
          text={`Are you sure you want to delete campaign '${campaignToDelete.name}'?`}
          type='DANGER'
          onCancel={() => setCampaignToDelete('')}
          onConfirm={() => {
            handleDelete(campaignToDelete);
            setCampaignToDelete('');
          }}
        />
      )}

      <div className='builder-flex builder-mb-5'>
        <div className='builder-text-2xl builder-text-black builder-font-bold builder-mr-4'>Campaigns</div>
        <div className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-cursor-pointer opacity-100' onClick={() => history.push(`${routes.CAMPAIGN_DETAIL}/${v4()}`)}>
          <i className='fal fa-plus builder-mr-2' />
          Add new
        </div>
      </div>

      {campaigns.length === 0 && (
        <div className='builder-rounded-md builder-bg-blue-50 builder-p-4'>
          <div className='builder-flex'>
            <div className='builder-flex-shrink-0'>
              <i className='fal fa-info-circle builder-text-blue-600' />
            </div>
            <div className='builder-ml-3 builder-flex-1 md:builder-flex md:builder-justify-between'>
              <p className='builder-text-sm builder-text-blue-600'>No campaigns available for {isCurrentSiteProduction ? 'production' : 'drafts'}.</p>
            </div>
          </div>
        </div>
      )}

      {campaigns.length > 0 && (
        <div className='builder-flex builder-flex-col builder-w-full'>
          <div className='builder-overflow-x-auto'>
            <div className='builder-py-2 builder-align-middle builder-inline-block builder-min-w-full'>
              <div className='builder-shadow builder-overflow-hidden builder-border-b builder-border-gray-200'>
                <table className='builder-min-w-full builder-divide-y builder-divide-gray-200'>
                  <thead className='builder-bg-gray-50'>
                    <tr>
                      <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '40%' }}>
                        Name
                      </th>
                      <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '15%' }}>
                        Start
                      </th>
                      <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '15%' }}>
                        Stop
                      </th>
                      <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '15%' }}>
                        Status
                      </th>
                      <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '15%' }}>
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className='builder-bg-white builder-divide-y builder-divide-gray-200'>
                    {map(sortedCampaigns, (campaign) => {
                      const canEdit = !campaign.isDraft && !isCurrentSiteProduction ? false : true; // Only edit drafts in draft environment, (production posts not editable in draft)
                      return (
                        <tr key={campaign.id}>
                          <td className='builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-font-bold builder-text-black' style={{ width: '40%' }}>
                            {campaign.name}
                          </td>
                          <td className='builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '15%' }}>
                            {campaign.startDate}
                          </td>
                          <td className='builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '15%' }}>
                            {campaign.stopDate}
                          </td>
                          <td className='builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '15%' }}>
                            <StatusLabel campaign={campaign} />
                          </td>
                          <td className='builder-flex builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '15%' }}>
                            {canEdit && (
                              <React.Fragment>
                                <div className='builder-flex builder-items-center builder-cursor-pointer builder-border-r builder-border-gray-300 builder-mr-3 builder-pr-3' onClick={() => history.push(`${routes.CAMPAIGN_DETAIL}/${campaign.id}`)}>
                                  Edit <i className='fal fa-pencil-alt builder-ml-1' style={{ fontSize: 12 }} />
                                </div>
                                <div className='builder-flex builder-items-center builder-cursor-pointer ' onClick={() => setCampaignToDelete(campaign)}>
                                  Delete <i className='fal fa-trash-alt builder-ml-1' style={{ fontSize: 12 }} />
                                </div>
                              </React.Fragment>
                            )}
                            {!canEdit && (
                              <React.Fragment>
                                <span className='builder-font-bold'>No actions allowed in draft environment</span>
                              </React.Fragment>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Overview;
