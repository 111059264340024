import { v4 } from 'uuid';
import { includes } from 'lodash';

const USER_QUERY = `query User($application: Int!){
  user(application: $application) {
    id
    firstName
    lastName   
    email
    roles
    session
  } 
  otherSessionsCount(application: $application) 
}`;

export const session_default = {
  id: v4(),
  authenticated: false,
  user: {
    id: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    roles: []
  },
  sessionId: undefined,
  editAllowed: undefined
};

export async function initSession() {
  const data = await fetchUserInfo();

  if (data !== undefined) {
    return {
      ...session_default,
      authenticated: true,
      user: {
        id: data.user.id,
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        email: data.user.email,
        roles: data.user.roles,
        isSuperUser: includes(data.user.roles, 'super_user')
      },
      sessionId: data.session,
      editAllowed: data.otherSessionsCount === 0
    };
  } else {
    return session_default;
  }
}

export const resetSessionToDefault = () => {
  return session_default;
};

export const isAuthenticatedPending = (session) => {
  return session === undefined;
};

export const isAuthenticated = (session) => {
  return session && session.authenticated;
};

export const updateSession = (session, user, sessionId, otherSessionsCount) => {
  return {
    ...session,
    authenticated: true,
    user: {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      roles: user.roles,
      isSuperUser: includes(user.roles, 'super_user')
    },
    sessionId: sessionId,
    editAllowed: otherSessionsCount === 0
  };
};

async function fetchUserInfo() {
  return fetch('/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ query: USER_QUERY, variables: { application: 0 } })
  })
    .then((resp) => resp.json())
    .then((result) => {
      const { user } = result.data;
      const { otherSessionsCount } = result.data;
      if (user) {
        return {
          user: {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            roles: user.roles
          },
          session: user.session,
          otherSessionsCount: otherSessionsCount
        };
      } else {
        return undefined;
      }
    })
    .catch((err) => {
      return undefined;
    });
}

export async function fetchUserData() {
  return fetchUserInfo();
}
