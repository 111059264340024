import React, { useReducer } from 'react';
import { initialState, reducer } from '../reducer';
import GeneralMeta from './GeneralMeta';

const GeneralMetaContainer = ({ context }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <div>
      <GeneralMeta context={context} state={state} dispatch={dispatch} />
    </div>
  );
};

export default GeneralMetaContainer;
