// CustomBlockSearchInputType = {
//   id: (uuid optional),
//   websiteId: (uuid required)
// }

import cogoToast from 'cogo-toast';
import { useReducer } from 'react';
import { initialState, reducer } from '../reducer';
import {
  customBlockDeleteHandler,
  customBlockRemoveDeletePopupHandler,
  customBlockResetDeletingHandler,
  customBlockSetDeletingHandler,
  customBlockShowDeletePopupHandler,
  customBlocksResetLoadingHandler,
  customBlocksSetHandler,
  customBlocksSetLoadingHandler
} from '../actions';

export const DataQuery = `
  query ($data: CustomBlockSearchInputType!) {
    customBlocks(data: $data) {
        id
        description
        content {
          culture
          data
          id
        }
    }
}`;

export const DeleteMutation = `
  mutation ($data: CustomBlockDeleteInputType!) {
    customBlockDelete(data: $data) {
        ok
    }
}`;

export const useCustomBlocks = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchData = (websiteId) => {
    const variables = {
      data: {
        websiteId: websiteId
      }
    };

    dispatch(customBlocksSetLoadingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: DataQuery, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data) {
          const data = result?.data;
          dispatch(customBlocksSetHandler(data?.customBlocks));
        } else {
          cogoToast.error('No data returned from the server.');
        }
        dispatch(customBlocksResetLoadingHandler());
      })
      .catch((err) => {
        cogoToast.error('Something went wrong.');
        dispatch(customBlocksResetLoadingHandler());
      });
  };

  const showDeletePopup = (id) => {
    dispatch(customBlockShowDeletePopupHandler(id));
  };

  const removeDeletePopup = () => {
    dispatch(customBlockRemoveDeletePopupHandler());
  };

  const handleDelete = (id, websiteId) => {
    const variables = {
      data: {
        id: id,
        websiteId: websiteId
      }
    };

    dispatch(customBlockSetDeletingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: DeleteMutation, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data) {
          const data = result?.data?.customBlockDelete;
          if (data?.ok) {
            dispatch(customBlockDeleteHandler(id));
            cogoToast.success('The custom block deleted.');
          } else {
            cogoToast.error('Server error. The custom block could not be deleted.');
          }
        } else {
          cogoToast.error('The custom block could not be deleted.');
        }
        dispatch(customBlockResetDeletingHandler());
        dispatch(customBlockRemoveDeletePopupHandler());
      })
      .catch((err) => {
        cogoToast.error('Something went wrong.');
        dispatch(customBlockResetDeletingHandler());
        dispatch(customBlockRemoveDeletePopupHandler());
      });
  };

  return {
    fetchData: (websiteId) => fetchData(websiteId),
    showDeletePopup: (id) => showDeletePopup(id),
    removeDeletePopup: () => removeDeletePopup(),
    handleDelete: (id, websiteId) => handleDelete(id, websiteId),
    loading: state.customBlocksLoading,
    error: state.customBlocksError,
    state: state
  };
};
