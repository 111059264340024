import React, { useContext, useEffect } from 'react';
import ConfirmBox from '../../../../components/common/ConfirmBox';
import { find } from 'lodash';
import { StateContext } from '../../../../App';
import Header from './Header';
import CustomBlocks from './CustomBlocks';
import { useCustomBlocks } from '../hooks/useCustomBlocks';
import LoadingSkeleton from './LoadingSkeleton';
import { ErrorIndicator } from '../../../../components/common';

const Overview = ({ context, currentLanguage, setCurrentLanguage }) => {
  const contextState = useContext(StateContext);

  const currentWebsite = find(contextState?.websites, (w) => w.id === contextState.currentWebsite);
  const { fetchData, showDeletePopup, removeDeletePopup, handleDelete, loading, error, state } = useCustomBlocks();

  useEffect(() => {
    if (currentWebsite) {
      fetchData(currentWebsite.id);
    }
  }, [currentWebsite]);

  if (!currentWebsite || loading) {
    return <LoadingSkeleton />;
  }

  if (currentWebsite && !loading && error) {
    return (
      <div className='builder-p-5 builder-w-full builder-relative'>
        <ErrorIndicator error='The custom blocks could not be fetched.' />
      </div>
    );
  }

  return (
    <div data-testid='custom-blocks-overview-container' className='builder-flex builder-flex-col builder-px-5 builder-pb-5 builder-w-full builder-relative'>
      {state.customBlockConfirmDeleteId !== undefined && (
        <ConfirmBox
          title='Delete custom block'
          text='Are you sure you want to delete this custom block?'
          type='DANGER'
          onCancel={removeDeletePopup}
          onConfirm={() => handleDelete(state.customBlockConfirmDeleteId, currentWebsite.id)}
          loading={state.customBlockDeleting}
        />
      )}
      <Header context={context} />
      <CustomBlocks customBlocks={state?.customBlocks} onHandleDeletePopup={showDeletePopup} context={context} />
    </div>
  );
};

export default Overview;
