import React, { useEffect, useState } from 'react';
import { default as MainHeader } from '../../../components/Header';
import Detail from './Detail';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { routes } from '../../data/constants';
import { useManualQuery, useMutation } from 'graphql-hooks';
import { DealershipQuery, DealershipUpdateMutation } from '../../data/graphql';
import LoadingIndicator from '../../../components/common/indicators/LoadingIndicator';
import ErrorIndicator from '../../../components/common/indicators/ErrorIndicator';
import { groupBy, isEmpty, isEqual, mapValues, omit, orderBy } from 'lodash';
import cogoToast from 'cogo-toast';
import Header from './Header';
import { flatMap } from 'lodash/collection';
import { cleanedClosingDaysForCompare, cleanedOpeningsHoursForCompare } from '../utils';

const initialDealership = {
  id: '',
  name: '',
  address: '',
  postal: '',
  city: '',
  reference: '',
  email: '',
  facebook: '',
  twitter: '',
  instagram: '',
  linkedin: '',
  fax: '',
  tel: '',
  url: '',
  image: '',
  openingsHours: [],
  closingDays: []
};

const Dealership = ({ context }) => {
  const [initDealership, setInitDealership] = useState(initialDealership);
  const [dealership, setDealership] = useState(initialDealership);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  let location = useLocation();
  let history = useHistory();
  const { id } = useParams();
  const [fetchDealership, { loading: fetching, error: errorFetchingDealership }] = useManualQuery(DealershipQuery, { variables: { id: parseInt(id) } });
  const [updateMutation, { loading: updating }] = useMutation(DealershipUpdateMutation);

  useEffect(() => {
    if (location.state) {
      if (location.state.dealership) {
        setInitDealership(location.state.dealership);
        setDealership(location.state.dealership);
      }
    } else {
      const getDealership = async () => {
        const res = await fetchDealership();
        setInitDealership(res.data.dealership);
        setDealership(res.data.dealership);
      };
      getDealership();
    }
    history.replace(); // Clear location state.
  }, []);

  const onChange = (newDealership) => {
    setDealership(newDealership);
  };

  const handleGeneralSettingsSave = () => {
    updateMutation({
      variables: {
        data: dealership
      }
    })
      .then((res) => {
        const { ok } = res.data.dealershipUpdate;
        if (ok) {
          cogoToast.success('Updated!');
          setInitDealership(dealership);
        }
      })
      .catch((error) => {
        cogoToast.error('Error updating!');
      });
  };

  const isDealershipChanged = (oldDealership, newDealership) => {
    const cleanedInitialDealershipToCompare = { ...oldDealership, openingsHours: cleanedOpeningsHoursForCompare(oldDealership.openingsHours), closingDays: cleanedClosingDaysForCompare(oldDealership.closingDays) };
    const cleanedUpdatedDealershipToCompare = { ...newDealership, openingsHours: cleanedOpeningsHoursForCompare(newDealership.openingsHours), closingDays: cleanedClosingDaysForCompare(newDealership.closingDays) };
    return JSON.stringify(cleanedInitialDealershipToCompare) !== JSON.stringify(cleanedUpdatedDealershipToCompare);
  };

  const hasChanges = () => {
    return isDealershipChanged(initDealership, dealership);
  };

  const handleBack = () => {
    if (hasChanges()) {
      setShowConfirmBox(true);
    } else {
      history.push(routes.SETTINGS_DEALERSHIPS);
    }
  };

  const handleCancel = () => {
    history.push(routes.SETTINGS_DEALERSHIPS);
  };

  const handleBackConfirm = () => {
    history.push(routes.SETTINGS_DEALERSHIPS);
  };

  const handleBackCancel = () => {
    setShowConfirmBox(false);
  };

  const dealershipName = !isEmpty(dealership) ? dealership.name : 'Dealership';
  return (
    <div className='builder-flex builder-flex-1 builder-flex-col builder-h-screen builder-font-body builder-text-sm'>
      <div className='builder-sticky builder-top-0 builder-z-10 builder-bg-white'>
        <MainHeader />
      </div>

      <Header title={dealershipName} onBack={handleBack} onCancel={handleCancel} onSave={handleGeneralSettingsSave} loading={updating} saveDisabled={!hasChanges()} />

      <div className='builder-flex builder-items-center builder-justify-center builder-bg-white'>
        <div className='builder-container'>
          {fetching && !errorFetchingDealership && <LoadingIndicator />}
          {!fetching && errorFetchingDealership && <ErrorIndicator error='The dealership could not be fetched.' />}
          {!fetching && !errorFetchingDealership && (
            <Detail dealership={dealership} showConfirmBox={showConfirmBox} onBackConfirm={handleBackConfirm} onBackCancel={handleBackCancel} handleChange={(newDealership) => onChange(newDealership)} context={context} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dealership;
