import { actionTypes } from './reducer';

export const pageTypeInitializeHandler = (id) => ({
  type: actionTypes.PAGE_TYPE_INITIALIZE,
  payload: {
    id
  }
});

export const pageTypeSetInitializedHandler = () => ({
  type: actionTypes.PAGE_TYPE_SET_INITIALIZED
});

export const pageTypeSetHandler = (pageType) => ({
  type: actionTypes.PAGE_TYPE_SET,
  payload: {
    pageType
  }
});

export const pageTypeSetNameHandler = (name) => ({
  type: actionTypes.PAGE_TYPE_SET_NAME,
  payload: {
    name
  }
});

export const pageTypeSetPathHandler = (path) => ({
  type: actionTypes.PAGE_TYPE_SET_PATH,
  payload: {
    path
  }
});

export const pageTypeSetCategoryHandler = (category) => ({
  type: actionTypes.PAGE_TYPE_SET_CATEGORY,
  payload: {
    category
  }
});

export const pageTypeClearMatchPathHandler = () => ({
  type: actionTypes.PAGE_TYPE_CLEAR_MATCH_PATH
});

export const pageTypeSetMatchPathHandler = (matchPath) => ({
  type: actionTypes.PAGE_TYPE_SET_MATCH_PATH,
  payload: {
    matchPath
  }
});

export const pageTypeSetDisabledHandler = (disabled) => ({
  type: actionTypes.PAGE_TYPE_SET_DISABLED,
  payload: {
    disabled
  }
});

export const pageTypeSetSingleUseHandler = (singleUse) => ({
  type: actionTypes.PAGE_TYPE_SET_SINGLE_USE,
  payload: {
    singleUse
  }
});

export const pageTypeSetIsTemplateHandler = (isTemplate) => ({
  type: actionTypes.PAGE_TYPE_SET_IS_TEMPLATE,
  payload: {
    isTemplate
  }
});

export const pageTypeSetIndexableHandler = (indexable) => ({
  type: actionTypes.PAGE_TYPE_SET_INDEXABLE,
  payload: {
    indexable
  }
});
