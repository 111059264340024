import React, { useEffect, useRef, useState } from 'react';
import { get, filter, some, map, includes, find } from 'lodash';
import { useDrag, useDrop } from 'react-dnd';
import { DND_TYPES } from '../../../../constants';
import QuestionGroups from './QuestionGroups';
import Row from './Row';
import { orderByPriority } from '../../utils';
import { ROW_HEIGHT, SPACE_BETWEEN_CATEGORY_ROWS } from '../reducer';
import { collapseStatusChangeHandler, collapseStatusesOnDragHandler, resetCollapseStatusesOnDragDoneHandler } from '../actions';

const Category = ({ category, index, state, dispatch, currentLanguage, onEditCategory, onDeleteCategory, onChangePriority, isDeleting }) => {
  const [height, setHeight] = useState(0);
  const dndCategoryRef = useRef(null);

  const categoryId = category.id;
  const categoryTitle = get(category.title, currentLanguage);
  const categoryGroups = orderByPriority(filter(state.questionGroups, (questionGroup) => questionGroup.categoryId === category.id));

  const isCollapsible = some(state.questionGroups, (questionGroup) => questionGroup.categoryId === categoryId);
  const collapseStatus = find(state?.collapseStatus?.categories, (collapseStatus) => collapseStatus.id === category.id);
  const isCollapsed = collapseStatus?.isCollapsed;
  const acceptedDragANdDropType = DND_TYPES.CATEGORY;
  const canBeDeleted = !isCollapsible;

  // Get total children (groups and questions) in this category
  const resolveTotalCategoryChildren = () => {
    const categoryGroupIds = map(
      filter(state.questionGroups, (questionGroup) => questionGroup.categoryId === categoryId),
      (group) => group.id
    );

    const notCollapsedGroupIds = map(
      filter(state?.collapseStatus?.questionGroups, (group) => includes(categoryGroupIds, group.id) && group.isCollapsed === false),
      (group) => group.id
    );

    const categoryQuestions = filter(state.questions, (question) => includes(notCollapsedGroupIds, question.groupId));

    return categoryGroupIds.length + categoryQuestions.length;
  };

  // Calculate category content height based on total rows
  const resolveContainerHeight = () => {
    const totalCategoryChildren = resolveTotalCategoryChildren();
    const totalContainerHeight = totalCategoryChildren * ROW_HEIGHT + (totalCategoryChildren - 1) * SPACE_BETWEEN_CATEGORY_ROWS;
    return totalContainerHeight > 0 ? totalContainerHeight : 0;
  };

  // Effect which calculates the category content height on every add, delete of collapse of a child
  useEffect(() => {
    setHeight(resolveContainerHeight());
  }, [state.categories, state.questionGroups, state.questions, state.collapseStatus]);

  const handleEditCategory = (e) => {
    e.stopPropagation();
    onEditCategory(categoryId);
  };

  const handleDeleteCategory = (e) => {
    e.stopPropagation();
    onDeleteCategory(categoryId);
  };

  const handleRowClick = () => {
    if (!isDeleting && isCollapsible) {
      dispatch(collapseStatusChangeHandler('categories', categoryId));
    }
  };

  // Droppable
  const [{ handlerId, isOver }, drop] = useDrop({
    accept: acceptedDragANdDropType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: !!monitor.isOver()
      };
    },
    drop(item, monitor) {
      if (!dndCategoryRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Time to actually perform the action
      if (isOver) {
        onChangePriority(dragIndex, hoverIndex);
      }
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  // Draggable
  const [{ isDragging }, drag] = useDrag({
    type: acceptedDragANdDropType,
    item: () => {
      return { index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  drag(drop(dndCategoryRef));

  const resolveStyling = () => {
    // content not visible
    if (isCollapsed || isDragging || !isCollapsible) {
      return {
        style: { height: 0 },
        classes: 'builder-opacity-0'
      };
    }

    // content visible
    return {
      style: { height: height ? height : '' },
      classes: 'builder-opacity-100'
    };
  };

  const { classes, style } = resolveStyling();

  // Effect to collapse question groups on drag and undo it if drag is done
  useEffect(() => {
    isDragging ? dispatch(collapseStatusesOnDragHandler('categories')) : dispatch(resetCollapseStatusesOnDragDoneHandler('categories'));
  }, [isDragging]);

  return (
    <div className='builder-overflow-hidden'>
      <div ref={dndCategoryRef} data-handler-id={handlerId} className={`${isCollapsible && !isCollapsed ? 'builder-mb-2' : ''}`}>
        {isDeleting && <div data-testid='loading-overlay' className='builder-absolute builder-inset-0 builder-bg-gray-600 builder-opacity-30 builder-z-50' />}

        <Row title={categoryTitle} onClick={handleRowClick} onEdit={handleEditCategory} onDelete={handleDeleteCategory} canBeDeleted={canBeDeleted} isCollapsed={isCollapsed} isCollapsible={isCollapsible} isOver={isOver} isDragging={isDragging} />
      </div>
      <div className={`builder-ml-4 builder-transition-all builder-ease-in-out builder-transform ${classes}`} style={{ ...style }}>
        <QuestionGroups questionGroups={categoryGroups} currentLanguage={currentLanguage} state={state} dispatch={dispatch} />
      </div>
    </div>
  );
};

export default Category;
