import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { routes } from '../../../../data/constants';
import ButtonWithIndicator from '../../../../../components/common/ButtonWithIndicator';
import TextInput from '../../../../builder/editors/components/TextInput';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { find, findKey, forEach, get, head, isEmpty, map } from 'lodash';
import { v4 } from 'uuid';
import { StateContext } from '../../../../../App';

import cogoToast from 'cogo-toast';
import GroupSelector from './GroupSelector';
import LoadingSkeleton from './LoadingSkeleton';
import {
  groupsAndCategoriesResetFetchingHandler,
  groupsAndCategoriesSetFetchingHandler,
  groupsAndCategoriesSetHandler,
  questionGroupAddHandler,
  questionInitializeHandler,
  questionResetLoadingHandler,
  questionResetSavingHandler,
  questionSetAnswerHandler,
  questionSetGroupHandler,
  questionSetHandler,
  questionSetInitializedHandler,
  questionSetLoadingHandler,
  questionSetSaveCompletedHandler,
  questionSetSavingHandler,
  questionSetTitleHandler,
  questionUndoChangesHandler
} from '../actions';
import { initialAnswer, initialState, reducer } from '../reducer';
import WysiwygEditor from '../../../../../components/common/WysiwygEditor';
import CustomModal from '../../../../../components/common/Modal';
import { default as QuestionGroupDetail } from '../../../question-groups/detail/components/Detail';

export const CategoriesAndGroupsQuery = `
  query CategoriesAndGroupsQuery {
    faqCategories {
        id
        title        
        priority
    }
    
    faqQuestionGroups {
        id
        title
        categoryId
        priority
    }
}`;

export const QuestionQuery = `query FaqQuestions($data: FaqQuestionSearchInputType!) { 
  faqQuestions(data: $data) { 
    id
    title
    answer {
        id
        culture
        data
    }
    groupId
    priority
    isDraft
    disabled
  } 
}`;

export const QuestionSaveMutation = `mutation FaqQuestionUpdate($data: FaqQuestionUpdateInputType!) {
  faqQuestionUpdate(data: $data) {
      ok
  }
}`;

const Detail = ({ context, currentLanguage, setCurrentLanguage }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const globalState = useContext(StateContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [formErrors, setFormErrors] = useState({});
  const { id: idFromUrl } = useParams();
  const languages = globalState?.configuration?.website?.languages;

  const history = useHistory();
  const location = useLocation();
  const isCreate = location?.state?.isCreate;

  const fetchQuestion = (questionId) => {
    dispatch(questionSetLoadingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: QuestionQuery, variables: { data: { id: questionId } } })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data) {
          const question = head(result?.data.faqQuestions);
          if (!question) {
            dispatch(questionInitializeHandler(idFromUrl));
          } else {
            dispatch(questionSetHandler(question));
          }
          dispatch(questionResetLoadingHandler());
        } else {
          dispatch(questionResetLoadingHandler());
          cogoToast.error('No data returned from the server.');
        }
      })
      .catch((err) => {
        dispatch(questionResetLoadingHandler());
        cogoToast.error('Something went wrong.');
      });
  };

  const fetchGroupsAndCategories = () => {
    dispatch(groupsAndCategoriesSetFetchingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: CategoriesAndGroupsQuery })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data) {
          const categories = result?.data?.faqCategories;
          const groups = result?.data?.faqQuestionGroups;
          dispatch(groupsAndCategoriesSetHandler(categories, groups));
        } else {
          dispatch(groupsAndCategoriesResetFetchingHandler());
          cogoToast.error('No data returned from the server.');
        }
      })
      .catch((err) => {
        dispatch(groupsAndCategoriesResetFetchingHandler());
        cogoToast.error('Something went wrong.');
      });
  };

  useEffect(() => {
    if (!isCreate) {
      fetchQuestion(idFromUrl);
    } else {
      dispatch(questionInitializeHandler(idFromUrl));
    }
    fetchGroupsAndCategories();
    history.replace(`${routes.FAQ_QUESTION_DETAIL}/${idFromUrl}`);
  }, []);

  useEffect(() => {
    if (state.formInitialized) {
      setFormErrors({});
      const { errors } = validateForm();
      setFormErrors(errors);
    }
  }, [state.question, state.formInitialized]);

  if (state.loading || state.groupsAndCategoriesFetching) {
    return <LoadingSkeleton />;
  }

  const question = state.question;

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    forEach(languages, (language) => {
      if (isEmpty(question.title[language])) {
        errors[`title-${language}`] = 'The title needs to be added in all languages.';
        isValid = false;
      }
    });

    // The category is required
    if (!question.groupId) {
      errors['group'] = 'The group needs to be selected.';
      isValid = false;
    }

    return {
      isValid,
      errors
    };
  };

  const isValid = () => {
    setFormErrors({});
    const { isValid, errors } = validateForm();
    setFormErrors(errors);
    return isValid;
  };

  const saveQuestion = ({ addAnother }) => {
    dispatch(questionSetInitializedHandler());
    if (isValid()) {
      dispatch(questionSetSavingHandler());
      const variables = {
        data: {
          id: question.id,
          title: JSON.stringify(question.title),
          groupId: question.groupId,
          answer: question.answer,
          disabled: question.disabled,
          isDraft: question.isDraft
        }
      };

      fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query: QuestionSaveMutation, variables: variables })
      })
        .then((resp) => resp.json())
        .then((result) => {
          if (result?.data?.faqQuestionUpdate?.ok) {
            cogoToast.success('Question saved');
            dispatch(questionSetSaveCompletedHandler());
            if (addAnother) {
              const newId = v4();
              dispatch(questionInitializeHandler(newId));
              history.replace(`${routes.FAQ_QUESTION_DETAIL}/${newId}`);
            }
          } else {
            const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
            cogoToast.error(error);
            dispatch(questionResetSavingHandler());
          }
        })
        .catch((err) => {
          cogoToast.error('Something went wrong. The question could not be saved.');
          dispatch(questionResetSavingHandler());
        });
    }
  };

  const onCreateCategory = () => {
    const id = v4();
    history.push(`${routes.FAQ_CATEGORY_DETAIL}/${id}`, {
      isCreate: true
    });
  };

  const onCreateGroup = () => {
    // const id = v4();
    // history.push(`${routes.FAQ_QUESTION_GROUP_DETAIL}/${id}`, {
    //   isCreate: true
    // });
    setIsModalOpen(true);
  };

  const tabClassNames = 'relative -bottom-px builder-p-3 builder-cursor-pointer builder-text-black builder-text-md builder-uppercase focus:outline-none';
  const activeTabClassNames = 'builder-font-bold builder-bg-white builder-border-t builder-border-r builder-border-l builder-border-b-0 builder-rounded-sm builder-border-gray-300';
  const tabPanelClassNames = 'builder-bg-white builder-border-l builder-border-r builder-border-gray-300';

  const isLoading = state.saving || state.loading || state.categoriesFetching;

  const handleQuestionGroupSave = (category, questionGroup) => {
    dispatch(questionGroupAddHandler(category, questionGroup));
    setIsModalOpen(false);
  };

  return (
    <div className='builder-flex builder-flex-col builder-flex-1 builder-p-5'>
      <div className='builder-flex builder-flex-row builder-items-center builder-justify-between builder-mb-5'>
        <div className='builder-flex builder-flex-row builder-items-center'>
          <Link to={`${routes.FAQ_OVERVIEW}`} className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-cursor-pointer opacity-100 builder-mr-4'>
            <i className='fa fa-chevron-left builder-mr-2' />
            Overview
          </Link>
          <div className='builder-text-2xl builder-text-black builder-font-bold builder-mr-4'>Question</div>
        </div>
        <div className='flex'>
          {state.history === undefined && (
            <ButtonWithIndicator
              loading={isLoading}
              disabled={isLoading}
              onClick={() => history.push(`${routes.FAQ_OVERVIEW}`)}
              icon='fal fa-times'
              text='Cancel'
              colorClass='builder-bg-primary builder-text-white'
              borderClass='builder-border builder-border-bg-blue-500'
              className='builder-ml-0 builder-mr-0'
            />
          )}
          {state.history && (
            <ButtonWithIndicator
              loading={isLoading}
              disabled={state.history.length === 1 || isLoading}
              onClick={() => dispatch(questionUndoChangesHandler())}
              icon='fal fa-undo'
              text='Undo changes'
              colorClass='builder-bg-primary builder-text-white'
              borderClass='builder-border builder-border-bg-blue-500'
              className='builder-ml-0 builder-mr-0'
            />
          )}

          <ButtonWithIndicator
            loading={isLoading}
            disabled={isLoading}
            onClick={saveQuestion}
            icon='fal fa-save'
            text='Save'
            colorClass='builder-bg-primary builder-text-white'
            borderClass='builder-border builder-border-bg-blue-500'
            className='builder-ml-0 builder-mr-0'
          />

          <ButtonWithIndicator
            loading={isLoading}
            disabled={isLoading}
            onClick={() => saveQuestion({ addAnother: true })}
            icon='far fa-layer-plus'
            text='Save and add another'
            colorClass='builder-bg-primary builder-text-white'
            borderClass='builder-border builder-border-bg-blue-500'
            className='builder-ml-0 builder-mr-0'
          />
        </div>
      </div>
      <div className='builder-flex builder-p-5 builder-w-full h-full builder-bg-gray-50 builder-relative'>
        {isLoading && <div className='builder-absolute builder-inset-0 builder-bg-gray-200 builder-z-20 builder-opacity-30' />}

        <div className='builder-w-3/4 builder-mr-5'>
          <div className='builder-flex builder-w-full h-full'>
            <div className='builder-w-full'>
              <div className='builder-flex builder-flex-col builder-mb-5'>
                <Tabs defaultIndex={0}>
                  <TabList className='builder-flex builder-flex-row builder-justify-start builder-align-center builder-border-b builder-border-gray-300'>
                    {map(languages, (language) => {
                      const hasErrors = findKey(formErrors, (value, key) => key.endsWith(`-${language}`));
                      const className = `${tabClassNames} ${hasErrors ? 'builder-bg-red-500' : ''}`;
                      return (
                        <Tab key={`question-${language}`} className={className} selectedClassName={activeTabClassNames}>
                          {language}
                        </Tab>
                      );
                    })}
                  </TabList>

                  {map(languages, (language) => {
                    const questionTitle = get(question.title, language);
                    const questionAnswer = find(question?.answer, (answer) => answer.culture === language);
                    return (
                      <TabPanel key={`question-content-${language}`} className={tabPanelClassNames}>
                        <div className='builder-flex builder-flex-col builder-p-4 builder-space-y-4 builder-border-b builder-border-gray-300'>
                          {/*TITLE*/}
                          <div>
                            <TextInput
                              dataTestId='question-input-title'
                              label='Title'
                              placeHolder='Add question title'
                              disabled={isLoading}
                              className='builder-flex builder-flex-1 builder-max-w-xl builder-rounded-md'
                              value={questionTitle}
                              errorText={formErrors[`title-${language}`]}
                              onChanged={(value) => dispatch(questionSetTitleHandler(value, language))}
                            />
                          </div>

                          {/*ANSWER*/}
                          <div>
                            <div className='builder-text-black builder-text-md builder-font-bold builder-mb-2'>Content</div>
                            <WysiwygEditor data={questionAnswer?.data || initialAnswer.data} onChange={(newValue) => dispatch(questionSetAnswerHandler(questionAnswer?.id, newValue, language))} />
                          </div>
                        </div>
                      </TabPanel>
                    );
                  })}
                </Tabs>
              </div>
            </div>
          </div>
        </div>

        <div className='builder-w-1/4'>
          <GroupSelector
            categories={state.categories}
            groups={state.groups}
            currentLanguage={currentLanguage}
            selectedGroupId={question.groupId}
            onSelectGroup={(groupId) => dispatch(questionSetGroupHandler(groupId))}
            handleCreateCategory={onCreateCategory}
            handleCreateGroup={onCreateGroup}
            errorText={formErrors['group']}
          />
        </div>
      </div>

      <CustomModal isOpen={isModalOpen} onClose={() => setIsModalOpen(!isModalOpen)} title='Add new group'>
        <QuestionGroupDetail
          context={context}
          currentLanguage={currentLanguage}
          setCurrentLanguage={setCurrentLanguage}
          modal={{
            onClose: () => setIsModalOpen(!isModalOpen),
            onSave: (category, questionGroup) => handleQuestionGroupSave(category, questionGroup)
          }}
        />
      </CustomModal>
    </div>
  );
};

export default Detail;
