import React, { useEffect, useRef, useState } from 'react';
import { get, map } from 'lodash';

const Topic = ({ topic, categories, context, currentLanguage, state, dispatch, onEdit, onDelete, isDeleting }) => {
  const [categoriesMaxWidth, setCategoriesMaxWidth] = useState('auto');
  const topicTitle = get(topic.title, currentLanguage);
  const titleRef = useRef(null);
  const buttonsRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef?.current && titleRef?.current && buttonsRef?.current) {
      const categoriesMaxWidth = contentRef?.current?.clientWidth - titleRef?.current?.clientWidth - buttonsRef?.current?.clientWidth - 48 - 32;
      setCategoriesMaxWidth(categoriesMaxWidth);
    }
  }, []);

  const handleEditTopic = (e) => {
    e.stopPropagation();
    onEdit(topic.id);
  };

  const handleDeleteTopic = (e) => {
    e.stopPropagation();
    onDelete(topic.id);
  };

  const renderCategories = () => {
    if (!categories || categories.length < 1) return;

    return (
      <div className='builder-flex builder-flex-1 builder-items-center builder-text-gray-700'>
        <div className='builder-mx-2'>|</div>
        <div className='builder-mr-2 builder-font-thin'>Categories:</div>
        {map(categories, (category, index) => {
          const categoryTitle = get(category.title, currentLanguage);
          const isLast = index === categories.length - 1;
          return (
            <div key={category.id} className='builder-flex builder-items-center builder-whitespace-nowrap'>
              {categoryTitle}
              {!isLast && <div style={{ marginRight: 4 }}>,</div>}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div ref={contentRef} className='builder-flex builder-items-center builder-border-b builder-border-gray-300 builder-z-30 builder-relative builder-bg-white builder-max-w-7xl'>
      {isDeleting && <div data-testid='loading-overlay' className='builder-absolute builder-inset-0 builder-bg-gray-600 builder-opacity-30 builder-z-50' />}

      <div className='builder-flex builder-w-full builder-items-center builder-space-x-6 builder-px-4' style={{ height: 42 }}>
        <div ref={titleRef} className='builder-flex builder-items-center'>
          <div className='builder-whitespace-nowrap'>{topicTitle}</div>
        </div>

        <div className='builder-overflow-scroll no-scrollbar' style={{ width: categoriesMaxWidth }}>
          {renderCategories()}
        </div>

        <div ref={buttonsRef} className='builder-flex builder-items-center builder-z-20'>
          <div onClick={handleEditTopic} className='builder-flex builder-items-center builder-cursor-pointer hover:builder-text-gray-800 builder-transition-all'>
            <i className='far fa-pencil builder-mr-1' style={{ fontSize: 11, marginTop: -2 }} /> Edit
          </div>
          <div className='builder-mx-2'>|</div>
          <div onClick={handleDeleteTopic} className='builder-flex builder-items-center builder-cursor-pointer hover:builder-text-gray-800 builder-transition'>
            <i className='fal fa-trash-alt builder-mr-1' style={{ fontSize: 11, marginTop: -2 }} /> Delete
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topic;
