import React, { useContext, useEffect } from 'react';
import PageMeta from '../../details/components/PageMeta';
import { useGeneralMeta, useGeneralMetaState } from '../hooks';
import { StateContext } from '../../../../App';
import { find } from 'lodash';
import ButtonWithIndicator from '../../../../components/common/ButtonWithIndicator';
import LoadingSkeleton from './LoadingSkeleton';
import cogoToast from 'cogo-toast';

const GeneralMeta = ({ state, dispatch, context }) => {
  const contextState = useContext(StateContext);
  const currentWebsite = find(contextState?.websites, (w) => w.id === contextState.currentWebsite);

  const { fetchPage, fetching, page, savePage, saving } = useGeneralMeta(state, dispatch);
  const { onMetaUpdate, toggleCollapse } = useGeneralMetaState(state, dispatch);

  const handleSave = () => {
    const hasChanges = state.hasUnsavedChanges;
    if (hasChanges) {
      savePage(state.page, currentWebsite.id);
    } else {
      cogoToast.info('No changes to save');
    }
  };

  useEffect(() => {
    fetchPage(currentWebsite.id);
  }, []);

  if (fetching) {
    return (
      <div className='builder-mb-6'>
        <LoadingSkeleton />
      </div>
    );
  }

  return (
    <div data-testid='seo-general-meta' className='builder-space-y-4 builder-max-w-7xl builder-min-w-min builder-bg-white builder-mb-6'>
      <div className='builder-flex builder-items-center builder-justify-between builder-p-4 builder-bg-gray-50 builder-text-black builder-text-[18px] builder-font-bold builder-mb-2 builder-cursor-pointer' onClick={toggleCollapse}>
        General meta
        <i className={`fal ${state.isCollapsed ? 'fa-angle-down' : 'fa-angle-up'}`} style={{ fontSize: 24 }} />
      </div>
      {!state.isCollapsed && (
        <div>
          <div className='builder-leading-[20px] builder-px-4'>
            The default meta title and meta description are from the home page. You can edit them here or go to the home page in the list below. <br />
            On any other page, if they are not added, those from home page will be applied.
          </div>
          <div className='builder-p-4'>
            <PageMeta page={page} onMetaUpdate={onMetaUpdate} context={context} isLoading={saving || fetching} />

            <div className='builder-flex builder-justify-end builder-mt-4'>
              <ButtonWithIndicator
                dataTestId='page-general-meta-save-button'
                loading={saving}
                disabled={saving}
                onClick={handleSave}
                icon='fal fa-save'
                text='Save'
                colorClass='builder-bg-primary builder-text-white'
                borderClass='builder-border builder-border-bg-blue-500'
                className='builder-ml-0 builder-mr-0'
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GeneralMeta;
