import React, { useState } from 'react';
import { get, map } from 'lodash';

const CategorySelector = ({ categories, selectedCategoryId, onSelectCategory, handleCreateCategory, currentLanguage, errorText, className, isCollapsible }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const onHandleClick = (e, categoryId) => {
    e.stopPropagation();
    const isSelected = e.target.checked;
    onSelectCategory(isSelected ? categoryId : '');
  };

  if (categories.length === 0) {
    return (
      <React.Fragment>
        <div className='builder-font-bold builder-mt-4'>No categories defined.</div>
        <div className='builder-underline builder-font-semibold builder-cursor-pointer builder-mt-4' onClick={handleCreateCategory}>
          Create new category
        </div>
      </React.Fragment>
    );
  }

  return (
    <div>
      <div data-testid='question-groups-category-wrapper' className={`${className} ${errorText ? 'builder-bg-red-50' : 'builder-bg-white'}`}>
        <div className={`builder-flex builder-justify-between ${isCollapsible ? 'builder-cursor-pointer' : ''}`} onClick={() => (isCollapsible ? setIsCollapsed(!isCollapsed) : undefined)}>
          <div className='builder-text-black builder-text-md builder-font-bold'>Categories</div>
          {isCollapsible && <i className={`fas builder-text-md ${isCollapsed ? 'fa-caret-down' : 'fa-caret-up'}`} />}
        </div>

        <div className={`builder-space-y-2 builder-transition-all builder-ease-in builder-transform ${isCollapsed ? 'builder-hidden' : 'builder-block builder-mt-4'}`}>
          {map(categories, (category) => {
            const categoryTitle = get(category.title, currentLanguage);
            const isSelected = category.id === selectedCategoryId;
            return (
              <div key={category.id} className='builder-flex builder-items-center builder-group'>
                <div className='builder-mr-2'>
                  <input
                    id={category.id}
                    data-testid='question-group-category-checkbox'
                    type='checkbox'
                    checked={isSelected}
                    onChange={(e) => onHandleClick(e, category.id)}
                    className='builder-h-4 builder-w-4 builder-text-indigo-600 focus:builder-ring-indigo-500 builder-border builder-border-gray-300 builder-rounded-md builder-cursor-pointer'
                  />
                </div>
                <label htmlFor={category.id} className='group-hover:builder-text-gray-700 cursor-pointer'>
                  {categoryTitle}
                </label>
              </div>
            );
          })}

          <div data-testid='add-category-button' className='builder-flex builder-justify-end builder-mt-6 builder-cursor-pointer builder-font-bold hover:builder-text-gray-600' onClick={handleCreateCategory}>
            <i className='far fa-plus builder-mr-2' /> Add new category
          </div>
        </div>
      </div>
      {errorText && <div className='builder-mt-2 builder-text-sm builder-text-red-500'>{errorText}</div>}
    </div>
  );
};

CategorySelector.defaultProps = {
  className: 'builder-p-4',
  isCollapsible: true
};

export default CategorySelector;
