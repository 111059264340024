import React from 'react';

const LoadingSkeleton = () => {
  return (
    <div data-testid='general-meta-loading-skeleton' className='builder-bg-white builder-animate-pulse builder-p-4 builder-max-w-7xl builder-min-w-min'>
      <div className='builder-bg-gray-200 builder-mb-2' style={{ height: 30, width: '20%' }} />
      <div className='builder-bg-gray-200 builder-mb-1' style={{ height: 15, width: '70%' }} />
      <div className='builder-bg-gray-200 builder-mb-4' style={{ height: 15, width: '50%' }} />
      <div className='builder-bg-gray-200 builder-mb-2' style={{ height: 30 }} />
      <div className='builder-bg-gray-200 builder-mb-2' style={{ height: 60 }} />
      <div className='builder-flex builder-justify-end'>
        <div className='builder-bg-gray-200' style={{ height: 30, width: '20%' }} />
      </div>
    </div>
  );
};

export default LoadingSkeleton;
