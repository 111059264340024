import { useState } from 'react';
import { useMutation } from 'graphql-hooks';

export const FileUploadMutation = `mutation FileUpload($file: Upload!) { 
        fileUpload(file: $file) { 
            ok 
            uuid
            location
            url            
        }
    }`;

export function useFileUpload() {
  const [url, setUrl] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [mutation] = useMutation(FileUploadMutation);

  const handleFileUpload = (file, onComplete = undefined, onUploadProgress = undefined) => {
    setLoading(true);

    const formData = new FormData();

    formData.append(
      'operations',
      JSON.stringify({
        query: FileUploadMutation,
        variables: { file: null }
      })
    );
    // Map the file field to the corresponding file
    formData.append('map', JSON.stringify({ 0: ['variables.file'] }));
    formData.append('0', file, file.name);

    let request = new XMLHttpRequest();
    request.open('POST', '/graphql', true);
    request.setRequestHeader('Accept-Language', 'en');
    request.setRequestHeader('Environment', 0);
    request.setRequestHeader('Accept', 'application/json');
    request.withCredentials = true; // Set credentials: 'same-origin'
    request.onload = function () {
      if (request.status >= 200 && request.status < 300) {
        const response = JSON.parse(request.responseText);
        if (response.errors) {
          setError(`GraphQL errors: ${response.errors}`);
        } else {
          setUrl(response.data.fileUpload.url);
          if (onComplete) onComplete(response.data.fileUpload.url);
        }
      } else {
        setError(`HTTP error: ${request.status} ${request.statusText}`);
      }
      setLoading(false);
    };

    // Add progress event handler
    request.upload.onprogress = function (event) {
      onUploadProgress && onUploadProgress(event);
    };

    // Send the request
    request.send(formData);
  };

  return {
    uploadFile: (file, onComplete = undefined, onUploadProgress = undefined) => handleFileUpload(file, onComplete, onUploadProgress),
    url: url,
    loading: loading,
    error
  };
}
