import React, { useEffect, useState } from 'react';
import ImagePicker from './ImagePicker';
import TextInput from '../../builder/editors/components/TextInput';
import OpeningHoursAndClosingDays from './OpeningHoursAndClosingDays';
import ConfirmBox from '../../../components/common/ConfirmBox';
import { useQuery } from 'graphql-hooks';
import { DealershipTypeTypesQuery } from '../../data/graphql';
import { ErrorIndicator } from '../../../components/common';

const Detail = ({ dealership, handleChange, showConfirmBox, onBackConfirm, onBackCancel, context }) => {
  const { loading, error, data } = useQuery(DealershipTypeTypesQuery);

  const handleHoursChange = (newHours, type) => {
    handleChange({
      ...dealership,
      openingsHours: [...dealership.openingsHours.filter((hour) => hour.type.id !== type.id), ...newHours]
    });
  };

  const handleDaysChange = (newDays, type) => {
    handleChange({
      ...dealership,
      closingDays: [...dealership.closingDays.filter((day) => day.type.id !== type.id), ...newDays]
    });
  };

  const renderHoursAndDays = () => {
    return data.dealershipLocationTypes.map((type) => {
      return (
        <div key={type.id} className='builder-flex builder-flex-col'>
          <div className='builder-flex builder-w-full builder-border-b builder-border-gray-100 builder-pb-2 builder-capitalize builder-text-[20px]'>{type.name}</div>
          {type.description && <div className='builder-mt-2 builder-text-gray-600 builder-text-sm'>{type.description}</div>}
          <OpeningHoursAndClosingDays
            days={dealership.closingDays.filter((day) => day.type.id === type.id)}
            hours={dealership.openingsHours.filter((day) => day.type.id === type.id)}
            type={type}
            onChangeHours={(newHours) => handleHoursChange(newHours, type)}
            onAddHours={(newHours) => handleHoursChange(newHours, type)}
            onRemoveHour={(newHours) => handleHoursChange(newHours, type)}
            onAddDays={(newDays) => handleDaysChange(newDays, type)}
            onRemoveDays={(newDays) => handleDaysChange(newDays, type)}
            onChangeDays={(newDays) => handleDaysChange(newDays, type)}
            context={context}
          />
        </div>
      );
    });
  };

  const getValueOrUndefined = (value) => {
    return value ? value : undefined;
  };

  return (
    <div className='builder-relative'>
      {showConfirmBox && <ConfirmBox onCancel={onBackCancel} onConfirm={onBackConfirm} />}
      <div className='builder-flex builder-items-center builder-justify-center'>
        <div className='builder-w-full'>
          <div className='builder-flex builder-items-center builder-justify-center'>
            <div className='builder-container builder-mt-4'>
              <div className='builder-bg-white builder-border builder-border-gray-200 builder-ring-1 builder-ring-black builder-ring-opacity-5'>
                <div className='builder-py-4 builder-border-b builder-border-gray-200 builder-bg-gray-100'>
                  <div className='builder-text-lg builder-leading-6 builder-font-medium builder-text-gray-900 builder-px-4 builder-text-[18px]'>Details</div>
                </div>

                <div className='builder-flex builder-p-4'>
                  <div className='builder-mr-4 overflow-hidden' style={{ width: 266 }}>
                    <ImagePicker value={dealership.image} onChange={(path) => handleChange({ ...dealership, image: path })} type='image' />
                  </div>
                  <div className='builder-flex builder-flex-col builder-justify-between builder-flex-1 builder-pl-4 builder-border-l builder-border-gray-200'>
                    <div className='builder-flex'>
                      <div className='builder-flex builder-flex-col builder-w-1/2 builder-pr-2'>
                        <label className='builder-text-sm builder-text-gray-500 builder-mb-1'>Name</label>
                        <TextInput value={getValueOrUndefined(dealership.name)} placeHolder='Name' onChanged={(value) => handleChange({ ...dealership, name: value })} />
                      </div>
                      <div className='builder-flex builder-flex-col builder-w-1/2 builder-pl-2'>
                        <label className='builder-text-sm builder-text-gray-500 builder-mb-1 builder-ml-2'>Email address</label>
                        <TextInput value={getValueOrUndefined(dealership.email)} placeHolder='Email' onChanged={(value) => handleChange({ ...dealership, email: value })} />
                      </div>
                    </div>
                    <div className='builder-flex builder-mt-4'>
                      <div className='builder-flex builder-flex-col builder-w-1/2 builder-pr-2'>
                        <label className='builder-text-sm builder-text-gray-500 builder-mb-1'>Phone number</label>
                        <TextInput value={getValueOrUndefined(dealership.tel)} placeHolder='Phone' onChanged={(value) => handleChange({ ...dealership, tel: value })} />
                      </div>
                      <div className='builder-flex builder-flex-col builder-w-1/2 builder-pl-2'>
                        <label className='builder-text-sm builder-text-gray-500 builder-mb-1'>Website</label>
                        <TextInput value={getValueOrUndefined(dealership.url)} placeHolder='website' onChanged={(value) => handleChange({ ...dealership, url: value })} />
                      </div>
                    </div>
                    <div className='builder-flex builder-mt-4'>
                      <div className='builder-flex builder-flex-col builder-w-1/2 builder-pr-2'>
                        <label className='builder-text-sm builder-text-gray-500 builder-mb-1'>Street/street number</label>
                        <TextInput value={getValueOrUndefined(dealership.address)} placeHolder='Address' onChanged={(value) => handleChange({ ...dealership, address: value })} />
                      </div>
                      <div className='builder-flex builder-w-1/2 builder-pl-2'>
                        <div className='builder-flex builder-flex-col builder-w-1/3 builder-pr-2'>
                          <label className='builder-text-sm builder-text-gray-500 builder-mb-1'>Postal code</label>
                          <TextInput value={getValueOrUndefined(dealership.postal)} placeHolder='Postal code' onChanged={(value) => handleChange({ ...dealership, postal: value })} />
                        </div>
                        <div className='builder-flex builder-flex-col builder-w-2/3 builder-pl-2'>
                          <label className='builder-text-sm builder-text-gray-500 builder-mb-1'>City</label>
                          <TextInput value={getValueOrUndefined(dealership.city)} placeHolder='City' onChanged={(value) => handleChange({ ...dealership, city: value })} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='builder-flex builder-flex-1 builder-px-4 builder-mb-4 builder-pt-4 builder-border-t builder-border-gray-200'>
                  <div className='builder-flex builder-flex-col builder-w-1/4 builder-pr-2'>
                    <label className='builder-text-sm builder-text-gray-500 builder-mb-1'>Facebook</label>
                    <TextInput value={getValueOrUndefined(dealership.facebook)} placeHolder='Facebook' onChanged={(value) => handleChange({ ...dealership, facebook: value })} />
                  </div>
                  <div className='builder-flex builder-flex-col builder-w-1/4 builder-px-2'>
                    <label className='builder-text-sm builder-text-gray-500 builder-mb-1'>Twitter</label>
                    <TextInput value={getValueOrUndefined(dealership.twitter)} placeHolder='Twitter' onChanged={(value) => handleChange({ ...dealership, twitter: value })} />
                  </div>
                  <div className='builder-flex builder-flex-col builder-w-1/4 builder-px-2'>
                    <label className='builder-text-sm builder-text-gray-500 builder-mb-1'>Linkedin</label>
                    <TextInput value={getValueOrUndefined(dealership.linkedin)} placeHolder='Linkedin' onChanged={(value) => handleChange({ ...dealership, linkedin: value })} />
                  </div>
                  <div className='builder-flex builder-flex-col builder-w-1/4 builder-pl-2'>
                    <label className='builder-text-sm builder-text-gray-500 builder-mb-1'>Instagram</label>
                    <TextInput value={getValueOrUndefined(dealership.instagram)} placeHolder='Instagram' onChanged={(value) => handleChange({ ...dealership, instagram: value })} />
                  </div>
                </div>
              </div>

              <div className='builder-bg-white builder-border builder-border-gray-200 builder-ring-1 builder-ring-black builder-ring-opacity-5 builder-mt-10'>
                <div className='builder-py-4 builder-border-b builder-border-gray-200 builder-flex builder-justify-between builder-bg-gray-100'>
                  <div className='builder-text-lg builder-leading-6 builder-font-medium builder-text-gray-900 builder-px-4 builder-text-[18px]'>Openings hours</div>
                </div>

                <div className='builder-flex builder-p-4 builder-flex-col'>
                  {loading && !error && (
                    <div>
                      <div className='builder-h-16 builder-w-full builder-bg-gray-100 builder-animate-pulse builder-mb-4'></div>
                      <div className='builder-h-[360px] builder-w-full builder-bg-gray-100 builder-animate-pulse builder-mb-4' style={{ height: 350 }}></div>
                      <div className='builder-h-[360px] builder-w-full builder-bg-gray-100 builder-animate-pulse' style={{ height: 350 }}></div>
                    </div>
                  )}
                  {!loading && error && <ErrorIndicator error='Dealership types could not be fetched.' />}
                  {!loading && !error && renderHoursAndDays()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
