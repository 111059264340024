import React from 'react';

const LoadingSkeleton = () => {
  return (
    <div data-testid='custom-block-detail-loading-skeleton' className='builder-flex builder-flex-col builder-px-5 builder-pb-5 builder-w-full builder-relative builder-animate-pulse'>
      <div className='builder-py-5 builder-flex builder-justify-between builder-items-center'>
        <div className='builder-flex builder-items-center'>
          <div className='builder-bg-gray-100 builder-h-10 builder-w-20 builder-mr-4' />
          <div className='builder-bg-gray-100 builder-h-10 builder-w-40' />
        </div>
        <div className='builder-bg-gray-100 builder-h-10 builder-w-20' />
      </div>

      <div className='builder-flex builder-h-96 builder-w-full' style={{ minHeight: 900 }}>
        <div className='builder-bg-gray-100 builder-w-1/2 builder-h-full builder-mr-4' />
        <div className='builder-bg-gray-100 builder-w-1/2 builder-h-full' />
      </div>
    </div>
  );
};

export default LoadingSkeleton;
