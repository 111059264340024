import React from 'react';

const NoResults = ({ text, icon }) => {
  return (
    <div data-testid='no-redirects-results' className='flex items-center p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 builder-space-x-2' role='alert'>
      {icon && <i className={icon} />}
      <div>{text}</div>
    </div>
  );
};

export default NoResults;
