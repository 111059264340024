import React from 'react';
import Checkbox from '../../../../components/common/Checkbox';
import PageMeta from './PageMeta';

const PageForm = ({ page, onMetaUpdate, onIndexableUpdate, context }) => {
  return (
    <div className='builder-flex builder-p-5 builder-w-full builder-bg-gray-50'>
      <div className='builder-flex builder-flex-col builder-mb-5 builder-w-full'>
        <PageMeta page={page} onMetaUpdate={onMetaUpdate} context={context} />

        <div className='builder-mt-6'>
          <div data-testid='seo-page-indexable-checkbox'>
            <div className='builder-text-black builder-text-md builder-font-bold builder-mb-2'>Indexable</div>
            <Checkbox label={page?.indexable ? 'The page will be indexed' : 'The page will not be indexed.'} checked={page?.indexable} onChange={onIndexableUpdate} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageForm;
