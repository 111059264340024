import React from 'react';

const ErrorIndicator = ({ error, icon }) => {
  return (
    <div className='w-full bg-red-200 flex items-center rounded'>
      <div className='flex h-full'>
        <div className='w-16 bg-red-300 flex items-center justify-center rounded-l'>
          <i className={`${icon} text-2xl text-red-900`} />
        </div>
        <div className='w-auto text-black opacity-75 items-center px-4 py-4  flex items-center'>
          <p className='leading-tight'>{error}</p>
        </div>
      </div>
    </div>
  );
};

ErrorIndicator.defaultProps = {
  error: 'Er ging iets mis. Probeer later opnieuw.',
  icon: 'fad fa-exclamation-circle'
};

export default ErrorIndicator;
