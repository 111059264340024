import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { initialState, reducer } from '../reducer';
import cogoToast from 'cogo-toast';

import {
  categoryInitializeHandler,
  categoryResetLoadingHandler,
  categoryResetSavingHandler,
  categorySetHandler,
  categorySetLoadingHandler,
  categorySetSaveCompletedHandler,
  categorySetSavingHandler,
  categoryUndoChangesHandler,
  categorySetInitializedHandler
} from '../actions';
import { head, isEmpty, forEach } from 'lodash';
import { routes } from '../../../../data/constants';
import { v4 } from 'uuid';
import { StateContext } from '../../../../../App';
import LoadingSkeleton from './LoadingSkeleton';
import { containsOnlyLowercaseNumbersAndHyphens } from '../../../utils';
import Header from './Header';
import Form from './Form';
import CategoryModal from './CategoryModal';

export const CategoryQuery = `query FaqCategories($data: FaqCategorySearchInputType!) { 
  faqCategories(data: $data) { 
    id
    title
    slug
    image
    priority
    isDraft
    disabled
  } 
}`;

export const CategorySaveMutation = `mutation FaqCategoryUpdate($data: FaqCategoryUpdateInputType!) {
  faqCategoryUpdate(data: $data) {
      ok
  }
}`;

const Detail = ({ context, currentLanguage, setCurrentLanguage, modal }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const globalState = useContext(StateContext);
  const [formErrors, setFormErrors] = useState({});
  const { id: idFromUrl } = useParams();
  const languages = globalState?.configuration?.website?.languages;
  const isModal = modal && !isEmpty(modal);

  const history = useHistory();
  const location = useLocation();
  const category = state.category;
  const isCreate = location?.state?.isCreate;

  const fetchCategory = (categoryId) => {
    dispatch(categorySetLoadingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: CategoryQuery, variables: { data: { id: categoryId } } })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data) {
          const category = head(result?.data.faqCategories);
          if (!category) {
            dispatch(categoryInitializeHandler(idFromUrl));
          } else {
            dispatch(categorySetHandler(category));
          }

          dispatch(categoryResetLoadingHandler());
        } else {
          dispatch(categoryResetLoadingHandler());
          cogoToast.error('No data returned from the server.');
        }
      })
      .catch((err) => {
        dispatch(categoryResetLoadingHandler());
        cogoToast.error('Something went wrong.');
      });
  };

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    forEach(languages, (language) => {
      if (isEmpty(category.title[language])) {
        errors[`title-${language}`] = 'The title needs to be added in all languages.';
        isValid = false;
      }

      if (!containsOnlyLowercaseNumbersAndHyphens(category.slug[language])) {
        errors[`slug-${language}`] = 'The slug can only have lowercase chars; numbers and -';
        isValid = false;
      }

      if (isEmpty(category.slug[language])) {
        errors[`slug-${language}`] = 'The slug needs to be added in all languages.';
        isValid = false;
      }
    });

    return {
      isValid,
      errors
    };
  };

  const isValid = () => {
    setFormErrors({});
    const { isValid, errors } = validateForm();
    setFormErrors(errors);
    return isValid;
  };

  useEffect(() => {
    if (state.formInitialized) {
      setFormErrors({});
      const { errors } = validateForm();
      setFormErrors(errors);
    }
  }, [state.category, state.formInitialized]);

  const saveCategory = ({ addAnother }) => {
    dispatch(categorySetInitializedHandler());
    if (isValid()) {
      dispatch(categorySetSavingHandler());
      const variables = {
        data: {
          id: category.id,
          title: JSON.stringify(category.title),
          slug: JSON.stringify(category.slug),
          image: JSON.stringify(category.image),
          disabled: category.disabled,
          isDraft: category.isDraft
        }
      };

      fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query: CategorySaveMutation, variables: variables })
      })
        .then((resp) => resp.json())
        .then((result) => {
          if (result?.data?.faqCategoryUpdate?.ok) {
            cogoToast.success('Category saved');
            dispatch(categorySetSaveCompletedHandler());

            if (addAnother) {
              const newId = v4();
              dispatch(categoryInitializeHandler(newId));
              history.replace(`${routes.FAQ_CATEGORY_DETAIL}/${newId}`);
            }

            if (isModal && modal.onSave) {
              modal.onSave(category);
            }
          } else {
            const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
            cogoToast.error(error);
            dispatch(categoryResetSavingHandler());
          }
        })
        .catch((err) => {
          cogoToast.error('Something went wrong. The category could not be saved.');
          dispatch(categoryResetSavingHandler());
        });
    }
  };

  useEffect(() => {
    if (isModal) {
      const newId = v4();
      dispatch(categoryInitializeHandler(newId));
    } else {
      if (!isCreate) {
        fetchCategory(idFromUrl);
      } else {
        dispatch(categoryInitializeHandler(idFromUrl));
      }
      history.replace(`${routes.FAQ_CATEGORY_DETAIL}/${idFromUrl}`);
    }
  }, []);

  if (state.loading) {
    return <LoadingSkeleton />;
  }

  if (isModal) {
    return <CategoryModal isLoading={state.saving || state.loading} languages={languages} formErrors={formErrors} state={state} dispatch={dispatch} onCancel={modal.onClose} onSave={saveCategory} />;
  }

  return (
    <div className='builder-flex builder-flex-col builder-p-5 builder-w-full'>
      <div className='builder-mb-5'>
        <Header
          isLoading={state.saving || state.loading}
          history={state.history}
          onCancel={() => history.push(`${routes.FAQ_OVERVIEW}`)}
          onUndo={() => dispatch(categoryUndoChangesHandler())}
          onSave={saveCategory}
          onSaveAndAdd={() => saveCategory({ addAnother: true })}
        />
      </div>

      <div className='builder-flex builder-p-5 builder-w-full h-full builder-bg-gray-50'>
        <div className='builder-w-full'>
          <div className='builder-flex builder-flex-col builder-mb-5 builder-relative'>
            <Form isLoading={state.saving || state.loading} languages={languages} formErrors={formErrors} state={state} dispatch={dispatch} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
