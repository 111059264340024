import React from 'react';
import { range } from 'lodash';
import { coreTypes } from 'dealer-website-components';
import GenericRangeSelect from '../../../../../components/common/GenericRangeSelect';

const totalSeats = 12;

const SeatsFilter = ({ filterValues, filterAdd, filterUpdate, hasFilter }) => {
  const { FilterTypes } = coreTypes;
  const filterValue = filterValues && filterValues.length > 0 ? filterValues[0] : undefined;
  const minValue = filterValue ? filterValue[0] : 1;
  const maxValue = filterValue ? filterValue[1] : totalSeats;
  const seatsFromOptions = range(1, maxValue + 1).map((seat) => {
    return {
      value: seat,
      label: seat
    };
  });
  const seatsToOptions = range(minValue, totalSeats + 1).map((seat) => {
    return {
      value: seat,
      label: seat
    };
  });

  const handleChange = (range) => {
    if (filterValues.length > 0) {
      filterUpdate(FilterTypes.seatsRange, range);
    } else {
      filterAdd(FilterTypes.seatsRange, range);
    }
  };

  return (
    <React.Fragment>
      <div className='builder-font-semibold builder-mb-2 builder-mt-5'>Nr. of seats</div>
      <GenericRangeSelect placeHolderFrom='From' placeHolderTo='To' optionsFrom={seatsFromOptions} optionsTo={seatsToOptions} value={filterValues && filterValues.length > 0 ? filterValues[0] : undefined} handleFilterChanged={handleChange} />
    </React.Fragment>
  );
};

export default SeatsFilter;
