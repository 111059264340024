import { actionTypes } from './reducer';

export const dataSetLoadingHandler = () => ({
  type: actionTypes.DATA_SET_LOADING
});

export const dataResetLoadingHandler = () => ({
  type: actionTypes.DATA_RESET_LOADING
});

export const dataSetHandler = (pageTypes) => ({
  type: actionTypes.DATA_SET,
  payload: {
    pageTypes
  }
});

export const pageTypeDeletedHandler = (pageTypeId) => ({
  type: actionTypes.PAGE_TYPE_DELETED,
  payload: {
    pageTypeId
  }
});

export const pageTypeAddHandler = (pageType) => ({
  type: actionTypes.PAGE_TYPE_ADD,
  payload: {
    pageType
  }
});
