import React from 'react';
import { includes, map, orderBy } from 'lodash';

const GenericList = ({ items, values, handleFilterChanged, containerClass }) => {
  const options = orderBy(
    map(items, (item) => {
      return {
        value: item.key,
        label: item.text
      };
    }),
    ['label']
  );
  return (
    <React.Fragment>
      <div className={containerClass}>
        {map(options, (option, i) => {
          const isSelected = includes(values, option.value);

          return (
            <div key={`filter-${option.value}-${i}`} className='builder-flex builder-flex-col builder-mb-1'>
              <div className='builder-flex builder-flex-row builder-py-1 builder-cursor-pointer' onClick={() => handleFilterChanged(option)}>
                <div className='builder-flex builder-items-center'>
                  <div className={`builder-flex builder-items-center builder-justify-center builder-rounded builder-border builder-border-primary builder-mr-2`} style={{ height: 16, width: 16 }}>
                    {isSelected && <i className='far fa-check builder-font-bold' style={{ fontSize: 10, marginTop: 2 }} />}
                  </div>
                </div>

                <div className={`builder-flex builder-flex-row builder-justify-between builder-flex-1 builder-relative ${isSelected ? 'builder-font-bold' : 'builder-font-normal'}`}>
                  <div className='builder-capitalize'>{option.label}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

GenericList.defaultProps = {
  containerClass: 'builder-space-y-2'
};

export default GenericList;
