export const initialState = {
  pages: [],
  pagesLoading: false,
  pagesError: undefined,
  isCollapsed: false
};

// ACTION TYPES //
export const actionTypes = {
  PAGES_SET: 'PAGES_SET',
  PAGES_SET_LOADING: 'PAGES_SET_LOADING',
  PAGES_RESET_LOADING: 'PAGES_RESET_LOADING',
  TOGGLE_COLLAPSE: 'TOGGLE_COLLAPSE'
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.PAGES_SET_LOADING:
      return {
        ...state,
        pagesLoading: true
      };
    case actionTypes.PAGES_RESET_LOADING:
      return {
        ...state,
        pagesLoading: false
      };
    case actionTypes.PAGES_SET:
      return {
        ...state,
        pages: action.payload.pages,
        pagesLoading: false,
        pagesError: undefined
      };
    case actionTypes.TOGGLE_COLLAPSE:
      return {
        ...state,
        isCollapsed: !state.isCollapsed
      };
    default:
      return state;
  }
};
