import React, { useState } from 'react';
import { map } from 'lodash';
import QuestionGroup from './QuestionGroup';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../data/constants';
import { groupChangePrioritiesHandler, groupSetDeletedHandler } from '../actions';
import cogoToast from 'cogo-toast';

export const FaqQuestionGroupDeleteMutation = `mutation FaqQuestionGroupDelete($data: FaqQuestionGroupDeleteInputType!) {
  faqQuestionGroupDelete(data: $data) {
      ok
  }
}`;

const QuestionGroups = ({ questionGroups, currentLanguage, state, dispatch, onCollapse }) => {
  const [deletingGroupId, setDeletingGroupId] = useState(undefined);
  const history = useHistory();

  const onEditGroup = (groupId) => {
    history.push(`${routes.FAQ_QUESTION_GROUP_DETAIL}/${groupId}`);
  };
  const onDeleteGroup = (groupId, categoryId) => {
    setDeletingGroupId(groupId);
    const variables = {
      data: {
        id: groupId
      }
    };

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: FaqQuestionGroupDeleteMutation, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data?.faqQuestionGroupDelete?.ok) {
          dispatch(groupSetDeletedHandler(categoryId, groupId));
          cogoToast.success('Questions group deleted.');
        } else {
          const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
          cogoToast.error(error);
        }
        setDeletingGroupId(undefined);
      })
      .catch((err) => {
        cogoToast.error('Something went wrong. The questions group could not be deleted.');
        setDeletingGroupId(undefined);
      });
  };
  const onChangePriority = (draggedGroupId, targetGroupId, targetCategoryId) => {
    dispatch(groupChangePrioritiesHandler(draggedGroupId, targetGroupId, targetCategoryId));
  };

  return (
    <div data-testid='question-groups-container' className='builder-space-y-2'>
      {map(questionGroups, (group, index) => {
        return (
          <QuestionGroup
            key={group.id}
            index={index}
            group={group}
            onEditGroup={onEditGroup}
            onDeleteGroup={onDeleteGroup}
            onChangePriority={onChangePriority}
            onCollapse={onCollapse}
            isDeleting={group.id === deletingGroupId}
            currentLanguage={currentLanguage}
            state={state}
            dispatch={dispatch}
          />
        );
      })}
    </div>
  );
};

export default QuestionGroups;
