import React from 'react';

const Header = ({ onCreateCategory, onCreateGroup, onCreateQuestion, createQuestionGroupButtonVisible, createQuestionButtonVisible, hasPriorityChanges, onSavePriorities, onCancelPriorities }) => {
  return (
    <div className='builder-flex builder-items-center builder-justify-between builder-py-5 builder-sticky builder-top-0 builder-z-40' style={{ backgroundColor: 'rgb(222, 226, 236)' }}>
      <div className='builder-flex builder-items-center'>
        <div className='builder-text-2xl builder-text-black builder-font-bold builder-mr-6'>FAQ</div>
        <div
          onClick={onCreateCategory}
          data-testid='create-category-button'
          className={`builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-primary builder-transition-all ${hasPriorityChanges ? 'builder-opacity-50' : 'builder-cursor-pointer builder-opacity-100'}`}
        >
          <i className='fal fa-plus builder-mr-2' />
          New category
        </div>

        {createQuestionGroupButtonVisible && (
          <div
            onClick={onCreateGroup}
            data-testid='create-question-group-button'
            className={`builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-primary builder-transition-all builder-ml-4 ${
              hasPriorityChanges ? 'builder-opacity-50' : 'builder-cursor-pointer builder-opacity-100'
            }`}
          >
            <i className='fal fa-plus builder-mr-2' />
            New group
          </div>
        )}

        {createQuestionButtonVisible && (
          <div
            onClick={onCreateQuestion}
            data-testid='create-question-button'
            className={`builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-primary builder-transition-all builder-ml-4 ${
              hasPriorityChanges ? 'builder-opacity-50' : 'builder-cursor-pointer builder-opacity-100'
            }`}
          >
            <i className='fal fa-plus builder-mr-2' />
            New question
          </div>
        )}
      </div>
      <div className={`builder-flex builder-items-center builder-space-x-2 builder-transition-all ${hasPriorityChanges ? 'builder-opacity-100' : 'builder-opacity-0'}`}>
        <div onClick={onCancelPriorities} data-testid='cancel-priority-changes-button' className='builder-px-4 builder-py-2 builder-text-primary builder-border builder-border-gray-300 builder-cursor-pointer opacity-100 builder-ml-4'>
          {/*<i className='fal fa-plus builder-mr-2' />*/}
          Cancel
        </div>
        <div onClick={onSavePriorities} data-testid='save-priority-changes-button' className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-primary builder-cursor-pointer opacity-100 builder-ml-4'>
          <i className='fal fa-save builder-mr-2' />
          Save changes
        </div>
      </div>
    </div>
  );
};

export default Header;
