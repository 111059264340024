import React from 'react';

const StatusLabel = ({ campaign }) => {
  if (campaign.isExpired) {
    return <div className='builder-bg-red-100 builder-rounded-md builder-text-red-900 builder-text-white builder-px-2 builder-py-1 builder-w-auto builder-inline'>Expired</div>;
  } else if (campaign.isActive) {
    return <div className='builder-bg-green-100 builder-rounded-md builder-text-green-900 builder-px-2 builder-py-1 builder-w-auto builder-inline'>Active</div>;
  } else if (campaign.isInFuture) {
    return <div className='builder-bg-gray-100 builder-rounded-md builder-text-gray-700 builder-px-2 builder-py-1 builder-w-auto builder-inline'>{`Starts in ${campaign.activeInDays} ${campaign.activeInDays === 1 ? 'day' : 'days'}`}</div>;
  } else {
    return '';
  }
};

export default StatusLabel;
