import React from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import EditorMenu from './EditorMenu';

const EditorHeader = ({ breadcrumbs, description, onBack, onClick, renderMenu }) => {
  return (
    <React.Fragment>
      {breadcrumbs && (
        <div className='builder-bg-gray-100 builder-p-4 builder-pb-0'>
          <div className='builder-flex'>
            <Breadcrumbs items={breadcrumbs} onBack={onBack} onClick={onClick} />
          </div>
        </div>
      )}

      {renderMenu && renderMenu() && (
        <div>
          <EditorMenu renderMenu={renderMenu} />

          {description && description !== '' && (
            <div className='builder-text-md builder-bg-gray-100 builder-italic builder-p-4 mt-2'>
              <i className='fal fa-info-circle builder-mr-4 builder-text-xl'></i>
              {description}
            </div>
          )}
        </div>
      )}

      {!renderMenu && (
        <React.Fragment>
          <div className='builder-border-b builder-bg-gray-100 builder-border-gray-400 builder-p-2 builder-pl-4 builder-pr-4' />

          {description && description !== '' && (
            <div className='builder-text-md builder-bg-gray-100 builder-italic builder-p-4 mt-2'>
              <i className='fal fa-info-circle builder-mr-4 builder-text-xl'></i>
              {description}
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default EditorHeader;
