import React, { useContext, useEffect, useState } from 'react';
import UseBuilderActions from '../../../builder/useBuilderActions';
import ButtonWithIndicator from '../../../../components/common/ButtonWithIndicator';
import { useMutation } from 'graphql-hooks';
import { useFileUpload } from '../../../builder/useFileUpload';
import { v4 } from 'uuid';
import DiffEditorModal from './DiffEditorModal';
import { StateContext } from '../../../../App';
import cogoToast from 'cogo-toast';

export const EXPORT_MUTATION = `
    mutation WebsiteDumpToFile {
        websiteDumpToFile {  
            ok
            url
        }
    }`;

export const IMPORT_MUTATION = `
    mutation WebsiteRestoreFromFile($fileUrl: String!) {
        websiteRestoreFromFile(fileUrl: $fileUrl) {  
            ok
            configuration
        }
    }`;

export const UPDATE_CONFIGURATION = `
    mutation ConfigurationSettingUpdate($value: String!) {
        configurationSettingUpdate(value: $value) {  
            ok            
        }
    }`;

let fileSelector;

const BackupAndRestore = ({ settings, isSuperUser, onChange, data, context }) => {
  const state = useContext(StateContext);
  const [exportMutation, { loading: loadingExport, error: errorExport }] = useMutation(EXPORT_MUTATION);
  const [importMutation, { loading: loadingImport, error: errorImport }] = useMutation(IMPORT_MUTATION);
  const [updateConfigurationMutation] = useMutation(UPDATE_CONFIGURATION);
  const [importFileUrl, setImportFileUrl] = useState(undefined);
  const [diffConfiguration, setDiffConfiguration] = useState(undefined);
  const [error, setError] = useState(undefined);
  const { uploadFile, _, loading: loadingFileUpload, error: errorFileUpload } = useFileUpload();
  const [downloadFile, setDownloadFile] = useState(undefined);
  const [progressUploadValue, setProgressUploadValue] = useState(0);

  const loading = loadingExport || loadingImport || loadingFileUpload;

  useEffect(() => {
    if (errorFileUpload) setError(errorFileUpload);
    else if (errorExport) setError(errorExport);
    else if (errorImport) setError(errorImport);
  }, [errorFileUpload, errorExport, errorImport]);

  if (!isSuperUser) {
    return <div> not allowed </div>;
  }
  const handleBackup = () => {
    exportMutation()
      .then((result) => {
        if (result.errors) {
          setError(result.errors[0]);
        } else if (result.data && result.data.websiteDumpToFile) {
          if (result.data.websiteDumpToFile.ok) {
            setDownloadFile(result.data.websiteDumpToFile.url);
            window.open(result.data.websiteDumpToFile.url, '_blank');
          } else {
            setError('Something went wrong..');
          }
        }
      })
      .catch((err) => {
        setError(err);
      });
  };

  const handleUploadAndRestore = (e) => {
    showFileSelector(e);
  };

  const handleRestore = (file) => {
    setImportFileUrl(file);

    importMutation({
      variables: {
        fileUrl: file
      }
    }).then((result) => {
      if (result.data && result.data.websiteRestoreFromFile) {
        if (result.data.websiteRestoreFromFile.ok) {
          const configuration = result.data.websiteRestoreFromFile.configuration;
          if (configuration) {
            setDiffConfiguration(JSON.parse(configuration));
            cogoToast.success('Restore complete!');
          }
        } else {
          setError('Something went wrong..');
        }
      }
    });
  };

  const handleUpdateConfiguration = (configuration) => {
    updateConfigurationMutation({
      variables: {
        value: configuration
      }
    }).then((result) => {
      if (result.data && result.data.configurationSettingUpdate) {
        if (result.data.configurationSettingUpdate.ok) {
          setDiffConfiguration(undefined);
          window.location.reload();
        } else {
          setError('Something went wrong..');
        }
      }
    });
  };

  const handleClose = () => {
    setDiffConfiguration(undefined);
    window.location.reload();
  };

  const handleProgress = (event) => {
    if (event.lengthComputable) {
      const percentComplete = (event.loaded / event.total) * 100;
      setProgressUploadValue(percentComplete);
    }
  };
  const showFileSelector = (e) => {
    e.preventDefault();

    fileSelector = document.createElement('input');
    fileSelector.setAttribute('id', v4());
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');

    fileSelector.addEventListener('change', (e) => {
      uploadFile(e.target.files[0], (url) => handleRestore(url), handleProgress);
    });

    fileSelector.setAttribute('accept', '.zip');
    fileSelector.click();
  };

  return (
    <div>
      <div className='flex flex-col'>
        <div className='builder-flex builder-flex-row builder-gap-4 pb-4'>
          <div className='builder-w-1/5'>
            <ButtonWithIndicator loading={loading} onClick={handleBackup} text='Backup to file' />
          </div>

          <div className='builder-w-1/5'>
            <ButtonWithIndicator loading={loading} onClick={handleUploadAndRestore} text='Restore from file' />
          </div>
        </div>
        {loadingFileUpload && (
          <div>
            <div>Uploading File...</div>
            <div>
              <progress value={progressUploadValue} max='100'>
                {' '}
                {progressUploadValue}%{' '}
              </progress>
            </div>
          </div>
        )}
        {loadingImport && <div>Parsing uploaded File...</div>}
        {loadingExport && <div>Creating export file...</div>}

        {importFileUrl && <div>Restoring.... this could take a wile</div>}

        {error && <div className='text-red-500'>{error}</div>}

        {downloadFile && (
          <div className=''>
            <div>if the dowload doens't start automatically you can try again by pressing this button...</div>
            <a
              className='builder-w-1/5 builder-flex builder-items-center builder-px-4 builder-py-2 builder-transition-all builder-cursor-pointer builder-border builder-border-gray-300 hover:builder-bg-gray-300'
              href={downloadFile}
              target='_blank'
              rel='noreferrer'
            >
              Download zip file
            </a>
          </div>
        )}
      </div>

      <DiffEditorModal
        visible={diffConfiguration !== undefined}
        leftCode={diffConfiguration}
        leftTitle='Configuration from import'
        rightCode={state.configuration}
        rightTitle='Current configuration'
        onApply={handleUpdateConfiguration}
        onClose={handleClose}
      />
    </div>
  );
};

export default BackupAndRestore;
