import React from 'react';
import { find, map, toLower, toUpper } from 'lodash';
import { routes } from '../../../data/constants';
import { useHistory } from 'react-router-dom';

const Page = ({ page, languages, context }) => {
  const history = useHistory();
  const columnClassName = 'builder-flex builder-px-4 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black';

  const resolveIcon = (hasValue) => {
    let iconClassName = 'fal fa-times builder-text-red-500';
    if (hasValue) iconClassName = 'fal fa-check builder-text-green-500';

    return <i className={iconClassName} style={{ fontSize: 12 }} />;
  };

  const onEditPage = (id, language) => {
    history.push(`${routes.SEO_DETAILS}/${id}`, {
      language: language
    });
  };

  return (
    <div className={`builder-flex builder-w-full builder-items-center builder-transition-all builder-transform builder-relative`}>
      <div className={`${columnClassName} builder-relative`} style={{ width: '40%', maxWidth: '40%' }}>
        <span className=''>{page?.path}</span>
      </div>

      <div className={`${columnClassName} builder-relative`} style={{ width: '23%', maxWidth: '23%' }}>
        <div className='builder-w-full builder-truncate'>{page?.typeName}</div>
      </div>

      <div className={`${columnClassName} builder-flex-col builder-space-y-1 builder-items-start`} style={{ width: '7%', maxWidth: '7%' }}>
        {map(languages, (language) => {
          const hasMetaForLanguage = find(page.meta, (meta) => toLower(meta.language) === toLower(language));
          return (
            <div key={`seo-page-meta-${language}`} className='builder-flex builder-items-center builder-space-x-2'>
              {resolveIcon(hasMetaForLanguage)}
              <span>{toUpper(language)}</span>
            </div>
          );
        })}
      </div>

      <div className={`${columnClassName} builder-relative`} style={{ width: '10%', maxWidth: '10%' }}>
        {resolveIcon(page?.indexable)}
      </div>

      <div className={columnClassName} style={{ width: '20%', maxWidth: '20%' }}>
        {map(languages, (language, index) => {
          return (
            <div key={`seo-page-button-language-${language}`} className='builder-flex builder-items-center'>
              <div className='builder-cursor-pointer hover:builder-text-gray-800 builder-uppercase' onClick={() => onEditPage(page.id, language)}>
                <span>{language}</span> <i className='far fa-pencil builder-ml-2' style={{ fontSize: 11 }} />
              </div>
              {index < language.length - 1 && <div className='builder-mx-4'>|</div>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Page;
