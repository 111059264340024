import React, { useRef, useState } from 'react';
import MonacoEditor from 'react-monaco-editor';

import FileUploader from './WysiwygEditorFileUploader';

export default function WysiwygEditor(props) {
  const [deviceType, setDeviceType] = useState('desktop');

  const getWidthByDevice = (device) => {
    switch (device) {
      case 'tablet':
        return 778;
      case 'mobile':
        return 375;
      default:
        return '100%';
    }
  };

  const eRef = useRef();
  const options = {
    selectOnLineNumbers: true,
    colorDecorators: true,
    minimap: {
      enabled: false
    }
  };

  return (
    <div className='builder-flex builder-flex-1 builder-flex-col' style={{ height: '90%', minHeight: 900 }}>
      <div className='builder-flex-1 builder-flex-row builder-flex' style={{ flex: 1 }}>
        <div className='builder-flex-1 builder-mr-4 builder-border overflow-x-scroll'>
          <FileUploader editorRef={eRef} />
          <div style={{ height: '100%', minHeight: 900 }} className='builder-border-gray-200'>
            <MonacoEditor
              ref={eRef}
              language='html'
              theme='light'
              value={props.data || undefined}
              defaultValue={'<div>Hello there! Add your HTML here</div>'}
              onChange={(newValue) => {
                props.onChange(newValue);
              }}
              options={options}
            />
          </div>
        </div>
        <div className=' builder-flex-1 margin-left-2 overflow-x-scroll builder-bg-white'>
          <div className='builder-flex builder-items-center builder-mb-2  builder-p-2'>
            <div className={`builder-flex builder-justify-center builder-mr-1 builder-cursor-pointer ${deviceType === 'desktop' ? 'builder-text-normal' : 'builder-text-gray-300'}`} style={{ width: 30 }} onClick={() => setDeviceType('desktop')}>
              <i className='fas fa-desktop-alt' />
            </div>
            <div
              className={`builder-flex builder-items-center builder-justify-center builder-mr-1 builder-cursor-pointer  ${deviceType === 'tablet' ? 'builder-text-normal' : 'builder-text-gray-300'}`}
              style={{ width: 30 }}
              onClick={() => setDeviceType('tablet')}
            >
              <i className='fas fa-tablet-alt' />
            </div>
            <div
              className={`builder-flex builder-items-center builder-justify-center builder-mr-1 builder-cursor-pointer ${deviceType === 'mobile' ? 'builder-text-normal' : 'builder-text-gray-300'}`}
              style={{ width: 30 }}
              onClick={() => setDeviceType('mobile')}
            >
              <i className='fas fa-mobile-alt' />
            </div>
          </div>
          <div className='builder-border builder-border-gray-200 builder-bg-gray-100  builder-flex builder-flex-1 builder-justify-center'>
            <div className='builder-overflow-hidden' style={{ width: getWidthByDevice(deviceType) }} dangerouslySetInnerHTML={{ __html: props.data }} />
          </div>
        </div>
      </div>
    </div>
  );
}
