import React, { useState } from 'react';
import { filter, find, map } from 'lodash';

const ConfirmDialog = ({ onCancel, onConfirm }) => {
  const options = {
    icon: 'fal fa-exclamation',
    iconBgColor: 'builder-bg-yellow-200',
    iconTextColor: 'builder-text-yellow-600',
    confirmButtonBgColor: 'builder-bg-yellow-600',
    confirmButtonTextColor: 'builder-text-white'
  };
  return (
    <div className='builder-flex builder-items-end builder-justify-center builder-px-4 builder-text-center sm:builder-block sm:builder-p-0 builder-border builder-border-gray-100' style={{ minWidth: 450 }}>
      <div className='builder-inline-block builder-align-bottom builder-bg-white builder-rounded-lg builder-px-4 builder-pt-5 builder-pb-4 builder-text-left builder-overflow-hidden builder-shadow-xl builder-transform builder-transition-all sm:builder-align-middle sm:builder-max-w-lg sm:builder-w-full sm:builder-p-4'>
        <div className='sm:builder-flex sm:builder-items-start'>
          <div className={`builder-mx-auto builder-flex-shrink-0 builder-flex builder-items-center builder-justify-center builder-h-12 builder-w-12 builder-rounded-full sm:builder-mx-0 sm:builder-h-10 sm:builder-w-10 ${options.iconBgColor}`}>
            <i className={`${options.icon} ${options.iconTextColor}`} />
          </div>
          <div className='builder-mt-3 builder-text-center sm:builder-mt-0 sm:builder-ml-4 sm:builder-text-left'>
            <h3 className='builder-text-lg builder-leading-6 builder-font-medium builder-text-gray-900' id='modal-title'>
              Are you sure?
            </h3>
            <div className='builder-mt-2'>
              <p className='builder-text-sm builder-text-gray-500'>All unsaved changes will be lost</p>
            </div>
          </div>
        </div>
        <div className='builder-mt-5 sm:builder-mt-4 sm:builder-ml-10 sm:builder-pl-4 sm:builder-flex'>
          <button
            type='button'
            onClick={onCancel}
            className={`builder-mt-3 builder-w-full builder-inline-flex builder-justify-center builder-border builder-border-gray-300 builder-px-4 builder-py-2 builder-bg-white builder-text-base builder-font-medium builder-text-gray-700 builder-shadow-sm sm:builder-mt-0 sm:builder-w-auto sm:builder-text-sm`}
          >
            Cancel
          </button>
          <button
            type='button'
            onClick={onConfirm}
            className={`builder-inline-flex builder-justify-center builder-w-full builder-border builder-border-transparent builder-shadow-sm builder-px-4 builder-py-2 builder-text-base builder-font-medium sm:builder-w-auto sm:builder-text-sm sm:builder-ml-3 ${options.confirmButtonBgColor} ${options.confirmButtonTextColor}`}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

const CopyBlockContent = ({ blockContentArray, language, onCopy }) => {
  const [open, setOpen] = useState(false);
  const [fromCulture, setFromCulture] = useState(null);

  const handleClick = (fromCulture) => {
    const contentToCopy = find(blockContentArray, (item) => item.culture === fromCulture);
    if (contentToCopy) {
      const existingContentItem = find(blockContentArray, (contentItem) => contentItem.culture !== fromCulture);
      if (existingContentItem) {
        setFromCulture(fromCulture);
      } else {
        onCopy(fromCulture);
      }
    }
  };

  const handleConfirmCopy = () => {
    onCopy(fromCulture);
    setFromCulture(null);
    setOpen(false);
  };

  if (!blockContentArray || blockContentArray?.length === 0) {
    return null;
  }

  return (
    <div className='builder-flex builder-flex-1 builder-relative' onMouseLeave={() => setOpen(false)}>
      {fromCulture && (
        <div className='builder-absolute builder-right-0 builder-bg-white' style={{ top: 42, zIndex: 778 }}>
          <ConfirmDialog onCancel={() => setFromCulture(null)} onConfirm={handleConfirmCopy} />
        </div>
      )}

      <div className='builder-flex builder-items-center builder-px-4 builder-py-2 builder-border builder-border-gray-200 builder-shadow-sm builder-text-gray-800 builder-cursor-pointer' onClick={() => setOpen(!open)}>
        <i className='fal fa-copy builder-mr-2' /> Copy block <i className={`fal builder-ml-2 ${open ? 'fa-caret-up' : 'fa-caret-down'}`} />
      </div>

      {open && (
        <div className='builder-absolute builder-pt-4' style={{ width: '100%', top: 29, zIndex: 777, left: -1, right: 2 }}>
          <div className='builder-bg-white builder-border builder-border-gray-200 builder-shadow-sm'>
            <div className='builder-space-y-1'>
              {map(
                filter(blockContentArray, (contentItem) => contentItem.culture !== language),
                (item) => {
                  return (
                    <div className='builder-cursor-pointer builder-p-4 hover:builder-bg-gray-100' onClick={() => handleClick(item.culture)}>
                      Copy from {item?.culture?.toUpperCase()}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CopyBlockContent;
