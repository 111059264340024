import React from 'react';
import { times } from 'lodash';

const LoadingSkeleton = () => {
  const renderTableSkeleton = () => {
    return (
      <div>
        <div className='builder-mb-4 builder-w-1/5 builder-h-[30px] builder-bg-gray-100 builder-animate-pulse builder-transition-all' />
        <div className='builder-min-w-full'>
          <div className='builder-w-full builder-h-[45px] builder-bg-gray-100 builder-animate-pulse builder-transition-all' />
          <div className='builder-space-y-1 builder-mt-2'>
            {times(10, (item) => {
              return <div key={item} className='builder-w-full builder-h-[30px] builder-bg-gray-100 builder-animate-pulse builder-transition-all' />;
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div data-testid='makes-loading-skeleton' className='builder-h-full builder-max-w-3xl'>
      <div className='builder-mt-3'>{renderTableSkeleton()}</div>
      <div className='builder-h-px builder-bg-gray-200 builder-mt-6 builder-mb-3' />
      {renderTableSkeleton()}
    </div>
  );
};

export default LoadingSkeleton;
