import { actionTypes } from './reducer';

export const campaignSetHandler = (dispatch, campaign) => {
  dispatch({
    type: actionTypes.CAMPAIGN_SET,
    payload: {
      campaign
    }
  });
};

export const campaignInitializeHandler = (dispatch, id) => {
  dispatch({
    type: actionTypes.CAMPAIGN_INITIALIZE,
    payload: {
      id
    }
  });
};

export const campaignSetNameHandler = (dispatch, name) => {
  dispatch({
    type: actionTypes.CAMPAIGN_SET_NAME,
    payload: {
      name
    }
  });
};

export const campaignSetStartDateHandler = (dispatch, startDate) => {
  dispatch({
    type: actionTypes.CAMPAIGN_SET_START_DATE,
    payload: {
      startDate
    }
  });
};

export const campaignSetStopDateHandler = (dispatch, stopDate) => {
  dispatch({
    type: actionTypes.CAMPAIGN_SET_STOP_DATE,
    payload: {
      stopDate
    }
  });
};

export const campaignUndoChangesHandler = (dispatch) => {
  dispatch({
    type: actionTypes.CAMPAIGN_UNDO_CHANGES,
    payload: {}
  });
};

export const campaignSetLoadingHandler = (dispatch) => {
  dispatch({
    type: actionTypes.CAMPAIGN_SET_LOADING,
    payload: {}
  });
};

export const campaignResetLoadingHandler = (dispatch) => {
  dispatch({
    type: actionTypes.CAMPAIGN_RESET_LOADING,
    payload: {}
  });
};
