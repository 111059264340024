import { map, filter, find } from 'lodash';
import { tryParse } from '../../../utils';
import { categoryInitialImage } from '../categories/detail/reducer';
import { isPriorityChanged, orderByPriority, updatePriorities } from '../utils';

export const ROW_HEIGHT = 42;
export const SPACE_BETWEEN_CATEGORY_ROWS = 8; // space-y-2 is used, which is 8px

export const actionTypes = {
  DATA_LOADING: 'DATA_LOADING',
  DATA_SET: 'DATA_SET',
  CATEGORY_DELETE: 'CATEGORY_DELETE',
  CATEGORY_CHANGE_DISABLED_STATUS: 'CATEGORY_CHANGE_DISABLED_STATUS',
  CATEGORY_CHANGE_PRIORITIES: 'CATEGORY_CHANGE_PRIORITIES',
  GROUP_CHANGE_PRIORITIES: 'GROUP_CHANGE_PRIORITIES',
  GROUP_DELETE: 'GROUP_DELETE',
  QUESTION_CHANGE_PRIORITIES: 'QUESTION_CHANGE_PRIORITIES',
  QUESTION_DELETE: 'QUESTION_DELETE',
  PRIORITIES_SET_SAVING: 'PRIORITIES_SET_SAVING',
  PRIORITIES_RESET_SAVING: 'PRIORITIES_RESET_SAVING',
  PRIORITIES_SAVE: 'PRIORITIES_SAVE',
  PRIORITIES_CANCEL: 'PRIORITIES_CANCEL',
  COLLAPSE_STATUS_CHANGE: 'COLLAPSE_STATUS_CHANGE',
  COLLAPSE_STATUSES_ON_DRAG: 'COLLAPSE_STATUSES_ON_DRAG',
  COLLAPSE_STATUSES_RESET_ON_DRAG_DONE: 'COLLAPSE_STATUSES_RESET_ON_DRAG_DONE'
};

export const initialState = {
  initialData: '',
  hasPriorityChanges: false,
  categories: [],
  questionGroups: [],
  questions: [],
  dataLoading: false,
  prioritiesSaving: false,
  collapseStatus: {
    categories: [],
    questionGroups: []
  }
};
export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.DATA_LOADING:
      return {
        ...state,
        dataLoading: true
      };

    case actionTypes.DATA_SET:
      const categories = action.payload.categories;
      const questionGroups = action.payload.questionGroups;
      const questions = action.payload.questions;

      const mappedCategories = map(categories, (category) => ({
        ...category,
        title: tryParse(category.title),
        slug: tryParse(category.slug),
        image: category.image ? tryParse(category.image) : categoryInitialImage
      }));

      const mappedQuestionGroups = map(questionGroups, (questionGroup) => ({
        ...questionGroup,
        title: tryParse(questionGroup.title)
      }));

      const mappedQuestions = map(questions, (question) => ({
        ...question,
        title: tryParse(question.title)
      }));

      let collapseStatus = {
        categories: map(categories, (category) => ({ id: category.id, isCollapsed: false })),
        questionGroups: map(questionGroups, (questionGroup) => ({ id: questionGroup.id, isCollapsed: false }))
      };

      return {
        ...state,
        dataLoading: false,
        categories: orderByPriority(mappedCategories),
        questionGroups: orderByPriority(mappedQuestionGroups),
        questions: orderByPriority(mappedQuestions),
        initialData: {
          categories: orderByPriority(mappedCategories),
          questionGroups: orderByPriority(mappedQuestionGroups),
          questions: orderByPriority(mappedQuestions)
        },
        collapseStatus: collapseStatus
      };

    case actionTypes.CATEGORY_CHANGE_DISABLED_STATUS:
      const newCategories = map(state.categories, (category) => {
        if (category.id === action.payload.categoryId) {
          return {
            ...category,
            disabled: action.payload.disabled
          };
        }
        return category;
      });

      return {
        ...state,
        categories: orderByPriority(newCategories)
      };

    case actionTypes.CATEGORY_CHANGE_PRIORITIES:
      const draggedCategory = state.categories[action.payload.draggedIndex];
      const targetCategory = state.categories[action.payload.targetIndex];
      const categoriesWithUpdatedPriorities = orderByPriority(updatePriorities(state.categories, draggedCategory, targetCategory));

      return {
        ...state,
        categories: categoriesWithUpdatedPriorities,
        hasPriorityChanges: isPriorityChanged(state.initialData, { categories: categoriesWithUpdatedPriorities, questionGroups: state.questionGroups, questions: state.questions })
      };

    case actionTypes.GROUP_CHANGE_PRIORITIES:
      const draggedGroup = find(state.questionGroups, (group) => group.id === action.payload.draggedGroupId);
      const targetGroup = find(state.questionGroups, (group) => group.id === action.payload.targetGroupId);

      let newQuestionGroups = state.questionGroups;
      let categoryId = draggedGroup?.categoryId;

      // Change categoryId of the group
      if (draggedGroup?.categoryId !== action.payload.targetCategoryId) {
        categoryId = action.payload.targetCategoryId;
        newQuestionGroups = map(state.questionGroups, (questionGroup) => {
          if (questionGroup.id === draggedGroup?.id) {
            return {
              ...questionGroup,
              categoryId: action.payload.targetCategoryId
            };
          }

          return questionGroup;
        });
      }

      const categoryGroupsWithUpdatedPriorities = updatePriorities(
        filter(newQuestionGroups, (group) => group.categoryId === categoryId),
        draggedGroup,
        targetGroup
      );

      const updatedQuestionGroups = map(state.questionGroups, (questionGroup) => {
        const matchedGroup = find(categoryGroupsWithUpdatedPriorities, (group) => group.id === questionGroup.id);
        return matchedGroup ? { ...matchedGroup } : { ...questionGroup };
      });

      return {
        ...state,
        questionGroups: orderByPriority(updatedQuestionGroups),
        hasPriorityChanges: isPriorityChanged(state.initialData, { categories: state.categories, questionGroups: updatedQuestionGroups, questions: state.questions })
      };

    case actionTypes.QUESTION_CHANGE_PRIORITIES:
      const draggedQuestion = find(state.questions, (question) => question.id === action.payload.draggedQuestionId);
      const targetQuestion = find(state.questions, (question) => question.id === action.payload.targetQuestionId);

      let currentQuestions = state.questions;
      let groupId = draggedQuestion?.groupId;

      // Change categoryId of the group
      if (draggedQuestion?.groupId !== action.payload.targetGroupId) {
        groupId = action.payload.targetGroupId;
        currentQuestions = map(state.questions, (question) => {
          if (question.id === draggedQuestion?.id) {
            return {
              ...question,
              groupId: action.payload.targetGroupId
            };
          }

          return question;
        });
      }

      const groupQuestionsWithUpdatedPriorities = updatePriorities(
        filter(currentQuestions, (question) => question.groupId === groupId),
        draggedQuestion,
        targetQuestion
      );

      const updatedQuestions = map(state.questions, (question) => {
        const matchedQuestion = find(groupQuestionsWithUpdatedPriorities, (item) => item.id === question.id);
        return matchedQuestion ? { ...matchedQuestion } : { ...question };
      });

      return {
        ...state,
        questions: orderByPriority(updatedQuestions),
        hasPriorityChanges: isPriorityChanged(state.initialData, { categories: state.categories, questionGroups: state.questionGroups, questions: updatedQuestions })
      };

    case actionTypes.CATEGORY_DELETE:
      let initialDataAfterCategoryDelete = state.initialData;
      if (initialDataAfterCategoryDelete && initialDataAfterCategoryDelete !== '') {
        initialDataAfterCategoryDelete = {
          ...state.initialData,
          categories: orderByPriority(filter(state.initialData.categories, (category) => category.id !== action.payload.categoryId))
        };
      }
      return {
        ...state,
        categories: orderByPriority(filter(state.categories, (category) => category.id !== action.payload.categoryId)),
        initialData: initialDataAfterCategoryDelete
      };

    case actionTypes.GROUP_DELETE:
      let initialDataAfterGroupDelete = state.initialData;
      if (initialDataAfterGroupDelete && initialDataAfterGroupDelete !== '') {
        initialDataAfterGroupDelete = {
          ...state.initialData,
          questionGroups: orderByPriority(filter(state.initialData.questionGroups, (questionGroup) => questionGroup.id !== action.payload.groupId || questionGroup.categoryId !== action.payload.categoryId))
        };
      }
      return {
        ...state,
        questionGroups: orderByPriority(filter(state.questionGroups, (questionGroup) => questionGroup.id !== action.payload.groupId || questionGroup.categoryId !== action.payload.categoryId)),
        initialData: initialDataAfterGroupDelete
      };

    case actionTypes.QUESTION_DELETE:
      let initialDataAfterQuestionDelete = state.initialData;
      if (initialDataAfterQuestionDelete && initialDataAfterQuestionDelete !== '') {
        initialDataAfterQuestionDelete = {
          ...state.initialData,
          questions: orderByPriority(filter(state.initialData.questions, (question) => question.groupId !== action.payload.groupId || question.id !== action.payload.questionId))
        };
      }
      return {
        ...state,
        questions: orderByPriority(filter(state.questions, (question) => question.groupId !== action.payload.groupId || question.id !== action.payload.questionId)),
        initialData: initialDataAfterQuestionDelete
      };

    case actionTypes.PRIORITIES_SET_SAVING:
      return {
        ...state,
        prioritiesSaving: true
      };
    case actionTypes.PRIORITIES_RESET_SAVING:
      return {
        ...state,
        prioritiesSaving: false
      };
    case actionTypes.PRIORITIES_CANCEL:
      return {
        ...state,
        categories: state.initialData.categories,
        questionGroups: state.initialData.questionGroups,
        questions: state.initialData.questions,
        prioritiesSaving: false,
        hasPriorityChanges: false
      };
    case actionTypes.PRIORITIES_SAVE:
      return {
        ...state,
        initialData: {
          categories: orderByPriority(state.categories),
          questionGroups: orderByPriority(state.questionGroups),
          questions: orderByPriority(state.questions)
        },
        prioritiesSaving: false,
        hasPriorityChanges: false
      };

    case actionTypes.COLLAPSE_STATUS_CHANGE:
      const item = find(state.collapseStatus[action.payload.key], (status) => status.id === action.payload.id);

      let updatedCollapseStatus = state.collapseStatus;
      if (item) {
        updatedCollapseStatus = {
          ...state.collapseStatus,
          [action.payload.key]: [...filter(state.collapseStatus[action.payload.key], (status) => status.id !== item.id), { id: item.id, isCollapsed: !item.isCollapsed }]
        };
      }

      return {
        ...state,
        collapseStatus: updatedCollapseStatus
      };
    case actionTypes.COLLAPSE_STATUSES_ON_DRAG:
      const key = action.payload.key;

      const newCollapseStatuses = {
        ...state.collapseStatus,
        [key]: map(state.collapseStatus[key], (item) => ({ ...item, isCollapsed: true }))
      };

      return {
        ...state,
        collapseStatus: newCollapseStatuses
      };

    case actionTypes.COLLAPSE_STATUSES_RESET_ON_DRAG_DONE:
      // const oldCollapseStatuses = JSON.parse(localStorage.getItem(COLLAPSE_STATUS_STORAGE_KEY));
      // return {
      //   ...state,
      //   collapseStatus: oldCollapseStatuses
      // };
      return state;

    default:
      return state;
  }
};
