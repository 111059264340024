import { filter, orderBy } from 'lodash';

export const getDealerMakes = (makes) => {
  return orderBy(
    filter(makes, (make) => make.priority > 0),
    ['priority'],
    ['desc']
  );
};

export const getOtherMakes = (makes) => {
  return orderBy(
    filter(makes, (make) => make.priority === 0),
    ['name'],
    ['asc']
  );
};
