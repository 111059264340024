import React, { useContext } from 'react';
import { find, map, orderBy } from 'lodash';
import CustomBlock from './CustomBlock';
import { StateContext } from '../../../../App';
import InfoIndicator from '../../../../components/common/indicators/InfoIndicator';

const CustomBlocks = ({ customBlocks, onHandleDeletePopup, context }) => {
  const contextState = useContext(StateContext);
  const languages = contextState?.configuration?.website?.languages;
  const columnClassName = 'builder-px-4 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-whitespace-nowrap builder-text-black builder-uppercase builder-tracking-wider';

  if (customBlocks?.length < 1) {
    return (
      <div className='builder-w-full builder-relative'>
        <InfoIndicator message='There are no custom blocks to show.' />
      </div>
    );
  }

  return (
    <div data-testid='custom-blocks-container' className='builder-flex builder-flex-col builder-justify-center builder-space-y-4 builder-text-md builder-text-black builder-max-w-7xl builder-min-w-min builder-pb-5'>
      <div className='builder-min-w-full builder-divide-y builder-divide-gray-200'>
        <div className='builder-flex builder-items-center builder-bg-gray-50'>
          <div className={columnClassName} style={{ width: '50%' }}>
            Description
          </div>

          {map(languages, (language) => (
            <div key={`language-${language}`} className={columnClassName} style={{ width: '10%' }}>
              {language}
            </div>
          ))}

          <div className={columnClassName}>Actions</div>
        </div>

        <div className='builder-bg-white builder-divide-y builder-divide-gray-200'>
          {map(orderBy(customBlocks, 'description'), (customBlock) => {
            return <CustomBlock key={customBlock?.id} customBlock={customBlock} onHandleDeletePopup={onHandleDeletePopup} context={context} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default CustomBlocks;
