import { actionTypes } from './reducer';

export const customBlocksSetLoadingHandler = () => ({
  type: actionTypes.CUSTOM_BLOCKS_SET_LOADING
});

export const customBlocksResetLoadingHandler = () => ({
  type: actionTypes.CUSTOM_BLOCKS_RESET_LOADING
});

export const customBlocksSetHandler = (customBlocks) => ({
  type: actionTypes.CUSTOM_BLOCKS_SET,
  payload: {
    customBlocks
  }
});

export const customBlockShowDeletePopupHandler = (id) => ({
  type: actionTypes.CUSTOM_BLOCK_SHOW_DELETE_POPUP,
  payload: {
    id
  }
});

export const customBlockRemoveDeletePopupHandler = () => ({
  type: actionTypes.CUSTOM_BLOCK_REMOVE_DELETE_POPUP
});

export const customBlockDeleteHandler = (id) => ({
  type: actionTypes.CUSTOM_BLOCK_DELETED,
  payload: {
    id
  }
});

export const customBlockSetDeletingHandler = () => ({
  type: actionTypes.CUSTOM_BLOCK_SET_DELETING
});

export const customBlockResetDeletingHandler = () => ({
  type: actionTypes.CUSTOM_BLOCK_RESET_DELETING
});
