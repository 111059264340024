import { actionTypes } from './reducer';

export const filterSetHandler = (dispatch, filter) => {
  dispatch({
    type: actionTypes.FILTER_SET,
    payload: {
      filter
    }
  });
};

export const filterInitializeHandler = (dispatch, id) => {
  dispatch({
    type: actionTypes.FILTER_INITIALIZE,
    payload: {
      id
    }
  });
};

export const filterSetDescriptionHandler = (dispatch, description) => {
  dispatch({
    type: actionTypes.FILTER_SET_DESCRIPTION,
    payload: {
      description
    }
  });
};

export const filterSetTypeHandler = (dispatch, type) => {
  dispatch({
    type: actionTypes.FILTER_SET_TYPE,
    payload: {
      type
    }
  });
};

export const filterSetFiltersHandler = (dispatch, filters) => {
  dispatch({
    type: actionTypes.FILTER_SET_FILTERS,
    payload: {
      filters
    }
  });
};

export const filterFiltersModalHandler = (dispatch, filtersModal) => {
  dispatch({
    type: actionTypes.FILTERS_MODAL_SET_OPEN,
    payload: {
      filtersModal
    }
  });
};

export const filterUndoChangesHandler = (dispatch) => {
  dispatch({
    type: actionTypes.FILTER_UNDO_CHANGES,
    payload: {}
  });
};

export const filterSetLoadingHandler = (dispatch) => {
  dispatch({
    type: actionTypes.FILTER_SET_LOADING,
    payload: {}
  });
};

export const filterResetLoadingHandler = (dispatch) => {
  dispatch({
    type: actionTypes.FILTER_RESET_LOADING,
    payload: {}
  });
};
