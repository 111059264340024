import { map, filter } from 'lodash';

// INITIAL STATES
//
export const initialState = {
  advertisements: [],
  advertisementsLoading: true,
  advertisementsError: false,
  history: [] //?
};

export const initialAdvertisement = {
  id: '',
  description: '',
  content: [],
  groups: [],
  filters: [],
  disabled: false,
  isDraft: false
};

// ACTION TYPES
//
export const actionTypes = {
  ADVERTISEMENTS_SET: 'ADVERTISEMENTS_SET',
  ADVERTISEMENT_DELETE: 'ADVERTISEMENT_DELETE',
  ADVERTISEMENTS_SET_LOADING: 'ADVERTISEMENTS_SET_LOADING',
  ADVERTISEMENTS_RESET_LOADING: 'ADVERTISEMENTS_RESET_LOADING'
};

// REDUCERS
//
export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ADVERTISEMENTS_SET:
      return {
        ...state,
        advertisements: map(action.payload.advertisements, (ad) => ({
          ...initialAdvertisement,
          id: ad.id,
          description: ad.description,
          content: ad.content,
          isTemplate: ad.isTemplate,
          disabled: ad.disabled
        })),
        advertisementsLoading: false,
        advertisementsError: undefined
      };
    case actionTypes.ADVERTISEMENT_DELETE:
      return {
        ...state,
        advertisements: filter(state.advertisements, (ad) => ad.id !== action.payload.id),
        advertisementsLoading: false,
        advertisementsError: undefined
      };
    case actionTypes.ADVERTISEMENTS_SET_LOADING:
      return {
        ...state,
        advertisementsLoading: true
      };
    case actionTypes.ADVERTISEMENTS_RESET_LOADING:
      return {
        ...state,
        advertisementsLoading: false
      };
    default:
      return state;
  }
};
