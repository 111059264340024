export const TRACKING_STATE_QUERY = `
 query TrackingState {
  trackingState {  
    trackingUsers {
      trackingId
      userName
      route
      timestamp
    }
    isPublishing
  }
}
`;
export const TRACKING_USER_SUBSCRIPTION = `
 subscription TrackingUser($route: String) {
  trackingUser(route: $route) {
    trackingId
    userName
    action
    route
    timestamp
    value
  }
}
`;

export const TRACKING_USER_MUTATION = `
 mutation TrackingUser($data: TrackingUserInputType!) {
  trackingUser(data: $data) {
    ok
  }
}
`;

export const TRACKING_USER_ACTION_TYPES = {
  MOVE: 'move',
  LEAVE: 'leave',
  UPDATE: 'update',
  UPDATE_DONE: 'update_done',
  PUBLISH: 'publish',
  PUBLISH_DONE: 'publish_done',
  FETCH_WEBSITE: 'fetch_website', // Not implemented yet (not needed for now)
  UPDATE_PAGES: 'update_pages'
};

export const TRACKING_USER_ACTIONS = {
  UPDATE_TRACKING_USER: 'UPDATE_TRACKING_USER',
  SET_TRACKING_USERS: 'SET_TRACKING_USERS',
  UPDATE_TRACKING_PAGE: 'UPDATE_TRACKING_PAGE',
  UPDATE_IS_RELEASING: 'UPDATE_IS_RELEASING',
  SET_IS_SUBSCRIBED: 'SET_IS_SUBSCRIBED'
};

export const initialState = {
  users: {},
  pages: {},
  isReleasing: false,
  isSubscribed: false
};
// Actions

export const updateIsReleasing = (dispatch, isReleasing) => {
  dispatch({
    type: TRACKING_USER_ACTIONS.UPDATE_IS_RELEASING,
    payload: {
      isReleasing
    }
  });
};

export const setIsSubscribed = (dispatch, isSubscribed) => {
  dispatch({
    type: TRACKING_USER_ACTIONS.SET_IS_SUBSCRIBED,
    payload: {
      isSubscribed
    }
  });
};

export const updateTrackingUser = (dispatch, trackingId, data) => {
  dispatch({
    type: TRACKING_USER_ACTIONS.UPDATE_TRACKING_USER,
    payload: {
      trackingId,
      data
    }
  });
};

export const setTrackingUsers = (dispatch, users) => {
  dispatch({
    type: TRACKING_USER_ACTIONS.SET_TRACKING_USERS,
    payload: {
      users
    }
  });
};

export const updateTrackingPage = (dispatch, data) => {
  dispatch({
    type: TRACKING_USER_ACTIONS.UPDATE_TRACKING_PAGE,
    payload: {
      data
    }
  });
};

// Reducer
export const trackingReducer = (state, action) => {
  switch (action.type) {
    case TRACKING_USER_ACTIONS.UPDATE_IS_RELEASING:
      return {
        ...state,
        isReleasing: action.payload.isReleasing
      };
    case TRACKING_USER_ACTIONS.SET_IS_SUBSCRIBED:
      return {
        ...state,
        isSubscribed: action.payload.isSubscribed
      };
    case TRACKING_USER_ACTIONS.UPDATE_TRACKING_USER: {
      const newUsers = {
        ...state.users,
        [action.payload.trackingId]: action.payload.data
      };
      if (!action.payload.data.route) {
        delete newUsers[action.payload.trackingId];
      }
      return {
        ...state,
        users: newUsers
      };
    }
    case TRACKING_USER_ACTIONS.SET_TRACKING_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          ...action.payload.users
        }
      };
    case TRACKING_USER_ACTIONS.UPDATE_TRACKING_PAGE:
      const newPages = {
        ...state.pages,
        [action.payload.data.route]: action.payload.data
      };
      if (action.payload.data.action === TRACKING_USER_ACTION_TYPES.UPDATE_DONE) {
        delete newPages[action.payload.data.route];
      }
      return {
        ...state,
        pages: newPages
      };
    default:
      return state;
  }
};
