import React, { isValidElement, useContext, useEffect, useState } from 'react';
import Configuration from './configuration/Configuration';
import FileUploader from './file-uploader/FileUploader';
import Release from './release/Release';
import BackupAndRestore from './backup-restore/BackupAndRestore';
import { routes } from '../../data/constants';
import { default as PageTypesOverview } from './page-types/overview/components/Overview';
import { default as RedirectsOverview } from './redirects/overview/components/Overview';
import { default as MakesOverview } from './makes/overview/components/Overview';
import { find, omit } from 'lodash';
import Header from '../../../components/Header';
import { StateContext } from '../../../App';
import { Link, useLocation } from 'react-router-dom';
import Page404 from '../../../components/Page404';

const DynamicComponent = (props) => {
  if (isValidElement(props.component)) {
    return React.cloneElement(props.component, omit(props, 'component'));
  }

  return <div>The component has not been created yet.</div>;
};

const pages = [
  {
    title: 'Configuration JSON',
    component: <Configuration />,
    icon: 'far fa-cog',
    url: routes.SETTINGS_GENERAL_CONFIG
  },
  {
    title: 'File uploader',
    component: <FileUploader />,
    icon: 'fas fa-images',
    url: routes.SETTINGS_FILE_UPLOADER
  },
  {
    title: 'Release',
    component: <Release />,
    icon: 'far fa-upload',
    url: routes.SETTINGS_RELEASE
  },
  {
    title: 'Backup and Restore',
    component: <BackupAndRestore />,
    icon: 'far fa-database',
    url: routes.SETTINGS_BACKUP_AND_RESTORE
  },
  {
    title: 'Page types',
    component: <PageTypesOverview />,
    icon: 'fad fa-file',
    url: routes.SETTINGS_PAGE_TYPES
  },
  {
    title: 'Redirects',
    component: <RedirectsOverview />,
    icon: 'fal fa-directions',
    url: routes.SETTINGS_REDIRECTS
  },
  {
    title: 'Makes',
    component: <MakesOverview />,
    icon: 'fal fa-car',
    url: routes.SETTINGS_MAKES
  }
];

const getPage = (location) => {
  if (!location) return null;
  return find(pages, (page) => page.url === location.pathname) || null;
};

const GeneralSettings = ({ context }) => {
  let location = useLocation();
  const state = useContext(StateContext);
  const isSuperUser = state.session.user.isSuperUser;
  const currentState = state.history[state.history.length - 1];
  const [currentPage, setCurrentPage] = useState(getPage(location));

  useEffect(() => {
    setCurrentPage(getPage(location));
  }, [location.pathname]);

  if (!currentPage) {
    return <Page404 />;
  }

  return (
    <div className='builder-flex builder-flex-1 builder-flex-col builder-h-screen builder-font-body builder-text-sm'>
      <Header />

      <div className='builder-flex builder-bg-gray-50 builder-p-4 builder-items-center builder-sticky builder-top-0 builder-z-50 builder-border-b builder-border-slate-100 builder-mb-6' style={{ height: 60, minHeight: 60 }}>
        <div className='builder-font-bold builder-text-2xl builder-text-slate-800' style={{ maxWidth: 1600 }}>
          Settings - {currentPage?.title}
        </div>
      </div>

      <div className='builder-flex builder-items-center builder-justify-center builder-bg-white builder-h-screen'>
        <div className='builder-flex builder-w-full builder-h-full builder-px-4 builder-pb-6'>
          <div className='builder-flex builder-flex-col builder-border-r builder-border-gray-200' style={{ maxWidth: 220, width: 220, minWidth: 220 }}>
            {pages.map((page, i) => {
              const activePage = currentPage.url === page.url;
              return (
                <Link key={`${page.url}-${i}`} to={page.url} className={`builder-p-3 builder-cursor-pointer hover:bg-gray-50 ${activePage ? 'builder-bg-gray-50' : ''}`}>
                  <i className={`mr-2 ${page.icon}`} /> {page.title}
                </Link>
              );
            })}
          </div>
          <div className='builder-w-full builder-ml-4 builder-overflow-scroll' style={{ maxHeight: 'calc(100vh - 146px - 1.5rem)' }}>
            <DynamicComponent component={currentPage.component} isSuperUser={isSuperUser} currentState={currentState} context={context} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralSettings;
