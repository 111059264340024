import Pusher from 'pusher-js';

export let pusher = undefined;
export let channel = undefined;
export let userChannel = undefined;

export const initializePusher = (company_code, sessionId) => {
  pusher = new Pusher('725237852aa1a3c2d068', {
    cluster: 'eu'
  });

  channel = pusher.subscribe(`${company_code}_channel`);
  userChannel = pusher.subscribe(`${company_code}_channel_${sessionId}`);
};
