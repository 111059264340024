import React from 'react';
import { getKeyValueContentFieldsForBlock } from '../../../utils';
import * as components from 'dealer-website-components';

const BlockComponent = ({ block, context }) => {
  const Component = components[block.componentName];

  if (!Component) {
    return null;
  }

  return (
    <div id={`b${block.id.split('-')[0]}`} className='font-body'>
      <Component {...getKeyValueContentFieldsForBlock(block, context.culture)} context={context} />
    </div>
  );
};

export default BlockComponent;
