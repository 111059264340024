import React, { useState } from 'react';
import { useFileUpload } from './useFileUpload';
import { v4 } from 'uuid';
import FilePicker from './editors/FilePicker';
import Modal from 'react-modal';

let fileSelector;
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  },
  overlay: { zIndex: 9999 }
};

export const baseFileSelector = (BaseComponent, type) => {
  const BaseFileSelector = (props) => {
    const [url, setUrl] = useState(undefined);
    const { uploadFile, loading, error } = useFileUpload();
    const [filesModalVisible, setFilesModalVisible] = useState(false);

    const handleFileUpload = (e) => {
      showFileSelector(e);
    };

    const handleSelectExistingFile = () => {
      setFilesModalVisible(true);
    };

    const showFileSelector = (e) => {
      e.preventDefault();

      fileSelector = document.createElement('input');
      fileSelector.setAttribute('id', v4());
      fileSelector.setAttribute('type', 'file');
      fileSelector.setAttribute('multiple', 'multiple');

      fileSelector.addEventListener('change', (e) => {
        uploadFile(e.target.files[0], (url) => setUrl(url));
      });

      fileSelector.setAttribute('accept', type === 'image' ? '.png,.jpg,.jpeg,.svg,.webp,.pdf,.ico' : '.mp4,.mov,.m4v');
      fileSelector.click();
    };

    return (
      <React.Fragment>
        <BaseComponent {...props} url={url} handleFileUpload={handleFileUpload} handleSelectExistingFile={handleSelectExistingFile} loading={loading} error={error} />

        <Modal isOpen={filesModalVisible} onRequestClose={() => setFilesModalVisible(false)} ariaHideApp={false} appElement={document.getElementById('root') || undefined} style={customStyles} contentLabel='Example Modal'>
          <div className='builder-flex builder-justify-end builder-mb-4'>
            <div
              className='builder-cursor-pointer'
              onClick={() => {
                setFilesModalVisible(false);
              }}
            >
              <i className='fal fa-times builder-text-xl' />
            </div>
          </div>

          <div style={{ width: 700, minHeight: 400, maxHeight: 500 }}>
            <FilePicker
              onSelect={(file) => {
                setFilesModalVisible(false);
                setUrl(file.url);
              }}
              type={type}
            />
          </div>
        </Modal>
      </React.Fragment>
    );
  };

  return BaseFileSelector;
};
