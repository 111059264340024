import React, { useState } from 'react';
import TextArea from '../editors/components/TextArea';
import TextInput from '../editors/components/TextInput';
import { v4 } from 'uuid';
import { routes } from '../../data/constants';
import { useHistory } from 'react-router-dom';

const PageMetaProperties = ({ page, meta, onChangeMeta, onChangeIndexable, context }) => {
  const [isOpen, setIsOpen] = useState(true);
  const history = useHistory();

  // const handleChangeSlug = (value) => {
  //     onChangePage({
  //         ...page,
  //         translations: {
  //             ...page.translations,
  //             [context.culture]: value
  //         }
  //     })
  // }

  const handleChangeMeta = (name, value) => {
    onChangeMeta({
      ...meta,
      id: meta ? meta.id : v4(),
      description: meta?.description || '',
      [name]: value
    });
  };

  // const isVehicleDetailPage = page.type === 'vehicleDetail' || page.type === 'vehicleDetailLease';
  // const isStockPage = page.type === 'vehicles' || page.type === 'vehiclesLease';
  const isVehicleDetailPage = page?.category === 'productDetail';
  const isStockPage = page?.category === 'productList';

  // const onEditPage = (id, language) => {
  //   history.push(`${routes.SEO_DETAILS}/${id}`, {
  //     language: language
  //   });
  // };

  const goToSeoDetails = () => {
    history.push(`${routes.SEO_DETAILS}/${page.id}`, {
      language: context.culture
    });
  };

  return (
    <div className='builder-flex builder-flex-col'>
      <div className='builder-flex builder-justify-between builder-items-center builder-py-2 builder-border-b builder-border-gray-300 builder-shadow-sm builder-cursor-pointer' onClick={() => setIsOpen(!isOpen)}>
        <h3 className='builder-font-bold builder-text-lg builder-ml-4'>Page meta</h3>
        <i className={`fas builder-mr-4 builder-text-md ${isOpen ? 'fa-caret-up' : 'fa-caret-down'}`} />
      </div>
      <div className={`${isOpen ? 'builder-flex builder-flex-col' : 'builder-hidden'}`}>
        <div className=''>
          <div className='builder-flex builder-flex-col builder-p-4'>
            <label className='builder-block builder-text-gray-700 builder-text-md builder-font-bold builder-mb-2 '>Title</label>

            <TextInput placeholder='title' className='builder-border builder-border-gray-300 builder-rounded-md' value={meta ? meta.title : ''} onChanged={(value) => handleChangeMeta('title', value)} />
            {isVehicleDetailPage && <span className='builder-text-xs'>* Merk, model, versie worden automatisch toegevoegd</span>}

            {!isVehicleDetailPage && <label className='builder-block builder-text-gray-700 builder-text-md builder-font-bold builder-mb-2 builder-mt-2 builder-mt-6 '>Description</label>}

            {!isVehicleDetailPage && <TextArea placeholder='description' value={meta ? meta.description : ''} rows={10} onChanged={(value) => handleChangeMeta('description', value)} />}

            <div className='builder-flex builder-mt-6'>
              <label className='builder-block builder-text-gray-700 builder-text-md builder-font-bold builder-mb-2 builder-mt-2 builder-mr-4'>Indexable</label>

              <div className='builder-flex builder-items-center'>
                <input
                  type='checkbox'
                  checked={page.indexable}
                  onChange={(e) => onChangeIndexable(e.target.checked)}
                  className='builder-h-4 builder-w-4 builder-text-indigo-600 focus:builder-ring-indigo-500 builder-border builder-border-gray-300 builder-rounded-md'
                />
              </div>
            </div>

            {isStockPage && (
              <div className='builder-flex builder-flex-col builder-mt-6 builder-border-t builder-border-b builder-border-gray-200 builder-py-4'>
                <div className='builder-flex builder-items-center builder-font-semibold builder-text-primary builder-cursor-pointer hover:builder-font-bold' onClick={goToSeoDetails}>
                  <i className='fal fa-edit builder-mr-4' /> Edit/add custom SEO text
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageMetaProperties;
