import React, { useEffect, useReducer } from 'react';
import { initialState, reducer } from '../reducer';
import LoadingSkeleton from './LoadingSkeleton';
import DealerMakes from './DealerMakes';
import OtherMakes from './OtherMakes';
import { useMakes } from '../../hooks/useMakes';
import { getDealerMakes, getOtherMakes } from '../../utils';

const Overview = ({ context }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { fetchMakes, updateMakeSeo, updateMakePriority, onMakeDisabledUpdate, onRemoveFromDealerMakes, onAddToDealerMakes } = useMakes(state, dispatch);

  useEffect(() => {
    fetchMakes();
  }, []);

  const isLoading = state.loading;

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  const dealerMakes = getDealerMakes(state.makes);
  const otherMakes = getOtherMakes(state.makes);

  return (
    <div className='builder-h-full builder-relative builder-max-w-3xl'>
      <div data-testid='dealer-makes-container' className='builder-mt-3'>
        <DealerMakes makes={dealerMakes} onMakeSeoUpdate={updateMakeSeo} onMakePriorityUpdate={updateMakePriority} onMakeDisabledUpdate={onMakeDisabledUpdate} onRemoveFromDealerMakes={onRemoveFromDealerMakes} state={state} context={context} />
      </div>
      <div className='builder-h-px builder-bg-gray-200 builder-mt-6 builder-mb-3' />
      <div data-testid='other-makes-container'>
        <OtherMakes makes={otherMakes} onMakeDisabledUpdate={onMakeDisabledUpdate} onAddToDealerMakes={onAddToDealerMakes} state={state} context={context} />
      </div>
    </div>
  );
};

export default Overview;
