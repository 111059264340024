import { actionTypes } from './reducer';

export const customBlockSetFetchingHandler = () => ({
  type: actionTypes.CUSTOM_BLOCK_SET_FETCHING
});

export const customBlockResetFetchingHandler = () => ({
  type: actionTypes.CUSTOM_BLOCK_RESET_FETCHING
});

export const customBlockSetSavingHandler = () => ({
  type: actionTypes.CUSTOM_BLOCK_SET_SAVING
});

export const customBlockResetSavingHandler = () => ({
  type: actionTypes.CUSTOM_BLOCK_RESET_SAVING
});

export const customBlockSetHandler = (customBlock) => ({
  type: actionTypes.CUSTOM_BLOCK_SET,
  payload: {
    customBlock
  }
});

export const customBlockInitializeHandler = (languages) => ({
  type: actionTypes.CUSTOM_BLOCK_INITIALIZE,
  payload: {
    languages
  }
});

export const formErrorsSetHandler = (formErrors) => ({
  type: actionTypes.FORM_ERRORS_SET,
  payload: {
    formErrors
  }
});

export const customBlockSetDescriptionHandler = (updatedDescription) => ({
  type: actionTypes.CUSTOM_BLOCK_SET_DESCRIPTION,
  payload: {
    updatedDescription
  }
});

export const customBlockSetContentHandler = (language, updatedContent) => ({
  type: actionTypes.CUSTOM_BLOCK_SET_CONTENT,
  payload: {
    language,
    updatedContent
  }
});
