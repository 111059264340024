import React from 'react';
import { coreTypes } from 'dealer-website-components';
import Toggle from 'react-toggle';

const ShuffleFilter = ({ filterValues, filterAdd, filterUpdate }) => {
  const value = filterValues && filterValues.length > 0 ? filterValues[0] : false;
  const { FilterTypes } = coreTypes;
  const handleChange = (value) => {
    if (filterValues.length === 0) {
      filterAdd(FilterTypes.shuffle, value);
    } else {
      filterUpdate(FilterTypes.shuffle, value);
    }
  };

  return (
    <React.Fragment>
      <div className='builder-font-semibold builder-mb-2 builder-mt-5'>Shuffle</div>
      <div className='builder-mb-2'>Display vehicles in random order</div>

      <div className='builder-text-sm builder-font-medium  builder-mb-2'>
        <Toggle defaultChecked={value} aria-label='No label tag' onChange={(e) => handleChange(e.target.checked)} />
      </div>
    </React.Fragment>
  );
};

export default ShuffleFilter;
