import { tryParse } from '../../../../utils';
import { find, map, filter } from 'lodash';
import { orderByPriority } from '../../utils';
import { v4 } from 'uuid';

export const actionTypes = {
  QUESTION_SET_LOADING: 'QUESTION_SET_LOADING',
  QUESTION_RESET_LOADING: 'QUESTION_RESET_LOADING',
  QUESTION_INITIALIZE: 'QUESTION_INITIALIZE',
  QUESTION_SET: 'QUESTION_SET',
  QUESTION_SET_TITLE: 'QUESTION_SET_TITLE',
  QUESTION_SET_ANSWER: 'QUESTION_SET_ANSWER',
  QUESTION_SET_GROUP: 'QUESTION_SET_GROUP',
  QUESTION_ADD_GROUP: 'QUESTION_ADD_GROUP',
  QUESTION_SET_INITIALIZED: 'QUESTION_SET_INITIALIZED',
  QUESTION_UNDO_CHANGES: 'QUESTION_UNDO_CHANGES',
  QUESTION_SET_SAVING: 'QUESTION_SET_SAVING',
  QUESTION_RESET_SAVING: 'QUESTION_RESET_SAVING',
  QUESTION_SET_SAVE_COMPLETED: 'QUESTION_SET_SAVE_COMPLETED',

  GROUPS_AND_CATEGORIES_SET_FETCHING: 'GROUPS_AND_CATEGORIES_SET_FETCHING',
  GROUPS_AND_CATEGORIES_RESET_FETCHING: 'GROUPS_AND_CATEGORIES_RESET_FETCHING',
  GROUPS_AND_CATEGORIES_SET: 'GROUPS_AND_CATEGORIES_RESET_SET'
};

export const initialTitle = {
  nl: '',
  fr: '',
  en: ''
};

export const initialAnswer = {
  id: '',
  culture: '',
  data: '<div>Add your HTML here</div>'
};

export const initialQuestion = {
  id: '',
  title: initialTitle,
  groupId: '',
  answer: [],
  priority: 0,
  isDraft: false,
  disabled: false
};

export const initialState = {
  categories: [],
  groups: [],
  groupsAndCategoriesFetching: false,
  question: initialQuestion,
  saving: false,
  loading: false
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.QUESTION_SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case actionTypes.QUESTION_RESET_LOADING:
      return {
        ...state,
        loading: false
      };
    case actionTypes.QUESTION_SET_SAVING:
      return {
        ...state,
        saving: true
      };
    case actionTypes.QUESTION_RESET_SAVING:
      return {
        ...state,
        saving: false
      };
    case actionTypes.QUESTION_SET_SAVE_COMPLETED:
      return {
        ...state,
        saving: false
      };
    case actionTypes.QUESTION_SET_INITIALIZED:
      return {
        ...state,
        formInitialized: true
      };
    case actionTypes.QUESTION_INITIALIZE:
      return {
        ...state,
        history: undefined,
        question: {
          ...initialQuestion,
          id: action.payload.id
        }
      };
    case actionTypes.QUESTION_SET:
      const question = {
        id: action.payload.question.id,
        title: tryParse(action.payload.question.title),
        answer: action.payload.question.answer,
        groupId: action.payload.question.groupId,
        count: action.payload.question.count,
        priority: action.payload.question.priority,
        isDraft: action.payload.question.isDraft,
        disabled: action.payload.question.disabled
      };

      return {
        ...state,
        loading: false,
        question: question,
        history: [question]
      };
    case actionTypes.QUESTION_SET_TITLE:
      const newTitle = {
        ...state.question.title,
        [action.payload.language]: action.payload.title
      };

      return {
        ...state,
        history: state.history !== undefined ? [...state.history, { ...state.question }] : undefined,
        question: {
          ...state.question,
          title: newTitle
        }
      };

    case actionTypes.QUESTION_SET_ANSWER:
      const currentAnswer = find(state.question?.answer, (answer) => answer.culture === action.payload.language);
      let newAnswer;
      if (currentAnswer) {
        const otherAnswerData = filter(state.question.answer, (answer) => answer.id !== currentAnswer.id);
        newAnswer = [...otherAnswerData, { ...currentAnswer, data: action.payload.data }];
      } else {
        newAnswer = [...state.question.answer, { ...initialAnswer, id: v4(), culture: action.payload.language, data: action.payload.data }];
      }

      return {
        ...state,
        history: state.history !== undefined ? [...state.history, { ...state.question }] : undefined,
        question: {
          ...state.question,
          answer: newAnswer
        }
      };
    case actionTypes.QUESTION_SET_GROUP:
      return {
        ...state,
        history: state.history !== undefined ? [...state.history, { ...state.question }] : undefined,
        question: {
          ...state.question,
          groupId: action.payload.groupId
        }
      };
    case actionTypes.QUESTION_ADD_GROUP:
      const newCategory = action.payload.category;
      const existingCategory = find(state.categories, (category) => category.id === newCategory.id);
      let updatedCategories = [...state.categories];
      if (!existingCategory) {
        updatedCategories = [...updatedCategories, newCategory];
      }
      const newGroup = action.payload.group;

      return {
        ...state,
        categories: updatedCategories,
        groups: [...state.groups, newGroup],
        question: {
          ...state.question,
          groupId: newGroup.id
        }
      };
    case actionTypes.QUESTION_UNDO_CHANGES:
      return {
        ...state,
        question: {
          ...state.history[0]
        },
        history: [{ ...state.history[0] }]
      };
    case actionTypes.GROUPS_AND_CATEGORIES_SET_FETCHING:
      return {
        ...state,
        groupsAndCategoriesFetching: true
      };
    case actionTypes.GROUPS_AND_CATEGORIES_RESET_FETCHING:
      return {
        ...state,
        groupsAndCategoriesFetching: false
      };
    case actionTypes.GROUPS_AND_CATEGORIES_SET:
      return {
        ...state,
        groupsAndCategoriesFetching: false,
        categories: orderByPriority(
          map(action.payload.categories, (category) => ({
            id: category.id,
            title: tryParse(category.title),
            priority: category.priority
          }))
        ),
        groups: orderByPriority(
          map(action.payload.groups, (group) => ({
            id: group.id,
            title: tryParse(group.title),
            categoryId: group.categoryId,
            priority: group.priority
          }))
        )
      };
    default:
      return state;
  }
};
