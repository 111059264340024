import React from 'react';
import Dropdown from '../../../components/common/Dropdown';
import { map, orderBy, values } from 'lodash';
import { getTranslationForPage } from '../../../utils';

const PageSelectorV2 = ({ value, pages, onSelectPage, currentLanguage }) => {
  const dropDownOptions = orderBy(
    map(values(pages), (page) => {
      return {
        value: getTranslationForPage(page, currentLanguage),
        key: page.id,
        priority: page.url === '/index/' ? 1 : 0
      };
    }),
    ['priority', 'value'],
    ['desc', 'asc']
  );

  const renderPageName = (pageName) => {
    return pageName === '' ? '/homepage' : `/${pageName}`;
  };

  const renderItem = (item, index) => {
    let isLast = index === dropDownOptions.length - 1;
    return (
      <div
        className={`builder-flex builder-justify-between builder-px-2 builder-py-3 builder-ml-2 builder-mr-2 builder-text-sm hover:builder-bg-gray-100 ${isLast ? '' : 'builder-border-b builder-border-gray-300'}`}
        onClick={() => onSelectPage(item)}
      >
        <div className='builder-cursor-pointer'>{renderPageName(item.value)}</div>
      </div>
    );
  };

  return (
    <Dropdown
      centerText={false}
      minWidth='100%'
      maxWidth='100%'
      borderClassName=''
      defaultBgColor='builder-bg-gray-400'
      defaultTextColor='builder-text-white'
      selectClassName='builder-p-3'
      expandedBgColor='builder-bg-gray-400'
      expandedTextColor='builder-text-white'
      options={dropDownOptions}
      formatValue={(value) => renderPageName(value)}
      value={value ? value : undefined}
      renderItem={renderItem}
    />
  );
};

export default PageSelectorV2;
