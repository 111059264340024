import React from 'react';
import { range } from 'lodash';
//import { coreDefaults } from 'dealer-website-components';
import { coreTypes } from 'dealer-website-components';
import { formatNumber } from '../../../../../utils';
import GenericRangeSelect from '../../../../../components/common/GenericRangeSelect';

const MileageFilter = ({ filterData, filterValues, filterAdd, filterUpdate, hasFilter }) => {
  //const {FilterTypes, formatNumber} = coreDefaults;
  const { FilterTypes } = coreTypes;

  let mileagesFrom = range(0, 150000 + 1, 5000).map((mileage) => {
    return {
      value: Math.round(mileage / 1000) * 1000,
      label: `${formatNumber(Math.round(mileage / 1000) * 1000)} km`
    };
  });
  let mileagesTo = range(0, 150000 + 1, 5000).map((mileage) => {
    return {
      value: Math.round(mileage / 1000) * 1000,
      label: `${formatNumber(Math.round(mileage / 1000) * 1000)} km`
    };
  });

  const handleChange = (range) => {
    if (filterValues.length > 0) {
      filterUpdate(FilterTypes.mileage, range);
    } else {
      filterAdd(FilterTypes.mileage, range);
    }
  };

  return (
    <React.Fragment>
      <div className='builder-font-semibold builder-mb-2 builder-mt-5'>Mileage</div>
      <GenericRangeSelect placeHolderFrom='From' placeHolderTo='To' optionsFrom={mileagesFrom} optionsTo={mileagesTo} value={filterValues && filterValues.length > 0 ? filterValues[0] : undefined} handleFilterChanged={handleChange} />
    </React.Fragment>
  );
};

export default MileageFilter;
