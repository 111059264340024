import React from 'react';
import TextInput from './TextInput';
import EditorHeader from './EditorHeader';
import ImageUrlFieldEditor from '../ImageUrlFieldEditor';

const ImageDetailV2 = ({ image, onChange, definition, breadcrumbs, onBack }) => {
  const handleChangeField = (name, value) => {
    onChange({
      ...image,
      [name]: value
    });
  };

  return (
    <div className='builder-overflow-visible'>
      <React.Fragment>
        <EditorHeader description={definition.description} breadcrumbs={breadcrumbs} onBack={onBack} onClick={onBack} />

        <div className='builder-flex builder-flex-col builder-p-2  builder-bg-white builder-p-4'>
          <div className='builder-flex builder-flex-1 builder-flex-col builder-text-sm builder-pr-2'>
            <div className='builder-flex builder-flex-col builder-mb-2'>
              <div className='builder-flex builder-flex-col builder-mb-6'>
                <div className={`builder-draggable-item builder-flex builder-flex-row builder-border-b builder-border-gray-300 builder-p-4 builder-items-center  builder-bg-white`}>
                  <div className='builder-flex builder-flex-1 builder-items-center builder-text-sm builder-pr-2 builder-font-bold builder-text-primary'>
                    <i className='fal fa-edit builder-mr-4' /> Mobile
                  </div>
                </div>
                <div className=''>
                  <ImageUrlFieldEditor value={image['mobile']} type='image' className='builder-bg-white builder-px-0 builder-py-0' definition={definition} onChange={(url) => handleChangeField('mobile', url)} onBack={(index) => {}} />
                </div>
              </div>

              <div className='builder-flex builder-flex-col builder-mb-6'>
                <div className={`builder-draggable-item builder-flex builder-flex-row builder-border-b builder-border-gray-300 builder-p-4 builder-items-center  builder-bg-white`}>
                  <div className='builder-flex builder-flex-1 builder-items-center builder-text-sm builder-pr-2 builder-font-bold builder-text-primary'>
                    <i className='fal fa-edit builder-mr-4' /> Tablet
                  </div>
                </div>
                <div className=''>
                  <ImageUrlFieldEditor value={image['tablet']} type='image' className='builder-bg-white builder-px-0 builder-py-0' definition={definition} onChange={(url) => handleChangeField('tablet', url)} onBack={(index) => {}} />
                </div>
              </div>

              <div className='builder-flex builder-flex-col builder-mb-6'>
                <div className={`builder-draggable-item builder-flex builder-flex-row builder-border-b builder-border-gray-300 builder-p-4 builder-items-center  builder-bg-white`}>
                  <div className='builder-flex builder-flex-1 builder-items-center builder-text-sm builder-pr-2 builder-font-bold builder-text-primary'>
                    <i className='fal fa-edit builder-mr-4' /> Desktop
                  </div>
                </div>
                <div className=''>
                  <ImageUrlFieldEditor value={image['desktop']} type='image' className='builder-bg-white builder-px-0 builder-py-0' definition={definition} onChange={(url) => handleChangeField('desktop', url)} onBack={(index) => {}} />
                </div>
              </div>

              <div className='builder-flex builder-flex-col builder-mb-6'>
                <div className='builder-font-bold mb-2 builder-uppercase builder-text-primary'>Alt text:</div>
                <div className='builder-text-sm builder-font-medium  builder-mb-2'>
                  <TextInput placeholder='Image alt text' value={image.alt} onChanged={(value) => handleChangeField('alt', value)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};
export default ImageDetailV2;
