import { v4 } from 'uuid';
import { filter, find, isEqual, map, toLower } from 'lodash';
import { deserializePageSeo, generateKey } from '../utils';

export const filterTypes = {
  MAKE: 'make',
  MODEL: 'model',
  CATEGORY: 'category',
  FUEL: 'fuel',
  TRANSMISSION: 'gearbox'
};

export const initialPageSeoContent = {
  id: '',
  culture: '',
  data: ''
};

const initialFilters = {
  [filterTypes.MAKE]: null,
  [filterTypes.MODEL]: null,
  [filterTypes.CATEGORY]: null,
  [filterTypes.FUEL]: null,
  [filterTypes.TRANSMISSION]: null
};
const initialTranslationField = { de: '', en: '', fr: '', nl: '' };
export const initialPageSeo = {
  id: '',
  key: '/',
  filters: initialFilters,
  metaTitle: { ...initialTranslationField },
  metaDescription: { ...initialTranslationField },
  content: []
};

export const initialPageMeta = {
  id: '',
  language: '',
  title: '',
  description: ''
};

export const initialPage = {
  id: '',
  path: '',
  indexable: false,
  typeName: '',
  category: '',
  meta: []
};

export const pageSeoActionTypes = {
  ADD: 'ADD',
  EDIT: 'EDIT'
};

export const initialState = {
  page: initialPage,
  basePage: initialPage,
  pageHasChanges: false,
  pageFetching: false,
  pageError: undefined,
  pageSaving: false,

  pageSeos: [],
  pageSeosFetching: false,
  pageSeosFetchingError: undefined,

  pageSeo: initialPageSeo,
  pageSeoActionType: undefined,
  pageSeoHasChanges: false,
  basePageSeo: initialPageSeo,
  isDuplicatedPageSeoKey: false,
  pageSeoDeleting: false,
  pageSeoDeletingError: undefined,
  pageSeoSaving: false,
  pageSeoSavingError: undefined
};

// ACTION TYPES //
export const actionTypes = {
  PAGE_SET: 'PAGE_SET',
  PAGE_SET_FETCHING: 'PAGE_SET_FETCHING',
  PAGE_RESET_FETCHING: 'PAGE_RESET_FETCHING',
  PAGE_SET_SAVING: 'PAGE_SET_SAVING',
  PAGE_RESET_SAVING: 'PAGE_RESET_SAVING',
  PAGE_SAVED: 'PAGE_SAVED',
  PAGE_UPDATE_META: 'PAGE_UPDATE_META',
  PAGE_UPDATE_INDEXABLE: 'PAGE_UPDATE_INDEXABLE',

  PAGE_SEOS_SET: 'PAGE_SEOS_SET',
  PAGE_SET_SEOS_FETCHING: 'PAGE_SET_SEOS_FETCHING',
  PAGE_RESET_SEOS_FETCHING: 'PAGE_RESET_SEOS_FETCHING',

  PAGE_SEO_INITIALIZE: 'PAGE_SEO_INITIALIZE',
  PAGE_SET_SEO: 'PAGE_SET_SEO',
  PAGE_SEO_UPDATE_CONTENT: 'PAGE_SEO_UPDATE_CONTENT',
  PAGE_SEO_CLOSE_EDITOR: 'PAGE_SEO_CLOSE_EDITOR',
  PAGE_SEO_UPDATE_FILTER: 'PAGE_SEO_UPDATE_FILTER',
  PAGE_SEO_UPDATE: 'PAGE_SEO_UPDATE',

  PAGE_SEO_DELETE: 'PAGE_SEO_DELETE',
  PAGE_SET_SEO_DELETING: 'PAGE_SET_SEO_DELETING',
  PAGE_RESET_SEO_DELETING: 'PAGE_RESET_SEO_DELETING',

  PAGE_SEO_SAVE: 'PAGE_SEO_SAVE',
  PAGE_SET_SEO_SAVING: 'PAGE_SET_SEO_SAVING',
  PAGE_RESET_SEO_SAVING: 'PAGE_RESET_SEO_SAVING'
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.PAGE_SET_FETCHING:
      return {
        ...state,
        pageFetching: true
      };
    case actionTypes.PAGE_RESET_FETCHING:
      return {
        ...state,
        pageFetching: false
      };
    case actionTypes.PAGE_SET:
      return {
        ...state,
        page: action.payload.page,
        basePage: action.payload.page,
        pageFetching: false,
        pageError: undefined
      };
    case actionTypes.PAGE_SET_SAVING:
      return {
        ...state,
        pageSaving: true
      };
    case actionTypes.PAGE_RESET_SAVING:
      return {
        ...state,
        pageSaving: false
      };
    case actionTypes.PAGE_SAVED:
      // action.payload.savedPage
      return {
        ...state,
        pageSaving: false
      };
    case actionTypes.PAGE_UPDATE_META:
      const isExisting = find(state.page.meta, (meta) => toLower(action.payload.language) === toLower(meta.language));
      const newMeta = isExisting
        ? map(state.page.meta, (meta) => (toLower(action.payload.language) === toLower(meta.language) ? action.payload.updatedMeta : meta))
        : [
            ...state.page.meta,
            {
              ...initialPageMeta,
              ...action.payload.updatedMeta,
              language: toLower(action.payload.language),
              id: v4()
            }
          ];

      return {
        ...state,
        page: {
          ...state.page,
          meta: newMeta
        },
        pageHasChanges: !isEqual(state.basePage.meta, newMeta)
      };
    case actionTypes.PAGE_UPDATE_INDEXABLE:
      return {
        ...state,
        page: {
          ...state.page,
          indexable: action.payload.indexable
        },
        pageHasChanges: state.basePage.indexable !== action.payload.indexable
      };

    case actionTypes.PAGE_SET_SEOS_FETCHING:
      return {
        ...state,
        pageSeosFetching: true
      };
    case actionTypes.PAGE_RESET_SEOS_FETCHING:
      return {
        ...state,
        pageSeosFetching: false
      };
    case actionTypes.PAGE_SEOS_SET:
      return {
        ...state,
        pageSeos: map(action.payload.pageSeos, (pageSeo) => deserializePageSeo(pageSeo)),
        pageSeosFetching: false,
        pageSeosFetchingError: undefined
      };
    case actionTypes.PAGE_SET_SEO_DELETING:
      return {
        ...state,
        pageSeoDeleting: true
      };
    case actionTypes.PAGE_RESET_SEO_DELETING:
      return {
        ...state,
        pageSeoDeleting: false
      };
    case actionTypes.PAGE_SEO_DELETE:
      return {
        ...state,
        pageSeos: filter(state.pageSeos, (pageSeo) => pageSeo?.id !== action.payload.pageSeoId),
        pageSeo: state.pageSeo.id === action.payload.pageSeoId ? initialPageSeo : state.pageSeo,
        pageSeoDeleting: false,
        pageSeoDeletingError: undefined
      };
    case actionTypes.PAGE_SET_SEO:
      return {
        ...state,
        pageSeo: action.payload.pageSeo,
        basePageSeo: action.payload.pageSeo,
        pageSeoActionType: pageSeoActionTypes.EDIT
      };
    case actionTypes.PAGE_SEO_INITIALIZE:
      const initializedPageSeo = {
        ...initialPageSeo,
        id: v4(),
        content: map(action.payload.languages, (language) => ({
          ...initialPageSeoContent,
          culture: language,
          id: v4()
        }))
      };
      return {
        ...state,
        pageSeo: initializedPageSeo,
        basePageSeo: initializedPageSeo,
        pageSeoActionType: pageSeoActionTypes.ADD
      };
    case actionTypes.PAGE_SEO_UPDATE_CONTENT:
      const existingContent = find(state.pageSeo.content, (content) => toLower(action.payload.language) === toLower(content.culture));

      const newContent = existingContent
        ? map(state.pageSeo.content, (content) => (toLower(action.payload.language) === toLower(content.culture) ? { ...existingContent, data: action.payload.updatedData } : content))
        : [
            ...state.pageSeo.content,
            {
              ...initialPageSeoContent,
              data: action.payload.updatedData,
              culture: toLower(action.payload.language),
              id: v4()
            }
          ];

      return {
        ...state,
        pageSeo: {
          ...state.pageSeo,
          content: newContent
        },
        pageSeoHasChanges: !isEqual(state.basePageSeo.content, newContent)
      };

    case actionTypes.PAGE_SEO_CLOSE_EDITOR:
      return {
        ...state,
        pageSeo: initialPageSeo,
        pageSeoHasChanges: false
      };
    case actionTypes.PAGE_SEO_UPDATE:
      return {
        ...state,
        pageSeo: {
          ...state.pageSeo,
          ...action.payload.pageSeo
        },
        pageSeoHasChanges: !isEqual(state.basePageSeo, action.payload.pageSeo)
      };
    case actionTypes.PAGE_SEO_UPDATE_FILTER:
      const isExistingFilter = state.pageSeo.filters[action.payload.filter] === action.payload.value;
      const updatedFilters = {
        ...state.pageSeo.filters,
        [action.payload.filter]: isExistingFilter ? null : action.payload.value
      };

      return {
        ...state,
        pageSeo: {
          ...state.pageSeo,
          key: generateKey(updatedFilters),
          filters: {
            ...state.pageSeo.filters,
            [action.payload.filter]: isExistingFilter ? null : action.payload.value
          }
        },
        pageSeoHasChanges: !isEqual(state.basePageSeo.filters, updatedFilters),
        isDuplicatedPageSeoKey: !!find(state.pageSeos, (pageSeo) => pageSeo.key === generateKey(updatedFilters))
      };

    case actionTypes.PAGE_SET_SEO_SAVING:
      return {
        ...state,
        pageSeoSaving: true
      };
    case actionTypes.PAGE_RESET_SEO_SAVING:
      return {
        ...state,
        pageSeoSaving: false
      };
    case actionTypes.PAGE_SEO_SAVE:
      const existingPageSeo = find(state.pageSeos, (pageSeo) => pageSeo?.id === action.payload.pageSeo.id);

      const updatedPageSeos = existingPageSeo
        ? map(state.pageSeos, (pageSeo) => {
            if (pageSeo?.id === existingPageSeo?.id) {
              return { ...existingPageSeo, ...action.payload.pageSeo };
            }

            return pageSeo;
          })
        : [action.payload.pageSeo, ...state.pageSeos];

      return {
        ...state,
        pageSeos: updatedPageSeos,
        pageSeo: initialPageSeo,
        pageSeoSaving: false,
        pageSeoSavingError: undefined,
        pageSeoHasChanges: false,
        pageSeoActionType: undefined
      };
    default:
      return state;
  }
};
