import React from 'react';
import { includes, map, orderBy } from 'lodash';

const GenericColorList = ({ items, values, handleFilterChanged, gridClass }) => {
  const options = orderBy(
    map(items, (item) => {
      return {
        value: item.key,
        label: item.text,
        color: item.custom
      };
    }),
    ['label']
  );

  return (
    <React.Fragment>
      <div className={gridClass}>
        {map(options, (option) => {
          const isSelected = includes(values, option.value);
          return (
            <div key={`filter-${option.value}`} className='builder-flex builder-flex-col builder-text-gray-900 builder-mb-1'>
              <div className='builder-flex builder-flex-row builder-py-1 builder-cursor-pointer' onClick={() => handleFilterChanged(option)}>
                <div
                  className={`builder-flex builder-items-center builder-justify-center builder-mr-2 ${option.value === 'white' ? 'builder-border' : ''}`}
                  style={{
                    height: 16,
                    width: 16,
                    backgroundColor: option.color,
                    borderRadius: 4
                  }}
                >
                  {isSelected && <i className={`far fa-check builder-font-bold ${option.value === 'white' ? 'builder-text-primary' : 'builder-text-white'}`} style={{ fontSize: 10, marginTop: 2 }} />}
                </div>
                <div className={`builder-flex builder-flex-1 ${isSelected ? 'builder-font-semibold' : 'builder-font-normal'}`}>
                  <div className='builder-capitalize'>{option.label}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

GenericColorList.defaultProps = {
  gridClass: 'builder-grid builder-grid-cols-2 builder-gap-1'
};

export default GenericColorList;
