import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';

const Checkbox = ({ checked, onChange, label }) => {
  const [isChecked, setIsChecked] = useState(checked || false);
  const id = `checkbox-${v4()}`;

  useEffect(() => {
    if (checked !== undefined && checked !== null) {
      setIsChecked(checked);
    }
  }, [checked]);

  const handleChange = (e) => {
    setIsChecked(e.target.checked);
    onChange(e.target.checked);
  };

  return (
    <div className='builder-flex builder-items-center'>
      <input id={id} type='checkbox' checked={isChecked} onChange={handleChange} className='builder-w-4 builder-h-4 builder-text-primary builder-bg-gray-100 builder-border-gray-300 builder-rounded builder-cursor-pointer' />
      {label && (
        <label htmlFor={id} className='builder-ml-2 builder-text-sm builder-font-medium builder-text-gray-900'>
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
