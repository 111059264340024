import React, { useEffect, useState } from 'react';
import { monaco } from 'react-monaco-editor';

import { baseFileSelector } from '../../features/builder/baseFileSelector';

const FileUploaderBase = ({ url, handleFileUpload, handleSelectExistingFile, loading, error, editorRef }) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (url) {
      //Looks weird but is to insert it in multiple lines
      const imageElement = `<img
  src='${url}'
  alt='uploaded image'/>`;
      const currentPosition = editorRef.current.editor.getPosition();
      editorRef.current.editor.getModel().applyEdits([
        {
          range: monaco.Range.fromPositions(currentPosition),
          text: imageElement
        }
      ]);
    }
  }, [url]);

  return (
    <div className=' builder-border-gray-400  builder-border builder-bg-white '>
      <div className='builder-flex-row builder-flex builder-p-2'>
        <div
          className='builder-p-1 builder-text-primary builder-w-8 builder-justify-center builder-flex builder-cursor-pointer'
          onClick={() => {
            setOpen((prevValue) => !prevValue);
          }}
        >
          <i className='fas fa-image' />
          {open && (
            <div className='builder-absolute builder-border-gray-400 builder-mt-4 builder-ml-24 builder-bg-gray-100 builder-border-gray-200 builder-border builder-shadow-lg builder-p-1' style={{ zIndex: 9999 }} onMouseLeave={() => setOpen(false)}>
              <div className='builder-flex builder-flex-col'>
                <div
                  className='builder-p-1 builder-text-primary  builder-align-center builder-flex'
                  onClick={(event) => {
                    handleSelectExistingFile(event);
                  }}
                >
                  <i className='fas fa-image builder-w-4 ' />
                  <span className='builder-ml-2 builder-whitespace-nowrap'>Select existing</span>
                </div>
                <div
                  className='builder-p-1 builder-text-primary builder-align-center builder-flex'
                  onClick={(event) => {
                    handleFileUpload(event);
                  }}
                >
                  <i className='fal fa-plus builder-w-4' />
                  <span className='builder-ml-2 builder-whitespace-nowrap'>Add new</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default baseFileSelector(FileUploaderBase, 'image');
