import React, { useEffect, useRef, useState } from 'react';

const TextArea = ({ value, placeHolder, onChange, onChanged, className, rows, disabled, dataTestId }) => {
  const inputRef = useRef(null);
  const [text, setText] = useState(value);

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <textarea
      className={`builder-shadow builder-appearance-none builder-border builder-border-gray-300 builder-rounded builder-w-full builder-py-2 builder-px-3 builder-text-gray-700 builder-leading-tight focus:builder-outline-none ${className}`}
      ref={inputRef}
      data-testid={dataTestId}
      rows={rows}
      placeholder={placeHolder}
      value={text}
      disabled={disabled}
      onChange={(e) => {
        setText(e.target.value);
        if (onChange && typeof onChange === 'function') {
          onChange(e.target.value);
        }
      }}
      onBlur={() => {
        if (onChanged && typeof onChanged === 'function') {
          onChanged(text);
        }
      }}
      onFocus={() => {
        inputRef.current.focus();
        inputRef.current.select();
      }}
    />
  );
};

export default TextArea;
