import { actionTypes } from './reducer';

export const campaignsSetHandler = (dispatch, campaigns) => {
  dispatch({
    type: actionTypes.CAMPAIGNS_SET,
    payload: {
      campaigns
    }
  });
};

export const campaignDeleteHandler = (dispatch, id) => {
  dispatch({
    type: actionTypes.CAMPAIGN_DELETE,
    payload: {
      id
    }
  });
};

export const campaignsSetLoadingHandler = (dispatch) => {
  dispatch({
    type: actionTypes.CAMPAIGNS_SET_LOADING,
    payload: {}
  });
};

export const campaignsResetLoadingHandler = (dispatch) => {
  dispatch({
    type: actionTypes.CAMPAIGNS_RESET_LOADING,
    payload: {}
  });
};
