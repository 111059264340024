import { actionTypes } from './reducer';

export const categorySetLoadingHandler = () => ({
  type: actionTypes.CATEGORY_SET_LOADING
});

export const categoryResetLoadingHandler = () => ({
  type: actionTypes.CATEGORY_RESET_LOADING
});

export const categoryInitializeHandler = (id) => ({
  type: actionTypes.CATEGORY_INITIALIZE,
  payload: {
    id
  }
});

export const categorySetInitializedHandler = () => ({
  type: actionTypes.CATEGORY_SET_INITIALIZED
});

export const categorySetHandler = (category) => ({
  type: actionTypes.CATEGORY_SET,
  payload: {
    category
  }
});

export const categorySetTitleHandler = (title, language) => ({
  type: actionTypes.CATEGORY_SET_TITLE,
  payload: {
    title,
    language
  }
});

export const categorySetSlugHandler = (slug, language) => ({
  type: actionTypes.CATEGORY_SET_SLUG,
  payload: {
    slug,
    language
  }
});

export const categorySetImageHandler = (imagePathsPerDevice) => ({
  type: actionTypes.CATEGORY_SET_IMAGE,
  payload: {
    imagePathsPerDevice
  }
});

export const categoryUndoChangesHandler = () => ({
  type: actionTypes.CATEGORY_UNDO_CHANGES
});

export const categorySetSavingHandler = () => ({
  type: actionTypes.CATEGORY_SET_SAVING
});

export const categoryResetSavingHandler = () => ({
  type: actionTypes.CATEGORY_RESET_SAVING
});

export const categorySetSaveCompletedHandler = () => ({
  type: actionTypes.CATEGORY_SET_SAVE_COMPLETED
});
