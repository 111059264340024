import React from 'react';
import UseBuilderActions from '../../../../../builder/useBuilderActions';
import ButtonWithIndicator from '../../../../../../components/common/ButtonWithIndicator';

const ButtonReleaseRedirects = () => {
  const { executeScript, loading } = UseBuilderActions();
  return <ButtonWithIndicator loading={loading} onClick={() => executeScript('update_redirects', '')} text='Release redirects' />;
};

export default ButtonReleaseRedirects;
