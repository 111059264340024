import cogoToast from 'cogo-toast';
import { pageResetLoadingHandler, pageResetSavingHandler, pageSavedHandler, pageSetHandler, pageSetLoadingHandler, pageSetSavingHandler } from '../actions';
import { find, map, toLower } from 'lodash';

export const GeneralMetaQuery = `
  query ($data: PageSearchInputType!) {
    pages(data: $data) {
        id
        typeName
        meta {
          id
          description
          language
          title
        }
    }
}`;

export const SavePageMetaMutation = `
  mutation ($data: PageMetaUpdateBulkInputType!) {
    pageMetaUpdateBulk(data: $data){
      ok
    }
}`;

export const useGeneralMeta = (state, dispatch) => {
  const fetchPage = (websiteId) => {
    const variables = {
      data: {
        websiteId: websiteId
      }
    };

    dispatch(pageSetLoadingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: GeneralMetaQuery, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        const pages = result?.data?.pages;
        if (pages) {
          const homePage = find(pages, (page) => page.typeName === 'home');
          dispatch(pageSetHandler(homePage));
        } else {
          cogoToast.error('No pages returned from the server.');
        }
        dispatch(pageResetLoadingHandler());
      })
      .catch((err) => {
        cogoToast.error('Something went wrong.');
        dispatch(pageResetLoadingHandler());
      });
  };

  const savePage = (page, websiteId) => {
    const variables = {
      data: {
        pageMetas: map(page.meta, (meta) => ({
          id: meta.id,
          title: meta.title,
          description: meta.description,
          language: toLower(meta.language),
          pageId: page.id
        }))
      }
    };

    dispatch(pageSetSavingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: SavePageMetaMutation, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data?.pageMetaUpdateBulk?.ok) {
          dispatch(pageSavedHandler(page));
          cogoToast.success('Page meta saved.');
        } else {
          cogoToast.error('Page meta not saved.');
        }
        dispatch(pageResetSavingHandler());
      })
      .catch((err) => {
        cogoToast.error('Something went wrong.');
        dispatch(pageResetSavingHandler());
      });
  };

  return {
    fetching: state.fetching,
    error: state.fetchingError,
    page: state.page,
    fetchPage: (websiteId) => fetchPage(websiteId),
    savePage: (page, websiteId) => savePage(page, websiteId),
    saving: state.saving,
    savingError: state.savingError
  };
};
