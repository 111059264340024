import React from 'react';

const LoadingSkeleton = () => {
  return (
    <div data-testid='loading-skeleton-question-groups' className='builder-flex builder-flex-col builder-p-5 builder-w-full'>
      <div className='builder-flex builder-flex-row builder-items-center builder-justify-between builder-mb-5'>
        <div className='builder-flex builder-flex-row builder-items-center'>
          <div className='builder-h-8 builder-bg-gray-50 builder-animate-pulse builder-w-28 builder-mr-4' />
          <div className='builder-h-6 builder-bg-gray-50 builder-animate-pulse builder-w-80 ' />
        </div>
        <div className='builder-flex builder-items-center'>
          <div className='builder-h-8 builder-bg-gray-50 builder-animate-pulse builder-w-28 builder-mr-4' />
          <div className='builder-h-8 builder-bg-gray-50 builder-animate-pulse builder-w-28' />
        </div>
      </div>

      <div className='builder-animate-pulse'>
        <div className='builder-flex builder-items-center '>
          <div className='builder-w-8 builder-h-8 builder-bg-gray-50 builder-mr-1' />
          <div className='builder-w-8 builder-h-8 builder-bg-gray-50' />
        </div>
        <div className='builder-space-y-2 builder-p-4 builder-bg-gray-50'>
          <div>
            <div className='builder-w-32 builder-h-4 builder-bg-gray-200 builder-mb-1' />
            <div className='builder-w-96 builder-h-8 builder-bg-gray-200' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSkeleton;
