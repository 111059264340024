import React from 'react';
import InfoIndicator from '../../../../components/common/indicators/InfoIndicator';
import { map, orderBy } from 'lodash';
import cogoToast from 'cogo-toast';

const PageSeoOverview = ({ pageSeos, onDelete, onEdit, selectedPageSeo, deleting, isDuplicatedPageSeoKey, context }) => {
  if (pageSeos?.length < 1) {
    return (
      <div>
        <InfoIndicator message='You do not have any custom seo text for this page at the moment. ' />
      </div>
    );
  }

  const columnClassName = 'builder-px-4 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-whitespace-nowrap builder-text-black builder-uppercase builder-tracking-wider';
  const rowClassName = 'builder-flex builder-px-4 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black';

  const onEditSeoPageText = (pageSeo) => {
    onEdit(pageSeo);
  };

  const onDeleteSeoPageText = (pageSeoId) => {
    onDelete(pageSeoId, () => cogoToast.success('Page SEO deleted.'));
  };

  return (
    <div className='builder-divide-y builder-divide-gray-200'>
      <div className='builder-flex builder-items-center builder-bg-gray-200'>
        <div className={`${columnClassName} builder-cursor-pointer builder-space-x-2`} style={{ width: '70%' }}>
          <span>Filter</span>
        </div>

        <div className={columnClassName} style={{ width: '20%' }}>
          Actions
        </div>
      </div>

      <div className='builder-divide-y builder-divide-gray-200 builder-relative'>
        {deleting && <div data-testid='seo-overview-deleting-overlay' className='builder-bg-gray-200 builder-animate-pulse builder-absolute builder-inset-0 builder-z-10' />}
        {map(orderBy(pageSeos, 'key'), (pageSeo) => {
          const isSelected = pageSeo?.id === selectedPageSeo?.id;
          const isDuplicated = pageSeo?.key === selectedPageSeo?.key;
          return (
            <div key={pageSeo?.key} className={`builder-flex builder-w-full builder-items-center builder-transition-all builder-transform builder-relative ${isSelected || isDuplicated ? 'builder-bg-gray-100' : 'builder-bg-gray-50'}`}>
              <div className={`${rowClassName} builder-relative`} style={{ width: '70%', maxWidth: '70%' }}>
                <span className=''>{pageSeo?.key}</span>
              </div>

              <div className={rowClassName} style={{ width: '30%', maxWidth: '30%' }}>
                <div className='builder-flex builder-items-center'>
                  <div className='builder-cursor-pointer hover:builder-text-gray-800' onClick={() => onEditSeoPageText(pageSeo)}>
                    <span>Edit</span> <i className='far fa-pencil builder-ml-1' style={{ fontSize: 11 }} />
                  </div>
                  <div className='builder-mx-4'>|</div>
                  <div className='builder-cursor-pointer hover:builder-text-gray-800' onClick={() => onDeleteSeoPageText(pageSeo.id)}>
                    <span>Delete</span> <i className='far fa-trash builder-ml-1' style={{ fontSize: 11 }} />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PageSeoOverview;
