import { tryParse } from '../../../../utils';
import { convertToSlug } from '../../utils';
import { find, filter, map, maxBy } from 'lodash';
import { v4 } from 'uuid';
import { initialCategory } from '../../categories/detail/reducer';

export const actionTypes = {
  TOPIC_SET_LOADING: 'TOPIC_SET_LOADING',
  TOPIC_RESET_LOADING: 'TOPIC_RESET_LOADING',
  TOPIC_SET_INITIALIZED: 'TOPIC_SET_INITIALIZED',
  TOPIC_INITIALIZE: 'TOPIC_INITIALIZE',
  TOPIC_SET: 'TOPIC_SET',
  TOPIC_SET_TITLE: 'TOPIC_SET_TITLE',
  TOPIC_SET_SLUG: 'TOPIC_SET_SLUG',
  TOPIC_SET_CONTENT: 'TOPIC_SET_CONTENT',
  TOPIC_UPDATE_CATEGORY_IDS: 'TOPIC_UPDATE_CATEGORY_IDS',
  TOPIC_UNDO_CHANGES: 'TOPIC_UNDO_CHANGES',
  TOPIC_SET_SAVING: 'TOPIC_SET_SAVING',
  TOPIC_RESET_SAVING: 'TOPIC_RESET_SAVING',
  TOPIC_SET_SAVE_COMPLETED: 'TOPIC_SET_SAVE_COMPLETED',
  CATEGORIES_SET_FETCHING: 'CATEGORIES_SET_FETCHING',
  CATEGORIES_RESET_FETCHING: 'CATEGORIES_RESET_FETCHING',
  CATEGORIES_SET: 'CATEGORIES_SET',
  CATEGORIES_ADD: 'CATEGORIES_ADD'
};

export const topicInitialTitle = {
  nl: '',
  fr: '',
  en: ''
};

export const topicInitialSlug = {
  nl: '',
  fr: '',
  en: ''
};

export const initialContent = {
  id: '',
  culture: '',
  data: '<div>Add your HTML here</div>'
};

export const initialTopic = {
  id: '',
  title: topicInitialTitle,
  slug: topicInitialSlug,
  content: [],
  categoryIds: [],
  priority: 0,
  isDraft: false,
  disabled: false
};

export const initialNewCategory = {
  category: initialCategory,
  slugManuallyChanged: false,
  isSaving: false,
  formInitialized: false
};

export const initialState = {
  history: [],
  loading: false,
  saving: false,
  topic: initialTopic,
  categories: [],
  categoriesFetching: false,
  newCategory: initialNewCategory
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.TOPIC_SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case actionTypes.TOPIC_RESET_LOADING:
      return {
        ...state,
        loading: false
      };
    case actionTypes.TOPIC_SET_SAVING:
      return {
        ...state,
        saving: true
      };
    case actionTypes.TOPIC_RESET_SAVING:
      return {
        ...state,
        saving: false
      };
    case actionTypes.TOPIC_SET_SAVE_COMPLETED:
      return {
        ...state,
        saving: false
      };
    case actionTypes.TOPIC_INITIALIZE:
      return {
        ...state,
        history: undefined,
        topic: {
          ...initialTopic,
          id: action.payload.id
        }
      };
    case actionTypes.TOPIC_SET:
      const topic = {
        id: action.payload.topic.id,
        title: tryParse(action.payload.topic.title),
        slug: tryParse(action.payload.topic.slug),
        content: action.payload.topic.content,
        categoryIds: action.payload.topic.categoryIds,
        priority: action.payload.topic.priority,
        isDraft: action.payload.topic.isDraft,
        disabled: action.payload.topic.disabled
      };

      return {
        ...state,
        loading: false,
        topic: topic,
        history: [topic]
      };
    case actionTypes.TOPIC_SET_TITLE:
      const newTitle = {
        ...state.topic.title,
        [action.payload.language]: action.payload.title
      };

      const slugFromName = {
        ...state.topic.slug,
        [action.payload.language]: convertToSlug(action.payload.title)
      };

      return {
        ...state,
        history: state.history !== undefined ? [...state.history, { ...state.topic }] : undefined,
        topic: {
          ...state.topic,
          title: newTitle,
          slug: slugFromName
        }
      };

    case actionTypes.TOPIC_UPDATE_CATEGORY_IDS:
      const categoryIdExists = find(state.topic.categoryIds, (categoryId) => categoryId === action.payload.categoryId);
      const newCategoryIds = categoryIdExists ? filter(state.topic.categoryIds, (categoryId) => categoryId !== action.payload.categoryId) : [...state.topic.categoryIds, action.payload.categoryId];
      return {
        ...state,
        history: state.history !== undefined ? [...state.history, { ...state.topic }] : undefined,
        topic: {
          ...state.topic,
          categoryIds: newCategoryIds
        }
      };

    case actionTypes.TOPIC_SET_CONTENT:
      const currentContent = find(state.topic?.content, (content) => content.culture === action.payload.language);
      let newContent;
      if (currentContent) {
        const otherContentData = filter(state.topic.content, (content) => content.id !== currentContent.id);
        newContent = [...otherContentData, { ...currentContent, data: action.payload.data }];
      } else {
        newContent = [...state.topic.content, { ...initialContent, id: v4(), culture: action.payload.language, data: action.payload.data }];
      }

      return {
        ...state,
        history: state.history !== undefined ? [...state.history, { ...state.topic }] : undefined,
        topic: {
          ...state.topic,
          content: newContent
        }
      };
    case actionTypes.TOPIC_SET_SLUG:
      const newSlug = {
        ...state.topic.slug,
        [action.payload.language]: action.payload.slug
      };
      return {
        ...state,
        history: state.history !== undefined ? [...state.history, { ...state.topic }] : undefined,
        topic: {
          ...state.topic,
          slug: newSlug
        }
      };

    case actionTypes.TOPIC_UNDO_CHANGES:
      return {
        ...state,
        topic: {
          ...state.history[0]
        },
        history: [{ ...state.history[0] }]
      };
    case actionTypes.CATEGORIES_SET_FETCHING:
      return {
        ...state,
        categoriesFetching: true
      };
    case actionTypes.CATEGORIES_RESET_FETCHING:
      return {
        ...state,
        categoriesFetching: false
      };

    case actionTypes.CATEGORIES_SET:
      const mappedCategories = map(action.payload.categories, (category) => ({
        ...category,
        title: JSON.parse(category.title)
      }));
      return {
        ...state,
        categoriesFetching: false,
        categories: mappedCategories
      };
    case actionTypes.TOPIC_SET_INITIALIZED:
      return {
        ...state,
        formInitialized: true
      };

    case actionTypes.CATEGORIES_ADD:
      const categoryWithHighestPriority = maxBy(state.categories, 'priority');
      const highestPriority = categoryWithHighestPriority.priority;
      const category = {
        ...action.payload.category,
        priority: highestPriority + 1
      };

      return {
        ...state,
        categories: [...state.categories, category],
        topic: {
          ...state.topic,
          categoryIds: [...state.topic.categoryIds, category.id]
        }
      };

    default:
      return state;
  }
};
