import React from 'react';

const BadgeWithIcon = ({ label, text, icon, onClick, dataTestId }) => {
  return (
    <div data-testid={dataTestId} className='builder-flex builder-items-center'>
      {label && <span>{label}</span>}
      <div className='builder-flex builder-items-center builder-pl-2 builder-rounded-sm builder-bg-gray-100 builder-h-[26px]' onClick={onClick}>
        {text && <div className='builder-mr-2'>{text}</div>}

        {icon && (
          <div className='builder-flex builder-items-center builder-justify-center builder-px-2 builder-h-full builder-bg-gray-200 hover:builder-bg-gray-300 builder-transition-all builder-cursor-pointer'>
            <i className={icon} />
          </div>
        )}
      </div>
    </div>
  );
};

export default BadgeWithIcon;
