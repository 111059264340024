import React, { useState } from 'react';
import { includes, map, filter } from 'lodash';
import Topic from './Topic';
import { routes } from '../../../../data/constants';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import { topicSetDeleteHandler } from '../actions';

export const TopicDeleteMutation = `mutation FaqTopicDelete($data: FaqTopicDeleteInputType!) {
  faqTopicDelete(data: $data) {
      ok
  }
}`;

const Topics = ({ topics, context, currentLanguage, setCurrentLanguage, state, dispatch }) => {
  const [deletingTopicId, setDeletingTopicId] = useState(undefined);
  const history = useHistory();

  const onEdit = (topicId) => {
    history.push(`${routes.FAQ_TOPIC_DETAIL}/${topicId}`);
  };

  const onDelete = (topicId) => {
    setDeletingTopicId(topicId);
    const variables = {
      data: {
        id: topicId
      }
    };

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: TopicDeleteMutation, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data?.faqTopicDelete?.ok) {
          cogoToast.success('Topic deleted.');
          dispatch(topicSetDeleteHandler(topicId));
        } else {
          const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
          cogoToast.error(error);
        }
        setDeletingTopicId(undefined);
      })
      .catch((err) => {
        cogoToast.error('Something went wrong. The topic could not be deleted.');
        setDeletingTopicId(undefined);
      });
  };

  return (
    <div data-testid='topics-container' className='builder-flex builder-flex-col builder-justify-center builder-space-y-4 builder-text-md builder-text-black builder-max-w-7xl builder-min-w-min builder-pb-5'>
      {map(topics, (topic, index) => {
        return (
          <Topic
            key={topic.id}
            categories={filter(state.categories, (category) => includes(topic.categoryIds, category.id))}
            topic={topic}
            context={context}
            currentLanguage={currentLanguage}
            state={state}
            dispatch={dispatch}
            onEdit={onEdit}
            onDelete={onDelete}
            isDeleting={deletingTopicId === topic.id}
          />
        );
      })}
    </div>
  );
};

export default Topics;
