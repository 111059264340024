import React, { useEffect, useReducer, useState } from 'react';
import { initialState, reducer } from '../reducer';

import { v4 } from 'uuid';
import TextInput from '../../../../../builder/editors/components/TextInput';

import cogoToast from 'cogo-toast';
import { redirectInitializeHandler, redirectSetFromPathHandler, redirectSetHandler, redirectSetInitializedHandler, redirectSetToPathHandler, redirectSetTypeHandler } from '../actions';
import RedirectType from './RedirectType';
import TargetUrlField from './TargetUrlField';
import { find, head, isEmpty } from 'lodash';
import { isFromPathValid, isToPathValid } from '../utils';

export const RedirectUpdateMutation = `
  mutation RedirectUpdate($data: [RedirectUpdateInputType!]!) {
    redirectUpdate(data: $data) {
        ok
    }
  }
`;

export const RedirectsStatusCodesQuery = `
  query($data: RedirectSearchInputType!) {
    redirects(data: $data) {
      id
      fromUrlStatusCode
      toUrlStatusCode
    }
}`;

const AddEditRow = ({ redirect, redirects, onCancel, handleUpdate, pages, isLoadedFromFile, onErrorsChange, context }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [formErrors, setFormErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isFetchingStatus, setIsFetchingStatus] = useState(false);

  useEffect(() => {
    const isEdit = redirect !== undefined;

    if (isEdit) {
      dispatch(redirectSetHandler(redirect));
    } else {
      const newRedirectId = v4();
      dispatch(redirectInitializeHandler(newRedirectId));
    }
  }, []);

  useEffect(() => {
    onErrorsChange && typeof onErrorsChange === 'function' && onErrorsChange(isEmpty(formErrors));
  }, [formErrors]);

  useEffect(() => {
    if (state.formInitialized || isLoadedFromFile === true) {
      setFormErrors({});
      const { errors } = validateForm();
      setFormErrors(errors);
    }
  }, [state.redirect, state.formInitialized, isLoadedFromFile]);

  const hasChanges = isLoadedFromFile === true || JSON.stringify(state.redirect) !== JSON.stringify(state.initRedirect);

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (!isFromPathValid(state.redirect.fromPath)) {
      errors[`fromPath`] = 'From path needs to be formatted as: /from-some-url';
      isValid = false;
    }

    if (!isToPathValid(state.redirect.toPath)) {
      errors[`toPath`] = 'To path needs to be formatted as: /to-some-url/?param=value or /to-some-url/';
      isValid = false;
    }

    const sanitzeUrl = (url) => {
      let newUrl = url.replaceAll('//', '/');
      if (newUrl.includes('?')) {
        newUrl = newUrl.split('?')[0];
      }

      return newUrl;
    };

    const toUrls = redirects?.map((r) => sanitzeUrl(r.toPath));
    if (toUrls?.includes(sanitzeUrl(state.redirect.fromPath))) {
      errors[`fromPath`] = 'From path is already in the redirects as a toPath, this will create a loop of redirects';
      isValid = false;
    }

    return {
      isValid,
      errors
    };
  };

  const isValid = () => {
    setFormErrors({});
    const { isValid, errors } = validateForm();
    setFormErrors(errors);
    return isValid;
  };

  const fetchOnlineStatusCodes = () => {
    setIsFetchingStatus(true);
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: RedirectsStatusCodesQuery, variables: { data: { ids: [state.redirect.id] } } })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data?.redirects) {
          const newRedirect = head(result?.data?.redirects);
          const updatedRedirect = {
            ...state.redirect,
            fromUrlStatusCode: newRedirect.fromUrlStatusCode,
            toUrlStatusCode: newRedirect.toUrlStatusCode
          };

          dispatch(redirectSetHandler(updatedRedirect));

          handleUpdate(updatedRedirect);
          cogoToast.success('Redirect saved');
        } else {
          cogoToast.error('No online status code returned from the server.');
        }

        setIsSaving(false);
        setIsFetchingStatus(false);
      })
      .catch((err) => {
        cogoToast.error('Something went wrong.');
        setIsSaving(false);
        setIsFetchingStatus(false);
      });
  };

  const onSave = () => {
    dispatch(redirectSetInitializedHandler());
    if (isValid()) {
      const redirect = state.redirect;
      setIsSaving(true);
      fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: RedirectUpdateMutation,
          variables: {
            data: [
              {
                id: redirect.id,
                fromPath: redirect.fromPath,
                toPath: redirect.toPath,
                type: redirect.type
              }
            ]
          }
        })
      })
        .then((resp) => resp.json())
        .then((result) => {
          if (result?.data?.redirectUpdate?.ok && result?.data?.redirectUpdate?.ok?.length > 0 && find(result?.data?.redirectUpdate?.ok, (id) => id === redirect.id)) {
            fetchOnlineStatusCodes();
          } else {
            const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
            cogoToast.error(error);
            setIsSaving(false);
          }
        })
        .catch((err) => {
          cogoToast.error(err);
          setIsSaving(false);
        });
    }
  };

  const isLoading = isSaving || isFetchingStatus;

  if (!state.redirect.id) {
    return <div className='builder-bg-gray-100 builder-animate-pulse builder-w-full' />;
  }

  return (
    <div key={state.redirect.id} data-testid='redirect-add-edit-form' className='builder-flex builder-items-start builder-w-full builder-shadow builder-relative'>
      {isLoading && <div className='builder-absolute builder-inset-0 builder-bg-gray-100 builder-bg-opacity-25 builder-animate-pulse' />}

      <div style={{ width: '2%', maxWidth: '2%' }} />

      <div className='builder-flex builder-px-4 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black builder-h-full' style={{ width: '10%', maxWidth: '10%' }}>
        <RedirectType value={state.redirect.type} onChange={(newType) => dispatch(redirectSetTypeHandler(newType.code))} />
      </div>

      <div className='builder-flex builder-flex-col builder-space-y-1 builder-px-4 builder-py-4' style={{ width: '39%', maxWidth: '39%' }}>
        <div className='builder-text-md builder-text-black builder-w-full' data-testid='redirect-from-path-input-container'>
          <TextInput
            label='From path'
            placeHolder='Redirects from'
            dataTestId='redirect-from-path-input'
            errorText={formErrors['fromPath']}
            className='builder-flex builder-flex-1 builder-max-w-xl builder-rounded-md'
            value={state.redirect.fromPath}
            onChanged={(value) => dispatch(redirectSetFromPathHandler(value))}
          />
        </div>
        <div className='builder-text-gray-700 builder-text-[11px]'>Example: /from-some-url</div>
      </div>

      <div className='builder-flex builder-flex-col builder-px-4 builder-py-4' style={{ width: '39%', maxWidth: '39%' }}>
        <div className='builder-text-md builder-text-black builder-w-full' data-testid='redirect-to-path-input-container'>
          <TargetUrlField value={state.redirect.toPath} onChange={(newValue) => dispatch(redirectSetToPathHandler(newValue))} errorText={formErrors['toPath']} pages={pages} />
        </div>
        <div className='builder-text-gray-700 builder-text-[11px]'>Example: /to-some-url/?param=value</div>
      </div>

      <div className='builder-flex builder-items-center builder-px-4 builder-py-4 builder-whitespace-nowrap builder-text-md' style={{ width: '10%', maxWidth: '10%' }}>
        <div className='builder-flex builder-items-center'>
          <div
            data-testid='redirect-add-edit-form-cancel-button'
            className={`builder-flex builder-items-center builder-text-black builder-transition-all builder-underline  builder-border-r builder-border-gray-300 builder-mr-3 builder-pr-3 ${
              isLoading ? 'builder-opacity-50' : 'builder-cursor-pointer hover:builder-text-gray-800'
            }`}
            onClick={onCancel}
          >
            Cancel
          </div>

          <div
            data-testid='redirect-add-edit-form-save-button'
            className={`builder-flex builder-items-center builder-text-primary builder-transition-all ${hasChanges && !isLoading ? 'builder-group builder-cursor-pointer hover:builder-text-gray-800' : 'builder-opacity-50'}`}
            onClick={hasChanges ? () => onSave() : undefined}
          >
            <span className='builder-font-bold group-hover:builder-underline'>{isLoading ? 'Saving...' : 'Save'}</span>
            <i className='far fa-save builder-ml-1' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditRow;
