import React from 'react';
import { times } from 'lodash';

const LoadingSkeleton = () => {
  return (
    <div data-testid='seo-loading-skeleton' className='builder-flex builder-flex-col builder-relative builder-max-w-7xl builder-min-w-min'>
      <div className='builder-py-5 builder-flex builder-items-center'>
        <div className='builder-bg-gray-100 builder-h-10 builder-w-40' />
      </div>
      <div className='builder-w-full builder-space-y-2'>
        <div className='builder-bg-white builder-divide-y builder-divide-gray-200' />
        {times(15, (item) => {
          return <div key={item} className='builder-bg-gray-100 builder-transition-all builder-animate-pulse builder-h-[40px]' />;
        })}
      </div>
    </div>
  );
};

export default LoadingSkeleton;
