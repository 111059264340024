import React, { useContext, useState } from 'react';
import { StateContext } from '../../../../App';
import { filter, includes, map, orderBy } from 'lodash';
import Page from './Page';
import Search from '../../../../components/common/Search';
import NoResults from './NoResults';

const SORTER_FIELDS = {
  PATH: 'path',
  TYPE_NAME: 'typeName'
};

const initialSorter = {
  field: SORTER_FIELDS.PATH,
  value: 'asc'
};

const Pages = ({ pages, toggleCollapse, isCollapsed, context }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sorter, setSorter] = useState(initialSorter);
  const contextState = useContext(StateContext);
  const languages = contextState?.configuration?.website?.languages;
  const columnClassName = 'builder-px-4 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-whitespace-nowrap builder-text-black builder-uppercase builder-tracking-wider';

  const handleSort = (sortField) => {
    setSorter({
      field: sortField,
      value: sortField !== sorter.field || sorter.value === 'desc' ? 'asc' : 'desc'
    });
  };

  const resolveSortIcon = (sortField) => {
    if (sortField === sorter.field && sorter.value === 'asc') return <i className='fal fa-caret-down' style={{ fontSize: 11 }} />;
    if (sortField === sorter.field && sorter.value === 'desc') return <i className='fal fa-caret-up' style={{ fontSize: 11 }} />;

    return <i className='fal fa-minus' style={{ fontSize: 11 }} />;
  };

  pages = filter(pages, (page) => includes(page.path, searchTerm) || includes(page.typeName, searchTerm));
  pages = orderBy(pages, [sorter.field], [sorter.value]);

  return (
    <div data-testid='seo-pages-container' className='builder-flex builder-flex-col builder-justify-center builder-text-md builder-text-black builder-max-w-7xl builder-min-w-min builder-bg-white'>
      <div className='builder-flex builder-items-center builder-justify-between builder-p-4 builder-bg-gray-50 builder-text-black builder-text-[18px] builder-font-bold builder-cursor-pointer ' onClick={toggleCollapse}>
        Pages
        <i className={`fal ${isCollapsed ? 'fa-angle-down' : 'fa-angle-up'}`} style={{ fontSize: 24 }} />
      </div>
      {!isCollapsed && (
        <div data-testid='seo-pages-content'>
          <div className='builder-w-full builder-my-4'>
            <div className='builder-w-1/2 builder-mx-auto'>
              <Search onChange={(newSearchTerm) => setSearchTerm(newSearchTerm)} value={searchTerm} dataTestId='seo-pages-input-button' placeholder='Search page by path or type' />
            </div>
          </div>

          <div className='builder-min-w-full builder-divide-y builder-divide-gray-200'>
            <div className='builder-flex builder-items-center builder-bg-gray-50 builder-sticky builder-z-10' style={{ top: 75 }}>
              <div className={`${columnClassName} builder-cursor-pointer builder-space-x-2`} style={{ width: '40%' }} onClick={() => handleSort(SORTER_FIELDS.PATH)}>
                <span>Path</span>
                <span className='builder-text-gray-500'>{resolveSortIcon(SORTER_FIELDS.PATH)}</span>
              </div>

              <div className={`${columnClassName} builder-cursor-pointer builder-space-x-2`} style={{ width: '23%' }} onClick={() => handleSort(SORTER_FIELDS.TYPE_NAME)}>
                <span>Type</span>
                <span className='builder-text-gray-500'>{resolveSortIcon(SORTER_FIELDS.TYPE_NAME)}</span>
              </div>

              <div className={columnClassName} style={{ width: '7%' }}>
                Meta
              </div>

              <div className={columnClassName} style={{ width: '10%' }}>
                Indexable
              </div>

              <div className={columnClassName} style={{ width: '20%' }}>
                Actions
              </div>
            </div>

            {pages?.length < 1 && (
              <div data-testid='pages-no-results' className='builder-my-4 builder-w-full builder-flex builder-justify-center'>
                <div className='builder-w-1/2 builder-mt-4'>
                  <NoResults />
                </div>
              </div>
            )}

            {pages?.length > 0 && (
              <div className='builder-bg-white builder-divide-y builder-divide-gray-200'>
                {map(pages, (page) => {
                  return <Page key={page?.id} page={page} context={context} languages={languages} />;
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Pages;
