import React, { useEffect, useReducer } from 'react';
import { initialState, reducer } from '../reducer';
import { dataLoadingHandler, dataSetHandler, prioritiesCancelHandler, prioritiesResetSavingHandler, prioritiesSaveHandler, prioritiesSetSavingHandler } from '../actions';
import cogoToast from 'cogo-toast';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../data/constants';
import LoadingSkeleton from './LoadingSkeleton';
import NoResults from './NoResults';
import Categories from './Categories';
import Header from './Header';
import { map } from 'lodash';
import { v4 } from 'uuid';

export const DataQuery = `
  query {
    faqCategories {
        id
        title
        slug
        image
        priority
        isDraft
        disabled
    }

    faqQuestionGroups {
        id
        title
        categoryId
        priority
        isDraft
        disabled
    }
    faqQuestions {
        id
        title
        groupId
        priority
        isDraft
        disabled
    }
}`;

export const FaqUpdateMutation = `mutation FaqUpdate($data: FaqUpdateInputType!) {
  faqUpdate(data: $data) {
      ok
  }
}`;

const Overview = ({ context, currentLanguage, setCurrentLanguage }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  const fetchData = () => {
    dispatch(dataLoadingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: DataQuery })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data) {
          const data = result?.data;
          dispatch(dataSetHandler(data?.faqCategories, data?.faqQuestionGroups, data?.faqQuestions));
        } else {
          cogoToast.error('No data returned from the server.');
        }
      })
      .catch((err) => {
        cogoToast.error('Something went wrong.');
      });
  };

  const saveFaqPriorities = () => {
    dispatch(prioritiesSetSavingHandler());

    const variables = {
      data: {
        // id: questionGroup.id,
        categories: map(state.categories, (category) => ({ id: category.id, priority: category.priority })),
        questionGroups: map(state.questionGroups, (questionGroup) => ({ id: questionGroup.id, categoryId: questionGroup.categoryId, priority: questionGroup.priority })),
        questions: map(state.questions, (question) => ({ id: question.id, groupId: question.groupId, priority: question.priority }))
      }
    };

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: FaqUpdateMutation, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data?.faqUpdate?.ok) {
          cogoToast.success('Changes has been saved.');
          dispatch(prioritiesSaveHandler());
        } else {
          const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
          cogoToast.error(error);
          dispatch(prioritiesResetSavingHandler());
        }
      })
      .catch((err) => {
        cogoToast.error('Something went wrong.');
        dispatch(prioritiesResetSavingHandler());
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (state.dataLoading) {
    return <LoadingSkeleton />;
  }

  const onCreateCategory = () => {
    if (!state.hasPriorityChanges) {
      const id = v4();
      history.push(`${routes.FAQ_CATEGORY_DETAIL}/${id}`, {
        isCreate: true
      });
    }
  };

  const onCreateGroup = () => {
    if (!state.hasPriorityChanges) {
      const id = v4();
      history.push(`${routes.FAQ_QUESTION_GROUP_DETAIL}/${id}`, {
        isCreate: true
      });
    }
  };

  const onCreateQuestion = () => {
    if (!state.hasPriorityChanges) {
      const id = v4();
      history.push(`${routes.FAQ_QUESTION_DETAIL}/${id}`, {
        isCreate: true
      });
    }
  };

  const onSavePriorities = () => {
    if (state.hasPriorityChanges) {
      saveFaqPriorities();
    }
  };

  const onCancelPriorities = () => {
    if (state.hasPriorityChanges) {
      dispatch(prioritiesCancelHandler());
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div data-testid='overview-container' className='builder-flex builder-flex-col builder-px-5 builder-pb-5 builder-w-full builder-relative'>
        {state.prioritiesSaving && <div className='builder-absolute builder-inset-0 builder-bg-gray-300 builder-bg-opacity-40 builder-z-50' />}
        <Header
          onCreateCategory={onCreateCategory}
          onCreateGroup={onCreateGroup}
          onCreateQuestion={onCreateQuestion}
          createQuestionGroupButtonVisible={state.categories.length > 0}
          createQuestionButtonVisible={state.categories.length > 0 && state.questionGroups.length > 0}
          hasPriorityChanges={state.hasPriorityChanges}
          onSavePriorities={onSavePriorities}
          onCancelPriorities={onCancelPriorities}
        />

        <Categories categories={state.categories} currentLanguage={currentLanguage} state={state} dispatch={dispatch} />
        {state.categories.length < 1 && <NoResults />}
      </div>
    </DndProvider>
  );
};

export default Overview;
