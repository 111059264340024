import React, { useEffect, useReducer, useState } from 'react';
import { reducer, initialState } from '../reducer';
import cogoToast from 'cogo-toast';
import {
  dataResetLoadingHandler,
  dataSetHandler,
  dataSetLoadingHandler,
  redirectFromFileAddedHandler,
  redirectFromFileRemovedHandler,
  pagesSetHandler,
  redirectAddHandler,
  redirectDeletedHandler,
  redirectsFromFileSetHandler,
  redirectBulkSetSavingHandler,
  redirectBulkResetSavingHandler,
  redirectBulkSetSavedHandler,
  redirectSetToDeleteHandler,
  redirectToggleDeleteAllHandler,
  redirectBulkSetDeletingHandler,
  redirectBulkSetDeletedHandler,
  redirectBulkResetDeletingHandler,
  onlineStatusCodesSetFetchingHandler,
  onlineStatusCodesResetFetchingHandler,
  onlineStatusCodesSetHandler
} from '../actions';
import LoadingSkeleton from './LoadingSkeleton';
import ConfirmBox from '../../../../../../components/common/ConfirmBox';

import { filter, find, includes, map } from 'lodash';
import NoResults from './NoResults';
import AddEditRow from '../../detail/components/AddEditRow';
import Header from './Header';
import { isGoodFromPath, isGoodToPath, resolveRedirects, resolveSorter } from '../../../../utils';
import FileUploadOverview from './FileUploadOverview';
import Checkbox from '../../../../../../components/common/Checkbox';
import OnlineStatus from './OnlineStatus';
import { FILTER_TYPES } from '../../constants';

export const RedirectsDataQuery = `
  query {
    redirects {
      id
      type
      fromUrl
      fromPath
      toUrl
      toPath
    }
    pages {
      id
      url
      slug
    }
}`;

export const RedirectsStatusCodesQuery = `
  query {
    redirects {
      id
      fromUrlStatusCode
      toUrlStatusCode
    }
}`;

export const RedirectDeleteMutation = `
  mutation RedirectDelete($data: [RedirectDeleteInputType!]!) {
    redirectDelete(data: $data) {
        ok
    }
  }
`;

export const RedirectUpdateMutation = `
  mutation RedirectUpdate($data: [RedirectUpdateInputType!]!) {
    redirectUpdate(data: $data) {
        ok
        errors {
          message
          id
        }
    }
  }
`;

const sorters = {
  fromPath: { key: 'fromPath', value: 'desc', label: 'Redirect from path' },
  toPath: { key: 'toPath', value: 'desc', label: 'Redirect to path' },
  type: { key: 'type', value: 'desc', label: 'Redirect type' }
};

const Overview = ({ context }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [redirectToDelete, setRedirectToDelete] = useState(null);
  const [redirectDeletingId, setRedirectDeletingId] = useState(null);
  const [redirectToEdit, setRedirectToEdit] = useState(null);
  const [redirectToAdd, setRedirectToAdd] = useState(null);
  const [filters, setFilters] = useState([]);

  const fetchOnlineStatusCodes = (ids = []) => {
    dispatch(onlineStatusCodesSetFetchingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: RedirectsStatusCodesQuery, variables: { data: { ids: ids } } })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data) {
          const data = result?.data;
          dispatch(onlineStatusCodesSetHandler(data?.redirects));
        } else {
          cogoToast.error('No online status codes returned from the server.');
        }

        dispatch(onlineStatusCodesResetFetchingHandler());
      })
      .catch((err) => {
        cogoToast.error('Something went wrong.');
        dispatch(onlineStatusCodesResetFetchingHandler());
      });
  };

  const fetchData = () => {
    dispatch(dataSetLoadingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: RedirectsDataQuery })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data) {
          const data = result?.data;
          dispatch(dataSetHandler(data?.redirects));
          dispatch(pagesSetHandler(data?.pages));
          fetchOnlineStatusCodes();
        } else {
          cogoToast.error('No redirects returned from the server.');
        }

        dispatch(dataResetLoadingHandler());
      })
      .catch((err) => {
        cogoToast.error('Something went wrong.');
        dispatch(dataResetLoadingHandler());
      });
  };

  const handleBulkSave = () => {
    dispatch(redirectBulkSetSavingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: RedirectUpdateMutation,
        variables: {
          data: map(state.redirectsFromFile, (redirect) => ({
            id: redirect.id,
            fromPath: redirect.fromPath,
            toPath: redirect.toPath,
            type: redirect.type
          }))
        }
      })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data) {
          const data = result?.data;

          const updatedIds = data?.redirectUpdate?.ok;
          const failedIdsWithErrorText = data?.redirectUpdate?.errors;

          dispatch(redirectBulkSetSavedHandler(updatedIds, failedIdsWithErrorText));

          if (failedIdsWithErrorText && failedIdsWithErrorText.length < 1) {
            cogoToast.success('All redirects added successfully');
            fetchOnlineStatusCodes(updatedIds);
          } else {
            if (updatedIds && updatedIds.length > 0) {
              cogoToast.success(`${updatedIds.length} redirects added successfully`);
              fetchOnlineStatusCodes(updatedIds);
            }

            if (failedIdsWithErrorText && failedIdsWithErrorText.length > 0) {
              cogoToast.error(`${failedIdsWithErrorText.length} redirects NOT added successfully. Check the error below.`);
            }
          }
        } else {
          cogoToast.error('No data returned from the server.');
        }
        dispatch(redirectBulkResetSavingHandler());
      })
      .catch((err) => {
        cogoToast.error('Something went wrong.');
        dispatch(redirectBulkResetSavingHandler());
      });
  };

  const handleDelete = (redirect) => {
    const id = redirect.id;
    setRedirectDeletingId(id);
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: RedirectDeleteMutation,
        variables: {
          data: [{ id: id }]
        }
      })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data?.redirectDelete?.ok) {
          dispatch(redirectDeletedHandler(id));
          cogoToast.success('Redirect deleted');
        } else {
          const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
          cogoToast.error(error);
        }
        setRedirectDeletingId(null);
      })
      .catch((err) => {
        cogoToast.error(err);
        setRedirectDeletingId(null);
      });
  };

  const handleBulkDelete = () => {
    dispatch(redirectBulkSetDeletingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: RedirectDeleteMutation,
        variables: {
          data: map(state.redirectIdsToDelete, (id) => ({ id: id }))
        }
      })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data) {
          const data = result?.data;
          if (data?.redirectDelete.ok) {
            dispatch(redirectBulkSetDeletedHandler());
            cogoToast.success('Redirects deleted successfully.');
            onRemoveFilter(FILTER_TYPES.SEARCH);
          } else {
            cogoToast.error('Redirects not deleted successfully..');
          }
        }
        dispatch(redirectBulkResetDeletingHandler());
      })
      .catch((err) => {
        cogoToast.error('Something went wrong.');
        dispatch(redirectBulkResetDeletingHandler());
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onRemoveFilter = (type) => {
    setFilters(filter(filters, (filter) => filter.type !== type));
  };

  const resolveSortIcon = (key) => {
    const sortFilter = find(filters, (filter) => filter.type === FILTER_TYPES.SORT && filter.extra === key);

    if (!sortFilter) {
      return <i className='fal fa-minus builder-ml-2 builder-opacity-50 text-[10px]' />;
    }

    return <i className={`fas ${sortFilter.value === 'asc' ? 'fa-caret-down' : 'fa-caret-up'} builder-ml-2`} />;
  };

  const onAddSorter = (addedSorter) => {
    const currentSorter = find(filters, (filter) => filter.type === FILTER_TYPES.SORT);
    const newSorter = resolveSorter(currentSorter, addedSorter);

    const newSortFilter = {
      type: FILTER_TYPES.SORT,
      value: newSorter.value,
      label: newSorter.label,
      extra: newSorter.key
    };

    const existingFilter = find(filters, (filter) => filter.type === FILTER_TYPES.SORT);

    if (!existingFilter) {
      setFilters([...filters, newSortFilter]);
    } else {
      setFilters([...filter(filters, (filter) => filter.type !== FILTER_TYPES.SORT), newSortFilter]);
    }
  };

  const onHandleSearch = (value) => {
    setFilters([...filter(filters, (filter) => filter.type !== FILTER_TYPES.SEARCH), { type: FILTER_TYPES.SEARCH, value: value }]);
  };

  const onApplyStatusFilter = (status) => {
    const existingFilter = find(filters, (filter) => filter.type === FILTER_TYPES.STATUS);

    if (existingFilter) {
      const newValues = includes(existingFilter.value, status) ? filter(existingFilter.value, (v) => v !== status) : [...existingFilter.value, status];

      if (newValues.length < 1) {
        setFilters(filter(filters, (filter) => filter.type !== FILTER_TYPES.STATUS));
      } else {
        setFilters([...filter(filters, (filter) => filter.type !== FILTER_TYPES.STATUS), { type: FILTER_TYPES.STATUS, value: newValues }]);
      }
    } else {
      setFilters([...filters, { type: FILTER_TYPES.STATUS, value: [status] }]);
    }
  };

  const onEdit = (redirect) => {
    setRedirectToEdit(redirect);
  };

  const onDelete = (redirect) => {
    setRedirectToDelete(redirect);
  };

  const onAdd = () => {
    setRedirectToAdd({});
  };

  const onCancelAdd = () => {
    setRedirectToAdd(null);
  };

  if (state.loading) {
    return <LoadingSkeleton />;
  }

  const getRowClasses = (canBeChanged, isDeleting, editingActive) => {
    let classes = '';
    if (!canBeChanged) classes = 'builder-bg-gray-100';
    if (isDeleting) classes = 'builder-bg-gray-200 builder-transition-all builder-animate-pulse';
    if (editingActive) classes = 'builder-bg-opacity-25 builder-opacity-25';
    return `builder-flex ${classes}`;
  };

  const redirects = resolveRedirects(filters, state.redirects);

  const isEditOrAddActive = redirectToEdit || redirectToAdd;

  return (
    <div>
      {redirectToDelete !== null && (
        <ConfirmBox
          dataTestId='redirect-confirm-delete'
          title='Delete redirect'
          text={`Are you sure you want to delete redirect from '${redirectToDelete.fromPath}' to '${redirectToDelete.toPath}'?`}
          type='DANGER'
          onCancel={() => setRedirectToDelete(null)}
          onConfirm={() => {
            handleDelete(redirectToDelete);
            setRedirectToDelete(null);
          }}
        />
      )}

      {state.redirectsFromFile && state.redirectsFromFile.length > 0 && (
        <FileUploadOverview
          state={state}
          onRemove={(id) => dispatch(redirectFromFileRemovedHandler(id))}
          onAdd={(redirectFromFile) => dispatch(redirectFromFileAddedHandler(redirectFromFile))}
          onCancel={() => dispatch(redirectsFromFileSetHandler([]))}
          onBulkSave={() => handleBulkSave()}
          context={context}
        />
      )}

      <div className='builder-pb-4 builder-sticky builder-top-0 builder-bg-white builder-z-10 builder-border-b builder-border-gray-200 '>
        {isEditOrAddActive && <div className='builder-absolute builder-inset-0 builder-bg-white builder-z-10 builder-bg-opacity-75' />}
        <Header
          onAdd={onAdd}
          filters={filters}
          onRemoveFilter={onRemoveFilter}
          onSearch={onHandleSearch}
          redirects={state.redirects}
          onApplyStatusFilter={onApplyStatusFilter}
          buttonsDisabled={state.bulkDeleting || isEditOrAddActive}
          redirectIdsToDelete={state.redirectIdsToDelete}
          fetchingStatuses={state.fetchingOnlineStatusCodes}
          onBulkDelete={handleBulkDelete}
          onLoadedFromFile={(loadedRedirects) => dispatch(redirectsFromFileSetHandler(loadedRedirects))}
          context={context}
        />
      </div>

      <div className='builder-flex builder-flex-col builder-w-full'>
        <div className='builder-overflow-x-auto'>
          <div className='builder-align-middle builder-inline-block builder-min-w-full'>
            <div className='builder-shadow builder-overflow-hidden builder-border-b builder-border-gray-200'>
              <div className='builder-min-w-full builder-divide-y builder-divide-gray-200'>
                <div className={`builder-flex builder-items-center builder-bg-gray-50 ${isEditOrAddActive ? 'builder-bg-opacity-25 builder-opacity-25' : ''}`}>
                  <div className='builder-flex builder-items-center builder-justify-center' style={{ width: '2%' }}>
                    <Checkbox checked={state.redirects?.length > 0 && state.redirectIdsToDelete?.length > 0 && state.redirects?.length === state.redirectIdsToDelete?.length} onChange={(value) => dispatch(redirectToggleDeleteAllHandler(value))} />
                  </div>
                  <div
                    className='builder-px-4 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-whitespace-nowrap builder-text-black builder-uppercase builder-tracking-wider builder-cursor-pointer hover:builder-text-gray-800 builder-transition-all'
                    onClick={() => onAddSorter(sorters.type)}
                    style={{ width: '10%' }}
                  >
                    Type {resolveSortIcon(sorters?.type?.key)}
                  </div>
                  <div
                    className='builder-px-4 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-whitespace-nowrap builder-text-black builder-uppercase builder-tracking-wider builder-cursor-pointer hover:builder-text-gray-800 builder-transition-all'
                    onClick={() => onAddSorter(sorters.fromPath)}
                    style={{ width: '39%' }}
                  >
                    From path {resolveSortIcon(sorters?.fromPath?.key)}
                  </div>

                  <div
                    className='builder-px-4 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-whitespace-nowrap builder-text-black builder-uppercase builder-tracking-wider builder-cursor-pointer hover:builder-text-gray-800 builder-transition-all'
                    onClick={() => onAddSorter(sorters.toPath)}
                    style={{ width: '39%' }}
                  >
                    To path {resolveSortIcon(sorters?.toPath?.key)}
                  </div>

                  <div className='builder-px-4 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-whitespace-nowrap builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '10%' }}>
                    Actions
                  </div>
                </div>
                <div className='builder-bg-white builder-divide-y builder-divide-gray-200'>
                  {/*If add form is visible. Show if in the first row*/}
                  {redirectToAdd && (
                    <AddEditRow
                      onCancel={onCancelAdd}
                      handleUpdate={(redirect) => {
                        dispatch(redirectAddHandler(redirect));
                        setRedirectToAdd(null);
                      }}
                      redirects={redirects}
                      pages={state.pages}
                      context={context}
                    />
                  )}
                  {/*********************/}

                  {/*No results to show*/}
                  {redirects && redirects.length < 1 && (
                    <div className='builder-w-full builder-text-center builder-my-6 builder-max-w-7xl builder-mx-auto'>
                      <NoResults icon='far fa-info-circle' text='There are no redirects to show.' />
                    </div>
                  )}
                  {/*********************/}

                  {redirects &&
                    redirects.length > 0 &&
                    map(redirects, (redirect) => {
                      // Return edit form in a row
                      if (redirectToEdit && redirectToEdit?.id === redirect.id) {
                        return (
                          <AddEditRow
                            key={redirect.id}
                            redirect={redirect}
                            onCancel={() => setRedirectToEdit(null)}
                            handleUpdate={(redirect) => {
                              dispatch(redirectAddHandler(redirect));
                              setRedirectToEdit(null);
                            }}
                            pages={state.pages}
                            context={context}
                          />
                        );
                      }

                      // Return data
                      const canBeChanged = true;
                      const isDeleting = redirectDeletingId === redirect.id;
                      const rowClasses = getRowClasses(canBeChanged, isDeleting, isEditOrAddActive);
                      const selectedToDelete = includes(state.redirectIdsToDelete, redirect.id);

                      return (
                        <div key={redirect.id} className={rowClasses}>
                          <div className='builder-flex builder-items-center builder-justify-center' style={{ width: '2%' }}>
                            <Checkbox checked={selectedToDelete} onChange={() => dispatch(redirectSetToDeleteHandler(redirect.id))} />
                          </div>
                          <div className='builder-flex builder-items-center builder-px-4 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '10%', maxWidth: '10%' }}>
                            {redirect.type}
                          </div>
                          <div className='builder-flex builder-items-center builder-px-4 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black builder-font-semibold space-x-2' style={{ width: '39%', maxWidth: '39%' }}>
                            {/*<div className="builder-font-semibold">{generateUrlWithParams(redirect.fromPath, redirect.fromParams)}</div>*/}
                            <div className='builder-font-semibold truncate' title={redirect.fromPath}>
                              {redirect.fromPath}
                            </div>
                            <OnlineStatus isLoading={state.fetchingOnlineStatusCodes} url={redirect.fromUrl} statusCode={redirect.fromUrlStatusCode} isOnline={isGoodFromPath(redirect.type, redirect.fromUrlStatusCode)} />
                          </div>

                          <div className='builder-flex builder-items-center builder-px-4 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black builder-font-semibold space-x-2' style={{ width: '39%', maxWidth: '39%' }}>
                            {/*<div className="builder-font-semibold">{generateUrlWithParams(redirect.toPath, redirect.toParams)}</div>*/}
                            <div className='builder-font-semibold truncate' title={redirect.toPath}>
                              {redirect.toPath}
                            </div>
                            <OnlineStatus isLoading={state.fetchingOnlineStatusCodes} url={redirect.toUrl} statusCode={redirect.toUrlStatusCode} isOnline={isGoodToPath(redirect.toUrlStatusCode)} />
                          </div>

                          <div className='builder-flex builder-px-4 builder-py-4 builder-whitespace-nowrap builder-text-md' style={{ width: '10%', maxWidth: '10%' }}>
                            {canBeChanged ? (
                              <React.Fragment>
                                <div
                                  data-testid='redirect-edit-button'
                                  className={`builder-flex builder-items-center builder-border-r builder-border-gray-300 builder-mr-3 builder-pr-3 builder-text-black builder-transition-all ${
                                    canBeChanged ? 'builder-cursor-pointer hover:builder-text-gray-800' : ''
                                  }`}
                                  onClick={canBeChanged ? () => onEdit(redirect) : undefined}
                                >
                                  Edit <i className='fal fa-pencil-alt builder-ml-1' style={{ fontSize: 12 }} />
                                </div>
                                <div
                                  data-testid='redirect-delete-button'
                                  className={`builder-flex builder-items-center builder-text-black builder-transition-all ${canBeChanged ? 'builder-cursor-pointer hover:builder-text-gray-800' : ''}`}
                                  onClick={canBeChanged ? () => onDelete(redirect) : undefined}
                                >
                                  {isDeleting ? 'Deleting...' : 'Delete'}
                                  <i className='fal fa-trash-alt builder-ml-1' style={{ fontSize: 12 }} />
                                </div>
                              </React.Fragment>
                            ) : (
                              <i className='fad fa-minus' />
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
