import { useState } from 'react';

export const useGraphQLRequest = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const makeGraphQLRequest = async (query, variables) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query, variables })
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
      }

      const result = await response.json();
      setLoading(false);

      if (result.errors) {
        setError(result.errors[0].message);
        return null;
      }

      return result.data;
    } catch (error) {
      setLoading(false);
      setError(error.message);
      return null;
    }
  };

  return { makeGraphQLRequest, loading, error };
};
