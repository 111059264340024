import React, { useContext, useState, useEffect, useReducer } from 'react';
import { find, map } from 'lodash';
import { useHistory } from 'react-router-dom';
import ConfirmBox from '../../../../components/common/ConfirmBox';
import { StateContext } from '../../../../App';
import { routes } from '../../../data/constants';
import { v4 } from 'uuid';
import { advertisementDeleteHandler, advertisementsSetHandler } from '../actions';
import { reducer, initialState } from '../reducer';
import cogoToast from 'cogo-toast';
import LoadingSkeleton from './LoadingSkeleton';

export const AdsQuery = `query Advertisements{    
  advertisements {
    id
    description
    content{
      culture
    }
    isDraft
    disabled
  }   
}`;

export const AdDeleteMutation = `mutation AdvertisementDelete($data: AdvertisementDeleteInputType!) {
  advertisementDelete(data: $data) {
    ok
  }
}`;

const Overview = ({ context, currentLanguage, setCurrentLanguage, onChangeEnvironment }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const contextState = useContext(StateContext);
  const history = useHistory();

  const [confirmDelete, setConfirmDelete] = useState('');
  const currentWebsite = find(contextState?.websites, (w) => w.id === contextState.currentWebsite);
  const isCurrentSiteProduction = currentWebsite ? currentWebsite.isProduction : false;

  const advertisements = state.advertisements;
  const languages = contextState?.configuration?.website?.languages;

  const fetchAds = () => {
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: AdsQuery })
    })
      .then((resp) => resp.json())
      .then((result) => {
        advertisementsSetHandler(dispatch, result.data.advertisements);
      })
      .catch((err) => {});
  };

  const handleDelete = () => {
    const id = confirmDelete;

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: AdDeleteMutation, variables: { data: { id: id } } })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data?.advertisementDelete?.ok) {
          advertisementDeleteHandler(dispatch, id);
          cogoToast.success('Advertisement deleted');
        } else {
          const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
          cogoToast.error(error);
        }
        setConfirmDelete('');
      })
      .catch((err) => {});
  };

  const onEditAdvertisement = (id, lng) => {
    history.push(`${routes.ADVERTISEMENT_DETAIL}/${id}`, {
      language: lng
    });
  };

  const onCreateAdvertisement = () => {
    const id = v4();

    history.push(`${routes.ADVERTISEMENT_DETAIL}/${id}`, {
      language: 'nl'
    });
  };

  useEffect(() => {
    fetchAds();
  }, []);

  if (state.advertisements === undefined || state.advertisementsLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <div className='builder-flex builder-flex-col builder-p-5 builder-w-full'>
      {confirmDelete !== '' && <ConfirmBox title='Delete advertisement' text='Are you sure you want to delete this advertisement?' type='DANGER' onCancel={() => setConfirmDelete('')} onConfirm={handleDelete} />}

      <div className='builder-flex builder-mb-5'>
        <div className='builder-text-2xl builder-text-black builder-font-bold builder-mr-4'>Advertisements</div>
        <div onClick={onCreateAdvertisement} className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-cursor-pointer opacity-100'>
          <i className='fal fa-plus builder-mr-2' />
          Add new
        </div>
      </div>

      <div className='builder-flex builder-flex-col builder-w-full'>
        <div className='builder-overflow-x-auto'>
          <div className='builder-py-2 builder-align-middle builder-inline-block builder-min-w-full'>
            <div className='builder-shadow builder-overflow-hidden builder-border-b builder-border-gray-200'>
              <table className='builder-min-w-full builder-divide-y builder-divide-gray-200'>
                <thead className='builder-bg-gray-50'>
                  <tr>
                    <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '50%' }}>
                      Description
                    </th>
                    {map(languages, (language) => {
                      return (
                        <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '10%' }}>
                          {language}
                        </th>
                      );
                    })}
                    <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '30%' }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className='builder-bg-white builder-divide-y builder-divide-gray-200'>
                  {map(advertisements, (advertisement, i) => {
                    const canEdit = advertisement.isDraft && !isCurrentSiteProduction ? false : true; // Only edit drafts in draft environment, (production posts not editable in draft)
                    const description = advertisement.description && advertisement.description !== '' ? advertisement.description : '<no description>';

                    return (
                      <tr key='reference'>
                        <td className='builder-px-6' style={{ width: '50%' }}>
                          {description}
                        </td>
                        {map(languages, (language) => {
                          const hasAdvertisementForLanguage = find(advertisement.content, (content) => content.culture === language) ? true : false;
                          return (
                            <td className='builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '10%' }}>
                              {hasAdvertisementForLanguage ? <i className='fal fa-check builder-ml-1' style={{ fontSize: 12 }} /> : <i className='fal fa-times builder-ml-1' style={{ fontSize: 12 }} />}
                            </td>
                          );
                        })}

                        <td className='builder-flex builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '30%' }}>
                          {canEdit && (
                            <React.Fragment>
                              {map(languages, (language) => {
                                return (
                                  <div
                                    key={language}
                                    className='builder-flex builder-items-center builder-cursor-pointer builder-border-r builder-border-gray-300 builder-mr-3 builder-pr-3'
                                    onClick={() => onEditAdvertisement(advertisement.id, language)}
                                  >
                                    <span className='builder-uppercase'>{language}</span> <i className='fal fa-pencil-alt builder-ml-1' style={{ fontSize: 12 }} />
                                  </div>
                                );
                              })}
                              <div className='builder-flex builder-items-center builder-cursor-pointer' onClick={() => setConfirmDelete(advertisement.id)}>
                                Delete <i className='fal fa-trash-alt builder-ml-1' style={{ fontSize: 12 }} />
                              </div>
                            </React.Fragment>
                          )}
                          {!canEdit && (
                            <React.Fragment>
                              <span className='builder-font-bold'>No actions allowed in draft environment</span>
                            </React.Fragment>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
