import { endsWith, startsWith } from 'lodash';

export const MATCH_PATH_FIELDS = [':make', ':model', ':reference', ':category', ':topic'];

export const formatMatchPath = (matchPath) => {
  if (matchPath === '') return '';

  const matchPathStartsWithSlash = startsWith(matchPath, '/');
  const matchPathEndsWithSlash = endsWith(matchPath, '/');

  let newMatchPath = matchPath;
  if (!matchPathStartsWithSlash) newMatchPath = `/${newMatchPath}`;
  if (!matchPathEndsWithSlash) newMatchPath = `${newMatchPath}/`;

  return newMatchPath;
};
