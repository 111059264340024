export const LOADED_REDIRECT_KEYS_VARIATIONS = {
  fromPath: ['From Path', 'from_path', 'from path', 'fromPath', 'From path', 'From PATH'],
  toPath: ['To Path', 'to_path', 'to path', 'toPath', 'To path', 'To PATH'],
  type: ['Type', 'type']
};

export const STATUSES = [200, 301, 302, 404];

export const FILTER_TYPES = {
  SEARCH: 'SEARCH',
  SORT: 'SORT',
  STATUS: 'STATUS',
  BAD_REDIRECTS: 'BAD_REDIRECTS',
  GOOD_REDIRECTS: 'GOOD_REDIRECTS'
};
