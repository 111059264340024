import React, { useContext } from 'react';
import Header from './Header';
import { StateContext } from '../../../../App';
import { find } from 'lodash';
import LoadingSkeleton from './LoadingSkeleton';
import { default as GeneralMetaLoadingSkeleton } from '../../general-meta/components/LoadingSkeleton';
import GeneralMetaContainer from '../../general-meta/components/GeneralMetaContainer';
import PagesContainer from './PagesContainer';

const Overview = ({ context, currentLanguage, setCurrentLanguage }) => {
  const contextState = useContext(StateContext);

  const currentWebsite = find(contextState?.websites, (w) => w.id === contextState.currentWebsite);

  if (!currentWebsite) {
    return (
      <div data-testid='seo-overview-container-loading-skeleton' className='builder-p-5'>
        <GeneralMetaLoadingSkeleton />
        <LoadingSkeleton />
      </div>
    );
  }

  return (
    <div data-testid='seo-overview-container' className='builder-flex builder-flex-col builder-px-5 builder-pb-5 builder-w-full builder-relative' style={{ height: 'fit-content' }}>
      <Header context={context} />
      <GeneralMetaContainer context={context} />
      <PagesContainer context={context} />
    </div>
  );
};

export default Overview;
