import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../../data/constants';
import ButtonWithIndicator from '../../../../../components/common/ButtonWithIndicator';

const Header = ({ isLoading, history, onCancel, onUndo, onSave, onSaveAndAdd }) => {
  return (
    <div className='builder-flex builder-flex-row builder-items-center builder-justify-between'>
      <div className='builder-flex builder-flex-row builder-items-center'>
        <Link to={`${routes.FAQ_OVERVIEW}`} className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-cursor-pointer opacity-100 builder-mr-4'>
          <i className='fa fa-chevron-left builder-mr-2' />
          Overview
        </Link>
        <div className='builder-text-2xl builder-text-black builder-font-bold builder-mr-4'>Category</div>
      </div>
      <div className='flex'>
        {history === undefined && (
          <ButtonWithIndicator
            loading={isLoading}
            disabled={isLoading}
            onClick={onCancel}
            icon='fal fa-times'
            text='Cancel'
            colorClass='builder-bg-primary builder-text-white'
            borderClass='builder-border builder-border-bg-blue-500'
            className='builder-ml-0 builder-mr-0'
          />
        )}
        {history && (
          <ButtonWithIndicator
            loading={isLoading}
            disabled={history.length === 1 || isLoading}
            onClick={onUndo}
            icon='fal fa-undo'
            text='Undo changes'
            colorClass='builder-bg-primary builder-text-white'
            borderClass='builder-border builder-border-bg-blue-500'
            className='builder-ml-0 builder-mr-0'
          />
        )}

        <ButtonWithIndicator
          loading={isLoading}
          disabled={isLoading}
          onClick={onSave}
          icon='fal fa-save'
          text='Save'
          colorClass='builder-bg-primary builder-text-white'
          borderClass='builder-border builder-border-bg-blue-500'
          className='builder-ml-0 builder-mr-0'
        />
        <ButtonWithIndicator
          loading={isLoading}
          disabled={isLoading}
          onClick={onSaveAndAdd}
          icon='far fa-layer-plus'
          text='Save and add another'
          colorClass='builder-bg-primary builder-text-white'
          borderClass='builder-border builder-border-bg-blue-500'
          className='builder-ml-0 builder-mr-0'
        />
      </div>
    </div>
  );
};

export default Header;
