import { pageSetMetaHandler, toggleCollapseHandler } from '../actions';

export const useGeneralMetaState = (state, dispatch) => {
  const onMetaUpdate = (language, updatedMeta) => {
    dispatch(pageSetMetaHandler(language, updatedMeta));
  };

  const toggleCollapse = () => {
    dispatch(toggleCollapseHandler());
  };

  return {
    onMetaUpdate: (language, updatedMeta) => onMetaUpdate(language, updatedMeta),
    toggleCollapse: () => toggleCollapse()
  };
};
