import React, { useContext, useEffect, useState } from 'react';
import Header from './Header';
import HeaderMenu from '../features/builder/components/HeaderMenu';
import { Menu } from '../features/builder/components/Menu';
import { StateContext } from '../App';
import useLockedState from '../features/session/useLockedState';
import { useTrackingUsers } from '../features/session/useTrackingUsers';
import orderBy from 'lodash/orderBy';

const Template = ({ children, onChangeEnvironment, context }) => {
  const { isReleasing, userMoved } = useTrackingUsers();

  const state = useContext(StateContext);
  const currentState = state.history[state.history.length - 1];

  const { lockedState } = useLockedState();

  useEffect(() => {
    userMoved();
  }, [window.location.pathname]);

  return (
    <div className='builder-flex builder-flex-1 builder-flex-col builder-h-screen builder-font-body builder-text-sm'>
      {/*HEADER*/}
      <Header renderMenu={() => <HeaderMenu isReleasing={isReleasing} onChangeEnvironment={(websiteId) => onChangeEnvironment(websiteId)} />} onChangeEnvironment={(websiteId) => onChangeEnvironment(websiteId)} />

      <div className='builder-relative builder-flex builder-flex-1 ' style={{ minHeight: 'calc(100% - 48px)' }}>
        <div className=' builder-flex builder-flex-1 builder-h-full ' style={{ backgroundColor: '#dee2ec' }}>
          <Menu context={context} customBlocks={orderBy(currentState.customBlocks, 'description')} />
          <div className='builder-flex builder-w-full builder-flex-1 builder-overflow-x-hidden builder-overflow-y-scroll'>
            <div className='builder-h-fit builder-w-full builder-relative'>
              {lockedState && <div className=' builder-absolute builder-inset-0 builder-z-9999 builder-bg-white builder-opacity-50 builder-h-full'> </div>}
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template;
