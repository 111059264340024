import { GraphQLClient } from 'graphql-hooks';
import { resetSessionToDefault } from './features/data/session';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const resolveSocketUrl = (disabled = false) => {
  let protocol = 'wss://';
  let host = 'abcd';

  if (window.location.protocol !== 'https:') {
    protocol = 'ws://';
  }
  host = window.location.host;
  if (process.env.NODE_ENV !== 'production') {
    host = 'localhost:8000';
  }

  return protocol + host + '/subscriptions';
};

export const subscriptionClient = new SubscriptionClient(resolveSocketUrl(), {
  reconnect: true,
  inactivityTimeout: 0,
  lazy: true
});

export const client = new GraphQLClient({
  url: '/graphql',
  credentials: 'same-origin',
  fetchOptions: {
    keepalive: true
  },
  headers: { 'Accept-Language': 'en', Environment: 0 },
  onError: ({ operation, result }) => {
    if (result.error && result.error.graphQLErrors) {
      const error = result.error.graphQLErrors[0];
      if (error.code === '403') {
        resetSessionToDefault();
        window.location.reload();
      }
    }
  },
  subscriptionClient: () => subscriptionClient
});
