import { tryParse } from '../../../../utils';
import { convertToSlug } from '../../utils';

export const actionTypes = {
  CATEGORY_SET_LOADING: 'CATEGORY_SET_LOADING',
  CATEGORY_RESET_LOADING: 'CATEGORY_RESET_LOADING',
  CATEGORY_INITIALIZE: 'CATEGORY_INITIALIZE',
  CATEGORY_SET: 'CATEGORY_SET',
  CATEGORY_SET_TITLE: 'CATEGORY_SET_TITLE',
  CATEGORY_SET_SLUG: 'CATEGORY_SET_SLUG',
  CATEGORY_SET_IMAGE: 'CATEGORY_SET_IMAGE',
  CATEGORY_UNDO_CHANGES: 'CATEGORY_UNDO_CHANGES',
  CATEGORY_SET_SAVING: 'CATEGORY_SET_SAVING',
  CATEGORY_RESET_SAVING: 'CATEGORY_RESET_SAVING',
  CATEGORY_SET_SAVE_COMPLETED: 'CATEGORY_SET_SAVE_COMPLETED',
  CATEGORY_SET_INITIALIZED: 'CATEGORY_SET_INITIALIZED'
};

export const categoryInitialTitle = {
  nl: '',
  fr: '',
  en: ''
};

export const categoryInitialSlug = {
  nl: '',
  fr: '',
  en: ''
};

export const categoryInitialImage = {
  mobile: '',
  tablet: '',
  desktop: '',
  alt: ''
};

export const initialCategory = {
  id: '',
  title: categoryInitialTitle,
  slug: categoryInitialSlug,
  image: categoryInitialImage,
  priority: 0,
  isDraft: false,
  disabled: false
};

export const initialState = {
  history: [],
  loading: false,
  saving: false,
  category: initialCategory,
  slugManuallyChanged: false,
  formInitialized: false
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.CATEGORY_SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case actionTypes.CATEGORY_RESET_LOADING:
      return {
        ...state,
        loading: false
      };
    case actionTypes.CATEGORY_SET_SAVING:
      return {
        ...state,
        saving: true
      };
    case actionTypes.CATEGORY_RESET_SAVING:
      return {
        ...state,
        saving: false
      };
    case actionTypes.CATEGORY_SET_SAVE_COMPLETED:
      return {
        ...state,
        saving: false
      };
    case actionTypes.CATEGORY_SET_INITIALIZED:
      return {
        ...state,
        formInitialized: true
      };
    case actionTypes.CATEGORY_INITIALIZE:
      return {
        ...state,
        history: undefined,
        category: {
          ...initialCategory,
          id: action.payload.id
        }
      };
    case actionTypes.CATEGORY_SET:
      const category = {
        id: action.payload.category.id,
        title: tryParse(action.payload.category.title),
        slug: tryParse(action.payload.category.slug),
        image: action.payload.category.image ? tryParse(action.payload.category.image) : categoryInitialImage,
        priority: action.payload.category.priority,
        isDraft: action.payload.category.isDraft,
        disabled: action.payload.category.disabled
      };

      return {
        ...state,
        loading: false,
        category: category,
        history: [category]
      };
    case actionTypes.CATEGORY_SET_TITLE:
      const newTitle = {
        ...state.category.title,
        [action.payload.language]: action.payload.title
      };

      let categorySlug = state.category.slug;
      if (!state.slugManuallyChanged) {
        categorySlug = {
          ...state.category.slug,
          [action.payload.language]: convertToSlug(action.payload.title)
        };
      }

      return {
        ...state,
        history: state.history !== undefined ? [...state.history, { ...state.category }] : undefined,
        category: {
          ...state.category,
          title: newTitle,
          slug: categorySlug
        }
      };
    case actionTypes.CATEGORY_SET_SLUG:
      const newSlug = {
        ...state.category.slug,
        [action.payload.language]: action.payload.slug
      };
      return {
        ...state,
        history: state.history !== undefined ? [...state.history, { ...state.category }] : undefined,
        category: {
          ...state.category,
          slug: newSlug
        },
        slugManuallyChanged: true
      };
    case actionTypes.CATEGORY_SET_IMAGE:
      return {
        ...state,
        history: state.history !== undefined ? [...state.history, { ...state.category }] : undefined,
        category: {
          ...state.category,
          image: {
            ...state.category.image,
            ...action.payload.imagePathsPerDevice
          }
        }
      };
    case actionTypes.CATEGORY_UNDO_CHANGES:
      return {
        ...state,
        category: {
          ...state.history[0]
        },
        history: [{ ...state.history[0] }]
      };

    default:
      return state;
  }
};
