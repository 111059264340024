import React from 'react';
import { find, map, toString } from 'lodash';

const TYPES = [
  { code: 301, description: 'Permanent redirect.' },
  { code: 302, description: 'Temporary redirect' }
];

const RedirectType = ({ value, onChange }) => {
  const currentType = find(TYPES, (type) => toString(type.code) === toString(value));

  return (
    <div>
      <div data-testid='redirect-types' className='builder-flex builder-items-center builder-space-x-2 builder-mb-1 builder-pb-1 builder-border-b builder-border-gray-50 builder-overflow-y-scroll scrollbar'>
        {map(TYPES, (type) => {
          const isSelected = type.code === currentType?.code;
          return (
            <div
              key={type.code}
              title={type.description}
              className={`builder-text-sm builder-text-black builder-transition-all builder-p-1 builder-rounded builder-bg-gray-100 ${isSelected ? 'builder-opacity-25' : 'builder-cursor-pointer hover:builder-bg-gray-300'}`}
              onClick={isSelected ? undefined : () => onChange(type)}
            >
              {type.code}
            </div>
          );
        })}
      </div>

      <div className='builder-flex builder-items-center'>
        <span className='builder-mr-2'>Current type:</span>
        {currentType && (
          <span className='font-bold builder-mr-2' data-testid='redirect-type'>
            {currentType.code}
          </span>
        )}
        {!currentType && <i className='fad fa-minus' />}
      </div>
      {currentType && <div className='builder-text-gray-700 builder-text-[11px]'>{currentType.description}</div>}
    </div>
  );
};

export default RedirectType;
