import React from 'react';
import { orderBy, range } from 'lodash';
import { coreTypes } from 'dealer-website-components';
import GenericRangeSelect from '../../../../../components/common/GenericRangeSelect';

const YearFilter = ({ filterValues, filterAdd, filterUpdate, hasFilter }) => {
  const { FilterTypes } = coreTypes;
  const yearsFrom = range(2010, new Date().getFullYear() + 1).map((item) => {
    return {
      value: item,
      label: item
    };
  });

  const yearsTo = range(2010, new Date().getFullYear() + 1).map((item) => {
    return {
      value: item,
      label: item
    };
  });

  const handleChange = (range) => {
    if (filterValues.length > 0) {
      filterUpdate(FilterTypes.year, range);
    } else {
      filterAdd(FilterTypes.year, range);
    }
  };

  return (
    <React.Fragment>
      <div className='builder-font-semibold builder-mb-2 builder-mt-5'>First registration</div>
      <GenericRangeSelect
        placeHolderFrom='From'
        placeHolderTo='To'
        optionsFrom={orderBy(yearsFrom, ['value'], ['desc'])}
        optionsTo={orderBy(yearsTo, ['value'], ['desc'])}
        value={filterValues && filterValues.length > 0 ? filterValues[0] : undefined}
        handleFilterChanged={handleChange}
      />
    </React.Fragment>
  );
};

export default YearFilter;
