import { filter, map } from 'lodash';
import { tryParse } from '../../../../utils';
import { orderByPriority } from '../../utils';

export const actionTypes = {
  DATA_LOADING: 'DATA_LOADING',
  DATA_SET: 'DATA_SET',
  TOPIC_DELETE: 'TOPIC_DELETE'
};

export const initialState = {
  categories: [],
  topics: [],
  dataLoading: false
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.DATA_LOADING:
      return {
        ...state,
        dataLoading: true
      };
    case actionTypes.DATA_SET:
      const mappedCategories = map(action.payload.categories, (category) => ({
        ...category,
        title: tryParse(category.title)
      }));

      const mappedTopics = map(action.payload.topics, (topic) => ({
        ...topic,
        title: tryParse(topic.title)
      }));

      return {
        ...state,
        dataLoading: false,
        topics: orderByPriority(mappedTopics),
        categories: mappedCategories
      };
    case actionTypes.TOPIC_DELETE:
      return {
        ...state,
        topics: orderByPriority(filter(state.topics, (topic) => topic.id !== action.payload.topicId))
      };
    default:
      return state;
  }
};
