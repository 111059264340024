import React from 'react';
import { coreTypes } from 'dealer-website-components';

const SortByFilter = ({ filterValues, filterAdd, filterUpdate }) => {
  const value = filterValues && filterValues.length > 0 ? filterValues[0] : '';
  const { FilterTypes } = coreTypes;
  const handleChange = (value) => {
    if (filterValues.length === 0) {
      filterAdd(FilterTypes.sortBy, value);
    } else {
      filterUpdate(FilterTypes.sortBy, value);
    }
  };

  // Text field, to be replace by combo with all possible options
  return (
    <React.Fragment>
      <div className='builder-font-semibold builder-mb-2 builder-mt-5'>Order by</div>
      <div className='flex w-full mb-4 relative bg-white items-center rounded border border-primary font-secondary text-primary rounded-l-10 rounded-r-10'>
        <input
          type='text'
          className='flex-1 font-secondary text-primary outline-none rounded-l-10'
          style={{ padding: '15px 18px 15px 15px' }}
          placeholder='order field. ex:  make or -make'
          value={value}
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
    </React.Fragment>
  );
};

export default SortByFilter;
