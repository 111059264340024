import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { routes } from '../../../../data/constants';
import { find, forEach, head, isEmpty } from 'lodash';
import { StateContext } from '../../../../../App';
import { initialState, reducer } from '../reducer';
import {
  categoriesAddHandler,
  categoriesResetFetchingHandler,
  categoriesSetFetchingHandler,
  categoriesSetHandler,
  questionGroupInitializeHandler,
  questionGroupResetLoadingHandler,
  questionGroupResetSavingHandler,
  questionGroupSetCategoryHandler,
  questionGroupSetHandler,
  questionGroupSetInitializedHandler,
  questionGroupSetLoadingHandler,
  questionGroupSetSaveCompletedHandler,
  questionGroupSetSavingHandler,
  questionGroupUndoChangesHandler
} from '../actions';
import LoadingSkeleton from './LoadingSkeleton';
import cogoToast from 'cogo-toast';
import CategorySelector from './CategorySelector';
import { orderByPriority } from '../../../utils';
import { v4 } from 'uuid';
import CustomModal from '../../../../../components/common/Modal';
import { default as CategoryDetail } from '../../../categories/detail/components/Detail';
import Header from './Header';
import Form from './Form';
import QuestionGroupModal from './QuestionGroupModal';

export const CategoriesQuery = `
  query FaqCategories {
    faqCategories {
        id
        title
        priority
    }
}`;

export const QuestionGroupsQuery = `query FaqQuestionGroups($data: FaqQuestionGroupSearchInputType!) { 
  faqQuestionGroups(data: $data) { 
    id
    title
    categoryId
    priority
    isDraft
    disabled
  } 
}`;

export const QuestionGroupSaveMutation = `mutation FaqQuestionGroupUpdate($data: FaqQuestionGroupUpdateInputType!) {
  faqQuestionGroupUpdate(data: $data) {
      ok
  }
}`;

const Detail = ({ context, currentLanguage, setCurrentLanguage, modal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const globalState = useContext(StateContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [formErrors, setFormErrors] = useState({});
  const { id: idFromUrl } = useParams();
  const languages = globalState?.configuration?.website?.languages;
  const isModal = modal && !isEmpty(modal);

  const history = useHistory();
  const location = useLocation();
  const isCreate = location?.state?.isCreate;
  const isLoading = state.saving || state.loading || state.categoriesFetching;

  const fetchQuestionGroup = (questionGroupId) => {
    dispatch(questionGroupSetLoadingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: QuestionGroupsQuery, variables: { data: { id: questionGroupId } } })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data) {
          const questionGroup = head(result?.data.faqQuestionGroups);
          if (!questionGroup) {
            dispatch(questionGroupInitializeHandler(idFromUrl));
          } else {
            dispatch(questionGroupSetHandler(questionGroup));
          }
          dispatch(questionGroupResetLoadingHandler());
        } else {
          dispatch(questionGroupResetLoadingHandler());
          cogoToast.error('No data returned from the server.');
        }
      })
      .catch((err) => {
        dispatch(questionGroupResetLoadingHandler());
        cogoToast.error('Something went wrong.');
      });
  };

  const fetchCategories = () => {
    dispatch(categoriesSetFetchingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: CategoriesQuery })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data) {
          const categories = result?.data?.faqCategories;
          if (!categories || categories.length < 1) {
            dispatch(categoriesResetFetchingHandler());
            cogoToast.error('No categories to show.');
          }

          dispatch(categoriesSetHandler(categories));
        } else {
          dispatch(categoriesResetFetchingHandler());
          cogoToast.error('No data returned from the server.');
        }
      })
      .catch((err) => {
        dispatch(categoriesResetFetchingHandler());
        cogoToast.error('Something went wrong.');
      });
  };

  useEffect(() => {
    if (isModal) {
      const newId = v4();
      dispatch(questionGroupInitializeHandler(newId));
    } else {
      if (!isCreate) {
        fetchQuestionGroup(idFromUrl);
      } else {
        dispatch(questionGroupInitializeHandler(idFromUrl));
      }
      history.replace(`${routes.FAQ_QUESTION_GROUP_DETAIL}/${idFromUrl}`);
    }
    fetchCategories();
  }, []);

  const questionGroup = state.questionGroup;

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    forEach(languages, (language) => {
      if (isEmpty(questionGroup.title[language])) {
        errors[`title-${language}`] = 'The title needs to be added in all languages.';
        isValid = false;
      }
    });

    // The category is required
    if (!questionGroup.categoryId) {
      errors['category'] = 'The category needs to be selected.';
      isValid = false;
    }

    return {
      isValid,
      errors
    };
  };

  const isValid = () => {
    setFormErrors({});
    const { isValid, errors } = validateForm();
    setFormErrors(errors);
    return isValid;
  };

  const saveQuestionGroup = ({ addAnother }) => {
    dispatch(questionGroupSetInitializedHandler());
    if (isValid()) {
      dispatch(questionGroupSetSavingHandler());
      const variables = {
        data: {
          id: questionGroup.id,
          title: JSON.stringify(questionGroup.title),
          categoryId: questionGroup.categoryId,
          disabled: questionGroup.disabled,
          isDraft: questionGroup.isDraft
        }
      };

      fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query: QuestionGroupSaveMutation, variables: variables })
      })
        .then((resp) => resp.json())
        .then((result) => {
          if (result?.data?.faqQuestionGroupUpdate?.ok) {
            cogoToast.success('Question group saved');
            dispatch(questionGroupSetSaveCompletedHandler());
            if (addAnother) {
              const newId = v4();
              dispatch(questionGroupInitializeHandler(newId));
              history.replace(`${routes.FAQ_QUESTION_GROUP_DETAIL}/${newId}`);
            }

            if (isModal && modal.onSave) {
              const category = find(state.categories, (category) => category.id === questionGroup.categoryId);
              modal.onSave(category, questionGroup);
            }
          } else {
            const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
            cogoToast.error(error);
            dispatch(questionGroupResetSavingHandler());
          }
        })
        .catch((err) => {
          cogoToast.error('Something went wrong. The question group could not be saved.');
          dispatch(questionGroupResetSavingHandler());
        });
    }
  };

  useEffect(() => {
    if (state.formInitialized) {
      setFormErrors({});
      const { errors } = validateForm();
      setFormErrors(errors);
    }
  }, [state.questionGroup, state.formInitialized]);

  const onCreateCategory = () => {
    setIsModalOpen(true);
  };

  const handleCategorySave = (category) => {
    dispatch(categoriesAddHandler(category));
    setIsModalOpen(false);
  };

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (isModal) {
    return (
      <>
        <QuestionGroupModal
          isLoading={state.saving || state.loading}
          languages={languages}
          formErrors={formErrors}
          state={state}
          dispatch={dispatch}
          onCancel={modal.onClose}
          onSave={saveQuestionGroup}
          currentLanguage={currentLanguage}
          onCreateCategory={onCreateCategory}
        />
        <CustomModal isOpen={isModalOpen} onClose={() => setIsModalOpen(!isModalOpen)} title='Add new category'>
          <CategoryDetail
            context={context}
            currentLanguage={currentLanguage}
            setCurrentLanguage={setCurrentLanguage}
            modal={{
              onClose: () => setIsModalOpen(!isModalOpen),
              onSave: (category) => handleCategorySave(category)
            }}
          />
        </CustomModal>
      </>
    );
  }

  return (
    <div className='builder-flex builder-flex-col builder-flex-1 builder-p-5'>
      <div className='builder-mb-5'>
        <Header
          isLoading={state.saving || state.loading}
          history={state.history}
          onCancel={() => history.push(`${routes.FAQ_OVERVIEW}`)}
          onUndo={() => dispatch(questionGroupUndoChangesHandler())}
          onSave={saveQuestionGroup}
          onSaveAndAdd={() => saveQuestionGroup({ addAnother: true })}
        />
      </div>
      <div className='builder-flex builder-p-5 builder-w-full h-full builder-bg-gray-50 builder-relative'>
        {isLoading && <div className='builder-absolute builder-inset-0 builder-bg-gray-200 builder-z-20 builder-opacity-30' />}

        <div className='builder-w-3/4 builder-mr-5'>
          <Form isLoading={state.saving || state.loading} languages={languages} formErrors={formErrors} state={state} dispatch={dispatch} />
        </div>

        <div className='builder-w-1/4'>
          <CategorySelector
            categories={orderByPriority(state.categories)}
            selectedCategoryId={questionGroup.categoryId}
            onSelectCategory={(categoryId) => dispatch(questionGroupSetCategoryHandler(categoryId))}
            currentLanguage={currentLanguage}
            handleCreateCategory={onCreateCategory}
            errorText={formErrors['category']}
          />
        </div>
      </div>

      <CustomModal isOpen={isModalOpen} onClose={() => setIsModalOpen(!isModalOpen)} title='Add new category' contentLabel='Add new category modal'>
        <CategoryDetail
          context={context}
          currentLanguage={currentLanguage}
          setCurrentLanguage={setCurrentLanguage}
          modal={{
            onClose: () => setIsModalOpen(!isModalOpen),
            onSave: (category) => handleCategorySave(category)
          }}
        />
      </CustomModal>
    </div>
  );
};

export default Detail;
