import React from 'react';

const Header = ({ onCreateTopic }) => {
  return (
    <div className='builder-flex builder-items-center builder-justify-between builder-py-5 builder-sticky builder-top-0 builder-z-40' style={{ backgroundColor: 'rgb(222, 226, 236)' }}>
      <div className='builder-flex builder-items-center'>
        <div className='builder-text-2xl builder-text-black builder-font-bold builder-mr-6'>Topics</div>
        <div onClick={onCreateTopic} data-testid='create-topic-button' className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-primary builder-transition-all builder-cursor-pointer'>
          <i className='fal fa-plus builder-mr-2' />
          New topic
        </div>
      </div>
    </div>
  );
};

export default Header;
