import { actionTypes } from './reducer';

export const redirectInitializeHandler = (id) => ({
  type: actionTypes.REDIRECT_INITIALIZE,
  payload: {
    id
  }
});

export const redirectSetInitializedHandler = () => ({
  type: actionTypes.REDIRECT_SET_INITIALIZED
});

export const redirectSetHandler = (redirect) => ({
  type: actionTypes.REDIRECT_SET,
  payload: {
    redirect
  }
});

export const redirectSetFromPathHandler = (fromPath) => ({
  type: actionTypes.REDIRECT_SET_FROM_PATH,
  payload: {
    fromPath
  }
});

export const redirectSetToPathHandler = (toPath) => ({
  type: actionTypes.REDIRECT_SET_TO_PATH,
  payload: {
    toPath
  }
});

export const redirectSetTypeHandler = (type) => ({
  type: actionTypes.REDIRECT_SET_TYPE,
  payload: {
    type
  }
});
