import React from 'react';

const InfoIndicator = ({ message, icon }) => {
  if (!message) {
    return <div className='builder-hidden' />;
  }

  return (
    <div className='w-full bg-blue-100 flex items-center rounded'>
      <div className='flex h-full'>
        {icon && (
          <div className='w-16 bg-blue-300 flex items-center justify-center rounded-l'>
            <i className={`${icon} text-2xl text-black`} />
          </div>
        )}
        <div className='w-auto text-black opacity-75 items-center px-4 py-4  flex items-center'>
          <p className='leading-tight'>{message}</p>
        </div>
      </div>
    </div>
  );
};

export default InfoIndicator;
