import React from 'react';
import TextArea from '../../builder/editors/components/TextArea';
import moment from 'moment';
import DatePickerField from '../../../components/common/DatePickerField';
import { formatToISOWithLocalOffset } from '../../../utils';

const DaysWithDescription = ({ item, onChanged, context }) => {
  const getMinTime = (date) => {
    if (!date) return null;

    // Set minTime to the start time
    const minTime = new Date(date);
    minTime.setHours(minTime.getHours(), minTime.getMinutes(), 0, 0);

    return minTime;
  };

  const getMaxMaxTime = (date) => {
    if (!date) return null;

    // Set maxTime to the end of the day (23:59)
    const maxTime = new Date(date);
    maxTime.setHours(23, 59, 59, 999);
    return maxTime;
  };

  return (
    <div className='builder-space-y-2'>
      <div className='builder-flex builder-space-x-2'>
        <div className='builder-flex builder-flex-col builder-mb-2'>
          <div className='builder-mb-1'>Start day</div>
          <DatePickerField
            selected={item?.start ? new Date(item.start) : null}
            onChange={(date) => {
              const formattedStartDate = formatToISOWithLocalOffset(date);
              // Create a new moment object by adding 1 hour to formattedStartDate
              const formattedStopDate = formatToISOWithLocalOffset(moment(formattedStartDate).add(1, 'hours'));
              // Parse item.stop and formattedStopDate to moment objects for comparison
              // Check if current stop date is later that new stop date
              const stopDate = moment(item.stop);
              const newStopDate = moment(formattedStopDate);

              // Determine which stop date to use
              const finalStopDate = stopDate.isAfter(newStopDate) ? formatToISOWithLocalOffset(stopDate) : formattedStopDate;

              onChanged({
                ...item,
                start: formattedStartDate,
                stop: finalStopDate
              });
            }}
            className='builder-border-gray-300'
            showTime={true}
            minDate={new Date()}
            context={context}
          />
        </div>
        <div className='builder-flex builder-flex-col'>
          <div className='builder-mb-1'>Stop day</div>
          <DatePickerField
            selected={item?.stop ? new Date(item.stop) : null}
            onChange={(date) => {
              const formattedStopDate = formatToISOWithLocalOffset(date);
              onChanged({
                ...item,
                stop: formattedStopDate
              });
            }}
            className={`builder-border-gray-300 ${item?.start ? '' : 'builder-bg-gray-100 builder-pointer-events-none'}`}
            showTime={true}
            minDate={item?.start ? new Date(item?.start) : new Date()}
            minTime={item?.start ? getMinTime(item?.start) : null}
            maxTime={item?.start ? getMaxMaxTime(item?.start) : null}
            context={context}
          />
        </div>
      </div>

      <div className='builder-mb-1'>Message</div>
      <TextArea value={item?.description} className='builder-mt-2' rows={5} placeHolder='Add some description' onChange={(value) => onChanged({ ...item, description: value.length > 0 ? value : 'We are closed.' })} />
    </div>
  );
};

export default DaysWithDescription;
