import { tryParse } from '../../../../utils';
import { map, maxBy } from 'lodash';

export const actionTypes = {
  QUESTION_GROUP_SET_LOADING: 'QUESTION_GROUP_SET_LOADING',
  QUESTION_GROUP_RESET_LOADING: 'QUESTION_GROUP_RESET_LOADING',
  QUESTION_GROUP_INITIALIZE: 'QUESTION_GROUP_INITIALIZE',
  QUESTION_GROUP_SET: 'QUESTION_GROUP_SET',
  QUESTION_GROUP_SET_TITLE: 'QUESTION_GROUP_SET_TITLE',
  QUESTION_GROUP_SET_CATEGORY: 'QUESTION_GROUP_SET_CATEGORY',
  QUESTION_GROUP_SET_INITIALIZED: 'QUESTION_GROUP_SET_INITIALIZED',

  CATEGORIES_SET_FETCHING: 'CATEGORIES_SET_FETCHING',
  CATEGORIES_RESET_FETCHING: 'CATEGORIES_RESET_FETCHING',
  CATEGORIES_SET: 'CATEGORIES_RESET_SET',
  CATEGORIES_ADD: 'CATEGORIES_ADD',

  QUESTION_GROUP_UNDO_CHANGES: 'QUESTION_GROUP_UNDO_CHANGES',
  QUESTION_GROUP_SET_SAVING: 'QUESTION_GROUP_SET_SAVING',
  QUESTION_GROUP_RESET_SAVING: 'QUESTION_GROUP_RESET_SAVING',
  QUESTION_GROUP_SET_SAVE_COMPLETED: 'QUESTION_GROUP_SET_SAVE_COMPLETED'
};

export const initialTitle = {
  nl: '',
  fr: '',
  en: ''
};

export const initialQuestionGroup = {
  id: '',
  title: initialTitle,
  categoryId: '',
  priority: 0,
  isDraft: false,
  disabled: false
};

export const initialState = {
  history: [],
  categories: [],
  categoriesFetching: false,
  questionGroup: initialQuestionGroup,
  saving: false,
  loading: false
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.QUESTION_GROUP_SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case actionTypes.QUESTION_GROUP_RESET_LOADING:
      return {
        ...state,
        loading: false
      };
    case actionTypes.QUESTION_GROUP_SET_SAVING:
      return {
        ...state,
        saving: true
      };
    case actionTypes.QUESTION_GROUP_RESET_SAVING:
      return {
        ...state,
        saving: false
      };
    case actionTypes.QUESTION_GROUP_SET_INITIALIZED:
      return {
        ...state,
        formInitialized: true
      };
    case actionTypes.QUESTION_GROUP_SET_SAVE_COMPLETED:
      return {
        ...state,
        saving: false
      };
    case actionTypes.QUESTION_GROUP_INITIALIZE:
      return {
        ...state,
        history: undefined,
        questionGroup: {
          ...initialQuestionGroup,
          id: action.payload.id
        }
      };
    case actionTypes.QUESTION_GROUP_SET:
      const questionGroup = {
        id: action.payload.questionGroup.id,
        title: tryParse(action.payload.questionGroup.title),
        categoryId: action.payload.questionGroup.categoryId,
        priority: action.payload.questionGroup.priority,
        isDraft: action.payload.questionGroup.isDraft,
        disabled: action.payload.questionGroup.disabled
      };

      return {
        ...state,
        loading: false,
        questionGroup: questionGroup,
        history: [questionGroup]
      };
    case actionTypes.QUESTION_GROUP_SET_TITLE:
      const newTitle = {
        ...state.questionGroup.title,
        [action.payload.language]: action.payload.title
      };

      return {
        ...state,
        history: state.history !== undefined ? [...state.history, { ...state.questionGroup }] : undefined,
        questionGroup: {
          ...state.questionGroup,
          title: newTitle
        }
      };
    case actionTypes.QUESTION_GROUP_SET_CATEGORY:
      return {
        ...state,
        history: state.history !== undefined ? [...state.history, { ...state.questionGroup }] : undefined,
        questionGroup: {
          ...state.questionGroup,
          categoryId: action.payload.categoryId
        }
      };
    case actionTypes.QUESTION_GROUP_UNDO_CHANGES:
      return {
        ...state,
        questionGroup: {
          ...state.history[0]
        },
        history: [{ ...state.history[0] }]
      };
    case actionTypes.CATEGORIES_SET_FETCHING:
      return {
        ...state,
        categoriesFetching: true
      };
    case actionTypes.CATEGORIES_RESET_FETCHING:
      return {
        ...state,
        categoriesFetching: false
      };
    case actionTypes.CATEGORIES_SET:
      const mappedCategories = map(action.payload.categories, (category) => ({
        id: category.id,
        title: tryParse(category.title),
        priority: category.priority
      }));
      return {
        ...state,
        categoriesFetching: false,
        categories: mappedCategories
      };

    case actionTypes.CATEGORIES_ADD:
      const categoryWithHighestPriority = maxBy(state.categories, 'priority');
      const highestPriority = categoryWithHighestPriority?.priority ? categoryWithHighestPriority?.priority : -1;
      const category = {
        ...action.payload.category,
        priority: highestPriority + 1
      };

      return {
        ...state,
        categories: [...state.categories, category],
        questionGroup: {
          ...state.questionGroup,
          categoryId: category.id
        }
      };

    default:
      return state;
  }
};
