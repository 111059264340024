import React, { useState } from 'react';
import { map } from 'lodash';
import Category from './Category';
import { routes } from '../../../data/constants';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import { categoryChangePrioritiesHandler, categorySetDeletedHandler } from '../actions';

export const CategoryDeleteMutation = `mutation FaqCategoryDelete($data: FaqCategoryDeleteInputType!) {
  faqCategoryDelete(data: $data) {
      ok
  }
}`;

const Categories = ({ categories, currentLanguage, state, dispatch }) => {
  const [deletingCategoryId, setDeletingCategoryId] = useState(undefined);
  const history = useHistory();

  const onEditCategory = (categoryId) => {
    history.push(`${routes.FAQ_CATEGORY_DETAIL}/${categoryId}`);
  };

  const onDeleteCategory = (categoryId) => {
    setDeletingCategoryId(categoryId);
    const variables = {
      data: {
        id: categoryId
      }
    };

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: CategoryDeleteMutation, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data?.faqCategoryDelete?.ok) {
          cogoToast.success('Category deleted.');
          dispatch(categorySetDeletedHandler(categoryId));
        } else {
          const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
          cogoToast.error(error);
        }
        setDeletingCategoryId(undefined);
      })
      .catch((err) => {
        cogoToast.error('Something went wrong. The category could not be deleted.');
        setDeletingCategoryId(undefined);
      });
  };

  const onChangePriority = (dragIndex, targetIndex) => {
    dispatch(categoryChangePrioritiesHandler(dragIndex, targetIndex));
  };

  return (
    <div data-testid='categories-container' className='builder-flex builder-flex-col builder-justify-center builder-space-y-4 builder-text-md builder-text-black builder-max-w-7xl builder-min-w-min builder-pb-5'>
      {map(categories, (category, index) => {
        return (
          <div key={category.id}>
            <Category
              index={index}
              category={category}
              onEditCategory={onEditCategory}
              onDeleteCategory={onDeleteCategory}
              onChangePriority={onChangePriority}
              isDeleting={category.id === deletingCategoryId}
              currentLanguage={currentLanguage}
              state={state}
              dispatch={dispatch}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Categories;
