import React from 'react';
import Highlight from 'react-highlight';
import { find, forEach, keys, map, filter, includes } from 'lodash';

const indentString = (str, count, indent = '  ') => str.replace(/^/gm, indent.repeat(count));

const recursiveDataSchema = (data, result, level) => {
  forEach(
    filter(keys(data['properties']), (key) => key !== '_type'),
    (propName) => {
      const propType = data['properties'][propName].type;

      if (propType === 'object') {
        const indentTabs = level;
        result.push(`//${indentString(propName, indentTabs)}: {`);
        recursiveDataSchema(data['properties'][propName], result, (level += 1));
        result.push(`//${indentString('', indentTabs)}}`);
        level -= 1;
      } else {
        const propDescription = data['properties'][propName]['_comments'] ? data['properties'][propName]['_comments'] : undefined;
        const requiredFields = data['required'] ? data['required'] : [];
        result.push(`//${indentString(`${propName}: ${propType}${includes(requiredFields, propName) ? '!' : ''}  ${propDescription ? `=> ${propDescription}` : ''}`, level)}`);
      }
    }
  );
};

const getPropsDescription = (props, fieldTypes) => {
  const result = [];
  let level = 0;

  forEach(props, (prop) => {
    level = 1;
    const fieldTypeDefinition = find(fieldTypes, (ft) => ft.name === prop.type);

    if (fieldTypeDefinition) {
      result.push(`//${indentString(prop.name, level)}: {`);

      if (fieldTypeDefinition.dataSchema) {
        recursiveDataSchema(fieldTypeDefinition.dataSchema, result, (level += 1));
        level -= 1;
      } else {
        result.push(`//   whoops, schema for ${prop.type} not defined yet... work in progress...`);
        result.push(`//   schema for ${prop.type} not defined yet...`);
        result.push(`//   work in progress...`);
      }

      result.push(`//${indentString('', level)}} \n`);
    }
  });

  return result.join('\n');
};

const getComponentTemplate = (name, props, fieldTypes) => {
  return `import React from 'react';      

${getPropsDescription(props, fieldTypes)}
const ${name} = ({${map(props, (p) => p.name).join(', ')}, context}) => {   
    return <div>Your implementation of ${name}</div>;
};
        
${name}.human = {
    nl: 'Leesbare name voor ${name}',
    fr: 'Nom lisible pour ${name}',
    en: 'Readable name for ${name}'
};
        
export default ${name};
`;
};

const ComponentPreview = ({ block, fieldTypes }) => {
  const componentPreviewString = getComponentTemplate(block.name, block.fields, fieldTypes);

  return (
    <React.Fragment>
      <div className='builder-text-black builder-font-bold builder-text-2xl builder-mb-2'>Component example</div>
      <div className='builder-border builder-border-gray-400 builder-relative builder-flex builder-items-center'>
        <div className='builder-absolute builder-top-2 builder-right-2'>
          <div
            className='builder-cursor-pointer builder-border builder-border-gray-300 builder-rounded-md builder-p-2 hover:builder-border-gray-400'
            onClick={() => {
              navigator.clipboard.writeText(componentPreviewString);
            }}
          >
            <i className='fal fa-copy'></i>
          </div>
        </div>
        <div className='builder-w-full'>
          <Highlight className='typescript'>{componentPreviewString}</Highlight>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ComponentPreview;
