import { endsWith, startsWith } from 'lodash';

export const isFromPathValid = (fromPath) => {
  // URL empty
  if (!fromPath) return false;

  // URL doesn't start with '/'
  if (!startsWith(fromPath, '/')) return false;

  return true;
};

export const isToPathValid = (toPath) => {
  // URL empty
  if (!toPath) return false;

  // URL doesn't start with '/'
  if (!startsWith(toPath, '/')) return false;

  const indexOfQuestionMark = toPath.indexOf('?');
  const indexOfHashtag = toPath.indexOf('#');

  if (indexOfQuestionMark !== -1) {
    // If url contains a '?' it has to contain an '='
    const indexOfEqualMark = toPath.indexOf('=');
    if (indexOfEqualMark === -1) return false;

    // Before '?' it needs to be a '/' example: '/list/?model=test' not '/list?model=test'
    if (toPath[indexOfQuestionMark - 1] !== '/') return false;

    return true;
  } else if (indexOfHashtag !== -1) {
    // if urls contains # is does not need to end on '/'

    return true;
  }

  if (endsWith(toPath, '/')) return true;

  return false;
};
