import React, { useEffect, useState } from 'react';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';

const TimePickersV2 = ({ onChange, dayKey, start, stop }) => {
  const [startValue, setStartValue] = useState(start);
  const [stopValue, setStopValue] = useState(stop);

  useEffect(() => {
    if (startValue && stopValue) {
      onChange(startValue, stopValue, dayKey);
    }
  }, [startValue, stopValue]);

  useEffect(() => {
    setStartValue(start);
    setStopValue(stop);
  }, [start, stop]);

  return (
    <div>
      <div className='builder-flex builder-items-center builder-justify-center builder-mb-2'>
        <div className='builder-text-gray-700 builder-text-xs builder-mr-2 builder-w-20'>Start</div>
        <TimePicker
          showSecond={false}
          onChange={(value) => value && setStartValue(value.format('HH:mm'))}
          value={startValue ? moment(startValue, 'HH:mm') : undefined}
          className='builder-w-full'
          placeholder='--:--'
          minuteStep={5}
          addon={(param) => {}}
        />
      </div>

      <div className='builder-flex builder-items-center builder-justify-center'>
        <div className='builder-text-gray-700 builder-text-xs builder-mr-2 builder-w-20'>Stop</div>
        <TimePicker showSecond={false} onChange={(value) => value && setStopValue(value.format('HH:mm'))} value={stopValue ? moment(stopValue, 'HH:mm') : undefined} className='builder-w-full' minuteStep={5} placeholder='--:--' />
      </div>
    </div>
  );
};

export default TimePickersV2;
