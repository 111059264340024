import { toggleCollapseHandler } from '../actions';

export const usePagesState = (state, dispatch) => {
  const toggleCollapse = () => {
    dispatch(toggleCollapseHandler());
  };

  return {
    toggleCollapse: () => toggleCollapse()
  };
};
