import { actionTypes } from './reducer';

export const advertisementSetHandler = (dispatch, advertisement) => {
  dispatch({
    type: actionTypes.ADVERTISEMENT_SET,
    payload: {
      advertisement
    }
  });
};

export const advertisementInitializeHandler = (dispatch, id) => {
  dispatch({
    type: actionTypes.ADVERTISEMENT_INITIALIZE,
    payload: {
      id
    }
  });
};

export const advertisementSetDescriptionHandler = (dispatch, description) => {
  dispatch({
    type: actionTypes.ADVERTISEMENT_SET_DESCRIPTION,
    payload: {
      description
    }
  });
};

export const advertisementSetContentHandler = (dispatch, content) => {
  dispatch({
    type: actionTypes.ADVERTISEMENT_SET_CONTENT,
    payload: {
      content
    }
  });
};

export const advertisementSetGroupHandler = (dispatch, group) => {
  dispatch({
    type: actionTypes.ADVERTISEMENT_SET_GROUP,
    payload: {
      group
    }
  });
};

export const advertisementFiltersModalHandler = (dispatch, filtersModal) => {
  dispatch({
    type: actionTypes.FILTERS_MODAL_SET_OPEN,
    payload: {
      filtersModal
    }
  });
};

export const advertisementSetFiltersHandler = (dispatch, filters) => {
  dispatch({
    type: actionTypes.ADVERTISEMENT_SET_FILTERS,
    payload: {
      filters
    }
  });
};

export const advertisementUndoChangesHandler = (dispatch) => {
  dispatch({
    type: actionTypes.ADVERTISEMENT_UNDO_CHANGES,
    payload: {}
  });
};

export const advertisementSetLoadingHandler = (dispatch) => {
  dispatch({
    type: actionTypes.ADVERTISEMENT_SET_LOADING,
    payload: {}
  });
};

export const advertisementResetLoadingHandler = (dispatch) => {
  dispatch({
    type: actionTypes.ADVERTISEMENT_RESET_LOADING,
    payload: {}
  });
};
