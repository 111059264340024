import { actionTypes } from './reducer';

// Question loading state setters
export const questionSetLoadingHandler = () => ({
  type: actionTypes.QUESTION_SET_LOADING
});

export const questionResetLoadingHandler = () => ({
  type: actionTypes.QUESTION_RESET_LOADING
});

export const questionSetSavingHandler = () => ({
  type: actionTypes.QUESTION_SET_SAVING
});

export const questionResetSavingHandler = () => ({
  type: actionTypes.QUESTION_RESET_SAVING
});

export const questionSetInitializedHandler = () => ({
  type: actionTypes.QUESTION_SET_INITIALIZED
});
//

// Question data setters
export const questionInitializeHandler = (id) => ({
  type: actionTypes.QUESTION_INITIALIZE,
  payload: {
    id
  }
});

export const questionSetHandler = (question) => ({
  type: actionTypes.QUESTION_SET,
  payload: {
    question
  }
});

export const questionSetTitleHandler = (title, language) => ({
  type: actionTypes.QUESTION_SET_TITLE,
  payload: {
    title,
    language
  }
});

export const questionSetAnswerHandler = (id, data, language) => ({
  type: actionTypes.QUESTION_SET_ANSWER,
  payload: {
    id,
    data,
    language
  }
});

export const questionSetGroupHandler = (groupId) => ({
  type: actionTypes.QUESTION_SET_GROUP,
  payload: {
    groupId
  }
});

export const questionSetSaveCompletedHandler = () => ({
  type: actionTypes.QUESTION_SET_SAVE_COMPLETED
});

export const questionUndoChangesHandler = () => ({
  type: actionTypes.QUESTION_UNDO_CHANGES
});
//

// Categories and groups loading state setters
export const groupsAndCategoriesSetFetchingHandler = () => ({
  type: actionTypes.GROUPS_AND_CATEGORIES_SET_FETCHING
});

export const groupsAndCategoriesResetFetchingHandler = () => ({
  type: actionTypes.GROUPS_AND_CATEGORIES_RESET_FETCHING
});
//

// Categories data setter
export const groupsAndCategoriesSetHandler = (categories, groups) => ({
  type: actionTypes.GROUPS_AND_CATEGORIES_SET,
  payload: {
    categories,
    groups
  }
});

export const questionGroupAddHandler = (category, group) => ({
  type: actionTypes.QUESTION_ADD_GROUP,
  payload: {
    category,
    group
  }
});
//
