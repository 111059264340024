import React from 'react';
import { get, map } from 'lodash';

const Groups = ({ groups, selectedGroupId, handleCreateGroup, currentLanguage, onSelectGroup }) => {
  const renderNoGroups = () => {
    return (
      <React.Fragment>
        <div className='builder-font-bold builder-mt-4'>No groups defined.</div>
        <div className='builder-underline builder-font-semibold builder-cursor-pointer builder-mt-4' onClick={handleCreateGroup}>
          Create new group
        </div>
      </React.Fragment>
    );
  };

  if (!groups && groups.length < 1) {
    return renderNoGroups();
  }

  const onHandleClick = (e, groupId) => {
    e.stopPropagation();
    const isSelected = e.target.checked;
    onSelectGroup(isSelected ? groupId : '');
  };

  return (
    <div className='builder-ml-2 builder-space-y-1'>
      {map(groups, (group) => {
        const groupTitle = get(group.title, currentLanguage);
        const isSelected = group.id === selectedGroupId;
        return (
          <div key={group.id} className='builder-flex builder-items-center builder-group'>
            <input
              id={group.id}
              data-testid='question-group-checkbox'
              type='checkbox'
              checked={isSelected}
              onChange={(e) => onHandleClick(e, group.id)}
              className='builder-h-4 builder-w-4 builder-text-indigo-600 focus:builder-ring-indigo-500 builder-border builder-border-gray-300 builder-rounded-md builder-cursor-pointer'
            />
            <label htmlFor={group.id} className='group-hover:builder-text-gray-700 cursor-pointer builder-ml-2'>
              {groupTitle}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default Groups;
