import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { findKey, get, map } from 'lodash';
import TextInput from '../../../../builder/editors/components/TextInput';
import { questionGroupSetTitleHandler } from '../actions';

const Form = ({ isLoading, languages, formErrors, state, dispatch }) => {
  const tabClassNames =
    'relative -bottom-px builder-p-3 builder-cursor-pointer builder-text-black builder-text-md builder-uppercase focus:outline-none builder-transition-all builder-border-t builder-border-r builder-border-l builder-border-b-0 builder-border-transparent';
  const activeTabClassNames = 'builder-font-bold builder-bg-white builder-border-t builder-border-r builder-border-l builder-border-b-0 builder-rounded-sm builder-border-gray-300';
  const tabPanelClassNames = 'builder-bg-white builder-border-l builder-border-r builder-border-gray-300';
  const questionGroup = state.questionGroup;

  return (
    <div className='builder-flex builder-w-full h-full'>
      <div className='builder-w-full'>
        <div className='builder-flex builder-flex-col'>
          <Tabs defaultIndex={0}>
            <TabList className='builder-flex builder-flex-row builder-justify-start builder-align-center builder-border-b builder-border-gray-300'>
              {map(languages, (language) => {
                const hasErrors = findKey(formErrors, (value, key) => key.endsWith(`-${language}`));
                const className = `${tabClassNames} ${hasErrors ? 'builder-bg-red-500' : ''}`;
                return (
                  <Tab key={`question-groups-${language}`} className={className} selectedClassName={activeTabClassNames}>
                    {language}
                  </Tab>
                );
              })}
            </TabList>

            {map(languages, (language) => {
              const questionGroupTitle = get(questionGroup.title, language);
              return (
                <TabPanel key={`question-groups-content-${language}`} className={tabPanelClassNames}>
                  <div className='builder-flex builder-flex-col builder-p-4 builder-space-y-2 builder-border-b builder-border-gray-300'>
                    {/*TITLE*/}
                    <div>
                      <TextInput
                        dataTestId='question-group-input-title'
                        label='Title'
                        placeHolder='Add question group title'
                        disabled={isLoading}
                        errorText={formErrors[`title-${language}`]}
                        className='builder-flex builder-flex-1 builder-max-w-xl builder-rounded-md'
                        value={questionGroupTitle}
                        onChanged={(value) => dispatch(questionGroupSetTitleHandler(value, language))}
                      />
                    </div>
                  </div>
                </TabPanel>
              );
            })}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Form;
