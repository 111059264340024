import React, { useState } from 'react';
import { get, includes, map } from 'lodash';

const CategorySelector = ({ categories, selectedCategoryIds, onCategoryClick, handleCreateCategory, currentLanguage }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const onHandleClick = (e, categoryId) => {
    e.stopPropagation();
    onCategoryClick(categoryId);
  };

  if (categories.length === 0) {
    return (
      <React.Fragment>
        <div className='builder-font-bold builder-mt-4'>No categories defined.</div>
        <div className='builder-underline builder-font-semibold builder-cursor-pointer builder-mt-4' onClick={handleCreateCategory}>
          Create new category
        </div>
      </React.Fragment>
    );
  }

  return (
    <div data-testid='topic-category-wrapper' className='builder-p-4 builder-bg-white builder-z-10 builder-overflow-hidden'>
      <div className='builder-flex builder-justify-between builder-cursor-pointer builder-z-10 builder-relative' onClick={() => setIsCollapsed(!isCollapsed)}>
        <div className='builder-text-black builder-text-md builder-font-bold'>Categories</div>
        <i className={`fas builder-text-md ${isCollapsed ? 'fa-caret-down' : 'fa-caret-up'}`} />
      </div>

      <div className={`builder-space-y-2 builder-transition-all builder-ease-in builder-transform builder-bg-white ${isCollapsed ? 'builder-hidden' : 'builder-block builder-mt-4'}`}>
        {map(categories, (category) => {
          const categoryTitle = get(category.title, currentLanguage);
          const isSelected = includes(selectedCategoryIds, category.id);
          return (
            <div key={category.id} className='builder-flex builder-items-center builder-group'>
              <div className='builder-mr-2'>
                <input
                  id={category.id}
                  data-testid='topic-category-checkbox'
                  type='checkbox'
                  checked={isSelected}
                  onChange={(e) => onHandleClick(e, category.id)}
                  className='builder-h-4 builder-w-4 builder-text-indigo-600 focus:builder-ring-indigo-500 builder-border builder-border-gray-300 builder-rounded-md builder-cursor-pointer'
                />
              </div>
              <label htmlFor={category.id} className='group-hover:builder-text-gray-700 cursor-pointer'>
                {categoryTitle}
              </label>
            </div>
          );
        })}

        <div className='builder-flex builder-justify-end builder-mt-6 builder-cursor-pointer builder-font-bold hover:builder-text-gray-600' onClick={handleCreateCategory}>
          <i className='far fa-plus builder-mr-2' /> Add new category
        </div>
      </div>
    </div>
  );
};

export default CategorySelector;
