import { actionTypes } from './reducer';

export const dataSetLoadingHandler = () => ({
  type: actionTypes.DATA_SET_LOADING
});

export const dataResetLoadingHandler = () => ({
  type: actionTypes.DATA_RESET_LOADING
});

export const dataSetHandler = (makes) => ({
  type: actionTypes.DATA_SET,
  payload: {
    makes
  }
});

export const makeSetSeoUpdatingHandler = (makeId) => ({
  type: actionTypes.MAKE_SET_SEO_UPDATING,
  payload: {
    makeId
  }
});

export const makeResetSeoUpdatingHandler = () => ({
  type: actionTypes.MAKE_RESET_SEO_UPDATING
});

export const makeSeoUpdatedHandler = (makeId, makePriority, makeSeo) => ({
  type: actionTypes.MAKE_SEO_UPDATED,
  payload: {
    makeId,
    makePriority,
    makeSeo
  }
});

export const makeSetDisabledUpdatingHandler = (makeId) => ({
  type: actionTypes.MAKE_SET_DISABLED_UPDATING,
  payload: {
    makeId
  }
});

export const makeResetDisabledUpdatingHandler = () => ({
  type: actionTypes.MAKE_RESET_DISABLED_UPDATING
});

export const makeDisabledUpdatedHandler = (makeId, makeDisabled) => ({
  type: actionTypes.MAKE_DISABLED_UPDATED,
  payload: {
    makeId,
    makeDisabled
  }
});

export const makeSetPrioritiesUpdatingHandler = () => ({
  type: actionTypes.MAKE_SET_PRIORITIES_UPDATING
});

export const makeResetPrioritiesUpdatingHandler = () => ({
  type: actionTypes.MAKE_RESET_PRIORITIES_UPDATING
});
