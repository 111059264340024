import React, { useContext, useEffect, useReducer } from 'react';
import Pages from './Pages';
import { StateContext } from '../../../../App';
import { find } from 'lodash';
import { usePages, usePagesState } from '../hooks';
import LoadingSkeleton from './LoadingSkeleton';
import { ErrorIndicator } from '../../../../components/common';
import { initialState, reducer } from '../reducer';

const PagesContainer = ({ context }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const contextState = useContext(StateContext);

  const currentWebsite = find(contextState?.websites, (w) => w.id === contextState.currentWebsite);
  const { fetchPages, pages, loading, error } = usePages(state, dispatch);
  const { toggleCollapse } = usePagesState(state, dispatch);

  useEffect(() => {
    if (currentWebsite) {
      fetchPages(currentWebsite.id);
    }
  }, [currentWebsite]);

  if (!currentWebsite || loading) {
    return <LoadingSkeleton />;
  }

  if (currentWebsite && !loading && error) {
    return (
      <div data-testid='pages-container-error-indicator' className='builder-p-5 builder-w-full builder-relative'>
        <ErrorIndicator error='The pages could not be fetched.' />
      </div>
    );
  }

  return (
    <div data-testid='pages-container-content'>
      <Pages pages={pages} isCollapsed={state.isCollapsed} toggleCollapse={toggleCollapse} context={context} />
    </div>
  );
};

export default PagesContainer;
