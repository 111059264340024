import { filter, map } from 'lodash';

export const initialState = {
  customBlocks: [],
  customBlocksLoading: false,
  customBlocksError: undefined,
  customBlockDeleting: false,
  customBlockDeletingError: false,
  customBlockConfirmDeleteId: undefined
};

// ACTION TYPES //
export const actionTypes = {
  CUSTOM_BLOCKS_SET: 'CUSTOM_BLOCKS_SET',
  CUSTOM_BLOCK_DELETED: 'CUSTOM_BLOCK_DELETED',
  CUSTOM_BLOCKS_SET_LOADING: 'CUSTOM_BLOCKS_SET_LOADING',
  CUSTOM_BLOCKS_RESET_LOADING: 'CUSTOM_BLOCKS_RESET_LOADING',
  CUSTOM_BLOCK_SET_DELETING: 'CUSTOM_BLOCK_SET_DELETING',
  CUSTOM_BLOCK_RESET_DELETING: 'CUSTOM_BLOCK_RESET_DELETING',
  CUSTOM_BLOCK_SHOW_DELETE_POPUP: 'CUSTOM_BLOCK_SHOW_DELETE_POPUP',
  CUSTOM_BLOCK_REMOVE_DELETE_POPUP: 'CUSTOM_BLOCK_REMOVE_DELETE_POPUP'
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.CUSTOM_BLOCKS_SET_LOADING:
      return {
        ...state,
        customBlocksLoading: true
      };
    case actionTypes.CUSTOM_BLOCKS_RESET_LOADING:
      return {
        ...state,
        customBlocksLoading: false
      };
    case actionTypes.CUSTOM_BLOCKS_SET:
      return {
        ...state,
        customBlocks: action.payload.customBlocks,
        customBlocksLoading: false,
        customBlocksError: undefined
      };
    case actionTypes.CUSTOM_BLOCK_DELETED:
      return {
        ...state,
        customBlocks: filter(state.customBlocks, (customBlock) => customBlock.id !== action.payload.id),
        customBlocksLoading: false,
        customBlocksError: undefined
      };
    case actionTypes.CUSTOM_BLOCK_SET_DELETING:
      return {
        ...state,
        customBlockDeleting: true
      };
    case actionTypes.CUSTOM_BLOCK_RESET_DELETING:
      return {
        ...state,
        customBlockDeleting: false
      };
    case actionTypes.CUSTOM_BLOCK_SHOW_DELETE_POPUP:
      return {
        ...state,
        customBlockConfirmDeleteId: action.payload.id
      };
    case actionTypes.CUSTOM_BLOCK_REMOVE_DELETE_POPUP:
      return {
        ...state,
        customBlockConfirmDeleteId: undefined
      };
    default:
      return state;
  }
};
