import { actionTypes } from './reducers';

export const filtersSetHandler = (dispatch, filters) => {
  dispatch({
    type: actionTypes.FILTERS_SET,
    payload: {
      filters
    }
  });
};

export const filterDeleteHandler = (dispatch, id) => {
  dispatch({
    type: actionTypes.FILTER_DELETE,
    payload: {
      id
    }
  });
};

export const filtersSetLoadingHandler = (dispatch) => {
  dispatch({
    type: actionTypes.FILTERS_SET_LOADING,
    payload: {}
  });
};

export const filtersResetLoadingHandler = (dispatch) => {
  dispatch({
    type: actionTypes.FILTERS_RESET_LOADING,
    payload: {}
  });
};
