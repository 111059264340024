import { cloneDeep, forEach, values, includes } from 'lodash';
import { v4 } from 'uuid';

export const resolveDistributedContent = (pages, updatedBlock, isLmaPage) => {
  const affectedPages = [];
  const allPages = cloneDeep(pages);

  forEach(values(allPages), (page) => {
    if ((isLmaPage && includes(page.url, 'dealerGroup/')) || (!isLmaPage && !includes(page.url, 'dealerGroup/'))) {
      let pageAffected = false;
      forEach(page.blocks, (block) => {
        if (block.componentName === updatedBlock.componentName && block.contentType === updatedBlock.contentType && block.id !== updatedBlock.id) {
          const tmpContent = [];
          forEach(updatedBlock.content, (content) => {
            tmpContent.push({
              id: v4(),
              culture: content.culture,
              fields: content.fields
            });
          });
          block.content = tmpContent;
          pageAffected = true;
        }
      });

      if (pageAffected) {
        affectedPages.push(page);
      }
    }
  });

  return { allPages, affectedPages };
};
