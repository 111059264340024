import { actionTypes } from './reducer';

export const dataLoadingHandler = () => ({
  type: actionTypes.DATA_LOADING
});

export const dataSetHandler = (topics, categories) => ({
  type: actionTypes.DATA_SET,
  payload: {
    topics,
    categories
  }
});

export const topicSetDeleteHandler = (topicId) => ({
  type: actionTypes.TOPIC_DELETE,
  payload: {
    topicId
  }
});
