import React from 'react';
import ButtonWithIndicator from '../../../../components/common/ButtonWithIndicator';

const Header = ({ onCancel, onSave, loading, saveDisabled }) => {
  return (
    <div className='builder-flex builder-justify-end builder-mb-4'>
      {onCancel && (
        <div className='builder-bg-white builder-border builder-border-gray-300 builder-px-4 builder-py-2 builder-cursor-pointer hover:builder-bg-gray-100' onClick={onCancel}>
          Cancel
        </div>
      )}

      {onSave && <ButtonWithIndicator loading={loading} onClick={onSave} icon='fal fa-save' text='Save' disabled={saveDisabled} colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-primary' />}
    </div>
  );
};

export default Header;
