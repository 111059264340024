import { actionTypes } from './reducer';

export const topicSetLoadingHandler = () => ({
  type: actionTypes.TOPIC_SET_LOADING
});

export const topicResetLoadingHandler = () => ({
  type: actionTypes.TOPIC_RESET_LOADING
});

export const topicSetInitializedHandler = () => ({
  type: actionTypes.TOPIC_SET_INITIALIZED
});

export const topicInitializeHandler = (id) => ({
  type: actionTypes.TOPIC_INITIALIZE,
  payload: {
    id
  }
});

export const topicSetHandler = (topic) => ({
  type: actionTypes.TOPIC_SET,
  payload: {
    topic
  }
});

export const topicSetTitleHandler = (title, language) => ({
  type: actionTypes.TOPIC_SET_TITLE,
  payload: {
    title,
    language
  }
});

export const topicSetSlugHandler = (slug, language) => ({
  type: actionTypes.TOPIC_SET_SLUG,
  payload: {
    slug,
    language
  }
});

export const topicSetContentHandler = (id, data, language) => ({
  type: actionTypes.TOPIC_SET_CONTENT,
  payload: {
    id,
    data,
    language
  }
});

export const topicUpdateCategoryIdsHandler = (categoryId) => ({
  type: actionTypes.TOPIC_UPDATE_CATEGORY_IDS,
  payload: {
    categoryId
  }
});

export const topicUndoChangesHandler = () => ({
  type: actionTypes.TOPIC_UNDO_CHANGES
});

export const topicSetSavingHandler = () => ({
  type: actionTypes.TOPIC_SET_SAVING
});

export const topicResetSavingHandler = () => ({
  type: actionTypes.TOPIC_RESET_SAVING
});

export const topicSetSaveCompletedHandler = () => ({
  type: actionTypes.TOPIC_SET_SAVE_COMPLETED
});

// CATEGORIES ACTIONS //
export const categoriesSetFetchingHandler = () => ({
  type: actionTypes.CATEGORIES_SET_FETCHING
});

export const categoriesResetFetchingHandler = () => ({
  type: actionTypes.CATEGORIES_RESET_FETCHING
});

export const categoriesSetHandler = (categories) => ({
  type: actionTypes.CATEGORIES_SET,
  payload: {
    categories
  }
});

export const categoriesAddHandler = (category) => ({
  type: actionTypes.CATEGORIES_ADD,
  payload: {
    category
  }
});
/////////////////////////
