import React, { useEffect, useReducer } from 'react';
import Header from './Header';
import { v4 } from 'uuid';
import { routes } from '../../../../data/constants';
import { useHistory } from 'react-router-dom';
import Topics from './Topics';
import { initialState, reducer } from '../reducer';
import { dataLoadingHandler, dataSetHandler } from '../actions';
import cogoToast from 'cogo-toast';
import LoadingSkeleton from './LoadingSkeleton';
import NoResults from './NoResults';

export const DataQuery = `
  query {
    faqCategories {
        id
        title
    }

    faqTopics {
        id
        title
        categoryIds
    }
}`;

const Overview = ({ context, currentLanguage, setCurrentLanguage }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  const fetchData = () => {
    dispatch(dataLoadingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: DataQuery })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data) {
          const data = result?.data;
          dispatch(dataSetHandler(data?.faqTopics, data?.faqCategories));
        } else {
          cogoToast.error('No data returned from the server.');
        }
      })
      .catch((err) => {
        cogoToast.error('Something went wrong.');
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onCreateTopic = () => {
    const id = v4();
    history.push(`${routes.FAQ_TOPIC_DETAIL}/${id}`, {
      isCreate: true
    });
  };

  if (state.dataLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <div data-testid='overview-container' className='builder-flex builder-flex-col builder-px-5 builder-pb-5 builder-w-full builder-relative'>
      <Header context={context} onCreateTopic={onCreateTopic} />
      <Topics topics={state.topics} context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} state={state} dispatch={dispatch} />
      {state.topics < 1 && <NoResults />}
    </div>
  );
};

export default Overview;
