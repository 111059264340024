import React, { useState } from 'react';
import { find, map } from 'lodash';

const Dropdown = ({ options, value, renderItem, renderFooter, minWidth, minHeight, maxWidth, borderClassName, formatValue, placeHolder, centerText, defaultBgColor, selectClassName, defaultTextColor, expandedTextColor, expandedBgColor }) => {
  const [expanded, setExpanded] = useState(false);
  const selected = find(options, (item) => item.key === value);

  return (
    <div className='builder-w-full'>
      <div
        className={`builder-flex builder-relative builder-cursor-pointer ${defaultBgColor} ${borderClassName}`}
        style={{ minWidth: minWidth, maxWidth: maxWidth, minHeight: minHeight }}
        onClick={() => {
          setExpanded(!expanded);
        }}
        onMouseLeave={() => setExpanded(false)}
      >
        <div className={`builder-flex builder-flex-1 builder-cursor-pointer ${expanded ? `${expandedBgColor} ${expandedTextColor}` : `${defaultBgColor} ${defaultTextColor}`} ${selectClassName}`}>
          <div className={`builder-flex builder-flex-1 ${centerText ? 'builder-items-center builder-justify-center' : ''}`} style={{ paddingLeft: centerText ? 15 : 0 }}>
            {selected && <span className='builder-flex builder-items-center builder-pl-2 builder-text-sm'>{formatValue ? formatValue(selected.value) : selected.value}</span>}

            {!selected && <span className='builder-flex builder-items-center builder-pl-2 builder-text-sm builder-text-gray-600'>{placeHolder}</span>}
          </div>

          <div className='builder-flex builder-items-center builder-justify-center builder-text-md' style={{ width: 30 }}>
            <i className='fal fa-chevron-down' />
          </div>
        </div>

        {expanded && (
          <div
            className='builder-absolute builder-bg-white builder-border builder-border-gray-300 builder-border-t-0 builder-overflow-y-scroll builder-shadow-md'
            style={{
              top: '93%',
              zIndex: 999,
              left: -1,
              right: -1,
              maxHeight: 180
            }}
          >
            {map(options, (item, index) => {
              return (
                <div
                  key={`dropDownItem${index}`}
                  onClick={(e) => {
                    setExpanded(false);
                  }}
                >
                  {renderItem(item, index)}
                </div>
              );
            })}

            {renderFooter && renderFooter(() => setExpanded(false))}
          </div>
        )}
      </div>
    </div>
  );
};

Dropdown.defaultProps = {
  minWidth: 100,
  minHeight: 30,
  maxWidth: 300,
  borderClassName: 'builder-border builder-border-gray-300',
  placeHolder: 'Select item',
  centerText: false,
  defaultBgColor: 'builder-bg-white',
  expandedBgColor: 'builder-bg-white',
  selectClassName: '',
  defaultTextColor: '',
  expandedTextColor: ''
};

export default Dropdown;
