import { actionTypes } from './reducer';

export const advertisementGroupsSetHandler = (dispatch, advertisementGroups) => {
  dispatch({
    type: actionTypes.ADVERTISEMENT_GROUPS_SET_ALL,
    payload: {
      advertisementGroups
    }
  });
};

export const advertisementGroupsModalHandler = (dispatch, adGroupsModal) => {
  dispatch({
    type: actionTypes.ADVERTISEMENT_GROUPS_MODAL_SET_OPEN,
    payload: {
      adGroupsModal
    }
  });
};

export const advertisementGroupsAddHandler = (dispatch, advertisementGroup) => {
  dispatch({
    type: actionTypes.ADVERTISEMENT_GROUPS_ADD,
    payload: {
      advertisementGroup
    }
  });
};
