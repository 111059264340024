import { pageSetIndexableHandler, pageSetMetaHandler } from '../actions';

export const usePageState = (state, dispatch) => {
  const onMetaUpdate = (language, updatedMeta) => {
    dispatch(pageSetMetaHandler(language, updatedMeta));
  };

  const onIndexableUpdate = (indexable) => {
    dispatch(pageSetIndexableHandler(indexable));
  };

  return {
    onMetaUpdate: (language, updatedMeta) => onMetaUpdate(language, updatedMeta),
    onIndexableUpdate: (indexable) => onIndexableUpdate(indexable)
  };
};
