import React, { useEffect, useReducer, useRef, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './components/common/PrivateRoute';
import { generalSettingsRoutes, routes } from './features/data/constants';
import { initialState, MODAL_TYPES, rootReducer } from './features/data/reducers';

import { initSession, updateSession } from './features/data/session';
import { customBlockCreationHandler, customBlockEditHandler, dataSetHandler, modalCloseHandler, modalOpenHandler, pageCreationHandler, pageSelectHandler, publishLogAddHandler, setSession } from './features/data/actions';
import Login from './features/authentication/Login';
import Page404 from './components/Page404';
import Dealership from './features/settings/dealership/Dealership';
import Dealerships from './features/settings/dealership/Dealerships';
import { initializePusher, userChannel } from './pusher';
import { useManualQuery } from 'graphql-hooks';
import { DataQuery } from './features/data/graphql';
import PageCreateModal from './features/pages/PageCreateModal';
import { LoadingIndicator } from './components/common';
import { filter, find, head, map, values } from 'lodash';
import PublishModal from './features/builder/components/PublishModal';
import Builder from './features/builder/Builder';
import InactivityModal from './features/session/InactivityModal';
import { useSignOut } from './features/authentication/useSignout';
import Template from './components/Template';
import { default as CampaignsOverview } from './features/campaigns/overview/components/Overview';
import { default as CampaignDetail } from './features/campaigns/detail/components/Detail';
import { default as PagesOverview } from './features/pages/Overview';
import { default as BlogOverview } from './features/blog/Overview';
import { default as AdvertisementsOverview } from './features/advertisements/overview/components/Overview';
import { default as AdvertisementDetail } from './features/advertisements/detail/components/Detail';
import { default as BlocksOverview } from './features/blocks/Overview';
import { default as BlocksDetail } from './features/blocks/Detail';
import { default as VehicleFiltersOverview } from './features/filters/overview/components/Overview';
import { default as VehicleFiltersDetail } from './features/filters/detail/components/Detail';
import { default as FaqOverview } from './features/faq/overview/components/Overview';
import { default as TopicsOverview } from './features/faq/topics/overview/components/Overview';
import { default as FaqCategoryDetail } from './features/faq/categories/detail/components/Detail';
import { default as FaqQuestionGroupDetail } from './features/faq/question-groups/detail/components/Detail';
import { default as FaqQuestionDetail } from './features/faq/questions/detail/components/Detail';
import { default as FaqTopicDetail } from './features/faq/topics/detail/components/Detail';
import { default as CustomBlocksOverview } from './features/custom-blocks/overview/components/Overview';
import { default as CustomBlockDetails } from './features/custom-blocks/details/components/Details';
import { default as SeoOverview } from './features/seo/overview/components/Overview';
import { default as PageDetailsContainer } from './features/seo/details/components/PageDetailsContainer';
import { client } from './graphqlClient';
import { DefaultPageTypeNames } from './constants';
import GeneralSettings from './features/settings/general/GeneralSettings';
import Overview from './features/overview/Overview';
import EditorTemplate from './components/EditorTemplate';
import PreviewPage from './features/preview/PreviewPage';

export const StateContext = React.createContext();
export const DispatchContext = React.createContext();
// TODO clean app.js up, split code from userTracking to a custom hook and clean-up code

function App() {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  const [currentLanguage, setCurrentLanguage] = useState(undefined);
  const [fetchData, { loading: initialLoading, error: loadingError }] = useManualQuery(DataQuery);
  const currentState = state.history[state.history.length - 1];
  const [lastReload, setLastReload] = useState(undefined);
  const { signOut } = useSignOut(dispatch);

  const [inactivityTimeRemaining, _setInactivityTimeRemaining] = useState(0);
  const inactivityTimeRemainingRef = useRef(inactivityTimeRemaining);
  const setInactivityTimeRemaining = (val) => {
    _setInactivityTimeRemaining(val);
    inactivityTimeRemainingRef.current = val;
  };

  const interval = useRef(undefined);

  useEffect(() => {
    if (currentLanguage === undefined) {
      const currentState = state.history[state.history.length - 1];
      if (currentState && currentState.languages.length > 0) {
        const language = head(filter(currentState.languages, (l) => l.available));
        setCurrentLanguage(language.code);
      }
    }
  }, [state]);

  useEffect(() => {
    if (userChannel) {
      userChannel.bind('publish_output', function (data) {
        publishLogAddHandler(dispatch, data.message);
      });
    }
  }, [userChannel]);

  const domainName = () => {
    if (state && state.configuration) {
      let domainName = head(state.configuration.website.domains);
      if (domainName) {
        return domainName;
      }
    }

    return '';
  };

  const website = find(state.websites, (w) => w.id === state.currentWebsite);

  const context = {
    culture: currentLanguage,
    builder: true,
    multiLanguage: false,
    mapBoxToken: 'pk.eyJ1IjoiYXV0cmFsaXMiLCJhIjoiY2toeXR2emhwMDk0OTJ6cWh0Y3JjeG5kaSJ9.YCvAWa5Wyqof0wPdueve5w',
    pages: currentState.pages,
    // urls: DefaultPageNames,
    customBlocks: currentState.customBlocks,
    campaigns: currentState.campaigns,
    domainName: domainName(),
    // defaultPageNames: { ...DefaultPageNames },
    defaultPageNames: { ...DefaultPageTypeNames },
    environment: website && !website.isProduction ? 1 : 0
  };

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [state.session]);

  const alertUser = (e) => {
    if (state?.session?.authenticated && !window.location.pathname.includes('/preview/')) {
      signOut(false);
    }
    e.returnValue = '';
  };

  useEffect(() => {
    if (!state.contentTypeDefinitions && state.session && state.session.authenticated) {
      handleFetchWebsiteData();
    }
  }, [state.contentTypeDefinitions, state.session]);

  const handleFetchWebsiteData = (websiteId = undefined) => {
    const options = {};
    if (websiteId) {
      options['variables'] = { websiteId: websiteId };
    }

    const website = find(state.websites, (w) => w.id === websiteId);
    client.setHeader('Environment', !website || (website && website.isProduction) ? '0' : '1');
    fetchData(options).then((result) => {
      dataSetHandler(dispatch, result.data);
    });
  };

  useEffect(() => {
    async function initializeSession() {
      const session = await initSession();
      setSession(dispatch, session);
    }

    initializeSession();
  }, [lastReload]);

  useEffect(() => {
    if (state.configuration && state.session && state.session.user) {
      initializePusher(state.configuration.company_code, state.session.sessionId);
    }
  }, [state.configuration, state.session]);

  const handleAuthenticated = (user, sessionId, otherSessionsCount) => {
    const updatedSession = updateSession(state.session, user, sessionId, otherSessionsCount);
    setSession(dispatch, updatedSession);
  };

  const handleCloseModal = (type) => {
    modalCloseHandler(dispatch, type);
  };

  const handleAddPage = (id, type, path, copyFrom, category, indexable, matchPath) => {
    const existingPage = find(values(currentState.pages), (page) => page.url === path && page.matchPath === matchPath);

    if (!existingPage) {
      pageCreationHandler(dispatch, id, type, path, copyFrom, category, indexable);
      pageSelectHandler(dispatch, id);
    } else {
      pageSelectHandler(dispatch, existingPage.id);
    }
  };

  const handleAddCustomBlock = (addedCustomBlock) => {
    const isExistingCustomBlock = find(currentState?.customBlocks, (customBlock) => customBlock?.name === addedCustomBlock?.id);
    if (isExistingCustomBlock) {
      customBlockEditHandler(dispatch, addedCustomBlock);
    } else {
      customBlockCreationHandler(dispatch, addedCustomBlock);
    }
  };

  const handleCloseInactivityModal = () => {
    if (interval.current) clearInterval(interval.current);
  };

  // Inactivity logic
  useEffect(() => {
    let timeout = null;
    const mouseListener = (e) => {
      if (timeout) clearTimeout(timeout);
      const inactivityPopupCall = () => {
        if (interval.current) clearInterval(interval.current);
        setInactivityTimeRemaining(120);
        interval.current = setInterval(() => {
          setInactivityTimeRemaining(inactivityTimeRemainingRef.current - 1);
          if (inactivityTimeRemainingRef.current <= 0) {
            clearInterval(interval.current);
            signOut(true);
          }
        }, 1000);
        modalOpenHandler(dispatch, MODAL_TYPES.INACTIVITY);
      };

      // detect inactivity after 13 mins then you have 2 mins to respond
      if (state?.session?.authenticated) {
        timeout = setTimeout(inactivityPopupCall, 1000 * 60 * 13);
      }
    };

    document.addEventListener('mousemove', mouseListener);

    // Specify how to clean up after this effect:
    return () => {
      if (timeout) clearTimeout(timeout);
      document.removeEventListener('mousemove', mouseListener);
    };
  }, [state?.session?.authenticated]);

  if (loadingError) {
    return (
      <div className='builder-rounded-md builder-bg-red-50 builder-p-4 builder-m-10'>
        <div className='builder-text-md builder-font-medium builder-text-red-800'>Error loading website. Please contact administrator.</div>
      </div>
    );
  }

  if (initialLoading) {
    return <LoadingIndicator />;
  }

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>
        <Router>
          <React.Fragment>
            {/*ROUTES*/}
            <Switch>
              <Route path={routes.LOGIN}>
                <Login onAuthenticated={handleAuthenticated} />
              </Route>
              <PrivateRoute exact path={routes.HOME}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <Overview context={context} currentLanguage={currentLanguage} setCurrentLanguage={(langCode) => setCurrentLanguage(langCode)} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} />
                </Template>
              </PrivateRoute>
              <PrivateRoute exact path={`${routes.BUILDER}/:id?`}>
                <EditorTemplate onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <Builder context={context} currentLanguage={currentLanguage} setCurrentLanguage={(langCode) => setCurrentLanguage(langCode)} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} />
                </EditorTemplate>
              </PrivateRoute>
              <PrivateRoute exact path={`${routes.PREVIEW}/:id?`}>
                <PreviewPage />
              </PrivateRoute>
              <PrivateRoute path={`${routes.PAGES}`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <PagesOverview context={context} currentLanguage={currentLanguage} setCurrentLanguage={(langCode) => setCurrentLanguage(langCode)} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.BLOG}`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <BlogOverview context={context} currentLanguage={currentLanguage} setCurrentLanguage={(langCode) => setCurrentLanguage(langCode)} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.CAMPAIGNS}`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <CampaignsOverview context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.CAMPAIGN_DETAIL}/:id?`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <CampaignDetail context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.ADVERTISEMENTS}`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <AdvertisementsOverview context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.ADVERTISEMENT_DETAIL}/:id?`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <AdvertisementDetail context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.FAQ_OVERVIEW}`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <FaqOverview context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.FAQ_TOPICS}`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <TopicsOverview context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.FAQ_TOPIC_DETAIL}/:id?`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <FaqTopicDetail context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.FAQ_CATEGORY_DETAIL}/:id?`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <FaqCategoryDetail context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>

              <PrivateRoute path={`${routes.FAQ_QUESTION_GROUP_DETAIL}/:id?`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <FaqQuestionGroupDetail context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>

              <PrivateRoute path={`${routes.FAQ_QUESTION_DETAIL}/:id?`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <FaqQuestionDetail context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>

              <PrivateRoute path={`${routes.CUSTOM_BLOCKS}`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <CustomBlocksOverview context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>

              <PrivateRoute path={`${routes.CUSTOM_BLOCK}/:id?`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <CustomBlockDetails context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} onBlockAdded={(addedBlock) => handleAddCustomBlock(addedBlock)} />
                </Template>
              </PrivateRoute>

              <PrivateRoute path={`${routes.SEO_OVERVIEW}`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <SeoOverview context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>

              <PrivateRoute path={`${routes.SEO_DETAILS}/:id`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <PageDetailsContainer context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>

              <PrivateRoute path={`${routes.BLOCKS}`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <BlocksOverview context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.BLOCKS_DETAIL}/:id?`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <BlocksDetail context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.VEHICLE_FILTERS}`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <VehicleFiltersOverview context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.VEHICLE_FILTERS_DETAIL}/:id?`}>
                <Template onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <VehicleFiltersDetail context={context} />
                </Template>
              </PrivateRoute>

              {map(generalSettingsRoutes, (path) => (
                <PrivateRoute key={path} exact path={path}>
                  <GeneralSettings context={context} />
                </PrivateRoute>
              ))}

              <PrivateRoute path={`${routes.SETTINGS_DEALERSHIPS}`}>
                <Dealerships context={context} />
              </PrivateRoute>
              <PrivateRoute path={`${routes.SETTINGS_DEALERSHIP}/:id`}>
                <Dealership context={context} />
              </PrivateRoute>

              <Route path='*'>
                <Page404 />
              </Route>
            </Switch>

            {/*MODALS*/}
            <PageCreateModal
              isOpen={state.modal && state.modal.type === MODAL_TYPES.PAGE_CREATE}
              type={state.modal && state.modal.payload.type ? state.modal.payload.type : undefined}
              onCreate={(id, type, path, copyFrom, category, indexable, matchPath) => {
                handleAddPage(id, type, path, copyFrom, category, indexable, matchPath);
                handleCloseModal(MODAL_TYPES.PAGE_CREATE);
              }}
              onClose={() => handleCloseModal(MODAL_TYPES.PAGE_CREATE)}
              context={context}
            />

            <PublishModal isOpen={state.modal && state.modal.type === MODAL_TYPES.PUBLISH_WEBSITE} context={context} />
            <InactivityModal isOpen={state.modal && state.modal.type === MODAL_TYPES.INACTIVITY} onClose={handleCloseInactivityModal} timeRemaining={inactivityTimeRemaining} context={context} />
          </React.Fragment>
        </Router>
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
}

export default App;
