import React, { useState } from 'react';
import { get, map, filter } from 'lodash';
import Groups from './Groups';

const GroupSelector = ({ categories, groups, currentLanguage, selectedGroupId, onSelectGroup, handleCreateCategory, handleCreateGroup, errorText }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const renderNoCategories = () => {
    return (
      <React.Fragment>
        <div className='builder-font-bold builder-mt-4'>No categories defined.</div>
        <div className='builder-underline builder-font-semibold builder-cursor-pointer builder-mt-4' onClick={handleCreateCategory}>
          Create new category
        </div>
      </React.Fragment>
    );
  };

  if (categories.length === 0) {
    return renderNoCategories();
  }

  return (
    <div>
      <div data-testid='question-groups-wrapper' className={`builder-p-4 ${errorText ? 'builder-bg-red-50' : 'builder-bg-white'}`}>
        <div className='builder-flex builder-justify-between builder-cursor-pointer' onClick={() => setIsCollapsed(!isCollapsed)}>
          <div className='builder-text-black builder-text-md builder-font-bold'>Groups</div>
          <i className={`fas builder-text-md ${isCollapsed ? 'fa-caret-down' : 'fa-caret-up'}`} />
        </div>

        <div className={`builder-transition-all builder-ease-in builder-transform ${isCollapsed ? 'builder-hidden' : 'builder-block builder-mt-4'}`}>
          {map(categories, (category) => {
            const categoryTitle = get(category.title, currentLanguage);
            const categoryGroups = filter(groups, (group) => group.categoryId === category.id);

            return (
              <div key={`group-selector-${category.id}`} className='builder-mb-4'>
                <div className='builder-text-black builder-text-md builder-font-bold builder-mb-2'>{categoryTitle}</div>
                <Groups groups={categoryGroups} selectedGroupId={selectedGroupId} handleCreateGroup={handleCreateGroup} currentLanguage={currentLanguage} onSelectGroup={onSelectGroup} />
              </div>
            );
          })}

          <div className='builder-flex builder-justify-end builder-mt-6 builder-cursor-pointer builder-font-bold hover:builder-text-gray-600' onClick={handleCreateGroup}>
            <i className='far fa-plus builder-mr-2' /> Add new group
          </div>
        </div>
      </div>
      {errorText && <div className='builder-mt-2 builder-text-sm builder-text-red-500'>{errorText}</div>}
    </div>
  );
};

export default GroupSelector;
