import { overviewActionTypes, detailActionTypes } from './reducers';

export const overviewBlocksSetHandler = (dispatch, blocks) => {
  dispatch({
    type: overviewActionTypes.BLOCKS_SET,
    payload: {
      blocks
    }
  });
};

export const overviewBlockDeleteHandler = (dispatch, id) => {
  dispatch({
    type: overviewActionTypes.BLOCK_DELETE,
    payload: {
      id
    }
  });
};

export const detailBlockSetHandler = (dispatch, block) => {
  dispatch({
    type: detailActionTypes.BLOCK_SET,
    payload: {
      block
    }
  });
};

export const detailFieldTypesSetHandler = (dispatch, fieldTypes) => {
  dispatch({
    type: detailActionTypes.FIELD_TYPES_SET,
    payload: {
      fieldTypes
    }
  });
};

export const blockInitializeHandler = (dispatch, id) => {
  dispatch({
    type: detailActionTypes.BLOCK_INITIALIZE,
    payload: {
      id
    }
  });
};

export const blockSetNameHandler = (dispatch, name) => {
  dispatch({
    type: detailActionTypes.BLOCK_SET_NAME,
    payload: {
      name
    }
  });
};

export const blockSetIsTemplateHandler = (dispatch, isTemplate) => {
  dispatch({
    type: detailActionTypes.BLOCK_SET_IS_TEMPLATE,
    payload: {
      isTemplate
    }
  });
};

export const blockAddFieldHandler = (dispatch, name, type) => {
  dispatch({
    type: detailActionTypes.BLOCK_ADD_FIELD,
    payload: {
      name,
      type
    }
  });
};

export const blockDeleteFieldHandler = (dispatch, id) => {
  dispatch({
    type: detailActionTypes.BLOCK_DELETE_FIELD,
    payload: {
      id
    }
  });
};

export const blockSetFieldNameHandler = (dispatch, id, name) => {
  dispatch({
    type: detailActionTypes.BLOCK_SET_FIELD_NAME,
    payload: {
      id,
      name
    }
  });
};

export const blockUndoChangesHandler = (dispatch) => {
  dispatch({
    type: detailActionTypes.BLOCK_UNDO_CHANGES,
    payload: {}
  });
};
