import React from 'react';
import Form from './Form';
import ButtonWithIndicator from '../../../../../components/common/ButtonWithIndicator';

const CategoryModal = ({ isLoading, languages, formErrors, state, dispatch, onCancel, onSave }) => {
  return (
    <div data-testid='category-modal' className='builder-w-full'>
      <div className='builder-flex builder-flex-col builder-mb-5 builder-relative'>
        <Form isLoading={isLoading} languages={languages} formErrors={formErrors} state={state} dispatch={dispatch} />
      </div>

      <div className='builder-flex builder-justify-end builder-items-center builder-mt-6 builder-space-x-2 builder-rounded-b'>
        <ButtonWithIndicator
          loading={isLoading}
          disabled={isLoading}
          onClick={onCancel}
          icon='fal fa-times'
          text='Cancel'
          colorClass='builder-bg-white builder-text-gray-700'
          borderClass='builder-border builder-border-gray-300'
          className='builder-ml-0 builder-mr-0'
        />

        <ButtonWithIndicator
          loading={isLoading}
          disabled={isLoading}
          onClick={onSave}
          icon='fal fa-save'
          text='Save'
          colorClass='builder-bg-primary builder-text-white'
          borderClass='builder-border builder-border-bg-blue-500'
          className='builder-ml-0 builder-mr-0'
        />
      </div>
    </div>
  );
};

export default CategoryModal;
