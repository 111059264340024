import React, { useState } from 'react';
import { map } from 'lodash';
import Question from './Question';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../data/constants';
import { questionChangePrioritiesHandler, questionSetDeletedHandler } from '../actions';
import cogoToast from 'cogo-toast';

export const FaqQuestionDeleteMutation = `mutation FaqQuestionDelete($data: FaqQuestionDeleteInputType!) {
  faqQuestionDelete(data: $data) {
      ok
  }
}`;

const Questions = ({ questions, categoryId, currentLanguage, state, dispatch }) => {
  const [deletingQuestionId, setDeletingQuestionId] = useState(undefined);
  const history = useHistory();

  const onEditQuestion = (questionId) => {
    history.push(`${routes.FAQ_QUESTION_DETAIL}/${questionId}`);
  };

  const onDeleteQuestion = (questionId, groupId) => {
    setDeletingQuestionId(questionId);
    const variables = {
      data: {
        id: questionId
      }
    };

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: FaqQuestionDeleteMutation, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data?.faqQuestionDelete?.ok) {
          cogoToast.success('Question deleted.');
          dispatch(questionSetDeletedHandler(groupId, questionId));
        } else {
          const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
          cogoToast.error(error);
        }
        setDeletingQuestionId(undefined);
      })
      .catch((err) => {
        cogoToast.error('Something went wrong. The questions could not be deleted.');
        setDeletingQuestionId(undefined);
      });
  };

  const onChangePriority = (draggedQuestionId, targetQuestionId, targetGroupId) => {
    dispatch(questionChangePrioritiesHandler(draggedQuestionId, targetQuestionId, targetGroupId));
  };

  return (
    <div data-testid='questions-container' className='builder-space-y-2'>
      {map(questions, (question, index) => {
        return (
          <Question
            key={question.id}
            index={index}
            categoryId={categoryId}
            question={question}
            onEditQuestion={onEditQuestion}
            onDeleteQuestion={onDeleteQuestion}
            onChangePriority={onChangePriority}
            isDeleting={question.id === deletingQuestionId}
            currentLanguage={currentLanguage}
            state={state}
            dispatch={dispatch}
          />
        );
      })}
    </div>
  );
};

export default Questions;
