import React from 'react';
import SelectBox from './SelectBox';
import { filter } from 'lodash';

const GenericRangeSelect = ({ placeHolderFrom, placeHolderTo, optionsFrom, optionsTo, data, value, handleFilterChanged }) => {
  const valueFrom = value ? { label: value[0] ? value[0] : '' } : '';
  const valueTo = value ? { label: value[1] ? value[1] : '' } : '';

  if (value && value[0]) {
    optionsTo = filter(optionsTo, (y) => y.value >= value[0]);
  }
  if (value && value[1]) {
    optionsFrom = filter(optionsFrom, (y) => y.value <= value[1]);
  }

  return (
    <div className='builder-grid builder-grid-cols-2 builder-w-full '>
      <SelectBox
        value={valueFrom}
        options={optionsFrom}
        className='builder-no-right-rounded builder-no-right-border'
        placeholder={placeHolderFrom}
        isLoading={false}
        onChange={(item) => {
          const start = item.value;
          const stop = value && value[1] && value[1] > item.value ? value[1] : undefined;
          handleFilterChanged([start, stop]);
        }}
      />
      <SelectBox
        value={valueTo}
        options={optionsTo}
        className='builder-no-left-rounded'
        placeholder={placeHolderTo}
        isLoading={false}
        onChange={(item) => {
          const start = value && value[0] && value[0] < item.value ? value[0] : undefined;
          const stop = item.value;
          handleFilterChanged([start, stop]);
        }}
      />
    </div>
  );
};

export default GenericRangeSelect;
