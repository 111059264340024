import React from 'react';
import { times } from 'lodash';

const LoadingSkeleton = () => {
  return (
    <div data-testid='page-types-loading-skeleton' className='builder-w-full builder-space-y-2'>
      <div className='builder-bg-gray-100 builder-transition-all builder-animate-pulse builder-h-12' />
      {times(15, (item) => {
        return <div key={item} className='builder-bg-gray-100 builder-transition-all builder-animate-pulse builder-h-[100px]' />;
      })}
    </div>
  );
};

export default LoadingSkeleton;
