import React, { useState } from 'react';
import { isImage, isVideo } from '../../../../utils';
import FileUrlFieldEditor from '../../../builder/editors/FileUrlFieldEditor';
import cogoToast from 'cogo-toast';
import ReactPlayer from 'react-player';

const FileUploader = ({ context }) => {
  const [url, setUrl] = useState('');

  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(url);
      cogoToast.success('Copied.');
    } catch (e) {
      cogoToast.error('Could not copy');
    }
  };

  return (
    <div>
      <div className='builder-flex builder-flex-col builder-space-y-6' style={{ width: 300 }}>
        <FileUrlFieldEditor context={context} type='image' onChange={(imageUrl) => setUrl(imageUrl)} />
        <FileUrlFieldEditor context={context} type='video' onChange={(imageUrl) => setUrl(imageUrl)} />
      </div>
      {url && url !== '' && (
        <div className='builder-mt-5 builder-pt-5 builder-border-t builder-border-gray-400'>
          <div className='builder-flex builder-items-center builder-space-x-2'>
            <span className='builder-font-semibold'>{url}</span>
            <span className='builder-cursor-pointer hover:builder-text-gray-700' onClick={handleCopy}>
              <i className='fal fa-copy' /> Copy
            </span>
          </div>

          {url && url !== '' && isImage(url) && <img src={url} style={{ maxWidth: 500 }} alt={url} className='builder-mt-4' />}
          {url && url !== '' && isVideo(url) && (
            <div className='builder-mt-4'>
              <ReactPlayer url={url} playing={false} controls />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default FileUploader;
