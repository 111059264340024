import { filter, find, includes, map } from 'lodash';
import { formattedRedirectsFromFile } from '../utils';

export const actionTypes = {
  DATA_SET_LOADING: 'DATA_SET_LOADING',
  DATA_RESET_LOADING: 'DATA_RESET_LOADING',
  DATA_SET: 'DATA_SET',
  PAGES_SET: 'PAGES_SET',

  REDIRECTS_FROM_FILE_SET: 'REDIRECTS_FROM_FILE_SET',
  REDIRECT_FROM_FILE_REMOVED: 'REDIRECT_FROM_FILE_REMOVED',
  REDIRECT_FROM_FILE_ADDED: 'REDIRECT_FROM_FILE_ADDED',

  REDIRECT_DELETED: 'REDIRECT_DELETED',
  REDIRECT_ADD: 'REDIRECT_ADD',

  REDIRECT_BULK_SET_SAVING: 'REDIRECT_BULK_SET_SAVING',
  REDIRECT_BULK_RESET_SAVING: 'REDIRECT_BULK_RESET_SAVING',
  REDIRECT_BULK_SET_SAVED: 'REDIRECT_BULK_SET_SAVED',

  REDIRECT_BULK_SET_DELETING: 'REDIRECT_BULK_SET_DELETING',
  REDIRECT_BULK_RESET_DELETING: 'REDIRECT_BULK_RESET_DELETING',
  REDIRECT_BULK_SET_DELETED: 'REDIRECT_BULK_SET_DELETED',

  REDIRECT_SET_TO_DELETE: 'REDIRECT_SET_TO_DELETE',
  REDIRECT_TOGGLE_DELETE_ALL: 'REDIRECT_TOGGLE_DELETE_ALL',

  ONLINE_STATUS_CODES_SET_FETCHING: 'ONLINE_STATUS_CODES_SET_FETCHING',
  ONLINE_STATUS_CODES_RESET_FETCHING: 'ONLINE_STATUS_CODES_RESET_FETCHING',
  ONLINE_STATUS_CODES_SET_HANDLER: 'ONLINE_STATUS_CODES_SET_HANDLER'
};

export const initialState = {
  loading: false,
  bulkSaving: false,
  bulkDeleting: false,
  fetchingOnlineStatusCodes: false,
  redirects: [],
  pages: [],
  redirectsFromFile: [],
  notUpdatedRedirectsFromFile: [],
  redirectIdsToDelete: []
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.DATA_SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case actionTypes.DATA_RESET_LOADING:
      return {
        ...state,
        loading: false
      };
    case actionTypes.DATA_SET:
      return {
        ...state,
        loading: false,
        redirects: action.payload.redirects
      };

    case actionTypes.PAGES_SET:
      return {
        ...state,
        pages: map(action.payload.pages, (page) => {
          return {
            ...page,
            slug: JSON.parse(page.slug)
          };
        })
      };

    case actionTypes.REDIRECTS_FROM_FILE_SET:
      return {
        ...state,
        loading: false,
        redirectsFromFile: formattedRedirectsFromFile(action.payload.redirectsFromFile),
        notUpdatedRedirectsFromFile: []
      };

    case actionTypes.REDIRECT_DELETED:
      return {
        ...state,
        redirects: filter(state.redirects, (redirect) => redirect.id !== action.payload.redirectId)
      };

    case actionTypes.REDIRECT_FROM_FILE_REMOVED:
      return {
        ...state,
        redirectsFromFile: filter(state.redirectsFromFile, (redirectFromFile) => redirectFromFile.id !== action.payload.redirectFromFileId)
      };

    case actionTypes.REDIRECT_FROM_FILE_ADDED:
      return {
        ...state,
        redirectsFromFile: filter(state.redirectsFromFile, (redirectsFromFile) => redirectsFromFile.id !== action.payload.redirectFromFile.id),
        redirects: [...state.redirects, action.payload.redirectFromFile]
      };

    case actionTypes.REDIRECT_ADD:
      const redirectId = action.payload.redirect.id;
      const isExistingRedirect = find(state.redirects, (pt) => pt.id === redirectId);
      let newRedirects = [];

      if (isExistingRedirect) {
        newRedirects = map(state.redirects, (redirect) => {
          if (redirect.id === redirectId) {
            return {
              id: action.payload.redirect.id,
              type: action.payload.redirect.type,
              fromUrl: action.payload.redirect.fromUrl,
              fromPath: action.payload.redirect.fromPath,
              fromUrlStatusCode: action.payload.redirect.fromUrlStatusCode,
              toUrl: action.payload.redirect.toUrl,
              toPath: action.payload.redirect.toPath,
              toUrlStatusCode: action.payload.redirect.toUrlStatusCode
            };
          }
          return redirect;
        });
      } else {
        newRedirects = [action.payload.redirect, ...state.redirects];
      }
      return {
        ...state,
        redirects: newRedirects
      };

    case actionTypes.REDIRECT_BULK_SET_SAVING:
      return {
        ...state,
        bulkSaving: true
      };

    case actionTypes.REDIRECT_BULK_RESET_SAVING:
      return {
        ...state,
        bulkSaving: false
      };

    case actionTypes.REDIRECT_BULK_SET_DELETING:
      return {
        ...state,
        bulkDeleting: true
      };
    case actionTypes.REDIRECT_BULK_RESET_DELETING:
      return {
        ...state,
        bulkDeleting: false
      };
    case actionTypes.REDIRECT_BULK_SET_SAVED:
      const newlyAddedRedirects = filter(state.redirectsFromFile, (redirect) => includes(action.payload.updatedRedirectIds, redirect.id));
      const updatedRedirectsFromFile = filter(state.redirectsFromFile, (redirect) => !includes(action.payload.updatedRedirectIds, redirect.id));

      return {
        ...state,
        bulkSaving: false,
        redirects: [...state.redirects, ...newlyAddedRedirects],
        redirectsFromFile: updatedRedirectsFromFile,
        notUpdatedRedirectsFromFile: action.payload.notUpdatedRedirectIdsWithError
      };

    case actionTypes.REDIRECT_BULK_SET_DELETED:
      return {
        ...state,
        bulkDeleting: false,
        redirects: filter(state.redirects, (redirect) => !includes(state.redirectIdsToDelete, redirect.id)),
        redirectIdsToDelete: []
      };

    case actionTypes.REDIRECT_SET_TO_DELETE:
      let doesExist = find(state.redirectIdsToDelete, (id) => id === action.payload.id);

      return {
        ...state,
        redirectIdsToDelete: doesExist ? filter(state.redirectIdsToDelete, (id) => id !== action.payload.id) : [...state.redirectIdsToDelete, action.payload.id]
      };
    case actionTypes.REDIRECT_TOGGLE_DELETE_ALL:
      const deleteAll = action.payload.value;

      return {
        ...state,
        redirectIdsToDelete: deleteAll ? map(state.redirects, (redirect) => redirect.id) : []
      };

    case actionTypes.ONLINE_STATUS_CODES_SET_FETCHING:
      return {
        ...state,
        fetchingOnlineStatusCodes: true
      };
    case actionTypes.ONLINE_STATUS_CODES_RESET_FETCHING:
      return {
        ...state,
        fetchingOnlineStatusCodes: false
      };

    case actionTypes.ONLINE_STATUS_CODES_SET_HANDLER:
      return {
        ...state,
        redirects: map(state.redirects, (redirect) => {
          const foundStatus = find(action.payload.onlineStatusCodes, (onlineStatus) => onlineStatus.id === redirect.id);
          return foundStatus
            ? {
                ...redirect,
                fromUrlStatusCode: foundStatus.fromUrlStatusCode,
                toUrlStatusCode: foundStatus.toUrlStatusCode
              }
            : { ...redirect };
        })
      };

    default:
      return state;
  }
};
