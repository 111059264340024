import React, { useState } from 'react';
import { filter, forEach, keys, map } from 'lodash';

const CopyContentFrom = ({ contentType, allPages, currentPage, language, onCopy, isSaving }) => {
  const [open, setOpen] = useState(false);

  const handleClick = (option) => {
    onCopy(option.data);
  };
  const getCopyContent = () => {
    let result = {};
    const pages = filter(allPages, (p) => p.id !== currentPage.id);

    forEach(pages, (page, i) => {
      const blocksOfType = filter(page.blocks, (block) => block.contentType === contentType);

      forEach(blocksOfType, (block, i) => {
        result = {
          ...result,
          [page.url]: [
            {
              number: i,
              data: block
            }
          ]
        };
      });
    });
    return result;
  };
  const getOptions = (content) => {
    const options = [];
    forEach(keys(content), (page) => {
      forEach(content[page], (block) => {
        options.push({
          data: block.data,
          value: block.number > 0 ? `${page}/block_${block.number}` : `${page}`
        });
      });
    });
    return options;
  };

  const copyContent = getCopyContent();
  const options = getOptions(copyContent);

  if (options.length === 0) {
    return null;
  }

  return (
    <div className='builder-flex builder-flex-1 builder-relative' onMouseLeave={() => setOpen(false)}>
      <div
        className={`builder-flex builder-items-center builder-px-4 builder-py-2 builder-border builder-border-gray-200 builder-shadow-sm builder-text-gray-800 ${isSaving ? 'builder-pointer-events-none' : 'builder-cursor-pointer'}`}
        onClick={() => setOpen(!open)}
      >
        <i className='fal fa-copy builder-mr-2' /> Copy from
      </div>

      {open && (
        <div className='builder-absolute builder-bg-white builder-border builder-border-gray-200 builder-shadow-sm builder-p-4' style={{ width: '100%', top: 45, zIndex: 777, left: -1, right: 2 }}>
          <span className='builder-font-bold builder-text-sm'>Copy content from page:</span>
          <div className='builder-space-y-1 builder-mt-4'>
            {map(options, (option) => {
              return (
                <div className='builder-h-6 hover:builder-bg-gray-100' onClick={() => handleClick(option)}>
                  {option.value}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default CopyContentFrom;
