import React, { useContext } from 'react';
import { find, indexOf, lowerCase, map } from 'lodash';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import TextInput from '../../../builder/editors/components/TextInput';
import TextArea from '../../../builder/editors/components/TextArea';
import { StateContext } from '../../../../App';
import { useLocation } from 'react-router-dom';

const PageMeta = ({ page, onMetaUpdate, isLoading, context }) => {
  const globalState = useContext(StateContext);
  const location = useLocation();

  const pageLanguage = location?.state?.language ? location.state.language : context.culture;
  const languages = globalState?.configuration?.website?.languages;

  const tabClassNames = 'relative -bottom-px builder-p-3 builder-cursor-pointer builder-text-black builder-text-md builder-uppercase focus:outline-none';
  const activeTabClassNames = 'builder-font-bold builder-bg-white builder-border-t builder-border-r builder-border-l builder-border-b-0 builder-rounded-sm builder-border-gray-300';
  const tabPanelClassNames = 'builder-bg-white builder-border-l builder-border-r builder-border-gray-300';

  return (
    <>
      <Tabs defaultIndex={indexOf(languages, pageLanguage)} className='builder-h-full'>
        <TabList className='builder-flex builder-flex-row builder-justify-start builder-align-center builder-border-b builder-border-gray-300'>
          {map(languages, (language) => (
            <Tab key={`page-seo-tab-list-language-${language}`} className={tabClassNames} selectedClassName={activeTabClassNames}>
              {language}
            </Tab>
          ))}
        </TabList>

        {map(languages, (language) => {
          const currentPageMeta = find(page?.meta, (meta) => lowerCase(meta.language) === lowerCase(language));
          return (
            <TabPanel key={`seo-page-tab-panel-language-${language}`} className={tabPanelClassNames}>
              <div className='builder-p-6 builder-border-b builder-border-gray-300'>
                <div className='builder-flex builder-flex-col builder-mb-4'>
                  <div className='builder-text-black builder-text-md builder-font-bold builder-mb-2'>Meta title</div>
                  <TextInput
                    dataTestId='page-seo-detail-meta-title-input'
                    placeHolder='Add description'
                    disabled={isLoading}
                    className='builder-flex builder-flex-1 builder-border builder-w-full builder-rounded-md builder-border-gray-300'
                    value={currentPageMeta?.title}
                    onChanged={(value) =>
                      onMetaUpdate(language, {
                        ...currentPageMeta,
                        title: value
                      })
                    }
                  />
                </div>

                <div className='builder-flex builder-flex-col'>
                  <div className='builder-text-black builder-text-md builder-font-bold builder-mb-2'>Meta description</div>
                  <TextArea
                    value={currentPageMeta?.description}
                    dataTestId='page-seo-detail-meta-description-input'
                    placeHolder='Add description'
                    className='builder-flex builder-flex-1 builder-border builder-w-full builder-rounded-md builder-border-gray-300'
                    rows={10}
                    disabled={isLoading}
                    onChange={(value) =>
                      onMetaUpdate(language, {
                        ...currentPageMeta,
                        description: value
                      })
                    }
                  />
                </div>
              </div>
            </TabPanel>
          );
        })}
      </Tabs>
    </>
  );
};

export default PageMeta;
