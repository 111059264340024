import React from 'react';
import { useSignOut } from './useSignout';

const SignOutButton = () => {
  const { signOut } = useSignOut();

  return (
    <div
      className='builder-flex builder-px-4 builder-py-2 builder-ml-2 builder-text-gray-700 builder-border builder-border-transparent builder-text-sm builder-items-center builder-cursor-pointer hover:builder-text-gray-900 builder-text-gray-600 builder-font-thin'
      onClick={signOut}
    >
      <div>Logout</div>
      <i className='fal fa-sign-out builder-ml-2  builder-text-xs' />
    </div>
  );
};

export default SignOutButton;
