import React from 'react';
import UseBuilderActions from '../../../builder/useBuilderActions';
import ButtonWithIndicator from '../../../../components/common/ButtonWithIndicator';

const Release = ({ settings, isSuperUser, onChange, data, context }) => {
  const { executeScript, loading } = UseBuilderActions();

  if (!isSuperUser) {
    return <div> not allowed </div>;
  }

  return (
    <div>
      <div className='builder-flex builder-flex-col builder-space-y-4'>
        <div className='builder-w-1/5'>
          <ButtonWithIndicator loading={loading} onClick={() => executeScript('update', 'all')} text='Update customer' />
        </div>

        <div className='builder-w-1/5'>
          <ButtonWithIndicator loading={loading} onClick={() => executeScript('release', 'website')} text='Release website' />
        </div>

        <div className='builder-w-1/5'>
          <ButtonWithIndicator loading={loading} onClick={() => executeScript('release', 'cms')} text='Release cms' />
        </div>

        <div className='builder-w-1/5'>
          <ButtonWithIndicator loading={loading} onClick={() => executeScript('release', 'builder')} text='Release builder' />
        </div>

        <div className='builder-w-1/5'>
          <ButtonWithIndicator loading={loading} onClick={() => executeScript('release', 'all')} text='Release all' />
        </div>
      </div>
    </div>
  );
};

export default Release;
