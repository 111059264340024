import React from 'react';
import Select, { components } from 'react-select';

const SelectBox = ({ value, onChange, options, isDisabled, className, placeholder, isLoading, isMulti, customComponents, closeMenuOnSelect }) => {
  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <i className={`${props.selectProps.menuIsOpen ? 'fas fa-angle-up' : 'fas fa-angle-down'} text-secondary-two`} />
        </components.DropdownIndicator>
      )
    );
  };

  const formatGroupLabel = (data) => (
    <div>
      <span className='font-secondary font-semibold text-primary text-12'>- {data.label}</span>
      {/*<span>{data.options.length}</span>*/}
    </div>
  );

  const Components = customComponents ? { DropdownIndicator, ...customComponents } : { DropdownIndicator };

  return (
    <Select
      value={value}
      onChange={onChange}
      options={options}
      classNamePrefix='builder-select'
      className={`text-14 font-secondary text-primary ${className}`}
      components={Components}
      formatGroupLabel={formatGroupLabel}
      isDisabled={isDisabled}
      placeholder={placeholder}
      isLoading={isLoading}
      closeMenuOnSelect={closeMenuOnSelect}
      isMulti={isMulti}
      styles={{
        indicatorSeparator: (styles) => ({ display: 'none' }),
        indicatorContainer: (styles) => ({ marginTop: '1px' })
      }}
    />
  );
};

SelectBox.defaultProps = {
  value: null,
  options: [],
  isDisabled: false,
  className: '',
  placeholder: 'Select...',
  isLoading: false,
  customComponents: null,
  isMulti: false,
  closeMenuOnSelect: true
};

export default SelectBox;
