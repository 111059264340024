import React, { useRef } from 'react';
import { DND_TYPES } from '../../../../constants';
import { useDrag, useDrop } from 'react-dnd';
import { get } from 'lodash';
import Row from './Row';

const Question = ({ question, currentLanguage, index, categoryId, state, dispatch, onEditQuestion, onDeleteQuestion, onChangePriority, isDeleting }) => {
  const groupId = question.groupId;
  const questionId = question.id;
  const questionTitle = get(question.title, currentLanguage);

  const dndQuestionRef = useRef(null);
  // Only accepts drag and drop inside the same group.
  // const acceptedDragAndDropType = `${DND_TYPES.QUESTION}-${groupId}`;
  const acceptedDragAndDropType = `${DND_TYPES.QUESTION}`;

  // Droppable
  const [{ handlerId, isOver }, drop] = useDrop({
    accept: acceptedDragAndDropType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: !!monitor.isOver()
      };
    },
    drop(item, monitor) {
      if (!dndQuestionRef.current) {
        return;
      }

      const targetGroupId = groupId;

      const draggedQuestionId = monitor.getItem().questionId;
      const targetQuestionId = questionId;

      const hoverIndex = index;
      // Don't replace items with themselves
      if (targetQuestionId === draggedQuestionId) {
        return;
      }

      // Time to actually perform the action
      if (isOver) {
        // onChangePriority(dragIndex, hoverIndex, groupId, categoryId);
        onChangePriority(draggedQuestionId, targetQuestionId, targetGroupId);
      }
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  // Draggable
  const [{ isDragging }, drag] = useDrag({
    type: acceptedDragAndDropType,
    item: () => {
      return { index, groupId: groupId, questionId: questionId };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  drag(drop(dndQuestionRef));

  const handleEditQuestion = (e) => {
    e.stopPropagation();
    onEditQuestion(questionId);
  };

  const handleDeleteQuestion = (e) => {
    e.stopPropagation();
    onDeleteQuestion(questionId, groupId);
  };

  return (
    <div ref={dndQuestionRef} data-handler-id={handlerId}>
      {isDeleting && <div data-testid='loading-overlay' className='builder-absolute builder-inset-0 builder-bg-gray-600 builder-opacity-30 builder-z-50' />}
      <div className='builder-flex builder-items-center builder-w-full'>
        <Row title={questionTitle} onEdit={handleEditQuestion} onDelete={handleDeleteQuestion} canBeDeleted={true} isOver={isOver} isDragging={isDragging} />
      </div>
    </div>
  );
};

export default Question;
