import React, { useContext, useState } from 'react';
import { find, map } from 'lodash';
import { StateContext } from '../../../../App';
import { routes } from '../../../data/constants';
import { useHistory } from 'react-router-dom';

const CustomBlock = ({ customBlock, onHandleDeletePopup, context }) => {
  const contextState = useContext(StateContext);
  const languages = contextState?.configuration?.website?.languages;
  const history = useHistory();

  const columnClassName = 'builder-flex builder-items-center builder-px-4 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black';

  const onEditCustomBlock = (id, language) => {
    history.push(`${routes.CUSTOM_BLOCK}/${id}`, {
      language: language
    });
  };

  return (
    <div className={`builder-flex builder-w-full builder-items-center builder-transition-all builder-transform builder-relative`}>
      <div className={`${columnClassName} builder-relative`} style={{ width: '50%', maxWidth: '50%' }}>
        <span className=''>{customBlock?.description}</span>
      </div>

      {map(languages, (language) => {
        const hasCustomBlockForLanguage = !!find(customBlock.content, (content) => content.culture === language);
        const iconClassName = hasCustomBlockForLanguage ? 'fal fa-check builder-text-green-500' : 'fal fa-times builder-text-red-500';

        return (
          <div key={`custom-block-language-${language}`} className={columnClassName} style={{ width: '10%', maxWidth: '10%' }}>
            <i className={`${iconClassName} builder-ml-1`} style={{ fontSize: 12 }} />
          </div>
        );
      })}

      <div className='builder-flex builder-items-center'>
        {map(languages, (language) => {
          return (
            <div key={`custom-block-button-language-${language}`} className='builder-flex builder-items-center'>
              <div className='builder-cursor-pointer hover:builder-text-gray-800 builder-uppercase' onClick={() => onEditCustomBlock(customBlock.id, language)}>
                <span>{language}</span> <i className='far fa-pencil builder-ml-2' style={{ fontSize: 11 }} />
              </div>
              <div className='builder-mx-4'>|</div>
            </div>
          );
        })}
        <div data-testid='custom-block-delete-button' className='builder-cursor-pointer hover:builder-text-gray-800' onClick={() => onHandleDeletePopup(customBlock.id)}>
          Delete <i className='fal fa-trash-alt builder-ml-1' style={{ fontSize: 11 }} />
        </div>
      </div>
    </div>
  );
};

export default CustomBlock;
