import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { findKey, get, map } from 'lodash';
import TextInput from '../../../../builder/editors/components/TextInput';
import { categorySetImageHandler, categorySetSlugHandler, categorySetTitleHandler } from '../actions';
import JsonImageFieldEditor from '../../../../builder/editors/JsonImageFieldEditor';

const Form = ({ isLoading, languages, formErrors, state, dispatch }) => {
  const tabClassNames =
    'relative -bottom-px builder-p-3 builder-cursor-pointer builder-text-black builder-text-md builder-uppercase focus:outline-none builder-transition-all builder-border-t builder-border-r builder-border-l builder-border-b-0 builder-border-transparent';
  const activeTabClassNames = 'builder-font-bold builder-bg-white builder-border-t builder-border-r builder-border-l builder-border-b-0 builder-rounded-sm builder-border-gray-300';
  const tabPanelClassNames = 'builder-bg-white builder-border-l builder-border-r builder-border-gray-300';

  const category = state.category;

  return (
    <div>
      {isLoading && <div className='builder-absolute builder-inset-0 builder-bg-gray-200 builder-z-20 builder-opacity-30' />}
      <Tabs defaultIndex={0}>
        <TabList className='builder-flex builder-flex-row builder-justify-start builder-align-center builder-border-b builder-border-gray-300'>
          {map(languages, (language) => {
            const hasErrors = findKey(formErrors, (value, key) => key.endsWith(`-${language}`));
            const className = `${tabClassNames} ${hasErrors ? 'builder-bg-red-500' : ''}`;
            return (
              <Tab key={language} className={className} selectedClassName={activeTabClassNames}>
                {language}
              </Tab>
            );
          })}
        </TabList>

        {map(languages, (language) => {
          const categoryTitle = get(category.title, language);
          const categorySlug = get(category.slug, language);
          return (
            <TabPanel key={`content-${language}`} className={tabPanelClassNames}>
              <div className='builder-flex builder-flex-col builder-p-4 builder-space-y-2 builder-border-b builder-border-gray-300'>
                {/*TITLE*/}
                <div>
                  <TextInput
                    placeHolder='Add category title'
                    label='Title'
                    dataTestId='category-input-title'
                    errorText={formErrors[`title-${language}`]}
                    className='builder-flex builder-flex-1 builder-max-w-xl builder-rounded-md'
                    value={categoryTitle}
                    onChanged={(value) => dispatch(categorySetTitleHandler(value, language))}
                  />
                </div>

                {/*SLUG*/}
                <div>
                  <TextInput
                    placeHolder='Add category slug'
                    label='Slug'
                    errorText={formErrors[`slug-${language}`]}
                    className='builder-flex builder-flex-1 builder-max-w-xl builder-rounded-md'
                    value={categorySlug}
                    onChanged={(value) => dispatch(categorySetSlugHandler(value, language))}
                  />
                </div>

                {/*IMAGE*/}
                <div className='builder-max-w-xl'>
                  <div className='builder-text-black builder-text-md builder-font-bold builder-mb-2'>Image</div>
                  <JsonImageFieldEditor value={category.image} type='image' className='builder-bg-white' onChange={(imagePathsPerDevice) => dispatch(categorySetImageHandler(imagePathsPerDevice))} definition={{}} onBack={() => {}} />
                </div>
              </div>
            </TabPanel>
          );
        })}
      </Tabs>
    </div>
  );
};

export default Form;
