import { actionTypes } from './reducer';

export const pagesSetLoadingHandler = () => ({
  type: actionTypes.PAGES_SET_LOADING
});

export const pagesResetLoadingHandler = () => ({
  type: actionTypes.PAGES_RESET_LOADING
});

export const pagesSetHandler = (pages) => ({
  type: actionTypes.PAGES_SET,
  payload: {
    pages
  }
});

export const toggleCollapseHandler = () => ({
  type: actionTypes.TOGGLE_COLLAPSE
});
