import React, { useState, useEffect, useContext } from 'react';
import { filter, find, forEach, has, map, sortBy, upperFirst, values } from 'lodash';
import CopyContentFrom from './CopyContentFrom';
import { definitionsForContentType, getBuilderFields, getBuilderFieldsForField, getContentForField, getDefaultPropsForField, getHumanText } from '../../../utils';
import FieldEditor from './FieldEditor';
import DistributeContentTo from './DistributeContentTo';
import { StateContext } from '../../../App';
import Dropdown from '../../../components/common/Dropdown';
import Toggle from 'react-toggle';
import CopyBlockContent from './CopyBlockContent';

const mapDefinitions = (builderFields, definitions, currentLanguage) => {
  if (!builderFields) return definitions;

  let allBuilderFieldsHavePriority = true;

  forEach(builderFields, (value, key) => {
    if (!has(value, 'priority')) {
      allBuilderFieldsHavePriority = false;
    }
  });

  if (!allBuilderFieldsHavePriority) return definitions;

  // Add priority and humanName to definitions
  const updatedDefinitions = definitions.map((definition) => {
    const builderField = builderFields[definition.name];
    if (builderField) {
      return {
        ...definition,
        priority: builderField?.priority,
        label: builderField?.[currentLanguage]?.humanName // Assuming you want to update with the English humanName
      };
    }
    return definition;
  });

  // Sort definitions by priority
  return sortBy(updatedDefinitions, 'priority');
};

const BlockEditor = ({ block, pages, campaigns, currentPage, currentLanguage, contentTypeDefinitions, onCopyBlock, onCopyBlockContentItem, onCopyBlockToAll, onContentChange, onCampaignChange, onHideForCampaignChange, isSaving, context }) => {
  const [currentField, setCurrentField] = useState(undefined);
  const [isOpen, setIsOpen] = useState(true);
  const state = useContext(StateContext);
  const currentWebsite = find(state.websites, (w) => w.id === state.currentWebsite);
  const isCurrentSiteProduction = currentWebsite ? currentWebsite.isProduction : false;
  const getAvailableCampaigns = () => {
    if (isCurrentSiteProduction) {
      return filter(campaigns, (c) => !c.disabled && !c.isDraft);
    } else {
      return filter(campaigns, (c) => !c.disabled && c.isDraft);
    }
  };

  useEffect(() => {
    setCurrentField(undefined);
  }, [block.componentName]);

  const definitions = definitionsForContentType(contentTypeDefinitions, block.contentType);
  // if(definitions.length === 1 && currentField === undefined) {
  //     setCurrentField(definitions[0])
  // }

  const builderFields = getBuilderFields(block.contentType);

  const mappedDefinitions = mapDefinitions(builderFields, definitions, currentLanguage);

  const availableCampaigns = getAvailableCampaigns();
  const availableCampaignOptions = availableCampaigns.map((c) => ({
    key: c.id,
    value: c.name
  }));
  availableCampaignOptions.unshift({ key: null, value: 'Clear section' });

  return (
    <React.Fragment>
      {!currentField && (
        <div className=''>
          <div className='builder-flex builder-flex-col'>
            <div className={`builder-pt-2 builder-pb-4 ${isOpen ? 'builder-border-b builder-border-gray-300 builder-shadow-sm' : 'builder-mb-2'}`}>
              <div className='builder-flex builder-justify-between builder-items-center builder-cursor-pointer' onClick={() => setIsOpen(!isOpen)}>
                <h3 className='builder-font-bold builder-text-lg builder-ml-5'>{getHumanText(block.componentName, 'en')}</h3>
                <i className={`fas builder-mr-5 builder-text-md ${isOpen ? 'fa-caret-up' : 'fa-caret-down'}`} />
              </div>
              <div className='builder-flex builder-justify-between builder-px-5 builder-mt-2'>
                <div className='builder-flex'>
                  <div className='builder-mr-2'>
                    <DistributeContentTo onClick={() => onCopyBlockToAll(block, currentLanguage)} isSaving={isSaving} />
                  </div>

                  <CopyContentFrom contentType={block.contentType} allPages={pages} currentPage={currentPage} language={currentLanguage} onCopy={(copyBlock) => onCopyBlock(block.id, copyBlock, currentLanguage)} isSaving={isSaving} />
                </div>
                <div>
                  <CopyBlockContent blockContentArray={block.content} language={currentLanguage} onCopy={(fromCulture) => onCopyBlockContentItem(block.id, fromCulture, currentLanguage)} />
                </div>
              </div>
            </div>
          </div>
          <div className={`${isOpen ? 'builder-flex builder-flex-col ' : 'builder-hidden'}`}>
            <div className='builder-p-5'>
              {map(mappedDefinitions, (definition, i) => {
                const isLast = i === definitions.length - 1;
                return (
                  <div
                    key={`${definition.name}_${i}`}
                    className={`builder-flex builder-items-center builder-justify-between builder-cursor-pointer builder-p-2 hover:builder-bg-gray-100 ${isLast ? '' : 'builder-mb-6 builder-border-b'}`}
                    onClick={() => setCurrentField(definition)}
                  >
                    <div className='builder-flex builder-flex-col'>
                      <label className='builder-block builder-text-black builder-text-md builder-font-bold '>{upperFirst(definition.label)}</label>
                      {definition.description && <div className='builder-italic builder-text-sm builder-text-gray-700 builder-mt-2'>{definition.description}</div>}
                    </div>
                    <i className='fal fa-chevron-right builder-text-gray-400' style={{ fontSize: 14 }} />
                  </div>
                );
              })}
            </div>

            {availableCampaigns.length > 0 && (
              <div className='builder-flex builder-flex-col builder-bg-gray-100 builder-py-5 builder-border-t builder-border-gray-300'>
                <div className='builder-font-bold mb-2 builder-text-black builder-px-5'>Campaign</div>

                <div className='builder-flex builder-flex-1 builder-px-5'>
                  <Dropdown
                    options={availableCampaignOptions}
                    value={block.campaignId}
                    maxWidth='100%'
                    placeHolder='Select a campaign'
                    borderClassName='builder-border builder-border-gray-300 builder-rounded-md'
                    selectClassName='builder-py-2 builder-rounded-md'
                    renderItem={(item) => {
                      return (
                        <div className={`builder-flex builder-justify-between builder-p-2 ${!item.key ? ' builder-bg-gray-200 builder-text-gray-500' : ''}`} onClick={() => onCampaignChange(block.id, item.key)}>
                          <span className='builder-text-sm'>{item.value}</span>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className='builder-flex builder-w-full builder-mb-4 builder-mt-4 builder-px-5'>
                  <div className='builder-flex builder-flex-col builder-flex-1 builder-justify-center builder-ml-2'>Hide this component during campaign</div>
                  <div className='builder-flex builder-flex-col builder-justify-center builder-items-end'>
                    <Toggle
                      defaultChecked={false}
                      checked={block.hideForCampaign}
                      onChange={(e) => {
                        onHideForCampaignChange(block.id, e.target.checked);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {currentField && (
        <React.Fragment>
          <FieldEditor
            definition={currentField}
            block={block}
            value={getContentForField(block, currentField.name, currentLanguage)}
            builderFields={getBuilderFieldsForField(block, currentField.name, currentLanguage)}
            defaultProps={getDefaultPropsForField(block, currentField.name)}
            onChange={(field, value) => onContentChange(block.id, field, value, currentLanguage)}
            context={{ ...context, pages: values(pages), campaigns: campaigns }}
            breadcrumbs={[getHumanText(block.componentName, 'en'), upperFirst(currentField.label)]}
            onBack={(e) => {
              setCurrentField(undefined);
            }}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default BlockEditor;
