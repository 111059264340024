// INITIAL STATES
//
export const initialState = {
  history: [],
  campaign: undefined,
  campaignLoading: true,
  campaignError: false
};

export const initialCampaign = {
  id: '',
  name: '',
  startDate: new Date(),
  stopDate: new Date(),
  isExpired: false,
  isActive: false,
  isInFuture: false,
  activeInDays: '',
  disabled: false,
  isDraft: false
};

// ACTION TYPES
//
export const actionTypes = {
  CAMPAIGN_SET: 'CAMPAIGN_SET',
  CAMPAIGN_INITIALIZE: 'CAMPAIGN_INITIALIZE',
  CAMPAIGN_SET_NAME: 'CAMPAIGN_SET_NAME',
  CAMPAIGN_SET_START_DATE: 'CAMPAIGN_SET_START_DATE',
  CAMPAIGN_SET_STOP_DATE: 'CAMPAIGN_SET_STOP_DATE',
  CAMPAIGN_UNDO_CHANGES: 'CAMPAIGN_UNDO_CHANGES',
  CAMPAIGN_SET_LOADING: 'CAMPAIGN_SET_LOADING',
  CAMPAIGN_RESET_LOADING: 'CAMPAIGN_RESET_LOADING'
};

// REDUCERS
//
export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.CAMPAIGN_INITIALIZE:
      return {
        ...state,
        campaign: {
          ...initialCampaign,
          id: action.payload.id
        },
        history: undefined
      };
    case actionTypes.CAMPAIGN_SET:
      const campaign = {
        id: action.payload.campaign.id,
        name: action.payload.campaign.name,
        startDate: action.payload.campaign.start,
        stopDate: action.payload.campaign.stop,
        disabled: action.payload.campaign.disabled,
        isDraft: action.payload.campaign.isDraft
      };

      return {
        ...state,
        campaign: campaign,
        history: [campaign]
      };
    case actionTypes.CAMPAIGN_SET_NAME:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          name: action.payload.name
        },
        history: state.history !== undefined ? [...state.history, { ...state.campaign }] : undefined
      };
    case actionTypes.CAMPAIGN_SET_START_DATE:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          startDate: action.payload.startDate
        },
        history: state.history !== undefined ? [...state.history, { ...state.campaign }] : undefined
      };
    case actionTypes.CAMPAIGN_SET_STOP_DATE:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          stopDate: action.payload.stopDate
        },
        history: state.history !== undefined ? [...state.history, { ...state.campaign }] : undefined
      };
    case actionTypes.CAMPAIGN_UNDO_CHANGES:
      return {
        ...state,
        campaign: {
          ...state.history[0]
        },
        history: [{ ...state.history[0] }]
      };
    case actionTypes.CAMPAIGN_SET_LOADING:
      return {
        ...state,
        campaignLoading: true
      };
    case actionTypes.CAMPAIGN_RESET_LOADING:
      return {
        ...state,
        campaignLoading: false
      };

    default:
      return state;
  }
};
