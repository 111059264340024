import React from 'react';
import GenericSelect from '../../../../../components/common/GenericSelect';
import { coreTypes } from 'dealer-website-components';
import { includes } from 'lodash';

const FuelFilter = ({ filterData, filterValues, filterAdd, filterDelete }) => {
  const { FilterTypes } = coreTypes;
  const handleChange = (data) => {
    if (includes(filterValues, data.value)) {
      filterDelete(FilterTypes.fuel, data.value);
    } else {
      filterAdd(FilterTypes.fuel, data.value);
    }
  };

  return (
    <React.Fragment>
      <div className='builder-font-semibold builder-mb-2 builder-mt-5'>Fuel</div>
      <GenericSelect placeHolder='All' items={filterData} values={filterValues} handleFilterChanged={handleChange} />
    </React.Fragment>
  );
};

export default FuelFilter;
