import { filterTypes } from './details/reducer';
import { every, map, toString, values } from 'lodash';
import { isNull } from 'lodash/lang';

export const generateKey = (filters) => {
  if (!filters) return '/';
  if (every(values(filters), (value) => value == null || value === '')) return '/';
  const makeKey = filters[filterTypes.MAKE] === 'all' ? `{${filterTypes.MAKE}}` : filters[filterTypes.MAKE];
  const modelKey = filters[filterTypes.MODEL] === 'all' ? `{${filterTypes.MODEL}}` : filters[filterTypes.MODEL];
  const fuelKey = filters[filterTypes.FUEL] === 'all' ? `{${filterTypes.FUEL}}` : filters[filterTypes.FUEL];
  const transmissionKey = filters[filterTypes.TRANSMISSION] === 'all' ? `{${filterTypes.TRANSMISSION}}` : filters[filterTypes.TRANSMISSION];
  const categoryKey = filters[filterTypes.CATEGORY] === 'all' ? `{${filterTypes.CATEGORY}}` : filters[filterTypes.CATEGORY];

  let key = '';
  // let key = `/${makeKey}`;
  if (makeKey) key = `${key}/${makeKey}`;
  if (modelKey) key = `${key}/${modelKey}`;
  if (fuelKey) return `${key}/-${fuelKey}`;
  if (transmissionKey) return `${key}/-${transmissionKey}`;
  if (categoryKey) return `${key}/-${categoryKey}`;
  if (key === '') key = '/';

  return toString(key);
};

// Map it for the server. Some of the data need to be json stringified
export const serializePageSeo = (pageSeo) => {
  return {
    id: pageSeo.id,
    key: generateKey(pageSeo.filters),
    // filters: JSON.stringify(pageSeo.filters),
    filters: pageSeo.filters,
    metaTitle: JSON.stringify(pageSeo.metaTitle),
    metaDescription: JSON.stringify(pageSeo.metaDescription),
    content: map(pageSeo.content, (content) => ({
      ...content,
      data: JSON.stringify(content.data)
    }))
  };
};

export const hasFilter = (filterValue) => {
  return filterValue !== null && filterValue !== '' && filterValue !== undefined;
};

// Map it for the local state. Some of the data need to be json parsed
export const deserializePageSeo = (pageSeo) => {
  return {
    id: pageSeo.id,
    key: pageSeo.key,
    filters: JSON.parse(pageSeo.filters),
    metaTitle: JSON.parse(pageSeo.metaTitle ?? '{}'),
    metaDescription: JSON.parse(pageSeo.metaDescription ?? '{}'),
    content: map(pageSeo.content, (content) => ({
      ...content,
      data: JSON.parse(content.data)
    }))
  };
};
