import React from 'react';
import { coreTypes } from 'dealer-website-components';
import GenericColorList from '../../../../../components/common/GenericColorList';
import { find } from 'lodash';

const ExteriorColorFilter = ({ filterData, filterValues, filterAdd, filterDelete }) => {
  const { FilterTypes } = coreTypes;
  const handleChange = (data) => {
    const hasFilterForColor = find(filterValues, (i) => i === data.value);
    if (hasFilterForColor) {
      filterDelete(FilterTypes.exteriorColor, data.value);
    } else {
      filterAdd(FilterTypes.exteriorColor, data.value);
    }
  };

  return (
    <React.Fragment>
      <div className='builder-font-semibold builder-mb-2 builder-mt-5'>Body color</div>
      <GenericColorList placeHolder='All' items={filterData} values={filterValues} handleFilterChanged={handleChange} />
    </React.Fragment>
  );
};

export default ExteriorColorFilter;
