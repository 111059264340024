import React, { useContext, useEffect, useState } from 'react';
import { StateContext } from '../../../../App';
import { useLocation } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { find, indexOf, lowerCase, map, every } from 'lodash';
import DraftJsEditor from '../../../builder/editors/components/DraftJsEditor';
import PageSeoFilters from './PageSeoFilters';
import TextInput from '../../../builder/editors/components/TextInput';
import TextArea from '../../../builder/editors/components/TextArea';

const PageSeoForm = ({ pageSeo, onUpdateContent, onUpdateFilter, onUpdate, context }) => {
  const [shouldEditorUpdate, setShouldEditorUpdate] = useState(false);
  const globalState = useContext(StateContext);
  const location = useLocation();

  const pageLanguage = location?.state?.language ? location.state.language : context.culture;
  const languages = globalState?.configuration?.website?.languages;

  const tabClassNames = 'relative -bottom-px builder-p-3 builder-cursor-pointer builder-text-black builder-text-md builder-uppercase focus:outline-none';
  const activeTabClassNames = 'builder-font-bold builder-bg-white builder-border-t builder-border-r builder-border-l builder-border-b-0 builder-rounded-sm builder-border-gray-300';
  const tabPanelClassNames = 'builder-bg-white builder-border-l builder-border-r builder-border-gray-300';

  const handleChangeMetaTitle = (value, language) => {
    onUpdate({ ...pageSeo, metaTitle: { ...pageSeo.metaTitle, [language]: value } });
  };

  const handleChangeMetaDescription = (value, language) => {
    onUpdate({ ...pageSeo, metaDescription: { ...pageSeo.metaDescription, [language]: value } });
  };

  useEffect(() => {
    // ugly, but time issues
    setShouldEditorUpdate(true);
    setTimeout(() => {
      setShouldEditorUpdate(false);
    }, 100);
  }, [pageSeo?.id]);

  return (
    <div className='builder-flex builder-w-full builder-bg-gray-50'>
      <div className='builder-flex builder-flex-col builder-w-full'>
        <div className='builder-mb-6'>
          <div>
            <PageSeoFilters currentFilters={pageSeo.filters} onUpdateFilter={onUpdateFilter} context={context} />
          </div>
        </div>

        <Tabs defaultIndex={indexOf(languages, pageLanguage)} className='builder-h-full'>
          <TabList className='builder-flex builder-flex-row builder-justify-start builder-align-center builder-border-b builder-border-gray-300'>
            {map(languages, (language) => (
              <Tab key={`page-seo-tab-list-language-${language}`} className={tabClassNames} selectedClassName={activeTabClassNames}>
                {language}
              </Tab>
            ))}
          </TabList>

          {map(languages, (language) => {
            const currentPageSeoContent = find(pageSeo?.content, (content) => lowerCase(content.culture) === lowerCase(language));
            return (
              <TabPanel key={`seo-page-tab-panel-language-${language}`} className={tabPanelClassNames}>
                <div className='builder-p-6 builder-border-b builder-border-gray-300'>
                  <DraftJsEditor
                    value={currentPageSeoContent?.data}
                    // readOnly={isEditorDisabled}
                    className='builder-border builder-border-gray-300 builder-bg-white'
                    onChange={(value) => {
                      const isEmptyBlock = every(value?.blocks, (block) => block.text === '');
                      const editorValue = isEmptyBlock ? '' : value;
                      onUpdateContent(language, editorValue);
                    }}
                    updateValueOnChange={shouldEditorUpdate}
                  />
                </div>
                <div className='builder-p-6 builder-border-b builder-border-gray-300'>
                  <div className='builder-flex builder-flex-col builder-mb-4'>
                    <div className='builder-text-black builder-text-md builder-font-bold builder-mb-2'>Meta title</div>
                    <TextInput
                      dataTestId='page-seo-detail-meta-title-input'
                      placeHolder='Add description'
                      className='builder-flex builder-flex-1 builder-border builder-w-full builder-rounded-md builder-border-gray-300'
                      value={pageSeo?.metaTitle[language]}
                      onChanged={(value) => handleChangeMetaTitle(value, language)}
                    />
                  </div>

                  <div className='builder-flex builder-flex-col'>
                    <div className='builder-text-black builder-text-md builder-font-bold builder-mb-2'>Meta description</div>
                    <TextArea
                      value={pageSeo?.metaDescription[language]}
                      dataTestId='page-seo-detail-meta-description-input'
                      placeHolder='Add description'
                      className='builder-flex builder-flex-1 builder-border builder-w-full builder-rounded-md builder-border-gray-300'
                      rows={10}
                      onChange={(value) => handleChangeMetaDescription(value, language)}
                    />
                  </div>
                </div>
              </TabPanel>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default PageSeoForm;
