import { find, isEqual, map, toLower } from 'lodash';
import { v4 } from 'uuid';

export const initialMeta = {
  id: '',
  language: '',
  title: '',
  description: ''
};

export const initialPage = {
  id: '',
  typeName: '',
  meta: []
};

export const initialState = {
  page: initialPage,
  basePage: initialPage,
  fetching: false,
  fetchingError: undefined,
  hasUnsavedChanges: false,
  isCollapsed: false,
  saving: false
};

// ACTION TYPES //
export const actionTypes = {
  PAGE_SET: 'PAGE_SET',
  PAGE_SET_LOADING: 'PAGE_SET_LOADING',
  PAGE_RESET_LOADING: 'PAGE_RESET_LOADING',
  PAGE_SET_META: 'PAGE_SET_META',
  TOGGLE_COLLAPSE: 'TOGGLE_COLLAPSE',
  PAGE_SET_SAVING: 'PAGE_SET_SAVING',
  PAGE_RESET_SAVING: 'PAGE_RESET_SAVING',
  PAGE_SAVED: 'PAGE_SAVED'
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.PAGE_SET_LOADING:
      return {
        ...state,
        fetching: true
      };
    case actionTypes.PAGE_RESET_LOADING:
      return {
        ...state,
        fetching: false
      };
    case actionTypes.PAGE_SET:
      return {
        ...state,
        page: action.payload.page,
        basePage: action.payload.page,
        fetching: false,
        fetchingError: undefined
      };
    case actionTypes.PAGE_SET_META:
      const isExisting = find(state.page.meta, (meta) => toLower(action.payload.language) === toLower(meta.language));
      const newMeta = isExisting
        ? map(state.page.meta, (meta) => (toLower(action.payload.language) === toLower(meta.language) ? action.payload.updatedMeta : meta))
        : [
            ...state.page.meta,
            {
              ...initialMeta,
              ...action.payload.updatedMeta,
              language: toLower(action.payload.language),
              id: v4()
            }
          ];

      return {
        ...state,
        page: {
          ...state.page,
          meta: newMeta
        },
        hasUnsavedChanges: !isEqual(state.basePage.meta, newMeta)
      };
    case actionTypes.TOGGLE_COLLAPSE:
      return {
        ...state,
        isCollapsed: !state.isCollapsed
      };

    case actionTypes.PAGE_SET_SAVING:
      return {
        ...state,
        saving: true
      };
    case actionTypes.PAGE_RESET_SAVING:
      return {
        ...state,
        saving: false
      };
    case actionTypes.PAGE_SAVED:
      return {
        ...state,
        saving: false
      };
    default:
      return state;
  }
};
