import React from 'react';
import BlogOverviewProperties from './BlogOverviewProperties';
import { find } from 'lodash';
import { DefaultPageTypeNames } from '../../../constants';

const PageProperties = ({ page, properties, onChange, context }) => {
  const handleChangeBlogOverviewProperties = (data) => {
    onChange('blog-overview', data);
  };

  // Currently 1 type supported (blog-overview)
  if (page.type === DefaultPageTypeNames.blogPost) {
    const propertiesOfType = find(properties, (p) => p.type === 'blog-overview');
    return <BlogOverviewProperties data={propertiesOfType ? propertiesOfType.data : undefined} onChange={handleChangeBlogOverviewProperties} context={context} />;
  }

  return null;
};

export default PageProperties;
