import React from 'react';
import { includes, map } from 'lodash';
import { STATUSES } from '../../constants';

const StatusFilter = ({ fetchingStatuses, selectedStatuses, onStatusClick, redirects }) => {
  return (
    <div className='builder-flex builder-items-center builder-space-x-2'>
      {map(STATUSES, (status) => {
        const isSelected = includes(selectedStatuses, status);

        return (
          <div
            key={status}
            className={`builder-text-xs builder-font-medium builder-px-2.5 builder-py-0.5 builder-rounded builder-bg-gray-100 builder-text-gray-500 hover:builder-bg-gray-200 builder-transition-all builder-cursor-pointer 
                                ${isSelected ? 'builder-bg-gray-200' : ''}
                                ${fetchingStatuses ? 'builder-bg-opacity-75' : ''}
                              `}
            onClick={() => onStatusClick(status)}
          >
            {status}
          </div>
        );
      })}
    </div>
  );
};

export default StatusFilter;
