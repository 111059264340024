import React, { useState, useEffect, useReducer } from 'react';
import { map, orderBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import ConfirmBox from '../../components/common/ConfirmBox';
import { routes } from '../data/constants';
import { v4 } from 'uuid';
import { overviewBlockDeleteHandler, overviewBlocksSetHandler } from './actions';
import { overviewReducer, initialOverviewState } from './reducers';
import cogoToast from 'cogo-toast';

export const BlocksQuery = `query Blocks{    
  blocks {
    id
    name
    isTemplate
    readonly
  }   
}`;

export const BlockDeleteMutation = `mutation BlockDelete($data: BlockDeleteInputType!) { 
  blockDelete(data: $data) { 
    ok 
  }
}`;

const Overview = () => {
  const [state, dispatch] = useReducer(overviewReducer, initialOverviewState);
  const [confirmDelete, setConfirmDelete] = useState('');
  const history = useHistory();

  useEffect(() => {
    fetchBlocks();
  }, []);

  const fetchBlocks = () => {
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: BlocksQuery })
    })
      .then((resp) => resp.json())
      .then((result) => {
        overviewBlocksSetHandler(dispatch, result.data.blocks);
      })
      .catch((err) => {});
  };

  const handleDelete = () => {
    const id = confirmDelete;

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: BlockDeleteMutation, variables: { data: { id: id } } })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data?.blockDelete?.ok) {
          overviewBlockDeleteHandler(dispatch, id);
          cogoToast.success('Block deleted');
        } else {
          const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
          cogoToast.error(error);
        }
        setConfirmDelete('');
      })
      .catch((err) => {});
  };

  if (state.blocksLoading) {
    return <div>Loading</div>;
  }

  if (state.blocksError) {
    return <div>Error: {state.blocksError}</div>;
  }

  return (
    <div className='builder-flex builder-flex-col builder-p-5 builder-w-full'>
      {confirmDelete !== '' && <ConfirmBox title='Delete filter' text='Are you sure you want to delete this Block?' type='DANGER' onCancel={() => setConfirmDelete('')} onConfirm={handleDelete} />}

      <div className='builder-flex builder-mb-5'>
        <div className='builder-text-2xl builder-text-black builder-font-bold builder-mr-4'>Blocks</div>
        <div className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-cursor-pointer opacity-100' onClick={() => history.push(`${routes.BLOCKS_DETAIL}/${v4()}`)}>
          <i className='fal fa-plus builder-mr-2' />
          Add new
        </div>
      </div>
      <div className='builder-flex builder-flex-col builder-w-full'>
        <div className='builder-overflow-x-auto'>
          <div className='builder-py-2 builder-align-middle builder-inline-block builder-min-w-full'>
            <div className='builder-shadow builder-overflow-hidden builder-border-b builder-border-gray-200'>
              <table className='builder-min-w-full builder-divide-y builder-divide-gray-200'>
                <thead className='builder-bg-gray-50'>
                  <tr>
                    <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '50%' }}>
                      Block Name
                    </th>
                    <th scope='col' className='builder-px-6 builder-py-3 builder-text-center builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '20%' }}>
                      Template
                    </th>

                    <th scope='col' className='builder-px-6 builder-py-3 builder-text-left builder-text-sm builder-font-bold builder-text-black builder-uppercase builder-tracking-wider' style={{ width: '30%' }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className='builder-bg-white builder-divide-y builder-divide-gray-200'>
                  {state && state.blocks.length < 1 && (
                    <tr>
                      <td colSpan={3} className='builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black builder-text-center builder-bg-gray-100'>
                        No Blocks found
                      </td>
                    </tr>
                  )}
                  {state &&
                    state.blocks &&
                    map(orderBy(state.blocks, 'name', 'asc'), (block, i) => {
                      return (
                        <tr key={block.id}>
                          <td className='builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '50%' }}>
                            {block.name}
                          </td>

                          <td className='builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black builder-text-center' style={{ width: '20%' }}>
                            {block.isTemplate && <i className='fal fa-check'></i>}
                          </td>

                          <td className='builder-flex builder-px-6 builder-py-4 builder-whitespace-nowrap builder-text-md builder-text-black' style={{ width: '30%' }}>
                            {block.readonly && (
                              <React.Fragment>
                                <div className='builder-flex builder-items-center builder-cursor-pointer' onClick={() => history.push(`${routes.BLOCKS_DETAIL}/${block.id}`)}>
                                  View <i className='fal fa-eye builder-ml-1' style={{ fontSize: 12 }} />
                                </div>
                              </React.Fragment>
                            )}
                            {!block.readonly && (
                              <React.Fragment>
                                <div className='builder-flex builder-items-center builder-cursor-pointer builder-border-r builder-border-gray-300 builder-mr-3 builder-pr-3' onClick={() => history.push(`${routes.BLOCKS_DETAIL}/${block.id}`)}>
                                  Edit <i className='fal fa-pencil-alt builder-ml-1' style={{ fontSize: 12 }} />
                                </div>
                                <div className='builder-flex builder-items-center builder-cursor-pointer' onClick={() => setConfirmDelete(block.id)}>
                                  Delete <i className='fal fa-trash-alt builder-ml-1' style={{ fontSize: 12 }} />
                                </div>
                              </React.Fragment>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
