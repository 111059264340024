import React, { useState } from 'react';
import DaysWithDescription from '../components/DaysWithDescription';
import { last, orderBy } from 'lodash';
import { isDateInPast } from '../utils';

const ClosingDays = ({ days, onChange, onRemove, onAdd, context }) => {
  const hasDays = days && days.length > 0;
  const [activeDay, setActiveDay] = useState(null);

  const renderNoClosingDaysScreen = () => {
    return (
      <div className='builder-border builder-border-gray-100 builder-p-4 builder-space-y-4'>
        <div className='builder-flex builder-items-center'>
          <i className='fa-thin fa-empty-set builder-mr-2' />
          <div className='builder-text-[16px]'>No closing days selected.</div>
        </div>
        <div className='builder-flex builder-items-center builder-justify-center builder-border builder-border-gray-300 builder-bg-gray-50 builder-cursor-pointer hover:builder-bg-gray-100 builder-transition-all builder-p-2' onClick={onAdd}>
          <i className='fal fa-plus builder-text-gray-600 builder-mr-2' />
          <span>Add new</span>
        </div>
      </div>
    );
  };

  const renderAddNewButton = () => {
    return (
      <div className='builder-flex builder-items-center builder-justify-end builder-w-full'>
        <div className='builder-flex builder-items-center builder-justify-center builder-border builder-border-gray-300 builder-bg-gray-50 builder-cursor-pointer hover:builder-bg-gray-100 builder-transition-all builder-p-2' onClick={onAdd}>
          <i className='fal fa-plus builder-text-gray-600 builder-mr-2' />
          <span>Add new</span>
        </div>
      </div>
    );
  };

  const renderActiveAlert = () => {
    return (
      <div className='builder-rounded-md builder-bg-green-50 builder-px-4 builder-py-2'>
        <span className='builder-text-sm builder-font-medium builder-text-green-800'>Active</span>
      </div>
    );
  };

  const renderExpiredAlert = () => {
    return (
      <div className='builder-rounded-md builder-bg-yellow-50 builder-px-4 builder-py-2'>
        <span className='builder-text-sm builder-font-medium builder-text-yellow-700'>Expired</span>
      </div>
    );
  };

  const resolveClassName = (closingDayExpired, index) => {
    let className = 'builder-border-gray-100';
    if (closingDayExpired) className = 'builder-border-red-300 builder-opacity-50';
    if (activeDay === index) className = 'builder-border-gray-100 builder-opacity-100';

    return className;
  };

  return (
    <div className='builder-flex builder-flex-col builder-w-full builder-flex-1'>
      <div className='builder-flex builder-flex-wrap' style={{ marginLeft: -16 }}>
        {hasDays &&
          orderBy(days, ['stop'], ['desc']).map((item, i) => {
            const isExpired = isDateInPast(item.stop);
            let className = resolveClassName(isExpired, i);
            return (
              <div key={`day-${i}`} className='builder-mb-4 builder-w-1/3 builder-pl-4' onMouseEnter={() => setActiveDay(i)} onMouseLeave={() => setActiveDay(null)}>
                <div className={`builder-border builder-p-4 builder-shadow-sm ${className}`}>
                  <DaysWithDescription item={item} onChanged={(data) => onChange({ ...data, uuid: item.uuid })} context={context} />
                  <div className='builder-flex builder-justify-between builder-w-full builder-mt-4'>
                    {isExpired && renderExpiredAlert()}
                    {!isExpired && renderActiveAlert()}
                    <div
                      className='builder-flex builder-justify-center builder-items-center builder-text-xs builder-text-red-500 builder-cursor-pointer hover:text-red-800 builder-p-2 builder-border builder-border-red-500 hover:builder-border-red-800'
                      style={{ width: 'fit-content' }}
                      onClick={() => onRemove(item.uuid)}
                    >
                      <i className='fa-sharp fa-light fa-trash builder-mr-2' /> Remove
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        {!hasDays && renderNoClosingDaysScreen()}
      </div>

      {hasDays && renderAddNewButton()}
    </div>
  );
};

export default ClosingDays;
