import { filter, find, get, has, isString, map, orderBy, trim } from 'lodash';
import { tryParse } from '../../utils';

export const updatePriorities = (data, draggedItem, targetItem, startIndex = 0) => {
  const orderedData = map(orderBy(data, 'priority'), (item, index) => ({ ...item, priority: index + startIndex }));
  const draggedItemPriority = find(orderedData, (item) => item.id === draggedItem.id)?.priority;
  const targetItemPriority = find(orderedData, (item) => item.id === targetItem.id)?.priority;

  if (draggedItemPriority === undefined || targetItemPriority === undefined || draggedItemPriority === null || targetItemPriority === null) {
    return orderedData;
  }

  const minPriority = Math.min(draggedItemPriority, targetItemPriority);
  const maxPriority = Math.max(draggedItemPriority, targetItemPriority);

  const newData = map(orderedData, (item) => {
    if (item.priority === draggedItemPriority) {
      return { ...item, priority: targetItemPriority - 0.5 };
    } else if (item.priority >= minPriority && item.priority <= maxPriority) {
      const priorityOffset = targetItemPriority < draggedItemPriority ? 0.5 : -0.5;
      return { ...item, priority: item.priority + priorityOffset };
    }
    return item;
  });

  // return orderBy(newData, 'priority');

  return map(orderByPriority(newData), (item, index) => ({
    ...item,
    priority: index + startIndex
  }));
};

export const updateMakesPriorities = (makes, draggedMake, targetMake, startIndex = 1) => {
  const minPriority = Math.min(draggedMake.priority, targetMake.priority);
  const maxPriority = Math.max(draggedMake.priority, targetMake.priority);
  const makesWithUpdatedPriorities = map(makes, (make) => {
    if (make.priority === draggedMake.priority) {
      return { ...make, priority: targetMake.priority };
    } else if (make.priority > minPriority && make.priority <= maxPriority) {
      const priorityOffset = targetMake.priority < draggedMake.priority ? 1 : -1;
      return { ...make, priority: make.priority + priorityOffset };
    }
    return make;
  });

  return map(orderByPriority(makesWithUpdatedPriorities), (make, index) => ({
    ...make,
    priority: index + startIndex
  }));
};

export const resolveMakesPriorities = (makes, draggedMakeId, droppedMakeId) => {
  const draggedMake = find(makes, (make) => make.id === draggedMakeId);
  const targetMake = find(makes, (make) => make.id === droppedMakeId);

  // If dragged and dropped item are in de list with default makes, nothing changes
  if (draggedMake.priority === 0 && targetMake.priority === 0) {
    return makes;
  } else {
    // Drag from dealer makes to default makes
    if (draggedMake.priority > 0 && targetMake.priority === 0) {
      const makesWithPriorityEqualsZero = [...filter(makes, (make) => make.priority === 0), { ...draggedMake, priority: 0 }];
      const dealerMakes = makes.filter((dealerMake) => {
        return !makesWithPriorityEqualsZero.some((make) => make.id === dealerMake.id);
      });

      // Line up priorities of dealer makes. To avoid to have 1, 2, 4, 8 and line it up to be 1, 2, 3, 4
      const updatedDealerMakesPriorities = map(orderBy(dealerMakes, ['priority']), (make, index) => ({ ...make, priority: index + 1 }));

      return [...makesWithPriorityEqualsZero, ...updatedDealerMakesPriorities];
      // Drag from default makes to dealer makes or from dealer makes to dealer makes
    } else {
      // All makes with priority bigger than 0 (dealer makes)
      const makesWithPriorityBiggerThenOne = filter(makes, (make) => make.priority > 0);
      // Add dragged make if it's dragged from default makes, if it's dragged from dealer makes, do not add it because it's already in the list
      const newDealerMakes = draggedMake.priority === 0 ? [...makesWithPriorityBiggerThenOne, draggedMake] : makesWithPriorityBiggerThenOne;

      // Dealer makes with updated priorities based on place where dragged make is dropped
      const dealerMakesWithUpdatedPriorities =
        draggedMake.priority < targetMake.priority ? orderByPriority(updateMakesPriorities(newDealerMakes, draggedMake, targetMake)) : orderByPriority(updatePriorities(newDealerMakes, draggedMake, targetMake, 1));

      // All other makes which are not in dealerMakesWithUpdatedPriorities list
      const otherMakes = makes.filter((make) => {
        return !dealerMakesWithUpdatedPriorities.some((dealerMake) => dealerMake.id === make.id);
      });

      return [...otherMakes, ...dealerMakesWithUpdatedPriorities];
    }
  }
};

export const containsOnlyLowercaseAndHyphens = (text) => {
  return isString(text) && /^[a-z-/]+$/.test(text);
};

export const containsOnlyLowercaseNumbersAndHyphens = (text) => {
  return isString(text) && /^[a-z0-9-/]+$/.test(text);
};

export const convertToSlug = (text) => {
  if (text === undefined || text === null || text === '') return '';
  if (!isString(text)) return text;
  return trim(text.replaceAll(' ', '-').toLowerCase(), '_-.,');
};

export const resolveTranslationFromJson = (jsonData, key) => {
  const parsedData = tryParse(jsonData);
  if (has(parsedData, key)) {
    return get(parsedData, key);
  }

  return jsonData;
};

export const orderByPriority = (data, orderType = 'asc') => {
  return orderBy(data, 'priority', orderType);
};

export const isPriorityChanged = (initialState, newState) => {
  if (!initialState || !newState) return false;

  let priorityChanged = false;

  for (const category of newState.categories) {
    const categoryInInitialState = find(initialState.categories, (initialCategory) => initialCategory.id === category.id);
    if (!categoryInInitialState || category.priority !== categoryInInitialState.priority) {
      priorityChanged = true;
      break;
    }
  }

  if (!priorityChanged) {
    for (const questionGroup of newState.questionGroups) {
      const questionGroupInInitialState = find(initialState.questionGroups, (initialQuestionGroup) => initialQuestionGroup.id === questionGroup.id);
      if (!questionGroupInInitialState || questionGroup.priority !== questionGroupInInitialState.priority) {
        priorityChanged = true;
        break;
      }
    }
  }

  if (!priorityChanged) {
    for (const question of newState.questions) {
      const questionInInitialState = find(initialState.questions, (initialQuestion) => initialQuestion.id === question.id);
      if (!questionInInitialState || question.priority !== questionInInitialState.priority) {
        priorityChanged = true;
        break;
      }
    }
  }

  return priorityChanged;
};
