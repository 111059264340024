import React from 'react';
import { includes, map } from 'lodash';

const MatchPathField = ({ value, onChange, onClear, errorText, category, context }) => {
  const hasValue = value && value !== '';

  let matchPathFields = [':make', ':model', ':reference'];
  if (category === 'blog') {
    matchPathFields = [...matchPathFields, ':category', ':topic'];
  }

  return (
    <div>
      <div>
        <div data-testid='pageType-match-path-labels' className='builder-flex builder-items-center builder-space-x-2 builder-mb-1 builder-pb-1 builder-border-b builder-border-gray-50 builder-overflow-y-scroll scrollbar'>
          {map(matchPathFields, (matchField) => {
            const isAlreadySelected = includes(value, matchField);

            return (
              <div
                key={matchField}
                className={`builder-text-sm builder-text-black hover:builder-bg-gray-300 builder-transition-all builder-p-1 builder-rounded builder-bg-gray-100 ${
                  isAlreadySelected ? 'builder-opacity-50 builder-pointer-events-none' : 'builder-cursor-pointer'
                }`}
                onClick={() => onChange(matchField)}
              >
                {matchField}
              </div>
            );
          })}
        </div>

        <div className='builder-flex builder-items-center builder-w-full builder-border builder-border-gray-100 builder-px-2 builder-py-1'>
          <div className='builder-flex builder-mr-2'>Current:</div>
          {hasValue && (
            <div className='builder-flex builder-flex-1 font-bold builder-mr-2 builder-overflow-scroll no-scrollbar' data-testid='pathType-current-match-type'>
              {value}
            </div>
          )}
          {hasValue ? <i className='far fa-times builder-text-gray-700 builder-cursor-pointer hover:text-gray-600 builder-transition-all' onClick={onClear} /> : <i className='fad fa-minus' />}
        </div>
      </div>
      {errorText && (
        <div data-testid='pathType-current-match-type-error' className='builder-mt-1 builder-text-sm builder-text-red-500 builder-whitespace-normal'>
          {errorText}
        </div>
      )}
    </div>
  );
};

export default MatchPathField;
