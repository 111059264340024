import React, { useEffect } from 'react';
import { includes, map } from 'lodash';
import cogoToast from 'cogo-toast';

import GroupModal from './GroupModal';
import { initialState, reducer } from '../reducer';
import { advertisementGroupsAddHandler, advertisementGroupsSetHandler, advertisementGroupsModalHandler } from '../actions';

export const AdGroupSaveMutation = `mutation AdvertisementGroupUpdate($data: AdvertisementGroupUpdateInputType!) {
    advertisementGroupUpdate(data: $data) {
        ok
    }
  }`;

export const AdvertisementGroupsQuery = `query AdvertisementGroups{    
    advertisementGroups {
      id
      name
      isDraft
      disabled
    }   
  }`;

const GroupMenu = ({ advertisement, onSelectGroup, onDeselectGroup, context }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const advertisementGroups = state.advertisementGroups;

  const fetchAdGroups = () => {
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: AdvertisementGroupsQuery })
    })
      .then((resp) => resp.json())
      .then((result) => {
        advertisementGroupsSetHandler(dispatch, result.data.advertisementGroups);
      })
      .catch((err) => {});
  };

  const handleAdGroupsModal = () => {
    advertisementGroupsModalHandler(dispatch, { ...state.adGroupsModal, isOpen: !state.adGroupsModal.isOpen });
  };

  const handleSaveAdGroup = (adGroup) => {
    const variables = {
      data: {
        id: adGroup.id,
        name: adGroup.name,
        disabled: adGroup.disabled,
        isDraft: adGroup.isDraft
      }
    };

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: AdGroupSaveMutation, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data?.advertisementGroupUpdate?.ok) {
          cogoToast.success('Advertisement Group Saved');
          advertisementGroupsAddHandler(dispatch, adGroup);
          handleAdGroupsModal();
        } else {
          const error = result && result.errors && result.errors.length > 0 ? result.errors[0].message : 'Something went wrong';
          cogoToast.error(error);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchAdGroups();
  }, []);

  if (advertisementGroups.length === 0) {
    return (
      <React.Fragment>
        <div className='builder-font-bold builder-mt-4'>No groups defined.</div>
        <div className='builder-mb-4'> An advertisement will only be visible on the website if it contains a group.</div>
        <div className='builder-underline builder-font-semibold builder-cursor-pointer' onClick={handleAdGroupsModal}>
          Create new group
        </div>
        <GroupModal
          isOpen={state.adGroupsModal.isOpen}
          advertisementGroup={undefined} // TEMP Only needed when editing is required
          onSaveGroup={handleSaveAdGroup}
          onClose={() => {
            handleAdGroupsModal();
          }}
          context={context}
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className='builder-mt-4'>
        {map(advertisementGroups, (group) => {
          const selected = includes(advertisement.groups, group.id);
          return (
            <div key={group.id} className='builder-flex builder-items-center builder-mb-1'>
              <input
                type='checkbox'
                // defaultChecked={selected}
                checked={selected}
                onChange={(e) => (selected ? onDeselectGroup(group.id) : onSelectGroup(group.id))}
                className='builder-h-4 builder-w-4 builder-text-indigo-600 focus:builder-ring-indigo-500 builder-border builder-border-gray-300 builder-rounded-md builder-cursor-pointer'
              />
              <label className='builder-ml-2 builder-block builder-text-gray-900 builder-text-md'>{group.name}</label>
            </div>
          );
        })}

        <div className='builder-underline builder-font-semibold builder-cursor-pointer builder-mt-4' onClick={handleAdGroupsModal}>
          Create new group
        </div>
      </div>
      <GroupModal
        isOpen={state.adGroupsModal.isOpen}
        advertisementGroup={undefined} // TEMP Only needed when editing is required
        onSaveGroup={handleSaveAdGroup}
        onClose={() => {
          handleAdGroupsModal();
        }}
        context={context}
      />
    </React.Fragment>
  );
};

export default GroupMenu;
