/* eslint-disable no-console */
import { useQuery } from 'graphql-hooks';
import React, { useEffect } from 'react';
import { PreviewPageQuery } from '../data/graphql';
import { useParams, useLocation } from 'react-router-dom';
import { DataContext } from 'dealer-website-components';
import { filter, keyBy, map, mapValues, orderBy } from 'lodash';
import * as components from 'dealer-website-components';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { LoadingIndicator } from '../../components/common';

const b64DecodeUnicode = (str) => {
  try {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    ).replace(/&nbsp;/g, ' ');
  } catch (e) {
    return undefined;
  }
};

const tryParse = (value) => {
  try {
    return JSON.parse(value);
  } catch (ex) {
    return value;
  }
};

const parseText = (text, data) => {
  if (data) {
    return text;
  }
  return text;
};
const resolveTranslationBySlug = (page, lng) => {
  const slug = tryParse(page.slug);
  if (slug && slug[lng] && slug[lng] !== '') {
    return slug[lng];
  } else {
    return page.url;
  }
};

const createContext = (page, language, configuration, { allPagesData, vehicleSearchFilters = [], meta, defaultPageTypes = {}, defaultPageNames = {}, availableLanguages = [] }) => {
  const pageInfo = {
    url: page.url,
    name: page.name,
    analyticsName: page.analyticsName,
    analyticsType: page.analyticsType,
    matchPath: page.matchPath,
    type: page.type,
    category: page.category,
    slug: page.slug,
    key: page.key,
    keyPath: page.originalUrl,
    language: language,
    data: {
      dealerGroupKey: page.dealerGroupKey?.toLowerCase(),
      makeKey: page.data?.makeKey,
      modelKey: page.data?.modelKey,
      fuelKey: page.data?.fuelKey,
      gearboxKey: page.data?.gearboxKey,
      categoryKey: page.data?.categoryKey
    }
  };

  return {
    page: page,
    meta: meta,
    language: language,
    favoriteIconUrl: configuration.website.favorite_icon_url,
    version: 1.1,
    componentContext: {
      culture: language,
      builder: false,
      dealerGroup: page.data?.dealerGroup,
      dealerGroups: page.data?.dealerGroups,
      availableLanguages,
      page: page.originalUrl,
      pageInfo: pageInfo,
      data: page.data,
      properties: page.data,
      allPagesData: allPagesData,
      vehicleSearchFilters: vehicleSearchFilters.filter((f) => f.lng === language),

      environment: 0, // 0 = production && 1 = draft
      selfcheckUrl: configuration.website.selfcheck_url ? configuration.website.selfcheck_url : undefined,
      lmaHomePage: configuration.website.lma_homepage ? configuration.website.lma_homepage : undefined,

      defaultPageNames,
      defaultPageTypes,
      imageViewer: {
        imagePromoPosition: 4
      }
    }
  };
};

const resolveMetaData = (page, language, configuration) => {
  // Check if page has meta title and description set by the builder
  // If so use defined page data
  // If not use default meta title and description provided by configuration file

  let title = '';
  let description = '';

  const custom_meta = page.meta.find((meta) => meta.language.toLowerCase() === language);
  if (custom_meta) {
    title = custom_meta.title.replace(/\s\s+/g, ' ');
    description = custom_meta.description.replace(/\s\s+/g, ' ');
  } else {
    // TODO check with Boris if this still works or needs to be changed to the homepage description
    title = configuration.website.meta_title;
    description = configuration.website.meta_description;
  }

  return {
    title: title,
    description: description
  };
};

const Layout = (props) => {
  const { context } = props;

  return (
    <div className='font-body' itemScope='' itemType='http://schema.org/AutoDealer'>
      {props.children}
    </div>
  );
};
const CustomBlock = ({ block, context }) => {
  const getHtmlContent = (data, lng) => {
    const content = data.find((d) => d.culture === lng);
    const html = content ? content.fields.find((f) => f.type === 'html') : undefined;
    return html ? html.value : 'content not found';
  };

  return (
    <div id={`b${block.id.split('-')[0]}`}>
      {/*Using InnerHTML instead of dangerouslySetInnerHTML because 'inline javascript' sometimes not working! */}
      <div dangerouslySetInnerHTML={{ __html: getHtmlContent(block.content, context.culture) }} />
    </div>
  );
};

const Block = (props) => {
  const { block, context, store, overridePageMetaData, resolveAnalyticsData, pageVariables } = props;
  const parseJson = (value) => {
    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  };

  const parseField = (field) => {
    if (field.type && field.type.startsWith('Json')) {
      return parseJson(field.value);
    } else {
      return field.value;
    }
  };

  const parseFields = (fields) => {
    const parsed = map(fields, (field) => ({
      ...field,
      value: parseField(field)
    }));
    return parsed;
  };

  const getFieldsForContent = (block, culture) => {
    const filtered = filter(block.content, (content) => content.culture === culture);
    if (filtered.length >= 1) {
      return parseFields(filtered[0].fields);
    }
    return null;
  };

  const getKeyValueContentFieldsForBlock = (block, culture) => {
    const content = getFieldsForContent(block, culture);
    return mapValues(keyBy(content, 'name'), 'value');
  };
  const Component = components[block.variant];
  if (!Component) {
    return null;
  }

  return (
    <div id={`b${block.order}${block.variant}`}>
      <Component
        {...getKeyValueContentFieldsForBlock(block, context.culture)}
        {...context.properties}
        {...pageVariables} // f.e 'reference on vehicle detail page
        context={context}
        store={store}
        overridePageMetaData={overridePageMetaData}
        resolveAnalyticsData={resolveAnalyticsData}
        location={props.location}
      />
    </div>
  );
};

const Page = (props) => {
  const { page, componentContext } = props.pageContext;
  const configuration = props.configuration;
  // Mock translations for now
  const { t } = useTranslation();
  const context = {
    ...componentContext,
    t: (key) => {
      return t(key);
    },
    navigator: {
      navigate: (url, options) => {
        console.log('nav', url, options);
      },
      component: (props) => {
        return <a href={`https://${configuration.website.domains[0]}${configuration.website.path_prefix ? configuration.website.path_prefix : ''}${props.url}`}>{props.children}</a>;
      }
    }
  };

  const pageVariables = { reference: props.reference };
  const { location } = props;

  // New implementation of analytics

  useEffect(() => {
    if (page.type !== context.defaultPageTypes.vehicles) {
      // by setting shouldUpdateScroll to false in gatbsy-browser.js (for stock page when changing filters) this is needed for all other pages..
      window.scrollTo(0, 0);
    }
  }, []);

  const orderedBlocks = orderBy(page.blocks, 'order');

  return (
    <Layout context={context}>
      <DataContext.Consumer>
        {(value) => (
          <React.Fragment>
            {orderedBlocks.map((block) => {
              if (block.contentType !== 'CustomGeneratedBlockInBuilder') {
                return <Block block={block} key={block.id} context={context} store={value} pageVariables={pageVariables} location={location} />;
              } else {
                return <CustomBlock block={block} key={block.id} context={context} />;
              }
            })}
          </React.Fragment>
        )}
      </DataContext.Consumer>
    </Layout>
  );
};

const useSearchParams = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const PreviewPage = () => {
  const { id } = useParams();
  const searchParams = useSearchParams();
  const language = searchParams.get('language') ?? 'nl';
  const { loading, error, data } = useQuery(PreviewPageQuery, { variables: { data: { pageId: id, asStaticGenerator: true } } });

  const parsePageUrl = (page, language, variables, data = {}) => {
    let result = page.url;

    // New return server generated paths!!
    if (page && page.paths) {
      result = JSON.parse(page.paths)[language];
    }

    if (variables) {
      // variables for seo pages /make/model/ f.ex
      result = `${result}${variables}/`;
    }

    if (result && result.indexOf('{{') > -1) {
      result = parseText(result, data);
    }

    return result;
  };

  const modifyPageObjectWithTranslatedInfo = (page, language, variable = '', data = {}) => {
    const url = parsePageUrl(page, language, variable, data);
    return {
      ...page,
      url: url,
      originalUrl: page.url,
      key: parseText(page.url.replace('dealerGroup', '{{dealerGroup.key}}'), data),
      dealerGroupKey: data.dealerGroup?.key,
      matchPathFull: page.matchPath !== '' ? url : undefined
    };
  };

  const generatePageDataForContext = (page, language, variable = '', data = {}, params = {}) => {
    return {
      url: parsePageUrl(page, language, variable, data),
      keyPath: page.url,
      key: parseText(`${page.url}${variable}`, data),
      description: resolveTranslationBySlug(page, language),
      type: page.type,
      language: language,
      matchPath: page.matchPath && page.matchPath !== '' ? page.matchPath : undefined,
      ...params
    };
  };

  const mapDealerGroup = (group, lng) => {
    if (!group) {
      return undefined;
    }

    const content = tryParse(group.content);
    return {
      ...group,
      content: content[lng] && content[lng] !== null && content[lng] !== '' ? b64DecodeUnicode(content[lng]) : null
    };
  };

  const createPage = () => {
    const page = data.previewPageWebsite.pages[0];
    const configuration = JSON.parse(data.configuration);
    const dealerGroups = data.dealershipGroups;
    i18n.changeLanguage(language);
    const pageData = generatePageDataForContext(page, language);
    const pageToCreate = modifyPageObjectWithTranslatedInfo(page, language);
    const pageToCreateDefaultData = {
      dealerGroup: dealerGroups?.length === 1 && dealerGroups?.[0] ? mapDealerGroup(dealerGroups[0], language) : undefined,
      dealerGroups: dealerGroups?.map((dg) => mapDealerGroup(dg, language))
    };

    pageToCreate.data = { ...pageToCreateDefaultData };

    const allPagesData = [pageData];
    const vehicleSearchFilters = data.vehicleSearchFilters;
    // Parsing the pageTypes array to be an object {name: name}
    const defaultPageTypes = data.pageTypes.reduce((acc, item) => {
      acc[item.name] = item.name;
      return acc;
    }, {});

    // Parsing the pageTypes array to be an object {name: path}
    const defaultPageNames = data.pageTypes.reduce((acc, item) => {
      acc[item.name] = item.path;
      return acc;
    }, {});
    const availableLanguages = configuration.website.languages;

    const extraData = { allPagesData, vehicleSearchFilters, defaultPageTypes, defaultPageNames, availableLanguages };

    const meta = resolveMetaData(pageToCreate, language, configuration);
    const context = createContext(pageToCreate, language, configuration, { ...extraData, meta });

    return <Page pageContext={context} configuration={configuration} />;
  };

  return (
    <React.Fragment>
      {data && data.configuration && createPage()}
      {!data && <LoadingIndicator />}
    </React.Fragment>
  );
};

export default PreviewPage;
