import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { v4 } from 'uuid';
import TextInput from '../../../builder/editors/components/TextInput';
import ButtonWithIndicator from '../../../../components/common/ButtonWithIndicator';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '55%',
    overflow: 'visible'
  },
  overlay: {
    zIndex: 9999,
    background: '#00000080 0% 0% no-repeat'
  }
};

const initialState = {
  id: v4(),
  name: '',
  isDraft: false,
  disabled: false
};

const GroupModal = ({ isOpen, onClose, context, advertisementGroup, onSaveGroup }) => {
  const [state, setState] = useState(advertisementGroup ? advertisementGroup : initialState);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    setState(advertisementGroup ? advertisementGroup : initialState);
  }, [advertisementGroup]);

  const setValue = (key, value) => {
    setState({
      ...state,
      [key]: value
    });
  };

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (state.name === '' || state.name.length < 4) {
      errors['name'] = 'The advertisement group name needs to be at least 4 chars long.';
      isValid = false;
    }

    setFormErrors(errors);

    return isValid;
  };

  const handleSave = () => {
    if (validateForm()) {
      onSaveGroup(state);
      setState(advertisementGroup ? advertisementGroup : initialState);
    }
  };

  return (
    <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false} appElement={document.getElementById('root') || undefined} contentLabel='New advertisement group modal'>
      <div className='builder-flex builder-w-full builder-justify-end builder-mb-5'>
        <i className='fal fa-times builder-text-xl builder-cursor-pointer builder-text-2xl' onClick={onClose} />
      </div>

      <div className='builder-text-black builder-font-semibold builder-mb-2.5'>Create advertisement group</div>

      <div className='builder-w-full'>
        <div className='builder-flex-col'>
          <TextInput
            placeHolder='Name'
            className={`builder-flex builder-flex-1 builder-border builder-w-full builder-shadow-sm builder-rounded-md ${formErrors['name'] ? 'builder-border-red-300' : 'builder-border-gray-300'}`}
            value={state.name}
            onChanged={(value) => setValue('name', value)}
          />
          {formErrors['name'] && <span className='builder-mt-1 builder-text-sm builder-text-red-500'>{formErrors['name']}</span>}
        </div>
      </div>

      <div className='builder-flex builder-justify-start builder-mt-6'>
        <ButtonWithIndicator loading={false} onClick={onClose} className='builder-ml-0' text='Cancel' />

        <ButtonWithIndicator loading={false} onClick={handleSave} text='Save' colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-blue-500' />
      </div>
    </Modal>
  );
};

export default GroupModal;
