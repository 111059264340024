import React from 'react';

const LoadingSkeleton = () => {
  return (
    <div data-testid='page-seo-detail-loading-skeleton' className='builder-animate-pulse'>
      <div className='builder-w-full builder-bg-white builder-p-5 builder-mb-5'>
        <div className='builder-bg-gray-200 builder-mb-2' style={{ height: 30, width: '20%' }} />
        <div className='builder-bg-gray-200 builder-mb-1' style={{ height: 15, width: '70%' }} />
        <div className='builder-bg-gray-200 builder-mb-4' style={{ height: 15, width: '50%' }} />
        <div className='builder-bg-gray-200 builder-mb-2' style={{ height: 30 }} />
        <div className='builder-bg-gray-200 builder-mb-5' style={{ height: 200 }} />
        <div className='builder-bg-gray-200 builder-mb-2' style={{ height: 5, width: '10%' }} />
        <div className='builder-bg-gray-200 builder-mb-2' style={{ height: 5, width: '20%' }} />
      </div>

      <div className='builder-w-full builder-bg-white builder-p-5'>
        <div className='builder-bg-gray-200 builder-mb-2' style={{ height: 30, width: '20%' }} />
        <div className='builder-bg-gray-200 builder-mb-4' style={{ height: 15, width: '40%' }} />
        <div className='builder-bg-gray-200 builder-mb-2' style={{ height: 30, width: '60%' }} />
        <div className='builder-bg-gray-200 builder-mb-2' style={{ height: 30, width: '60%' }} />
        <div className='builder-bg-gray-200 builder-mb-2' style={{ height: 30, width: '60%' }} />
        <div className='builder-bg-gray-200 builder-mb-2' style={{ height: 30, width: '60%' }} />
      </div>
    </div>
  );
};

export default LoadingSkeleton;
