import React from 'react';
import ButtonWithIndicator from '../../../../../components/common/ButtonWithIndicator';
import Form from './Form';
import { orderByPriority } from '../../../utils';
import { questionGroupSetCategoryHandler } from '../actions';
import CategorySelector from './CategorySelector';

const QuestionGroupModal = ({ isLoading, languages, formErrors, state, dispatch, onCancel, onSave, currentLanguage, onCreateCategory }) => {
  const questionGroup = state.questionGroup;
  return (
    <div data-testid='question-group-modal' className='builder-w-full'>
      <div className='builder-flex builder-flex-col builder-mb-5 builder-relative'>
        <div className='builder-w-full builder-mb-5'>
          <Form isLoading={isLoading} languages={languages} formErrors={formErrors} state={state} dispatch={dispatch} />
        </div>
        <div className='builder-w-full'>
          <CategorySelector
            categories={orderByPriority(state.categories)}
            selectedCategoryId={questionGroup.categoryId}
            onSelectCategory={(categoryId) => dispatch(questionGroupSetCategoryHandler(categoryId))}
            currentLanguage={currentLanguage}
            handleCreateCategory={onCreateCategory}
            errorText={formErrors['category']}
            className='builder-p-0'
            isCollapsible={false}
          />
        </div>
      </div>

      <div className='builder-flex builder-justify-end builder-items-center builder-mt-6 builder-space-x-2 builder-rounded-b'>
        <ButtonWithIndicator
          loading={isLoading}
          disabled={isLoading}
          onClick={onCancel}
          icon='fal fa-times'
          text='Cancel'
          colorClass='builder-bg-white builder-text-gray-700'
          borderClass='builder-border builder-border-gray-300'
          className='builder-ml-0 builder-mr-0'
        />

        <ButtonWithIndicator
          loading={isLoading}
          disabled={isLoading}
          onClick={onSave}
          icon='fal fa-save'
          text='Save'
          colorClass='builder-bg-primary builder-text-white'
          borderClass='builder-border builder-border-bg-blue-500'
          className='builder-ml-0 builder-mr-0'
        />
      </div>
    </div>
  );
};

export default QuestionGroupModal;
