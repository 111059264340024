import { head, map, toLower } from 'lodash';
import cogoToast from 'cogo-toast';
import { pageResetFetchingHandler, pageResetSavingHandler, pageSetFetchingHandler, pageSetHandler, pageSetSavedHandler, pageSetSavingHandler, pageSetSeoSaveHandler } from '../actions';

export const PageQuery = `
  query ($data: PageSearchInputType!) {
    pages(data: $data) {
        id
        path
        indexable
        typeName
        category
        websiteId
        meta {
          id
          description
          language
          title
        }
    }
}`;

export const SavePageMetaMutation = `
  mutation ($data: PageMetaUpdateBulkInputType!) {
    pageMetaUpdateBulk(data: $data){
      ok
    }
}`;

export const usePage = (state, dispatch) => {
  const fetchPage = (pageId, websiteId, onComplete) => {
    const variables = {
      data: {
        id: pageId,
        websiteId: websiteId
      }
    };

    dispatch(pageSetFetchingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: PageQuery, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        const page = head(result?.data?.pages);
        if (result?.data) {
          if (page) {
            dispatch(pageSetHandler(page));
          } else {
            cogoToast.error('No page returned from the server.');
          }
        } else {
          cogoToast.error('No data returned from the server.');
        }
        dispatch(pageResetFetchingHandler());

        if (onComplete && typeof onComplete === 'function') {
          onComplete(page);
        }
      })
      .catch((err) => {
        cogoToast.error('Something went wrong.');
        dispatch(pageResetFetchingHandler());
      });
  };

  const savePage = (onComplete) => {
    const savedPage = state.page;
    const variables = {
      data: {
        pageMetas: map(savedPage.meta, (meta) => ({
          id: meta.id,
          title: meta.title,
          description: meta.description,
          language: toLower(meta.language),
          pageId: savedPage.id
        }))
      }
    };

    dispatch(pageSetSavingHandler());
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: SavePageMetaMutation, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data?.pageMetaUpdateBulk?.ok) {
          dispatch(pageSetSavedHandler(savedPage));
          cogoToast.success('Page meta saved.');

          if (onComplete && typeof onComplete === 'function') {
            onComplete(savedPage);
          }
        } else {
          cogoToast.error('Page meta not saved.');
        }
        dispatch(pageResetSavingHandler());
      })
      .catch((err) => {
        cogoToast.error('Something went wrong.');
        dispatch(pageResetSavingHandler());
      });
  };

  return {
    fetchPage: (pageId, websiteId, onComplete) => fetchPage(pageId, websiteId, onComplete),
    savePage: (onComplete) => savePage(onComplete),
    fetching: state.pageFetching,
    saving: state.pageSaving
  };
};
