import { map, filter } from 'lodash';
import { tryParse } from '../../../utils';

// INITIAL STATES
//
export const initialState = {
  filters: [],
  filtersLoading: true,
  filtersError: undefined,
  history: []
};

export const initialFilter = {
  id: '',
  description: '',
  type: '',
  filters: [],
  disabled: false,
  isDraft: false
};

// ACTION TYPES
//
export const actionTypes = {
  FILTERS_SET: 'FILTERS_SET',
  FILTER_DELETE: 'FILTER_DELETE',
  FILTERS_SET_LOADING: 'FILTERS_SET_LOADING',
  FILTERS_RESET_LOADING: 'FILTERS_RESET_LOADING'
};

// REDUCERS
//
export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FILTERS_SET:
      return {
        ...state,
        filters: map(action.payload.filters, (filter) => ({
          ...initialFilter,
          id: filter.id,
          description: tryParse(filter.description),
          type: filter.type,
          isDraft: filter.isDraft,
          disabled: filter.disabled
        })),
        filtersLoading: false,
        filtersError: undefined
      };
    case actionTypes.FILTER_DELETE:
      return {
        ...state,
        filters: filter(state.filters, (filter) => filter.id !== action.payload.id),
        filtersLoading: false,
        filtersError: undefined
      };
    case actionTypes.FILTERS_SET_LOADING:
      return {
        ...state,
        filtersLoading: true
      };
    case actionTypes.FILTERS_RESET_LOADING:
      return {
        ...state,
        filtersLoading: false
      };

    default:
      return state;
  }
};
