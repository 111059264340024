import React from 'react';

const LoadingSkeleton = () => {
  return (
    <div data-testid='overview-loading-skeleton' className='builder-p-5 builder-w-1/2'>
      <div className='builder-flex builder-items-center builder-mb-5'>
        <div className='builder-mr-6 builder-w-12 builder-bg-gray-100 builder-animate-pulse builder-h-8' />
        <div className='builder-mr-4 builder-w-36 builder-bg-gray-100 builder-animate-pulse builder-h-12' />
        <div className='builder-mr-4 builder-w-36 builder-bg-gray-100 builder-animate-pulse builder-h-12' />
        <div className='builder-w-36 builder-bg-gray-100 builder-animate-pulse builder-h-12' />
      </div>

      <div className='builder-flex builder-flex-col builder-justify-center builder-space-y-4 builder-max-w-7xl builder-min-w-min builder-pb-5'>
        {/*SECTION 1*/}
        <div className='builder-space-y-2'>
          {/*CATEGORY*/}
          <div className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />
          {/*GROUP*/}
          <div className='builder-pl-4'>
            <div className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />
          </div>
          {/*QUESTIONS*/}
          <div className='builder-pl-8 builder-space-y-2'>
            <div className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />
            <div className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />
            <div className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />
          </div>
        </div>
        {/*SECTION 2*/}
        <div className='builder-space-y-2'>
          {/*CATEGORY*/}
          <div className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />
          {/*GROUP*/}
          <div className='builder-pl-4'>
            <div className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />
          </div>
          {/*QUESTIONS*/}
          <div className='builder-pl-8 builder-space-y-2'>
            <div className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />
            <div className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />
            <div className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />
          </div>
        </div>
        {/*SECTION 3*/}
        <div className='builder-space-y-2'>
          {/*CATEGORY*/}
          <div className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />
          {/*GROUP*/}
          <div className='builder-pl-4'>
            <div className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />
          </div>
          {/*QUESTIONS*/}
          <div className='builder-pl-8 builder-space-y-2'>
            <div className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />
            <div className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />
            <div className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />
          </div>
        </div>
        {/*SECTION 4*/}
        <div className='builder-space-y-2'>
          {/*CATEGORY*/}
          <div className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />
          {/*GROUP*/}
          <div className='builder-pl-4'>
            <div className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />
          </div>
          {/*QUESTIONS*/}
          <div className='builder-pl-8 builder-space-y-2'>
            <div className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />
            <div className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />
            <div className='builder-h-11 builder-w-full builder-bg-gray-100 builder-animate-pulse' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSkeleton;
