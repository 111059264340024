import { map } from 'lodash';

// INITIAL STATES
//
export const initialState = {
  history: [], //?
  advertisement: undefined,
  advertisementGroups: [],
  adGroupsModal: {
    isOpen: false
  },
  filtersModal: {
    isOpen: false
  }
};

export const initialAdvertisement = {
  id: '',
  description: '',
  content: [],
  groups: [],
  filters: [],
  disabled: false,
  isDraft: false
};

export const initialAdvertisementContent = {
  id: '',
  culture: '',
  data: '<div>Add your HTML here</div>'
};

export const initialAdvertisementGroup = {
  id: '',
  name: '',
  disabled: false,
  isDraft: false
};

export const initialAdvertisementVehicleFilter = {
  id: '',
  make: [],
  model: [],
  fuel: [],
  label: []
};

// ACTION TYPES
//
export const actionTypes = {
  ADVERTISEMENT_GROUPS_SET_ALL: 'ADVERTISEMENT_GROUPS_SET_ALL',
  ADVERTISEMENT_GROUPS_MODAL_SET_OPEN: 'ADVERTISEMENT_GROUPS_MODAL_SET_OPEN',
  ADVERTISEMENT_GROUPS_ADD: 'ADVERTISEMENT_GROUPS_ADD'
};

// REDUCER
//
export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ADVERTISEMENT_GROUPS_SET_ALL:
      return {
        ...state,
        advertisementGroups: map(action.payload.advertisementGroups, (adGroup) => ({
          id: adGroup.id,
          name: adGroup.name,
          isDraft: adGroup.isDraft,
          disabled: adGroup.disabled
        }))
      };
    case actionTypes.ADVERTISEMENT_GROUPS_MODAL_SET_OPEN:
      return {
        ...state,
        adGroupsModal: action.payload.adGroupsModal
      };
    case actionTypes.ADVERTISEMENT_GROUPS_ADD:
      return {
        ...state,
        advertisementGroups: [
          ...state.advertisementGroups,
          {
            id: action.payload.advertisementGroup.id,
            name: action.payload.advertisementGroup.name,
            isDraft: action.payload.advertisementGroup.isDraft,
            disabled: action.payload.advertisementGroup.disabled
          }
        ]
      };
    default:
      return state;
  }
};
