import { tryParse } from '../../../utils';

// INITIAL STATES
//
export const initialState = {
  filter: undefined,
  filterLoading: true,
  filterError: undefined,
  filtersModal: {
    isOpen: false
  },
  history: []
};

export const initialFilter = {
  id: '',
  description: {},
  type: '',
  filters: [],
  disabled: false,
  isDraft: false
};

// ACTION TYPES
//
export const actionTypes = {
  FILTER_SET: 'FILTER_SET',
  FILTER_INITIALIZE: 'FILTER_INITIALIZE',
  FILTER_SET_DESCRIPTION: 'FILTER_SET_DESCRIPTION',
  FILTER_SET_TYPE: 'FILTER_SET_TYPE',
  FILTER_SET_FILTERS: 'FILTER_SET_FILTERS',
  FILTERS_MODAL_SET_OPEN: 'FILTERS_MODAL_SET_OPEN',
  FILTER_UNDO_CHANGES: 'FILTER_UNDO_CHANGES',
  FILTER_SET_LOADING: 'FILTER_SET_LOADING',
  FILTER_RESET_LOADING: 'FILTER_RESET_LOADING'
};

// REDUCER
//
export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FILTER_INITIALIZE:
      return {
        ...state,
        filter: {
          ...initialFilter,
          id: action.payload.id
        },
        history: undefined
      };
    case actionTypes.FILTER_SET:
      const filterData = {
        id: action.payload.filter.id,
        description: tryParse(action.payload.filter.description),
        type: action.payload.filter.type,
        filters: tryParse(action.payload.filter.filters),

        disabled: action.payload.filter.disabled ? action.payload.filter.disabled : false,
        isDraft: action.payload.filter.isDraft ? action.payload.filter.isDraft : false
      };

      return {
        ...state,
        filter: filterData,
        history: [filterData]
      };

    // FILTER_SET_DESCRIPTION
    case actionTypes.FILTER_SET_DESCRIPTION:
      return {
        ...state,
        filter: {
          ...state.filter,
          description: action.payload.description
        },
        history: state.history !== undefined ? [...state.history, { ...state.filter }] : undefined
      };

    // FILTER_SET_TYPE
    case actionTypes.FILTER_SET_TYPE:
      return {
        ...state,
        filter: {
          ...state.filter,
          type: action.payload.type
        },
        history: state.history !== undefined ? [...state.history, { ...state.filter }] : undefined
      };

    // FILTER_SET_FILTERS
    case actionTypes.FILTER_SET_FILTERS:
      return {
        ...state,
        filter: {
          ...state.filter,
          filters: tryParse(action.payload.filters)
        },
        history: state.history !== undefined ? [...state.history, { ...state.filter }] : undefined
      };

    case actionTypes.FILTERS_MODAL_SET_OPEN:
      return {
        ...state,
        filtersModal: action.payload.filtersModal
      };

    case actionTypes.FILTER_UNDO_CHANGES:
      return {
        ...state,
        filter: { ...state.history[0] },
        history: [{ ...state.history[0] }]
      };

    case actionTypes.FILTER_SET_LOADING:
      return {
        ...state,
        filterLoading: true
      };
    case actionTypes.FILTER_RESET_LOADING:
      return {
        ...state,
        filterLoading: false
      };

    default:
      return state;
  }
};
