import React from 'react';
import ButtonWithIndicator from '../../../components/common/ButtonWithIndicator';

const DistributeContentTo = ({ onClick, isSaving }) => {
  return (
    <ButtonWithIndicator
      loading={isSaving}
      disabled={isSaving}
      onClick={onClick}
      icon='fal fa-chart-network'
      text='Copy to all'
      colorClass='builder-bg-white builder-text-gray-800'
      borderClass='builder-border builder-border-gray-200'
      className='builder-ml-0 builder-mr-0'
    />
  );
};

export default DistributeContentTo;
