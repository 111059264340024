import React, { useEffect, useState } from 'react';

export const getFieldEditorComponent = async (type) => {
  try {
    return await import(`../editors/${type}Editor`);
  } catch (ex) {
    return await import(`../editors/EditorNotImplemented`);
  }
};

const FieldEditor = ({ block, defaultProps, builderFields, definition, value, onChange, context, breadcrumbs, onBack }) => {
  const [component, setComponent] = useState(null);
  useEffect(() => {
    getFieldEditorComponent(definition.type).then((comp) => {
      setComponent(comp);
    });
  }, [definition]);

  return (
    <React.Fragment>
      {component && (
        <React.Fragment>
          <component.default
            block={block}
            defaultProps={defaultProps}
            builderFields={builderFields}
            definition={definition}
            value={value}
            context={context}
            onChange={(value) => onChange(definition, value)}
            breadcrumbs={breadcrumbs}
            onBack={onBack}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default FieldEditor;
