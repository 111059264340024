import { LOADED_REDIRECT_KEYS_VARIATIONS } from './constants';
import { initialRedirectFromFile } from './detail/reducer';
import { v4 } from 'uuid';

export const formattedRedirectsFromFile = (redirectsFromFile) => {
  return redirectsFromFile.map((item) => {
    return Object.entries(LOADED_REDIRECT_KEYS_VARIATIONS).reduce(
      (result, [standardKey, variations]) => {
        const matchedKey = variations.find((variation) => item[variation] !== undefined);
        if (matchedKey) {
          result[standardKey] = item[matchedKey];
        }
        return result;
      },
      { ...initialRedirectFromFile, id: v4() }
    );
  });
};
