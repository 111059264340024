import { actionTypes } from './reducer';

export const advertisementsSetHandler = (dispatch, advertisements) => {
  dispatch({
    type: actionTypes.ADVERTISEMENTS_SET,
    payload: {
      advertisements
    }
  });
};

export const advertisementDeleteHandler = (dispatch, id) => {
  dispatch({
    type: actionTypes.ADVERTISEMENT_DELETE,
    payload: {
      id
    }
  });
};

export const advertisementsSetLoadingHandler = (dispatch) => {
  dispatch({
    type: actionTypes.ADVERTISEMENTS_SET_LOADING,
    payload: {}
  });
};

export const advertisementsResetLoadingHandler = (dispatch) => {
  dispatch({
    type: actionTypes.ADVERTISEMENTS_RESET_LOADING,
    payload: {}
  });
};
