import React from 'react';
import ButtonWithIndicator from '../../../../../../components/common/ButtonWithIndicator';
import Switch from '../../../../../../components/common/Switch';
import Search from '../../../../../../components/common/Search';
import SorterBadge from '../../../../components/SorterBadge';

const Header = ({ onCancel, showOnlyEditable, onToggleOnlyEditable, onAdd, onSearch, sorter, onClearSorter, disabled, context }) => {
  return (
    <div className='builder-flex builder-justify-between builder-items-center'>
      <div className='builder-flex builder-items-center builder-space-x-4 builder-divide-x builder-divide-gray-200'>
        <Switch context={context} label='Show only editable' isChecked={showOnlyEditable} onChange={onToggleOnlyEditable} />
        <div className='builder-pl-4'>
          <Search onChange={onSearch} dataTestId='pageType-search-input-button' placeholder='Search name, path or match path' />
        </div>
        {sorter && (
          <div data-testid='pageType-sort-filter' className='builder-pl-4'>
            <SorterBadge sorter={sorter} onClear={onClearSorter} dataTestId='pageType-sort-clear-filter-button' />
          </div>
        )}
      </div>

      <div>
        {onAdd && <ButtonWithIndicator onClick={onAdd} dataTestId='page-type-add-button' icon='fal fa-plus' text='Add' disabled={disabled} colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-primary' />}
      </div>
    </div>
  );
};

export default Header;
