import 'dealer-website-components/dist/css/index.css';
import React, { useRef, useState } from 'react';
import BlockContainer from './BlockContainer';
import { DataContextProvider } from 'dealer-website-components';
import { filter } from 'lodash';
import { useDrop } from 'react-dnd';
import { DND_TYPES } from '../../../constants';

export const WebsiteContainer = ({ page, onDrop, onEditContent, onChangeBlockVariant, onDelete, onMoveUp, onMoveDown, campaignFilter, context }) => {
  const [dragOver, setDragOver] = useState(false);
  const [draggingIndex, setDraggingIndex] = useState(null);
  const dropContainerRef = useRef(null);

  const [{ handlerId, isOver }, drop] = useDrop({
    accept: [DND_TYPES.LIST_COMPONENT, DND_TYPES.PAGE_COMPONENT],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: !!monitor.isOver()
      };
    },
    drop(item, monitor) {
      if (!dropContainerRef.current) {
        return;
      }
      // Drop only happens here on empty page
      if (monitor.getItemType() !== DND_TYPES.LIST_COMPONENT) {
        return;
      }

      onDrop({ addedIndex: 0, removedIndex: null, payload: item.component });
    }
  });

  if (!page) {
    return null;
  }

  let blocksToShow = page.blocks;
  if (campaignFilter === 'during_campaign') {
    blocksToShow = filter(page.blocks, (b) => !b.hideForCampaign || (b.hideForCampaign && b.hideForCampaign === false));
  }

  if (campaignFilter === 'no_campaign') {
    blocksToShow = filter(page.blocks, (b) => !b.campaignId || b.hideForCampaign || (b.hideForCampaign && b.hideForCampaign === true));
  }

  drop(dropContainerRef);
  return (
    <div id='website-container' className={`builder-bg-white builder-flex builder-flex-1 builder-relative builder-h-full builder-overflow-x-hidden ${dragOver ? 'builder-border-2 builder-border-blue-600' : 'builder-border-2 builder-border-white'}`}>
      <DataContextProvider withSubscriptions={false}>
        <div className='builder-flex builder-flex-col builder-w-full'>
          {blocksToShow.map((block, i) => {
            return (
              <BlockContainer
                draggingIndex={draggingIndex}
                setDraggingIndex={setDraggingIndex}
                key={i}
                index={i}
                block={block}
                context={context}
                onEditContent={onEditContent}
                onDelete={onDelete}
                onMoveUp={onMoveUp}
                onMoveDown={onMoveDown}
                onChangeBlockVariant={onChangeBlockVariant}
                onDrop={onDrop}
              />
            );
          })}

          {page && page.blocks.length === 0 && (
            <div ref={dropContainerRef} className='builder-flex builder-h-full builder-bg-white builder-flex-col builder-items-center builder-justify-center builder-font-body builder-relative builder-py-20'>
              <span className='builder-text-3xl builder-font-medium'>Start creating a new template by selecting UI components.</span>
              <span className='builder-text-gray-500 builder-text-lg builder-pt-2'>Drag & drop them here. You can change the content by clicking the "Change content" button.</span>

              {isOver && <div className='builder-absolute builder-inset-0 builder-bg-slate-400 builder-z-9999' />}
            </div>
          )}
        </div>
      </DataContextProvider>
    </div>
  );
};

export default WebsiteContainer;
