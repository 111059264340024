import React from 'react';
import { ROW_HEIGHT } from '../reducer';

const Row = ({ title, onEdit, onDelete, onClick, isCollapsible, isCollapsed, isOver, isDragging, canBeDeleted }) => {
  const resolveCollapseIconStyling = () => {
    let className = 'builder-text-md builder-transition-all builder-transform';
    let iconClassName = 'hidden';
    if (isCollapsible !== undefined) {
      if (isCollapsible === true) iconClassName = 'fas fa-caret-up';
      if (isCollapsible === false) iconClassName = 'fal fa-minus';
    }
    if (isCollapsed) className = `${className} builder-rotate-180`;

    return `${className} ${iconClassName}`;
  };

  const resolveStyling = () => {
    let className = 'builder-flex builder-items-center builder-flex-1 builder-h-full builder-space-x-4';
    if (isDragging) className = `${className} builder-opacity-40 builder-shadow-2xl`;
    if (isOver) className = `${className} builder-opacity-50`;
    if (onClick) className = `${className} builder-cursor-pointer`;
    if (isCollapsible !== undefined) className = `${className} builder-pl-4`;

    return {
      className,
      collapseIconClassName: resolveCollapseIconStyling()
    };
  };

  const { className, collapseIconClassName } = resolveStyling();

  return (
    <div className={`builder-w-full builder-items-center builder-transition-all builder-transform builder-relative ${isDragging ? 'builder-hidden' : ''}`} style={{ height: ROW_HEIGHT, marginTop: isOver ? ROW_HEIGHT : 0 }}>
      {isOver && <div className='builder-border builder-border-gray-300 builder-bg-gray-50 builder-opacity-50 builder-border-dashed builder-absolute builder-w-full builder-inset-x-0' style={{ height: ROW_HEIGHT, top: -ROW_HEIGHT }} />}

      <div className='builder-flex builder-items-center builder-w-full builder-h-full builder-bg-white '>
        <div className={`builder-group ${className}`} onClick={onClick ? onClick : undefined}>
          <i className={collapseIconClassName} />

          <i className='fal fa-grip-vertical builder-transition builder-opacity-0 group-hover:builder-opacity-50' />
          <div className='builder-flex builder-flex-1'>
            <span className=''>{title}</span>
          </div>
        </div>

        <div className='builder-flex builder-items-center builder-z-20 builder-pr-3'>
          <div onClick={onEdit} className='builder-flex builder-items-center builder-cursor-pointer hover:builder-text-gray-800 builder-transition-all'>
            <i className='far fa-pencil builder-mr-1' style={{ fontSize: 11, marginTop: -2 }} /> Edit
          </div>
          <div className='builder-mx-2'>|</div>
          <div
            onClick={canBeDeleted ? onDelete : undefined}
            className={`builder-flex builder-items-center builder-transition builder-z-20 ${canBeDeleted ? 'builder-cursor-pointer hover:builder-text-gray-800' : 'builder-opacity-50 builder-cursor-default'}`}
          >
            <i className='fal fa-trash-alt builder-mr-1' style={{ fontSize: 11, marginTop: -2 }} /> Delete
          </div>
        </div>
      </div>
    </div>
  );
};

export default Row;
