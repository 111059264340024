import React, { useState } from 'react';
import { filter, includes, map } from 'lodash';
import { mapArrayToString } from '../../../utils';

const PageTags = ({ pageTags, value, onChange, onAddNewTag, context }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleTagSelect = (key) => {
    let result = value.split(';');
    if (includes(value, key)) {
      //delete tag
      result = filter(value.split(';'), (t) => t !== key);
      onChange(mapArrayToString(result, ';'));
    } else {
      //add tag
      let result = value.split(';');
      result.push(key);
      onChange(mapArrayToString(result, ';'));
    }
  };

  return (
    <div className='builder-flex builder-flex-col'>
      <div className='builder-flex builder-justify-between builder-items-center builder-py-2 builder-border-b builder-border-gray-300 builder-shadow-sm builder-cursor-pointer' onClick={() => setIsOpen(!isOpen)}>
        <h3 className='builder-font-bold builder-text-lg builder-ml-4'>Tags</h3>
        <i className={`fas builder-mr-4 builder-text-md ${isOpen ? 'fa-caret-up' : 'fa-caret-down'}`} />
      </div>
      <div className={`${isOpen ? 'builder-flex builder-flex-col' : 'builder-hidden'}`}>
        <div className=''>
          <div className='builder-flex builder-flex-col builder-p-4'>
            <div className='builder-mt-4'>
              {pageTags?.length === 0 && <div>No tags yet, add one to the list below</div>}
              {map(pageTags, (tag) => {
                const selected = includes(value, tag.key);
                return (
                  <div className='builder-flex builder-items-center'>
                    <input
                      type='checkbox'
                      defaultChecked={selected}
                      onChange={(e) => handleTagSelect(tag.key)}
                      className='builder-h-4 builder-w-4 builder-text-indigo-600 focus:builder-ring-indigo-500 builder-border builder-border-gray-300 builder-rounded-md'
                    />
                    <label className='builder-ml-2 builder-block builder-text-sm builder-text-gray-900 builder-text-md'>{tag.description}</label>
                  </div>
                );
              })}
              {/*<div className='builder-my-4 builder-flex builder-flex-row'>*/}
              {/*  <div className='builder-flex-grow'>*/}
              {/*    <TextInput className='builder-border builder-border-gray-300 builder-rounded-md' value={newTagValue ? newTagValue : ''} onChanged={(value) => setNewTagValue(value)} placeHolder='Enter new tagName' />*/}
              {/*  </div>*/}
              {/*  <ButtonWithIndicator loading={false} onClick={handleAddNewTag} text='Add new tag' colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-blue-500' />*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTags;
