import React, { useState } from 'react';
import * as XLSX from 'xlsx';

const ButtonUploadFromExcel = ({ helperText, disabled, onLoaded }) => {
  const [infoVisible, setInfoVisible] = useState(false);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      readFile(selectedFile);
      e.target.value = ''; // Clear value to be able to add the same file again
    }
  };

  const readFile = (file) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const records = XLSX.utils.sheet_to_json(sheet);
      onLoaded(records);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div className='builder-flex builder-items-center builder-relative'>
      {infoVisible && helperText && <div className='builder-absolute builder-right-[105%] builder-top-0 builder-rounded builder-border builder-border-gray-100 builder-bg-white builder-p-4 builder-w-[250px] builder-z-10'>{helperText}</div>}
      {helperText && <i className='far fa-info-circle builder-mr-2' onMouseOver={() => setInfoVisible(true)} onMouseLeave={() => setInfoVisible(false)} />}

      <div className='builder-overflow-hidden builder-relative'>
        <div
          data-testid='import-from-file-button'
          className={`builder-bg-primary hover:builder-bg-opacity-90 builder-text-white builder-py-2 builder-px-4 builder-w-full builder-inline-flex builder-items-center ${
            disabled ? 'builder-opacity-25 builder-pointer-events-none' : 'builder-cursor-pointer'
          }`}
        >
          <i className='far fa-file-excel' />
          <span className='ml-2'>Upload Excel</span>
        </div>
        <input className='builder-cursor-pointer builder-text-[0px] builder-absolute builder-inset-x-0 builder-bottom-0 builder-top-[-7px] builder-opacity-0' disabled={disabled} accept='.xlsx, .csv' type='file' onChange={handleFileChange} />
      </div>
    </div>
  );
};

export default ButtonUploadFromExcel;
